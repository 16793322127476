import React, {useState} from 'react'
import './CProfileAbout.css'

import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';

import CPAComponent from './component/CPAComponent'
import SFComponent from './SFComponent/SFComponent';

import ArraylistCPA from './ArraylistCPA'
import ArraylistCPAF from './ArraylistCPAF'

import ReactPaginate from 'react-paginate'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import thumbsuporange from '../../../../../images/thumbsuporange.png'
import thumbsdown2 from '../../../../../images/thumbsdown2.png'

export default function CProfileAbout () {

    
// ----------courses---------------------

    // "arraylistarg" is a variable name which can holds Array current values and "i" is index
    const CPAList = ArraylistCPA.map( (arrayarg, i) => {
        return (
            <CPAComponent key={i} 
            id="i" 
            image0={ArraylistCPA[i].image0}
            image1={ArraylistCPA[i].image1}
            image2={ArraylistCPA[i].image2} 
            image3={ArraylistCPA[i].image3} 
            image4={ArraylistCPA[i].image4} 
            star={ArraylistCPA[i].star}
            watch={ArraylistCPA[i].watch}
            play={ArraylistCPA[i].play}
            heading={ArraylistCPA[i].heading}
            image5={ArraylistCPA[i].image5}
            name={ArraylistCPA[i].name}
            price={ArraylistCPA[i].price}
            dprice={ArraylistCPA[i].dprice}
            />
        )
    })

//-----------------feedback , paggination------------------


const [pageNumber, setPageNumber] = useState(0)

    const feedbackPerPage = 3
    const pageVisited = pageNumber * feedbackPerPage

    const CPAFList = ArraylistCPAF
    .slice(pageVisited, pageVisited + feedbackPerPage)
    .map((arrayarg, i) => {
        return (
            
                <SFComponent key={i} 
                id="i" 
                image1={ArraylistCPAF[i].image1}
                image2={ArraylistCPAF[i].image2}
                title={ArraylistCPAF[i].title}
                text1={ArraylistCPAF[i].text1}
                text2={ArraylistCPAF[i].text2}
                />
            

        )
    })
    
    const pageCount = Math.ceil(ArraylistCPAF.length / feedbackPerPage);
    const changePage = ({selected}) => {
        setPageNumber(selected)
    };



    return (

        <>
            {/* -------about part-------- */}
            <div className="p-5 bg-white CPA" style={{borderRadius:"10px"}}>
                <Row className="headingrow">
                        <h4 className="flex-grow-1 CPAheading">About Paul Walker</h4>
                    </Row>
                    <Row className="CPA-R2 py-3">
                        <p>
                        Effortless comfortable full leather lining eye-catching unique detail to the toe low 
                    ‘cut away’ sides clean and sleek. Polished finish elegant court shoe work duty stretchy
                    mid kitten heel this ladylike design slingback strap mid kitten heel this lady like 
                        design. Sharing is who I am, and teaching is where I am at my best, because I’ve been on 
                        both sides of that equation, and getting to deliver useful training is my meaningful 
                        way to  be a part of the creative community.am at my best. Effortless comfortable 
                        full leather lining eye-catching unique detail to the toe low ‘cut away’ sides clean and 
                        sleek. Polished finish elegant court shoe work duty stretchy mid kitten heel this 
                        ladylike design slingback strap mid kitten heel this lady like design. Sharing is who 
                        I am, and teaching is where I am at my best, because I’ve been on both sides of that 
                        equation, and getting to deliver useful training is my meaningful way to  be a part of 
                        the creative community.am at my best,</p>
                    </Row>
            </div>

            {/* -------------courses part---------------------- */}
            <div className="py-3">
                <div className="CPA-R1">
                
                    <h4>Courses</h4>
                </div>
                <div className="row CPA-R2">
                    
                    <div className="col-md-12 col-sm-12 col-12 d-flex">
                            {CPAList}
                    </div>
                </div>
            </div>

            {/* ------------------feedback part---------- */}
            <div className="px-3 CPA-feedback">
                <Row className="d-flex justify-content-between">
                    <div>
                        <h4 style={{fontSize:"30px", fontFamily:"sans-serif"}}>Student Feedback</h4>
                    </div>
                    <div>
                        <Link to='#/' type="button" className="btn dropdown-toggle" 
                            aria-haspopup="true" data-toggle="dropdown"
                            style={{border:"#CAC6D4 solid 1px", }}>
                            High Rated
                        </Link>
                        <span className="dropdown-menu ">
                            <a className="dropdown-item" href="#">Link</a>
                            <a className="dropdown-item" href="#">Link</a>
                            <a className="dropdown-item" href="#">Link 3</a>
                        </span>
                    </div>
                </Row>
                <Row className="bg-white my-3 py-3" style={{borderRadius:"10px"}}>
                    {/* -------------write feedback part-------------------------- */}
                    <div class="col-md-12">
                        <Row className="d-flex justify-content-between pb-2">
                            
                                <Col>
                                    {/* <h5>{props.title}</h5> */}
                                    <div className="d-flex">
                                        <div className="rate pl-0 align-items-center">
                                            <input type="radio" id="star35" name="rate" value="5"/>
                                            <label htmlFor="star5" title="text"></label>
                                            <input type="radio" id="star34" name="rate" value="4"/>
                                            <label htmlFor="star4" title="text"></label>
                                            <input type="radio" id="star33" name="rate" value="3"/>
                                            <label htmlFor="star3" title="text"></label>
                                            <input type="radio" id="star32" name="rate" value="2"/>
                                            <label htmlFor="star2" title="text"></label>
                                            <input type="radio" id="star31" name="rate" value="1"/>
                                            <label htmlFor="star1" title="text"></label>
                                        </div>
                                        {/* <p>a month ago</p> */}
                                    </div>
                                </Col>
                                {/* <Col className="d-flex justify-content-end">
                                    <div className="mr-2">
                                        <img className="px-1" src={thumbsuporange}></img>
                                        <img className="px-1" src={thumbsdown2}></img>
                                    </div>
                                    <div><Link style={{fontSize:"14px", color:"#5F5982"}} to="#/">Report</Link></div>
                                    
                                </Col> */}
                            
                        </Row>
                        <Row className="d-flex flex-column p-3" >
                            <div>
                                <input type="text" className="form-control" style={{background:"#F7F7F7"}}></input>
                            </div>
                            <div className="text-center">
                                <button className="btn my-2" style={{background:"#05CC69", color:"white", fontSize:"14px"}}>Feedback</button>
                            </div>
                        </Row>
                    </div>
                </Row>
                <Row className="py-3">
                    {CPAFList}
                </Row>
                <Row className="py-3 d-flex justify-content-center CPA-pagination">
                    {<ReactPaginate
                            previousLabel={<i class="fas fa-chevron-left"></i>}
                            nextLabel={<i class="fas fa-chevron-right"></i>}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationButtons"}
                            previousLinkClassName={"previousButton"}
                            nextLinkClassName={"nextButton"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />}
                </Row>
            </div>
        </>

    )

}