import React, { useState } from 'react'
import { Row } from 'react-bootstrap'
import './MyCalander.css'
import Calendar from 'react-calendar'

export default function MyCalander () {

    const [date, setDate] = useState(new Date());

    const onChange = date => {
        setDate(date);
    }
    


    return (

        <>

            <div className="calendar-column py-5">
                <Row className="d-flex justify-content-between align-items-center">
                    <div>
                        <h5 className="heading">Calander</h5>
                        <p className="text">You can choose your calander</p>
                    </div>
                    <div>
                        <button className="btn button">New Event</button>
                    </div>
                </Row>
                <Row className="pt-5 cstm-calendar">
                    <Calendar onChange={onChange} value={date}/>
                </Row>
            </div>

        </>

    )

}