import React, {Component, useState} from 'react';
import './Schedule.css'
import {DayPilot, DayPilotScheduler} from "daypilot-pro-react";
import Zoom from "./Zoom";
import {Button, Modal} from 'react-bootstrap'
import { Form, FormLabel } from 'react-bootstrap';
import MyCalander from '../mycalander/MyCalander';
import { Link } from 'react-router-dom';
import addcircle from '../../../../../images/addcircle.png'


class Scheduler extends Component {

    constructor(props) {
        super(props);

        this.state = {
            startDate: "2021-10-01",
            days: 31,
            scale: "Day",
            timeHeaders: [
                { groupBy: "Month"},
                { groupBy: "Day", format: "d"}
            ],
            cellWidthSpec: "Auto",
            cellWidth: 50,
            resources: [
                {name: "Resource A", id: "A"},
                {name: "Resource B", id: "B"},
                {name: "Resource C", id: "C"},
                {name: "Resource D", id: "D"},
                {name: "Resource E", id: "E"},
                {name: "Resource F", id: "F"},
                {name: "Resource G", id: "G"}
            ],
            events: [
                {id: 1, text: "Event 1", start: "2021-10-02T00:00:00", end: "2021-10-05T00:00:00", resource: "A" },
                {id: 2, text: "Event 2", start: "2021-10-03T00:00:00", end: "2021-10-10T00:00:00", resource: "C", barColor: "#38761d", barBackColor: "#93c47d" },
                {id: 3, text: "Event 3", start: "2021-10-02T00:00:00", end: "2021-10-08T00:00:00", resource: "E", barColor: "#f1c232", barBackColor: "#f1c232" },
                {id: 4, text: "Event 4", start: "2021-10-03T00:00:00", end: "2021-10-09T00:00:00", resource: "G", barColor: "#cc0000", barBackColor: "#ea9999" }
            ],
            show: false,
        };


        
    }

    zoomChange(args) {
        switch (args.level) {
            case "month":
                this.setState({
                    startDate: new DayPilot.Date("2021-10-01").firstDayOfMonth(),
                    days: new DayPilot.Date("2021-10-01").daysInMonth(),
                    scale: "Day"
                });
                break;
            case "week":
                this.setState({
                    startDate: new DayPilot.Date("2021-10-01").firstDayOfWeek(),
                    days: 7,
                    scale: "Day"
                });
                break;
            default:
                throw new Error("Invalid zoom level");
        }
    }

    

    cellWidthChange(ev) {
        const checked = ev.target.checked;
        this.setState({
            cellWidthSpec: checked ? "Auto" : "Fixed"
        });
    }

    // openModal1 () {
    //     const [modalIsOpen, setmodalIsOpen] = this.State(flase);
    //     setmodalIsOpen(true);
    // }
    handleModal () {
        this.setState({show: !this.state.show})
    }

    

    render() {
        const {...config} = this.state;

        
        return (

            <>
                <div className="py-3">
                    <div>
                        <div style={{background:"#005D8A", height:"50px"}} className="d-flex align-items-center pl-1">
                            <h5 style={{fontSize:"18px", fontFamily:"Poppins", color:"white"}}>May 6, Monday</h5>
                        </div>
                        <div className="text-end my-2"><button className="btn Mbutton-red" onClick={() => this.handleModal()}>Schedule your class</button></div>
                    </div>
                    <div>

                        <div className="toolbar">
                            <Zoom onChange={args => this.zoomChange(args)} />
                            <span className="toolbar-item"><label><input type="checkbox" checked={this.state.cellWidthSpec === "Auto"} onChange={ev => this.cellWidthChange(ev)} /> Auto width</label></span>
                        </div>

                        <DayPilotScheduler
                        {...config}
                        onEventMoved={args => {
                            console.log("Event moved: ", args.e.data.id, args.newStart, args.newEnd, args.newResource);
                            this.scheduler.message("Event moved: " + args.e.data.text);
                        }}
                        onEventResized={args => {
                            console.log("Event resized: ", args.e.data.id, args.newStart, args.newEnd);
                            this.scheduler.message("Event resized: " + args.e.data.text);
                        }}
                        onTimeRangeSelected={args => {
                            DayPilot.Modal.prompt("New event name", "Event").then(modal => {
                            this.scheduler.clearSelection();
                            if (!modal.result) {
                                return;
                            }
                            this.scheduler.events.add({
                                id: DayPilot.guid(),
                                text: modal.result,
                                start: args.start,
                                end: args.end,
                                resource: args.resource
                            });
                            });
                        }}
                        ref={component => { this.scheduler = component && component.control; }}
                        />
                    </div>

                    <div>
                        
                        <Modal className="cstm-Modal" show={this.state.show} onHide={() => this.handleModal()}>
                            <Modal.Header closeButton>
                                <div className="row col-md-12 d-flex justify-content-center">
                                    <div><h5 className="Mheader">May 6, Monday</h5></div>
                                    
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <div className="row d-flex mb-3">
                                        <div className="d-flex flex-column col-md-6">
                                            <FormLabel className="Mlabel" htmlFor="coursecategory">Student id</FormLabel>
                                            <select className="Mfield" name="year" id="coursecategory" placeholder="Student id">
                                                <option value="1990">1990</option>
                                                <option value="1991">1991</option>
                                                <option value="1992">1992</option>
                                                <option value="1993">1993</option>
                                            </select>
                                        </div>
                                        <div className="d-flex flex-column col-md-4">
                                            <FormLabel className="Mlabel" htmlFor="coursecategory">Time</FormLabel>
                                            <select className="Mfield" name="year" id="coursecategory" placeholder="Student id">
                                                <option value="1990">1990</option>
                                                <option value="1991">1991</option>
                                                <option value="1992">1992</option>
                                                <option value="1993">1993</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row d-flex mb-3">
                                        <div className="d-flex flex-column col-md-10">
                                            <FormLabel className="Mlabel" htmlFor="coursecategory">Select Lecture</FormLabel>
                                            <select className="Mfield" name="year" id="coursecategory" placeholder="Student id">
                                                <option value="1990">1990</option>
                                                <option value="1991">1991</option>
                                                <option value="1992">1992</option>
                                                <option value="1993">1993</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row d-flex flex-column">
                                        <div>
                                            <p className="Mlabel">OR</p>
                                        </div>
                                        <div className="d-flex">
                                            <div className="d-flex flex-column col-md-10">
                                                <FormLabel className="Mlabel" htmlFor="coursecategory">Add extra Lecture</FormLabel>
                                                <select className="Mfield" name="year" id="coursecategory" placeholder="Student id">
                                                    <option value="1990">1990</option>
                                                    <option value="1991">1991</option>
                                                    <option value="1992">1992</option>
                                                    <option value="1993">1993</option>
                                                </select>
                                            </div>
                                            <div className="d-flex justify-content-end align-items-center col-md-2"><Link to='#/'><img src={addcircle}></img></Link></div>
                                            
                                        </div>
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer><Button className="Mbutton" onClick={() => this.handleModal()}>Save</Button></Modal.Footer>
                            
                        </Modal>
                    </div>
                </div>
            </>
            
        );
    }
}

export default Scheduler;
