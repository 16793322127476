import React, { useEffect, useState } from 'react';
import '../feeTypeAPI.css'
// import { Button, Table } from 'semantic-ui-react'
import { Modal } from 'react-bootstrap';
import MUIDataTable from "mui-datatables";
import {MuiThemeProvider, createMuiTheme, Tooltip, IconButton} from "@material-ui/core";
import { PersonAdd } from "@material-ui/icons";

import axios from 'axios';
import UpdateFeeType from './updateFeeType';
import CreateFeeType from './createFeeType';

export default function ReadFeeType() {

    const [modalState, setModalState] = useState(false);

    const manageState = () => {
        setModalState(!modalState)
    }
    const [updateModalState, setUpdateModalState] = useState(false);

    const updateManageState = () => {
        setUpdateModalState(!updateModalState)
    }

    const [APIData, setAPIData] = useState([]);
    const [selectData, setSelectData] = useState('')

    const axiosGetFunc = () => {
        axios.get(`http://zsktech1-001-site1.ftempurl.com/api/FeeType`)
        .then((response) => {
            console.log(response)
            setAPIData(response.data.data);
        })
    }

    useEffect(() => {
        axiosGetFunc();
    }, [])

    const setData = (data) => {
        console.log(data);
        // let { id, firstName, lastName, checkBox } = data;
        // localStorage.setItem('ID', id);
        // localStorage.setItem('First Name', firstName);
        // localStorage.setItem('Last Name', lastName);
        // localStorage.setItem('CheckBox Value', checkBox)
        let { feeTypeId, name, statusId } = data;
        localStorage.setItem('ID', feeTypeId);
        localStorage.setItem('Name', name);
        localStorage.setItem('Status Id', statusId);
     }

     const onDelete = (id) => {
        axios.delete(`http://zsktech1-001-site1.ftempurl.com/api/FeeType/${id}`)
        .then(() => {
            // getData();
            axiosGetFunc();
        })
    }



    


//     const colData = () => {
//         onDelete(Object.values({...countries[tableMeta.rowIndex]})[2])
//   }

    //   const getData = () => {
    //     axios.get(`http://zsktech1-001-site1.ftempurl.com/api/FeeType`)
    //         .then((getData) => {
    //              setAPIData(getData.data.data);
    //          })
    // }
    
    
    // const dataView = APIData.map((data, i) => {
    //     return (
    //                 <Update key={i}
    //                     name={data.name}
    //                     statusId={data.statusId}
    //                 />
    //     )
    // })

   

        

    const columns = [
        {
            name: "feeTypeId",
            label: "FeeType Id",
            options: {
                filter: false,
                display: false,
                }
        },
        {
        name: "name",
        label: "Name",
        options: {
            filter: true,
            sort: true,
            }
        },
        // {
        // name: "statusId",
        // label: "Status Id",
        // options: {
        //     filter: true,
        //     sort: false,
        //     }
        // },
        {
            label: "Status Id",
            name: "statusId",
            options: {
                filter: true,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {

                    let abc = Object.values({...APIData[tableMeta.rowIndex]})[2]
                    console.log("selected Data",abc)

                  return (
                      <select class="form-select" aria-label="Default select example"
                      >
                          <option >--Choose</option>
                          <option selected value={abc}>{abc}</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                    </select>
                  );
                }
              }
        },
        {
            name: "Edit",
            options: {
              filter: true,
              sort: false,
              empty: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                console.log(APIData)
                    return (
                        <div>
                                   <button className="btn edit-btn btn-primary"
                                    onClick={() => {
                                            updateManageState();
                                            let data1 = Object.values({...APIData[tableMeta.rowIndex]})
                                            let obj = Object.assign({'feeTypeId':data1[0],'name':data1[1], 'statusId':data1[2]});
                                            console.log("arrayDATA",obj)
                                            setData(obj)

                                        }
                                        }
                                    >
                                    Edit
                                    </button>
                        </div>
                    )
                }
            }
          },
          {
            name: "Delete",
            options: {
              filter: true,
              sort: false,
              empty: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                //   console.log(value.id)
                  return (
                      
                      <div>
                      
                        <button className="btn btn-danger" 
                        onClick={() => {
                            console.log("hammad",Object.values({...APIData[tableMeta.rowIndex]}))
                            onDelete(Object.values({...APIData[tableMeta.rowIndex]})[0])
                        }}>
                        delete
                        </button>
                        {
                            /* {APIData.map (data => {
                            return (
                                <button className="btn btn-danger"
                            onClick={() => onDelete(data.id)}>Delete</button>
                            )
                        })} */}
    
                            {/* <button className="btn edit-btn btn-danger"
                            onClick={() => onDelete(colData())}
                            >
                            Delete
                            </button> */}
                        
                      </div>
                    
                  )
              }
          }
        },
    ];

   

    {/* const data = [
    { name: "Joe James", company: "Test Corp", city: "Yonkers", state: "NY" },
    { name: "John Walsh", company: "Test Corp", city: "Hartford", state: "CT" },
    { name: "Bob Herm", company: "Test Corp", city: "Tampa", state: "FL" },
    { name: "James Houston", company: "Test Corp", city: "Dallas", state: "TX" },
    ]; */}


    // ADD custom ADD-button => to add new data
    const AddButton = () => (
        <Tooltip disableFocusListener title="Add User">
          <IconButton onClick={() => {manageState()}}>
            <PersonAdd />
          </IconButton>
        </Tooltip>
      ); 

    const options = {
    filterType: 'checkbox',
    customToolbar: AddButton,
    };


    return (
        <>

            <div className="container col-md-12 col-sm-12 col-12 mx-5 py-5 px-4 feeLevel">
                <MUIDataTable
                        title={"Employee List"}
                        data={APIData}
                        columns={columns}
                        options={options}
                        />
            </div>



            {/* <Table singleLine>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Status Id</Table.HeaderCell> */}
                        {/* <Table.HeaderCell>Checked</Table.HeaderCell> */}
                        {/* <Table.HeaderCell>Update</Table.HeaderCell>
                        <Table.HeaderCell>Delete</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {APIData.map((data, i) => {
                        return (
                            <Table.Row key={i}>
                                <Table.Cell>{data.name}</Table.Cell>
                                <Table.Cell>{data.statusId}</Table.Cell> */}
                                {/* <Table.Cell>{data.checkBox ? 'Checked' : 'UnChecked'}</Table.Cell> */}
                                {/* <Table.Cell> 
                                    <Button onClick={() => {manageState() ; setData(data)}}>Update</Button>
                                </Table.Cell>
                                <Table.Cell>
                                    <Button onClick={() => onDelete(data.id)}>Delete</Button>
                                </Table.Cell>
                            </Table.Row>
                        )
                    })}
                    
                </Table.Body>
            </Table> */}

            
            <Modal className="cstmAPI-Modal" show={updateModalState} onHide={() => updateManageState()}>
                <Modal.Header closeButton >
                    <div className="row d-flex px-3">
                    <div><h5 className="header">Details</h5></div>

                    </div>
                </Modal.Header>
                <Modal.Body>
                    <UpdateFeeType/> 
                    {/* {dataView} */}
                </Modal.Body>
            </Modal>
            <Modal className="cstmAPI-Modal" show={modalState} onHide={() => manageState()}>
                <Modal.Header closeButton >
                    <div className="row d-flex px-3">
                    <div><h5 className="header">Details</h5></div>

                    </div>
                </Modal.Header>
                <Modal.Body>
                    <CreateFeeType/>
                </Modal.Body>
            </Modal>
        </>
    )
}