import React from 'react';
import './AboutUs.css';
import Navbar from '../Header/NavbarComponent/NavbarComponent';
import SkillfyPowers from './Components/SkillfyPowers/SkillfyPowers';
import KnowAboutBest from './Components/KnowAboutBest/KnowAboutBest';
import KnowAboutLearning from './Components/KnowAboutLearning/KnowAboutLearning';
import IBelieve from './Components/Ibelieve/Ibelieve';
import LearnMore from './Components/LearnMore/LearnMore';
import BecomeOrConstruct from './Components/BecomeOrConstruct/BecomeOrConstruct';
import CustomFooter from '../CustomFooter/CustomFooter';


const AboutUs = () => {
    return (
        <div className="main_wrapper">
            <Navbar />
            <SkillfyPowers />
            <KnowAboutBest />
            <KnowAboutLearning />
            <IBelieve />
            <LearnMore />
            <BecomeOrConstruct />
            <CustomFooter />
        </div>
    )
}

export default AboutUs;