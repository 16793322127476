import React from 'react'
import './LecturesHistory.css'

export default function LecturesHistory () {


    return (

        <></>

    )

}