import React from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import Popper from 'popper.js';
import '../GetYourCourses/GetYourCourses.min.css';
import GetYourCoursesRow2Component from '../GetYourCourses/GetYourCoursesRow2component/GetYourCoursesRow2component';

import { Link } from 'react-router-dom';

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'


import Courseimage1 from '../../../images/Courseimage1-min.png'
import Courseimage2 from '../../../images/Courseimage2-min.png'
import Courseimage3 from '../../../images/Courseimage3-min.png'

const GetYourCourses = () => {

    const settings = {
        dots:true,
        centerMode: true,
        arrows:false,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        infinite:true,
        speed:500,
        slidesToShow: 3,
        slidesToScroll: 1,
        cssEase: "linear",
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    cssEase: "linear",
                    focusOnSelect: true,
                    arrows:false,
                    autoplay: true,
                    pauseOnHover: true,
                    dots: true,
                    autoplaySpeed: 5000,
                    speed:500,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    cssEase: "linear",
                    focusOnSelect: true,
                    arrows:false,
                    autoplay: true,
                    pauseOnHover: true,
                    dots: true,
                    autoplaySpeed: 5000,
                    speed:500,
                }
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    cssEase: "linear",
                    focusOnSelect: true,
                    arrows:false,
                    autoplay: true,
                    pauseOnHover: true,
                    dots: true,
                    autoplaySpeed: 5000,
                    speed:500,
                }
            }
        ]
    }

    


    

    return (


            <div className="container-fluid GetYourCourses-containerfluid-1 ">
            <div className="container">
                <div className="row r1 py-4 align-items-center">
                    <div className="col-md-9 c1 my-4">
                        <h4 className="h4-custom mb-0">Get choice of your courses</h4>
                    </div>
                    <div className="col-md-3 c2 d-flex justify-content-between align-items-center">
                        <div className="dropdown GetYourCourses-dropdown">
                            <button type="button" className="btn cstm-button dropdown-toggle" data-toggle="dropdown">
                                Design
                            </button>
                            <div className="dropdown-menu">
                                <a className="dropdown-item" href="#">Link 1</a>
                                <a className="dropdown-item" href="#">Link 2</a>
                                <a className="dropdown-item" href="#">Link 3</a>
                            </div>
                        </div>
                        <Link to='/ViewAllCourses'>
                            <button className="btn cstm-button cstm-VAbtn btn-md px-4">View all</button>
                        </Link>
                    </div>
                    
                    
                </div>
                <div className="row pb-4 ">
                    <Slider {...settings} className="col-md-12 col-sm-12 col-12">
                        <Link to="/CourseStudent">
                            <GetYourCoursesRow2Component id="1" src={Courseimage1} name="Web design"/>
                        </Link>
                        <Link to="/CourseStudent">
                            <GetYourCoursesRow2Component id="2" src={Courseimage2} name="Python"/>
                        </Link>
                        <Link to="/CourseStudent">
                            <GetYourCoursesRow2Component id="3" src={Courseimage3} name="JAVA"/>
                        </Link>
                        <Link to="/CourseStudent">
                            <GetYourCoursesRow2Component id="4" src={Courseimage1} name="SQL"/>
                        </Link>
                        <Link to="/CourseStudent">
                            <GetYourCoursesRow2Component id="5" src={Courseimage2} name="PHP"/>
                        </Link>
                        <Link to="/CourseStudent">
                            <GetYourCoursesRow2Component id="6" src={Courseimage3} name="C++"/>
                        </Link>
                        
                        
                    </Slider>
                </div>
                
            </div>
        </div>
            


        
    )
}

export default GetYourCourses;