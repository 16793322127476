import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './CourseDetails.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import Star from '../../../../images/Star.png';
import Layer8 from '../../../../images/Layer8.png';
import Layer9 from '../../../../images/Layer9.png';
import Duration from '../../../../images/Duration.png';
import Book from '../../../../images/book.png';
import TV from '../../../../images/tv.png';
import Stopw from '../../../../images/stopw.png';
import Window from '../../../../images/window.png';
import Player from 'video-react/lib/components/Player';
import Instagram from '../../../../images/Instagram.png';
import Facebook1 from '../../../../images/facebook1.png';
import Whatsapp1 from '../../../../images/whatsapp1.png';
import Twitter1 from '../../../../images/twitter1.png';
import Linkedin1 from '../../../../images/linkedin1.png';
import Youtube1 from '../../../../images/youtube1.png';
import reddit from '../../../../images/reddit.png';
import { Link } from 'react-router-dom';
import Overview from './Components/Overview/Overview';
import Curriculum from './Components/Curriculum/Curriculum';
import Review from './Components/Review/Review';

export default function CourseDetails (props) {



    return (

        <>
            <div className="col-md-12 bg-white">
                <div className="container">
                    <Row className="p-3 CD-R1">
                        <h3 className="my-title">Adobe Masterclass Photoshop, Illustrator, XD & InDesign</h3>
                        <div className="col-md-6 d-flex pl-0 py-2">
                            <Col className="px-0">
                                <div className="d-flex mb-2">
                                    <img src={Star}></img>
                                    <p>4.5 (4.5 (1,348 ratings)</p>
                                </div>
                                <div className="d-flex">
                                    <img src={Duration}></img>
                                    <p>Duration 10 week</p>
                                </div>         
                            </Col>
                            <Col className="px-0">
                                <div className="d-flex mb-2">
                                    <img src={Layer8}></img>
                                    <p>Enrolled 45 students</p>
                                </div>
                                <div className="d-flex">
                                    <img src={Layer9}></img>
                                    <p>36 Lesion</p>
                                </div>    
                            </Col>
                        </div>
                    </Row>
                    <Row>
                        <Col className="col-md-7">
                            <div className="my-4">
                                <Player
                                playsInline
                                poster="/assets/poster.png"
                                src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                                className="custom-video"
                                />
                            </div>
                            <div className="cstm-tab">
                                <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item mr-2">
                                        <a className="nav-link active" data-toggle="tab" href="#tabs-6" role="tab">Overview</a>
                                    </li>
                                    <li className="nav-item mr-2">
                                        <a className="nav-link" data-toggle="tab" href="#tabs-7" role="tab">Cirriculum</a>
                                    </li>
                                    <li className="nav-item mr-2">
                                        <a className="nav-link" data-toggle="tab" href="#tabs-8" role="tab">Instructor</a>
                                    </li>
                                    <li className="nav-item mr-2">
                                        <a className="nav-link" data-toggle="tab" href="#tabs-9" role="tab">Reviews</a>
                                    </li>
                                </ul>
                                {/* <!-- Tab panes --> */}
                                <div className="tab-content">
                                    <div className="tab-pane active my-5" id="tabs-6" role="tabpanel">
                                        <Overview />
                                    </div>
                                    <div className="tab-pane" id="tabs-7" role="tabpanel">
                                        <Curriculum />
                                    </div>
                                    <div className="tab-pane" id="tabs-8" role="tabpanel">
                                    <p>third Panel</p>
                                    </div>
                                    <div className="tab-pane" id="tabs-9" role="tabpanel">
                                        
                                        <Review />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className="my-card col-md-5">
                            <div className="card my-4 px-4">
                                <div className="card-header">
                                    <h4>$49.65</h4>
                                </div>
                                <div className="card-body">
                                    <div className="card-title">This course includes</div>
                                    <div className="card-text mb-1"><img src={Book}></img>Language - Englisha</div>
                                    <div className="card-text mb-1"><img src={TV}></img>Use on desktop, tablet & mobile</div>
                                    <div className="card-text mb-1"><img src={Stopw}></img>Full lifetime access</div>
                                    <div className="card-text mb-1"><img src={Window}></img>Certificate of Completion</div>
                                </div>
                                <div className="card-footer cstm-card-footer bg-white pr-0">
                                    <div className="card-title">Share this course</div>
                                    <Link className="mr-1" to="#/"><img src={Instagram}></img></Link>
                                    <Link className="mr-1" to="#/"><img src={Facebook1}></img></Link>
                                    <Link className="mr-1" to="#/"><img src={Whatsapp1}></img></Link>
                                    <Link className="mr-1" to="#/"><img src={Twitter1}></img></Link>
                                    <Link className="mr-1" to="#/"><img src={Linkedin1}></img></Link>
                                    <Link className="mr-1" to="#/"><img src={Youtube1}></img></Link>
                                    <Link className="mr-1" to="#/"><img src={reddit}></img></Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

        </>


    )
        

}