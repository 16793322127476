import React , {useState} from 'react'
import { Col, Row } from 'react-bootstrap'
import './Dashboard.css'

import UnpaidStd from '../DBfinance/components/UnpaidStd/UnpaidStd'
import LineChart from '../DBfinance/components/LineChart/LineChart'
import Piechart from './components/Piechart/Piechart'
import RecentStudents from './components/RecentStudents/RecentStudents'
import Calendar from 'react-calendar'
import Cards from './components/Cards/Cards'
import Cards2 from './components/Cards2/Cards2'
import VideoList from './components/VideoList/VideoList'
import StdSummary from './components/StdSummary/StdSummary'
import DoughntChart from './components/DoughntChart/DoughntChart'

import whitepeople from '../../../../images/whitepeople.png'
import whitecap from '../../../../images/whitecap.png'
import icoption from '../../../../images/icoption.png'
import profilePicture111 from '../../../../images/profilePicture111.png'

const Dashboard = (props) => {

    const [date, setDate] = useState(new Date());

    const onChange = date => {
        setDate(date);
    }

    // card
    const CardsArrayList = [
        {
            id:1,
            image1: whitepeople,
            number: "9.825",
            text1: "Total Students",
            text2: "+0,5% than last month",
            style: {
                background: "#C61CCA",
            }
           
        },
        {
            id:2,
            image1: whitecap,
            number: "653",
            text1: "Total Students",
            text2: "-2% than last month",
            style: {
                background: "#093CBD",
            }
            
        },
        
    ]

    // mapping

    const CardList = CardsArrayList.map( ( arg, i) => {
        return (
            <Cards key={i} 
            id="i"
            image1={CardsArrayList[i].image1}
            number={CardsArrayList[i].number}
            text1={CardsArrayList[i].text1} 
            text2={CardsArrayList[i].text2}
            style={CardsArrayList[i].style}  
            
            
            />
        )
    })


    // card
    const CardsArrayList2 = [
        {
            id:1,
            chart: <DoughntChart />,
            number: '345',
            text1: "Events",
            // text2: "+0,5% than last month",
            // style: {
            //     background: "#C61CCA",
            // }
           
        },
        {
            id:2,
            chart: <DoughntChart />,
            number: '4,563',
            text1: "Videos",
            // text2: "-2% than last month",
            // style: {
            //     background: "#093CBD",
            // }
            
        },
        
    ]

    // mapping

    const CardList2 = CardsArrayList2.map( ( arg, i) => {
        return (
            <Cards2 key={i} 
            id="i"
            chart={CardsArrayList2[i].chart}
            number={CardsArrayList2[i].number}
            text1={CardsArrayList2[i].text1} 
            // text2={CardsArrayList[i].text2}
            // style={CardsArrayList[i].style}  
            
            
            />
        )
    })


    // recent students
    const ArrayListRecentStudents = [
        {
            id:1,
            image1: profilePicture111,
            name: "Dawud Khan",
            ID:" ID 0012345678",
            
            
        },
        {
            id:2,
            image1: profilePicture111,
            name: "Dawud Khan",
            ID:" ID 0012345678",
            
            
        },
        {
            id:3,
            image1: profilePicture111,
            name: "Dawud Khan",
            ID:" ID 0012345678",
            
            
        },
        {
            id:4,
            image1: profilePicture111,
            name: "Dawud Khan",
            ID:" ID 0012345678",
            
            
        },
        {
            id:5,
            image1: profilePicture111,
            name: "Dawud Khan",
            ID:" ID 0012345678",
            
            
        },
        {
            id:6,
            image1: profilePicture111,
            name: "Dawud Khan",
            ID:" ID 0012345678",
            
            
        },
     
    ]


    // "arraylistarg" is a variable name which can holds Array current values and "i" is index
    const RecentStudentsList = ArrayListRecentStudents.map( (arg, i) => {
        return (
            <RecentStudents key={i} 
            id="i"
            ID={ArrayListRecentStudents[i].ID}
            image1={ArrayListRecentStudents[i].image1}
            name={ArrayListRecentStudents[i].name}
            />
        )
    })

    return (
        <>
            <div className="container Dashboard col-md-12 p-4">
                <Row>
                    <Col className="col-md-6">
                        <div className="d-flex">
                            {CardList}
                        </div>
                        <div className="d-flex">
                            {CardList2}
                        </div>
                        <div className="calendarRow p-2 my-3">
                            <Calendar onChange={onChange} value={date}/>
                        </div>
                        <div>
                            <UnpaidStd />
                        </div>
                    </Col>
                    <Col className="col-md-6">
                        <div className="chart-row p-2">
                            <div className="col-md-12 d-flex align-items-center">
                                <div className="flex-grow-1">
                                    <h5 className="heading">Finance</h5>
                                </div>
                                <div>
                                <img className="dropdown-toggle" aria-haspopup="true" data-toggle="dropdown" src={icoption}></img>
                                            <span className="dropdown-menu cstm-CQuiz-dropdownmenu">
                                            <a className="dropdown-item" href="#">Quiz</a>
                                            <a className="dropdown-item" href="#">Edit</a>
                                            <a className="dropdown-item" href="#">Delete</a>
                                            </span>
                                </div>
                            </div>
                            <div>
                                <LineChart />
                            </div>   
                        </div>
                        
                        
                        <div className="doughntchart-row p-2 my-3">
                            <div className="col-md-12 d-flex justify-content-between">
                                <div className="flex-grow-1"><h5 className="heading">Pie Chart</h5></div>
                                <div>
                                <img className="dropdown-toggle" aria-haspopup="true" data-toggle="dropdown" src={icoption}></img>
                                            <span className="dropdown-menu cstm-CQuiz-dropdownmenu">
                                            <a className="dropdown-item" href="#">Quiz</a>
                                            <a className="dropdown-item" href="#">Edit</a>
                                            <a className="dropdown-item" href="#">Delete</a>
                                            </span>
                                </div>
                            </div>
                            <div className="d-flex doughntchart py-3">
                                <div className="col-md-12 d-flex">
                                    <Piechart />
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="col">
                                    <h5 className="heading">Increase App profile strength</h5>
                                    <p className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
                                    tempor incididunt ut labore et dolore magna</p>
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                    <button className="btn button">Learn More</button>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <Row>
                                <Col className="col-md-6">
                                    <div>
                                        <StdSummary />
                                    </div>
                                    <div className="VideoList">
                                        <VideoList/>
                                    </div>
                                </Col>
                                <Col className="col-md-6 RecentStudents p-2">
                                    <div className="align-items-start d-flex flex-column">
                                        <h5 className="heading">Recent Students</h5>
                                        <p className="text">You have 245 clients</p>
                                    </div>
                                    <div className="py-2">{RecentStudentsList}</div>
                                    <div className="text-center">
                                        <button className="btn button1">Learn More</button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
            
        </>
    )
}

export default Dashboard
