import React from 'react'
import './CQuizResult.css'

export default function CQuizResult (props) {


    return (
        <>
            <div className="CQuizResult-container">
                
                <div>
                    <table class="table table-hover">
                        {/* <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">First</th>
                            <th scope="col">Last</th>
                            <th scope="col">Handle</th>
                            </tr>
                        </thead> */}
                        <tbody>
                            <tr>
                            <th scope="row"><img src={props.Ellipse62}></img></th>
                            <td>{props.title}</td>
                            <td><img src={props.questionanswer}></img>{props.qa}</td>
                            <td><img src={props.timer}></img>{props.time}</td>
                            <td><img src={props.cross}></img>{props.one}</td>
                            <td><img src={props.check}></img>{props.nine}</td>
                            <td><img src={props.list}></img>{props.per}</td>
                            {/* <td>
                                <span>
                                    <img src={props.questionanswer}></img>{props.qa}
                                </span>
                                <span>
                                    <img src={props.timer}></img>{props.time}
                                </span>
                                <span>
                                    <img src={props.cross}></img>{props.one}
                                </span>
                                <span>
                                    <img src={props.check}></img>{props.nine}
                                </span>
                                <span>
                                    <img src={props.list}></img>{props.per}
                                </span>
                                
                            </td> */}
                            {/* <td><img src={props.timer}></img>{props.time}</td> */}
                            <td className="text-end">
                                
                                <img className="dropdown-toggle" aria-haspopup="true" data-toggle="dropdown" src={props.Group3dots}></img>
                                <span className="dropdown-menu cstm-CQuizResult-dropdownmenu">
                                <a className="dropdown-item" href="#">Quiz</a>
                                <a className="dropdown-item" href="#">Edit</a>
                                <a className="dropdown-item" href="#">Delete</a>
                                </span>
                            </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
            </div>
        </>
    )

}