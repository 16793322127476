import React from 'react'
import './DiscountTypeAPI.css'



import ReadDiscountType from './Components/readDiscountType'

const DiscountTypeAPI = () => {
    return (
        <>
            <div className="container cstm-one">
                <div>
                    <ReadDiscountType/>
                </div>
            </div>
            
        </>
    )
}

export default DiscountTypeAPI;
