import React, {useState} from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './TeacherReview.css'
import Thumbup from '../../../images/thumbup.png'
import Thumbdown from '../../../images/thumbdown.png'
import TRcomponent from './component/TRcomponent'
import ReactPaginate from 'react-paginate'

export default function TeacherReview () {

    const ArraylistTR = [
        {
            id:1,
            image1:Thumbup,
            image2: Thumbdown,
            title: "Wynton McCurdy",
            text1: "a month ago",
            text2: "Very specific course, but it definitely has value. Created a freqtrade rading bot from their other course, and this makes it super easy to install on a raspberry (in my case). I had no experience with a raspberry before. With the scripts provided the bot will automatically start in case of no connection or no power, which is awesome, so I won’t have to worry about that anymore. Definitely recommend",
        },
        {
            id:2,
            image1:Thumbup,
            image2: Thumbdown,
            title: "Wynton McCurdy",
            text1: "a month ago",
            text2: "Very specific course, but it definitely has value. Created a freqtrade rading bot from their other course, and this makes it super easy to install on a raspberry (in my case). I had no experience with a raspberry before. With the scripts provided the bot will automatically start in case of no connection or no power, which is awesome, so I won’t have to worry about that anymore. Definitely recommend",
        },
        {
            id:3,
            image1:Thumbup,
            image2: Thumbdown,
            title: "Wynton McCurdy",
            text1: "a month ago",
            text2: "Very specific course, but it definitely has value. Created a freqtrade rading bot from their other course, and this makes it super easy to install on a raspberry (in my case). I had no experience with a raspberry before. With the scripts provided the bot will automatically start in case of no connection or no power, which is awesome, so I won’t have to worry about that anymore. Definitely recommend",
        },
    ]


    const [pageNumber, setPageNumber] = useState(0)

    const feedbackPerPage = 3
    const pageVisited = pageNumber * feedbackPerPage

    const TRList = ArraylistTR
    .slice(pageVisited, pageVisited + feedbackPerPage)
    .map((arrayarg, i) => {
        return (
            
            <TRcomponent key={i} 
            id="i" 
            image1={ArraylistTR[i].image1}
            image2={ArraylistTR[i].image2}
            title={ArraylistTR[i].title}
            text1={ArraylistTR[i].text1}
            text2={ArraylistTR[i].text2}
            />
            

        )
    })
    
    const pageCount = Math.ceil(ArraylistTR.length / feedbackPerPage);
    const changePage = ({selected}) => {
        setPageNumber(selected)
    };


    return (


        <>

                <div className="container TR-container px-0">
                    <Row className="d-flex justify-content-between px-3">
                        <div>
                            <h4>Student Feedback</h4>
                        </div>
                        <div>
                            <Link to='#/' type="button" className="btn dropdown-toggle" aria-haspopup="true" data-toggle="dropdown">
                                High Rated
                            </Link>
                            <span className="dropdown-menu ">
                                <a className="dropdown-item" href="#">Link</a>
                                <a className="dropdown-item" href="#">Link</a>
                                <a className="dropdown-item" href="#">Link 3</a>
                            </span>
                        </div>
                    </Row>
                    <Row className="py-3 cstm-row2">
                        {TRList}
                    </Row>
                    <Row className="py-3 d-flex justify-content-center TR-pagination">
                    {<ReactPaginate
                            previousLabel={<i class="fas fa-chevron-left"></i>}
                            nextLabel={<i class="fas fa-chevron-right"></i>}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationButtons"}
                            previousLinkClassName={"previousButton"}
                            nextLinkClassName={"nextButton"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />}
                    </Row>
                </div>

        </>


    )


}