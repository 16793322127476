import React from 'react'
import './TableComponent.css'
import TableStructure from './TableStructure/TableStructure'
import buy from '../../../../../../../images/buy.png'

const TableComponent = () => {


    const ArrayListTeacherTableComponent = [
        {
            id:1,
            image: buy,
            ID:"#0012345678",
            time: "06:24:45 AM",
            output: "+$5,553",
            
        },
        {
            id:2,
            image: buy,
            ID:"#0012345678",
            time: "06:24:45 AM",
            output: "+$5,553",
            
        },
        {
            id:3,
            image: buy,
            ID:"#0012345678",
            time: "06:24:45 AM",
            output: "+$5,553",
            
        },
        {
            id:4,
            image: buy,
            ID:"#0012345678",
            time: "06:24:45 AM",
            output: "+$5,553",
            
        },
        {
            id:5,
            image: buy,
            ID:"#0012345678",
            time: "06:24:45 AM",
            output: "+$5,553",
            
        },
        {
            id:6,
            image: buy,
            ID:"#0012345678",
            time: "06:24:45 AM",
            output: "+$5,553",
            
        },
     
    ]


    // "arraylistarg" is a variable name which can holds Array current values and "i" is index
    const TeacherTableList = ArrayListTeacherTableComponent.map( (arg, i) => {
        return (
            <TableStructure key={i} 
            id="i"
            ID={ArrayListTeacherTableComponent[i].ID}
            image={ArrayListTeacherTableComponent[i].image}
            time={ArrayListTeacherTableComponent[i].time} 
            output={ArrayListTeacherTableComponent[i].output}
            />
        )
    })

    return (
        <>
            <div className="bg-white">
                <div className="Tablestructurefinance">
                    <table class="table tbl-style">
                        <tbody>
                            {TeacherTableList}
                        </tbody>
                    </table>
                </div>
            </div>
            
        </>
    )
}

export default TableComponent
