import React from 'react'
import './CCAssessment.css'

export default function CCAssessment (props) {


    return (
        <>
            <div className="CCAssessment-container">
                
                <div>
                    <table class="table table-hover">
                        <tbody>
                            <tr>
                            <th scope="row"><img src={props.Ellipse62}></img></th>
                            <td>{props.title}</td>
                            <td><img src={props.pdf}></img>{props.FileName}</td>
                            <td><img src={props.timer}></img>{props.time}</td>
                            <td>{props.date}</td>
                            <td className="text-end">
                                
                                <img className="dropdown-toggle" aria-haspopup="true" data-toggle="dropdown" src={props.Group3dots}></img>
                                <span className="dropdown-menu cstm-CCAssessment-dropdownmenu">
                                <a className="dropdown-item" href="#">View</a>
                                <a className="dropdown-item" href="#">Edit</a>
                                <a className="dropdown-item" href="#">Delete</a>
                                </span>
                            </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
            </div>
        </>
    )

}