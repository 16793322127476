import React, {useState} from 'react'
import './CQuizes.css'
import {Button, Form, Modal, FormLabel} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import SubjQuiz from '../SubjQuiz/SubjQuiz'
import MyQuiz from '../../../MyQuiz'

// import SubjQuiz from './Components/Quizes/SubjQuiz/SubjQuiz'

export default function CQuizes (props) {

    // const [currentComponent, setCurrentComponent] = useState("Quizes");

    // const subjQuizButton = () => {
    //     return (
    //         <>
    //          <MyQuiz data={subjQuizView} />      
    //          {/* <button className="btn btn-primary" onClick={() => setCurrentComponent("SubjQuiz")}>subjquizes</button> */}
    //         </>
    //     )
    // }

    // const subjQuizView = () => {
    //     return(
    //         <>
    //             {currentComponent == "SubjQuiz" && <SubjQuiz/>}
    //         </>
    //     )
    // }

    // <MyQuiz data={subjQuizView} />

    // const SubjQuiz = <SubjQuiz/>

    // const [currentComponent, setCurrentComponent] = useState("SubjQuiz");
    // const show = currentComponent == "SubjQuiz" && <SubjQuiz/>
    // const showFunc = () => {
    //     return (
            
    //             setCurrentComponent(<SubjQuiz/>)
            
    //     )
    // }

       


    return (
        <>
            <div className="CQuizes-container">
                
                <div>
                    <table class="table table-hover">
                        <tbody>
                            <tr>
                            <th scope="row"><img src={props.Ellipse62}></img></th>
                            <td>{props.title}</td>
                            <td className="text-end"><img src={props.questionanswer}></img>{props.qa}</td>
                            <td><img src={props.timer}></img>{props.time}</td>
                            <td className="text-end">
                                
                                <img className="dropdown-toggle" aria-haspopup="true" data-toggle="dropdown" src={props.Group3dots}></img>
                                <span className="dropdown-menu cstm-CQuiz-dropdownmenu">
                                    <ul className="nav-tab">
                                        <li className="nav-item">
                                            {/* <a className="nav-link" data-toggle="tab" href="#subjQuiz" role="tab">Quiz</a> */}
                                            {/* <a className="btn">Quize</a> */}
                                            {/* <button className="btn btn-primary" onClick={() => props.showFunc}>subjquizes</button> */}
                                            {/* <subjQuizButton/> */}
                                            {/* <button className="btn btn-primary" onClick={() => setCurrentComponent("SubjQuiz")}>subjquizes</button> */}
                                            <button className="btn btn-primary">subjquizes</button>
                                            {/* <MyQuiz view={showFunc} /> */}
                                            {/* {props.button} */}
                                            
                                            {/* <SubjQuiz/> */}
                                        </li>
                                        <li className="nav-item">
                                            <a className="dropdown-item" href="#">Edit</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="dropdown-item" href="#">Delete</a>    
                                        </li>
                                    </ul>
                                
                                {/* <a className="dropdown-item" href="#">Quiz</a> */}
                                {/* <a className="dropdown-item" href="#">Edit</a>
                                <a className="dropdown-item" href="#">Delete</a> */}
                                </span>
                            </td>
                            </tr>
                        </tbody>
                    </table>
                    {/* <MyQuiz data={showFunc}/> */}
                    {/* <MyQuiz data={<subjQuizView/>} />   */}
                </div>


                <Modal className="cstm-Modal">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <div className="row d-flex mb-3">
                                <div className="d-flex flex-column col-md-6">
                                    <img src={props.Ellipse62}></img>
                                    <div>
                                        <FormLabel className="CQMlabel" htmlFor="coursecategory">{props.title}</FormLabel>
                                        <select className="CQMfield" name="year" id="coursecategory" placeholder="Student id">
                                            <option value="1990">1990</option>
                                            <option value="1991">1991</option>
                                            <option value="1992">1992</option>
                                            <option value="1993">1993</option>
                                        </select>
                                    </div>
                                    <div>
                                        <FormLabel className="CQMlabel" htmlFor="coursecategory">Duration</FormLabel>
                                        <select className="CQMfield" name="year" id="coursecategory" placeholder="Student id">
                                            <option value="1990">1990</option>
                                            <option value="1991">1991</option>
                                            <option value="1992">1992</option>
                                            <option value="1993">1993</option>
                                        </select>
                                    </div>
                                    
                                </div>
                            </div>                                
                        </Form>
                    </Modal.Body>
                    
                </Modal>
                
            </div>
        </>
    )

}