import React, { useState } from 'react'
import { Button, Checkbox, Form } from 'semantic-ui-react'
// import { useHistory } from 'react-router';

import axios from 'axios';

const CreateCity = () => {

    
    const [countryId, setCountryId] = useState('');
    const [name, setName] = useState('');
    const [county, setCounty] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [areaCode, setAreaCode] = useState('');

    

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(e)
    }

    
    const postData = () => {
        
        // console.log(name);
        // console.log(Country);

        // axios.post(`https://616078fdfaa03600179fbaa7.mockapi.io/fakeData` 
            axios.post(`http://zsktech1-001-site1.ftempurl.com/api/City`, {
            countryId,
            name,
            county,
            zipCode,
            areaCode
        })
        // .then(() => {
        //     history.push('/read')
        // })


    }


    return (
        <>
            <Form className="create-form" onSubmit={handleSubmit}>
                <Form.Field>
                    <label>Country Id</label>
                    <input placeholder='Country Id'
                        name="countryId"
                        onChange={(e) => setCountryId(e.target.value)}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Name</label>
                    <input placeholder='Name'
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Country</label>
                    <input placeholder='Country' 
                        name="county"
                        onChange={(e) => setCounty(e.target.value)}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Zip Code</label>
                    <input placeholder='Zip Code' 
                        name="zipCode"
                        onChange={(e) => setZipCode(e.target.value)}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Area Code</label>
                    <input placeholder='Area Code' 
                        name="areaCode"
                        onChange={(e) => setAreaCode(e.target.value)}
                    />
                </Form.Field>
                {/* <Form.Field>
                <Checkbox label='I agree to the Terms and Conditions'
                onChange={(e) => setCheckBox(!checkBox)} />
                </Form.Field> */}
                <Button className="btn cstm-Submitbtn" type='submit' onClick={postData}>Submit</Button>
            </Form>
        </>
    )
}

export default CreateCity;