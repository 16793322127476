import React from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import Popper from 'popper.js';
import './BecomeOrConstruct.css';

import Tada from 'react-reveal/Tada';

const BecomeOrConstruct = () => {
    return (
        <div className="container-fluid BecomeOrConstruct-container-fluid">
            <div className="container">
                <div className="row">
                    <Tada>
                    <div className="col-md-6 cstm-col">
                        <div className="BecomeOrConstruct-Teachercolumn">
                            <div className="Teachercolumn-img mr-3"><img src=""></img>
                            </div>
                            <div className="Teachercolumn-text">
                                <h5>Beome a Teacher</h5>
                                <p>Teach what you love. Coursector gives you the <br /> tools to create a course.</p>
                                <button className="btn btn-danger btn-danger-cstm">Apply as teacher</button>
                            </div>
                        </div>
                    </div>
                    </Tada>
                    <Tada>
                    <div className="col-md-6 cstm-col">
                        <div className="BecomeOrConstruct-Teachercolumn">
                            <div className="Teachercolumn-img mr-3"><img src=""></img></div>
                            <div className="Teachercolumn-text">
                                <h5>Constructor for Bussiness</h5>
                                <p>Get unlimited access to 3,000+ of Coursector’s <br /> top courses for your team</p>
                                <button className="btn btn-danger btn-danger-cstm">Get skillfy for bussiness</button>
                            </div>
                        </div>
                    </div>
                    </Tada>

                </div>
                
                
            </div>
        </div>
    )
}

export default BecomeOrConstruct;