import React from 'react'
import './EduandDeals.css'
import Path65 from '../../../../../images/Path65.png'
import Path66 from '../../../../../images/Path66.png'
import SideImage from '../../../../../images/SideImage.png'
import EduandDealsComp from './EduandDealsComp/EduandDealsComp'

export default function EduandDeals () {


    //-------------------Education offers and deals--------------

    const ArrayListEdu = [
        {
            id: 1,
            text: "Up To 58% Off",
            title: "Get 12-Month Subscription",
            discount: "Total Discount",
            dvalue: "50%",
            image: SideImage,
            backgroundimg: Path65,
            
        },
        {
            id: 2,
            text: "Up To 58% Off",
            title: "Get 12-Month Subscription",
            discount: "Total Discount",
            dvalue: "50%",
            image: SideImage,
            backgroundimg: Path66,
            
        },
        {
            id: 3,
            text: "Up To 58% Off",
            title: "Get 12-Month Subscription",
            discount: "Total Discount",
            dvalue: "50%",
            image: SideImage,
            backgroundimg: Path65,
            
        },
    ]

    // "arraylistarg" is a variable name which can holds Array current values and "i" is index
    const EduList = ArrayListEdu.map( (arrayarg, i) => {
        return (

            

            <EduandDealsComp key={i} 
            id="i" 
            text={ArrayListEdu[i].text}
            title={ArrayListEdu[i].title}
            discount={ArrayListEdu[i].discount} 
            dvalue={ArrayListEdu[i].dvalue} 
            image={ArrayListEdu[i].image}
            backgroundimg={ArrayListEdu[i].backgroundimg} 
            
            />
        )
    })


    return (

        <>
            <div className="container EduStdCrdcontainer px-0">
                <div className="py-3">
                    <div className="row cstm-r1 py-4 align-items-center">
                        <div className="col-md-9 my-4">
                            <h4 className="heading mb-0">Top Education offers and deals are listed here</h4>
                        </div>
                        <div className="col-md-3 cstm-col2 d-flex justify-content-end align-items-center">
                        <button className="btn btn-danger btn-md px-4">View all</button>
                        </div>
                        
                        
                    </div>
                    {/* CPA-R2  , CrsStdCrd*/}
                    <div className="row EduStdCrd-R2 d-flex flex-wrap justify-content-between align-content-between">
                    
                    
                        {EduList}
                    
                        
                    </div>
                </div>
            </div>
        </>

    )

}