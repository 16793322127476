import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './CHistory.css'

export default function CHistory (props) {

    return (

        <>
            
            <div className="row py-1" style={{border:"#DBDBDB solid 1px"}}>
                <Col className="CHcontainer col-md-10">
                    <div><h5 className="course">{props.course}</h5></div>
                    <div className="d-flex">
                        <span><p className="transid">Transaction ID </p></span>
                        <span><p className="account">{props.transid}</p></span>
                    </div>
                    <div><p className="dateANDtime">{props.datetime}</p> </div>
                </Col>
                <Col className="CHcontainer col-md-2 d-flex flex-column justify-content-end">
                    <div><p className="cash">{props.cash}</p></div>
                    <div><Link to="#"><p className="view">View</p></Link></div>
                </Col>
            </div>
            
        </>

    )


}