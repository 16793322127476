import {React , Component} from 'react';
import './StdProView.css'
import Navbar from '../../components/Header/NavbarComponent/NavbarComponent';
import Footer from '../../components/CustomFooter/CustomFooter';
import SPVHeaderArea from './SPVHeaderArea/SPVHeaderArea';
import StudentProfile from './StudentProfile/StudentProfile';
import StdCourse from './StdCourse/StdCourse';
import StdQuiz from './StdQuiz/StdQuiz';
import StdLecture from './StdLecture/StdLecture';
import StdWallet from './StdWallet/StdWallet';
import StdAssessment from './StdAssessment/StdAssessment';
import StdSchedule from './StdSchedule/StdSchedule'




class StdProView extends Component {

    componentDidMount = () => {}

    render () {
        return (
            <>
                <Navbar/>
                <SPVHeaderArea />
                {/* <div className="TV-cstm-bg"><TeacherProfile /></div> */}
                {/* <!-- Tab panes --> */}
                <div className="tab-content">
                    <div className="tab-pane active" id="studentprofile" role="tabpanel">
                        <StudentProfile />
                    </div>
                    <div className="tab-pane" id="stdcourse" role="tabpanel">
                        <StdCourse />
                    </div>
                    <div className="tab-pane" id="stdschedule" role="tabpanel">
                        <StdSchedule/>
                    </div>
                    <div className="tab-pane" id="stdquiz" role="tabpanel">
                        <StdQuiz/>
                    </div>
                    <div className="tab-pane" id="stdassessment" role="tabpanel">
                        <StdAssessment />
                    </div>
                    <div className="tab-pane" id="stdwallet" role="tabpanel">
                        <StdWallet />
                        
                    </div>
                    <div className="tab-pane" id="stdlecture" role="tabpanel">
                        <StdLecture />
                        
                    </div>
                </div>
                <Footer/>

                

                
            </>
        )
    }
}

export default StdProView;

