import React from 'react';
import './ExpandingLearning.css';
import pinpointorange from '../../../images/pinpointorange.png';
import pinpointblue from '../../../images/pinpointblue.png';
import pinpointgreen from '../../../images/pinpointgreen.png';

const ExpandingLearning = () => {
    return (
        <div className="container-fluid ExpandingLearning">
            <div className="container">

                <div className="cstm-row-1 mb-5">
                    <h4 class="contact_form_heading">Expanding Learning Opportunities</h4>
                    <button className="btn btn-danger">Join Now</button>
                </div>

                <div className="">
                    {/* Newyork */}
                    <div className="row align-items-center mb-5">
                        <div className="col-md-9 ExpandingLearning-row2-row1-col1 pink_bg">
                            <div className="inner-content-cstm pl-5 text-left">
                                <h6>CONTACT</h6>
                                <h5>New York</h5>
                                <p className="ExpandingLearning_desc">Our mission is to democratize education through the offering of world-class
                                higher education opportunities that are accessible, flexible, and economical.
                                Virtually anyone on the planet with an internet connection and a commitment
                                to self-empowerment through learning can come to Skillify,</p>
                                <div className="d-flex align-items-center">
                                    <img className="mr-3" src={pinpointorange}></img>
                                    <p>600 Harrison St. 3rd Floor San Francisco, CA 94107</p>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-md-3 ExpandingLearning-row2-row1-col2">
                            <img className="cstm-img"></img>
                        </div>
                    </div>

                    {/* Newyork */}
                    <div className="row align-items-center mb-5">
                        <div className="col-md-3 ExpandingLearning-left">
                            <img className="cstm-img"></img>
                        </div>
                        <div className="col-md-9 ExpandingLearning-row2-row1-col1 blue_bg">
                            <div className="inner-content-cstm pl-5 text-left mx-auto">
                                <h6>CONTACT</h6>
                                <h5>New York</h5>
                                <p className="ExpandingLearning_desc">Our mission is to democratize education through the offering of world-class
                                higher education opportunities that are accessible, flexible, and economical.
                                Virtually anyone on the planet with an internet connection and a commitment
                                to self-empowerment through learning can come to Skillify,</p>
                                <div className="d-flex align-items-center">
                                    <img className="mr-3" src={pinpointorange}></img>
                                    <p>600 Harrison St. 3rd Floor San Francisco, CA 94107</p>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                    
                    {/* Abohar , Pakistan */}
                    <div className="row align-items-center mb-5">
                        <div className="col-md-9 ExpandingLearning-row2-row1-col1 green_bg">
                            <div className="inner-content-cstm pl-5 text-left">
                                <h6>CONTACT</h6>
                                <h5>Abohar, Pakistan</h5>
                                <p className="ExpandingLearning_desc">Our mission is to democratize education through the offering of world-class
                                higher education opportunities that are accessible, flexible, and economical.
                                Virtually anyone on the planet with an internet connection and a commitment
                                to self-empowerment through learning can come to Skillify,</p>
                                <div className="d-flex align-items-center">
                                    <img className="mr-3" src={pinpointgreen}></img>
                                    <p>600 Harrison St. 3rd Floor San Francisco, CA 94107</p>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-md-3 ExpandingLearning-row2-row1-col2">
                            <img className="cstm-img"></img>
                        </div>
                    </div>
                    
                </div>

            </div>
        </div>
    )
}

export default ExpandingLearning;