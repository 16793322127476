import React from 'react';
import './Terms&Conditions.css';
import Navbar from '../Header/NavbarComponent/NavbarComponent';
import Banner from './Components/Banner/Banner';
import Conditions from './Components/Conditions/Conditions';
import CustomFooter from '../CustomFooter/CustomFooter';


const TermsAndConditions = () => {
    return (
        <div className="main_wrapper">
            <Navbar />
            <Banner />
            <Conditions />
            <CustomFooter />
        </div>
    )
}

export default TermsAndConditions;