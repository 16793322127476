import React from 'react'
import './DBfinance.css'
import { Row } from 'react-bootstrap'
import Cards from './components/Cards/Cards'
import AppExpanse from './components/AppExpanse/AppExpanse'
import LineChart from './components/LineChart/LineChart'
import UnpaidStd from './components/UnpaidStd/UnpaidStd'
import bluecap from '../../../../images/bluecap.png'
import purplecap from '../../../../images/purplecap.png'
import greenpeople from '../../../../images/greenpeople.png'
import redpeople from '../../../../images/redpeople.png'
import bluegraph from '../../../../images/bluegraph.png'
import purplegraph from '../../../../images/purplegraph.png'
import greengraph from '../../../../images/greengraph.png'
import redgraph from '../../../../images/redgraph.png'
import icoption from '../../../../images/icoption.png'

const DBfinance = () => {

    const CardsArrayList = [
        {
            id:1,
            image1: bluecap,
            number: "2,478",
            text: "Total Students",
            image2: bluegraph,
        },
        {
            id:2,
            image1: purplecap,
            number: "2,478",
            text: "Total Students",
            image2: purplegraph,
        },
        {
            id:3,
            image1: redpeople,
            number: "2,478",
            text: "Total Students",
            image2: redgraph,
        },
        {
            id:4,
            image1: greenpeople,
            number: "2,478",
            text: "Total Students",
            image2: greengraph,
        },
    ]

    // mapping

    const CardList = CardsArrayList.map( ( arg, i) => {
        return (
            <Cards key={i} 
            id="i"
            image1={CardsArrayList[i].image1}
            number={CardsArrayList[i].number}
            text={CardsArrayList[i].text} 
            image2={CardsArrayList[i].image2} 
            
            />
        )
    })


    return (
        <>
            <div className="container DBfinance col-md-12 p-5">
                <Row>
                    {CardList}
                </Row>
                <Row className="chart-row py-3 px-2 my-3">
                    <div className="col-md-12 d-flex align-items-center">
                        <div className="flex-grow-1">
                            <h5>Balance Analyics</h5>
                        </div>
                        <div className="px-2">
                        <select className="button p-3" name="year" id="language" placeholder="Language">
                                            <option placeholder="Select">Select</option>
                                            <option value="ENGLISH">January</option>
                                            <option value="URDU">Feburary</option>
                                            <option value="CHINESS">March</option>
                                            <option value="ARABIC">April</option>
                                        </select>
                        </div>
                        <div>
                        <img className="dropdown-toggle" aria-haspopup="true" data-toggle="dropdown" src={icoption}></img>
                                    <span className="dropdown-menu cstm-CQuiz-dropdownmenu">
                                    <a className="dropdown-item" href="#">Quiz</a>
                                    <a className="dropdown-item" href="#">Edit</a>
                                    <a className="dropdown-item" href="#">Delete</a>
                                    </span>
                        </div>
                    </div>
                    <div className="col-md-12 px-2 chart">
                        <LineChart/>
                    </div>
                </Row>
                <Row>
                    <div className="col-md-6 pl-0 pr-1 ">
                        <AppExpanse/>
                    </div>
                    <div className="col-md-6 pl-1 pr-0">
                        <UnpaidStd/>
                    </div>
                </Row>
            </div>
            
        </>
    )
}

export default DBfinance
