import React from 'react'
import { Col, Form, FormLabel } from 'react-bootstrap'
import './NewQuiz.css'
import Ellipse62 from '../../../../../../images/Ellipse62.png'

export default function NewQuiz () {


    return (


        <>
            <div className="container NQ-container">
                {/* <div className="row py-3 d-flex justify-content-center">
                    <button className="btn NQ-btn">Create New Quiz</button>
                </div> */}
                <div className="row NQ-img py-3" style={{background:"#F6F7F9"}}>
                    <Col className="col-md-4">
                        <div>
                            <img src={Ellipse62}></img>
                        </div>
                    </Col>
                    <Col className="col-md-8 d-flex flex-column justify-content-center">
                        <Form>
                            <div className="row d-flex mb-3">
                                <div className="d-flex flex-column col-md-12">
                                    <FormLabel className="NQlabel" htmlFor="coursecategory">Quiz Name</FormLabel>
                                    <select className="NQfield" name="year" id="coursecategory" placeholder="Student id">
                                        <option value="1990">1990</option>
                                        <option value="1991">1991</option>
                                        <option value="1992">1992</option>
                                        <option value="1993">1993</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row d-flex mb-3">
                                <div className="d-flex flex-column col-md-4">
                                    <FormLabel className="NQlabel" htmlFor="coursecategory">Quiz Category</FormLabel>
                                    <select className="NQfield" name="year" id="coursecategory" placeholder="Student id">
                                        <option value="1990">1990</option>
                                        <option value="1991">1991</option>
                                        <option value="1992">1992</option>
                                        <option value="1993">1993</option>
                                    </select>
                                </div>
                                <div className="d-flex flex-column col-md-4">
                                    <FormLabel className="NQlabel" htmlFor="coursecategory">Languages</FormLabel>
                                    <select className="NQfield" name="year" id="coursecategory" placeholder="Student id">
                                        <option value="1990">1990</option>
                                        <option value="1991">1991</option>
                                        <option value="1992">1992</option>
                                        <option value="1993">1993</option>
                                    </select>
                                </div>
                                <div className="d-flex flex-column col-md-4">
                                    <FormLabel className="NQlabel" htmlFor="coursecategory">No of Question</FormLabel>
                                    <select className="NQfield" name="year" id="coursecategory" placeholder="Student id">
                                        <option value="1990">1990</option>
                                        <option value="1991">1991</option>
                                        <option value="1992">1992</option>
                                        <option value="1993">1993</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row d-flex mb-3">
                                <div className="d-flex flex-column col-md-4">
                                    <FormLabel className="NQlabel" htmlFor="coursecategory">Duration</FormLabel>
                                    <select className="NQfield" name="year" id="coursecategory" placeholder="Student id">
                                        <option value="1990">1990</option>
                                        <option value="1991">1991</option>
                                        <option value="1992">1992</option>
                                        <option value="1993">1993</option>
                                    </select>
                                </div>
                                <div className="d-flex flex-column col-md-8">
                                    <FormLabel className="NQlabel" htmlFor="coursecategory">Student</FormLabel>
                                    <select className="NQfield" name="year" id="coursecategory" placeholder="Student id">
                                        <option value="1990">1990</option>
                                        <option value="1991">1991</option>
                                        <option value="1992">1992</option>
                                        <option value="1993">1993</option>
                                    </select>
                                </div>
                            </div>
                        </Form>
                    </Col>
                </div>
                <div className="d-flex justify-content-center py-2">
                    <button className="btn" style={{color:"white",background:"#05CC69",fontSize:"12px",fontFamily:"Poppins"}}>Next</button>
                </div>
            </div>
        </>

    )
 
}