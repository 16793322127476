import React from 'react'
import './Barchart.css' 
import { Bar } from 'react-chartjs-2'

const Barchart = () => {

    const data= {
        labels: ['Sunday','Monday','Tuesday','Wednesday'],
        datasets: [{
            label: '# of Votes',
            data: [45,40,10,35],
            backgroundColor: [
                'rgba(129, 108, 225, 0.2)',
                'rgba(129, 108, 225, 0.2)',
                'rgba(129, 108, 225, 0.2)',
                'rgba(129, 108, 225, 0.2)',
            ],
            borderColor: [
                'rgb(129, 108, 225)',
                'rgb(129, 108, 225)',
                'rgb(129, 108, 225)',
                'rgb(129, 108, 225)'
            ],
            borderWidth: 1,
            
        },
        {
            label: '# of Votes2',
            data: [50,30,45,60],
            backgroundColor: [
                'rgba(119, 36, 139, 0.2)',
                'rgba(119, 36, 139, 0.2)',
                'rgba(119, 36, 139, 0.2)',
                'rgba(119, 36, 139, 0.2)',
            ],
            borderColor: [
                'rgb(119, 36, 139)',
                'rgb(119, 36, 139)',
                'rgb(119, 36, 139)',
                'rgb(119, 36, 139)',
            ],
            borderWidth: 1,
            
        }
    ]
    }

    

    const options= {
        maintainAspectRatio: false,
        scales: {
            yAxes: {
                ticks:{
                    beginAtZero: true
                }
            }
        }
    }

    

    return <Bar data={data} options={options} height={400}/>
}

export default Barchart
