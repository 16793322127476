import React, { useState, useEffect } from 'react'
import { Button, Checkbox, Form } from 'semantic-ui-react'
// import { useHistory } from 'react-router';

import axios from 'axios';

const UpdateEvaluation = () => {

    
    // const [firstName, setFirstName] = useState('');
    // const [lastName, setLastName] = useState('');
    // const [checkBox , setCheckBox] = useState(false)
    // const [id, setID] = useState(null);
    const [name, setName] = useState('');
    const [statusId, setStatusId] = useState('');
    const [evaluationTypeId, setEvaluationTypeId] = useState(null);

    let wrapper = React.createRef();

    useEffect(() => {
            // setID(localStorage.getItem('ID'))
            // setFirstName(localStorage.getItem('First Name'));
            // setLastName(localStorage.getItem('Last Name'));
            // setCheckBox(localStorage.getItem('Checkbox Value'))
            setEvaluationTypeId(localStorage.getItem('ID'))
            setName(localStorage.getItem('Name'));
            setStatusId(localStorage.getItem('Status Id'));
            
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(e)
    }

    // let history = useHistory();

    const updateAPIData = () => {
        axios.put(`http://zsktech1-001-site1.ftempurl.com/api/Evaluation/${evaluationTypeId}`, {
            // firstName,
            // lastName,
            // checkBox
            name,
            statusId,
            
        })
        // .then(() => {
        //     // history.push('/read')
            
        // })
    }
    
    // const postData = () => {
    //     console.log(firstName);
    //     console.log(lastName);
    //     console.log(checkBox);

    //     axios.post(`https://616078fdfaa03600179fbaa7.mockapi.io/fakeData`, {
    //         firstName,
    //         lastName,
    //         checkBox
    //     })


    // }


    return (
        <>
            <Form className="create-form" onSubmit={handleSubmit}>
                <Form.Field>
                    <label>Name</label>
                    <input placeholder='First Name'
                        ref={wrapper}
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Status Id</label>
                    <input placeholder='Status Id' 
                        ref={wrapper}
                        name="statusId"
                        value={statusId}
                        onChange={(e) => setStatusId(e.target.value)}
                    />
                </Form.Field>
                {/* <Form.Field>
                <Checkbox 
                    label='I agree to the Terms and Conditions'
                    onChange={(e) => setCheckBox(!checkBox)}
                    checked={checkBox} 
                />
                </Form.Field> */}
                <Button className="btn cstm-Updatebtn" type='submit' onClick={updateAPIData}>Update</Button>
            </Form>
        </>
    )
}

export default UpdateEvaluation;