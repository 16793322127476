import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Player from 'video-react/lib/components/Player'
import './CIComponent.css'

export default function CIComponent (props) {


    return (
        <>
            <div className="my-2">
                <Row className="StdCI py-3 px-2 d-flex align-items-center bg-white">
                    <Col className="col-md-2 col-sm-3 col-3">
                        <div className="StdCI-myplayer">
                            <Player
                                playsInline
                                poster="/assets/poster.png"
                                src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                                className="custom-video"
                            />
                        </div>
                    </Col>
                    <Col className="col-md-5 col-sm-5 col-5">
                        <div><h5 className="tp">{props.title}</h5></div>
                        <div className="d-flex align-items-center">
                            <img className="mr-1" src={props.profile}></img>
                            <p className="name">{props.name}</p>
                        </div>
                    </Col>
                    <Col className="col-md-3 col-sm-3 col-3 d-flex justify-content-center">
                        <div className="d-flex flex-column justify-content-between">
                            <div><p className="tp">{props.price}</p></div>
                            <div><p className="APDV" style={{textDecoration:"line-through"}}>{props.aprice}</p></div>
                        </div>
                        <div className="d-flex justify-content-end flex-column"><p className="APDV">{props.dvalue}</p></div>
                    </Col>
                    <Col className="col-md-2 col-sm-1 col-1 d-flex justify-content-center">
                        <Link to="#/"><img src={props.close}></img></Link>
                    </Col>
                </Row>
            </div>
        </>
    )


}