import {React , Component} from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './TVHeaderArea.css';
import Whitestar from '../../../assets/images/whitestar.png';
import Yellowstar from '../../../assets/images/yellowstar.png';


class TVHeaderArea extends Component {

    componentDidMount = () => {}

    render () {
        return (
            <div className="container-fluid TVHA-containerfluid col-md-12">
                <div className="container TVHA-container px-0">
                    <h3>TeacherProfile</h3>
                    <section className="p-3 cstm-section col-md-12">
                        <Row className="TVHA-cstm-section-R1">
                            <Col className="TVHA-section-R1-C1 col-md-2 "><img></img></Col>
                            <Col className="TVHA-section-R1-C2 col-md-3 ">
                                <Row className="TVHA-section-R1-C2-R1">
                                    <h5 className="cstm-h5">Paul Walker</h5>
                                    <p className="cstm-p "><img src={Whitestar}></img> Top Teacher</p>
                                </Row>
                                <Row className="TVHA-section-R1-C2-R2">
                                    <p className="m-0 cstm-p cstm-textLeft">Creative coder & Designer</p>
                                </Row>
                            </Col>
                            <Col className="TVHA-section-R1-C3 col-md-4 ">
                                <Row>
                                    <p className="cstm-p ">22 </p>
                                    <p className="cstm-p ">22 </p>
                                    <p className="cstm-p "><img src={Yellowstar}></img> 4.7</p>
                                </Row>
                                <Row>
                                    <p className="cstm-p ">Courses</p>
                                    <p className="cstm-p ">Students</p>
                                    <p className="cstm-p ">Rating</p>
                                </Row>
                            </Col>
                            <Col className="TVHA-section-R1-C4 col-md-3 ">
                                <button className="btn w-75 cstm-button one">Student Lectures</button>
                                <button className=" btn w-75 cstm-button two">Message</button>
                            </Col>
                        </Row>
                        <Row className="TVHA-cstm-section-R2 px-4">
                            {/* <Link to='/#'><button>About</button></Link>
                            <Link to='/#'><button>Courses</button></Link>
                            <Link to='/#'><button>Review</button></Link> */}
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" data-toggle="tab" href="#tabs-1" role="tab">About</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#tabs-2" role="tab">Course</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#tabs-3" role="tab">Review</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#tabs-4" role="tab">Schedule</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#tabs-5" role="tab">Quiz</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#assessment" role="tab">Assessment</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#wallet" role="tab">Wallet</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#lecture" role="tab">Lecture</a>
                                </li>
                            </ul>
                        </Row>
                        
                    </section>
                </div>
            </div>
        )
    }
}

export default TVHeaderArea;

