import React from 'react'
import './WeatherForecastAPI.css'



import ReadWeatherForecast from './Components/readWeatherForecast'

const WeatherForecastAPI = () => {
    return (
        <>
            <div className="container cstm-one">
                <div>
                    <ReadWeatherForecast/>
                </div>
            </div>
            
        </>
    )
}

export default WeatherForecastAPI;
