import {React , Component} from 'react';
import './StudentView.css'
import Navbar from '../../components/Header/NavbarComponent/NavbarComponent';
import Footer from '../../components/CustomFooter/CustomFooter';
import SVHeader from './SVHeader/SVHeader';
import TPStudent from './TPStudent/TPStudent';
import CourseStudent from './CourseStudent/CourseStudent';
import Cartpage from './Cartpage/Cartpage';




export default function StudentView () {



    
        return (
            <>
                <Navbar/>
                <SVHeader/>
                {/* ------teacher profile student---------- */}
                {/* <TPStudent/> */}
                {/* --------course student---------- */}
                {/* <CourseStudent/> */}
                {/* -------cart page-------- */}
                {/* <Cartpage/> */}

                <div class="tab-content std-View" id="myTabContent">
                
  <div class="tab-pane fade show active" id="stdV1" role="tabpanel" ><TPStudent/></div>
  <div class="tab-pane fade" id="stdV2" role="tabpanel" ><CourseStudent/></div>
  <div class="tab-pane fade" id="stdV3" role="tabpanel" ><Cartpage/></div>
</div>
                
                <Footer/>

                

                
            </>
        )
    
}

