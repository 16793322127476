import React from 'react'
import { Col, Row } from 'react-bootstrap'
import './Withdraw.css'
import wallet from '../../../../images/wallet.png'
import money from '../../../../images/money.png'

export default function Withdraw () {

    const ArrayWithdraw = [
        {
            id:1,
            title: "My Balance",
            cash: "$250",
            addedd: "8 May 2021",
        }
    ]

    return (

        <>
            <div className="WDcontainer col-md-12">
                <Row style={{borderBottom:"#CAC6D4 solid 1px"}} className="mb-3 py-2">
                    <Col className="d-flex align-items-center">
                        <span><img src={wallet}></img></span>
                        <span className="ml-2"><h5 className="WDheading">Wallet</h5></span>
                    </Col>
                    <Col className="text-end">
                        <button className="btn WDbutton">Withdraw</button>
                    </Col>
                </Row>
                <Row className="py-5 d-flex align-content-center justify-content-center">
                    <div className="card WDcard px-5">
                        <div className="card-body">
                            <div className="text-center"><img src={money}></img></div>
                            <div className="text-center">
                                {ArrayWithdraw.map(e => (
                                    <h5 className="title">{e.title}</h5>
                                ))}</div>
                            <div className="text-center">
                                {ArrayWithdraw.map(e => (
                                    <p className="cashtext">{e.cash}</p>
                                ))}
                            </div>
                            <div className="text-center" style={{background:"#F56962",borderRadius:"10px",padding:"3px 5px"}}>
                                {ArrayWithdraw.map(e => (
                                    <p>Addedd: {e.addedd}</p>
                                ))}
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
        </>

    )

}