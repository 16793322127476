import React from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import Popper from 'popper.js';
import './KnowAboutBest.css';

const KnowAboutBest = () => {
    return (
        <div className="known-about-best-section">
            <div className="container">
                <div className="row known-about-best-headline">
                    <div className="col-md-9">
                        <h4 className="cstm-h4">Know about best online learning platform</h4>
                    </div>
                    <div className="col-md-3 cstm-btn-div">
                        <button className="btn button btn-danger">Start now</button>
                    </div>
                </div>
               
                <div className="row">
                    <div className="col-md-6">
                        <ul className="known-about-best-list">
                            <li>
                                <h5>Unlimited Course & Students</h5>
                                 <p>Find what you are intersted to learn online and choose what exactly best for you 
                                that you really passionate to learn and get to study about it</p>
                            </li>
                             <li>
                                <h5>Learn from industry experts</h5>
                                 <p>Find what you are intersted to learn online and choose what exactly best for you 
                                that you really passionate to learn and get to study about it</p>
                            </li>
                             <li>
                                <h5>Earn A Certificate Or Degree</h5>
                                  <p>Find what you are intersted to learn online and choose what exactly best for you 
                                that you really passionate to learn and get to study about it</p>
                            </li>
                        </ul>
                    </div>
                    
                    <div className="KnowAboutBest-container-row1-column1 col-md-6 col-md-6">
                        {/* <img src={Image}></img> */}
                        <div className="KnowAboutBest-container-row1-column1-row1">
                            <div><img></img></div>
                            <div><img></img></div>
                        </div>
                        <div className="KnowAboutBest-container-row1-column1-row2">
                            <div><img></img></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KnowAboutBest;