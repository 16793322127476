import React from "react"
import "./SVHeader.css"

export default function SVHeader () {

    return (


        <>
            <div className="container-fluid SVHcontainerfluid">
            
            {/* ------just for example */}
            <ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="home-tab" data-toggle="tab" data-target="#stdV1" type="button" role="tab" aria-controls="home" aria-selected="true">view1</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="profile-tab" data-toggle="tab" data-target="#stdV2" type="button" role="tab" aria-controls="profile" aria-selected="false">view2</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="contact-tab" data-toggle="tab" data-target="#stdV3" type="button" role="tab" aria-controls="contact" aria-selected="false">view3</button>
  </li>
  <li><h3 className="bg-warning">this view tab is just for testing screens</h3></li>
</ul>

            </div>
        </>

    )

}