import React from 'react';
import './GotAQueestion.css';
import bannerpic from '../../../images/bannerpic.png';

import searchbtn from '../../../images/searchbtn.png';

import { useSpring, animated } from 'react-spring'


const GotAQueestion = () => {


    const props = useSpring({ from: { opacity: 0 , marginTop: -500 } , to: { opacity: 1 , marginTop:0 } })

    return (
        <div className="container-fluid Got-A-Question" style={{background:"#005D8A"}}>
                <div className="container cstm-container">
                <animated.div style={props}>
                    <div className="row align-items-center pt-5">
                        <div className="col-md-6">
                            <h3 className="banner-heading">Got a question about using Skillfy?</h3>
                            <p className="banner-descr">This is the place to start. Find the answers you need from the Skillfy Community or our award-winning support team.</p>
                            
                            
                            <button className="btn btn-danger px-4 py-3" type="submit">Help Cneter</button>
                               

                        </div>
                        <div className="col-md-6 cstm-banner-image">
                            <img className="img-fluid cstm-img" src={bannerpic}></img>
                        </div>
                    </div>
                    </animated.div>
                    
                </div>
        </div>
    )
}

export default GotAQueestion;