import React from 'react'
import { Link } from 'react-router-dom'
import './CCAssessment.css'

export default function CCAssessment (props) {


    return (
        <>
            <div className="CCAssessment-container">
                
                <div>
                    <table class="table table-hover">
                        <tbody>
                            <tr>
                            <th scope="row"><img src={props.Ellipse62}></img></th>
                            <td>{props.title}</td>
                            <td><img src={props.pdf}></img>{props.FileName}</td>
                            <td><img src={props.timer}></img>{props.time}</td>
                            <td>{props.date}</td>
                            <td className="text-end">
                                
                                {/* <button className="btn button px-3">Open</button> */}
                                <Link to="/files" className="btn button px-3">Open</Link>
                            </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
            </div>
        </>
    )

}