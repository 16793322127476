import React from 'react'
import './VideoList.css'

import Player from 'video-react/lib/components/Player';
import { Link } from 'react-router-dom';


const VideoList = () => {
    return (
        <>
            <div className="card VideoListContainer p-2">
                <div className="card-body">
                    <div className="d-flex flex-column align-items-start">
                        <h5 className="heading">Video List</h5>
                        <p className="text">Lorem ipsum</p>
                    </div>
                    <div className="py-2 d-flex flex-column align-items-start">
                        <Player
                                        playsInline
                                        poster="/assets/poster.png"
                                        src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                                        className="custom-video"
                                        />
                        <p className="text">Russian Lenguage Basic </p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <Link className="morevideos" to="#/">More Videos</Link>
                        <p className="text"><i className="fas fa-chart-bar pr-2"></i>6,723</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VideoList
