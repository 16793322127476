import React from 'react'
import { Col } from 'react-bootstrap'
import './CLecs.css'

export default function CLecs (props) {

    return (

        <>
        {/* col-md-4 px-0 py-2 */}
            <div className="col-md-4 col-sm-12 col-12 py-3 px-2">
                <div className="card CLScard py-2">
                    <div className="card-body d-flex p-0">
                        <Col className="col-md-5 d-flex flex-column align-items-center" style={{lineHeight:"4"}}>
                            <div><img src={props.image}></img></div>
                            <div><h5 className="color-family name">{props.name}</h5></div>
                            <div><p className="color-family date">{props.date}</p></div>
                        </Col>
                        <Col className="col-md-7 d-flex flex-column">
                            <div className="d-flex justify-content-between">
                                <div><h5 className="color-family title">{props.title}</h5></div>
                                <div><p className="color-family lecturenum">{props.lecturenum}</p></div>
                            </div>
                            <div><p className="color-family text text-justify">{props.text}</p></div>
                            <div className="d-flex justify-content-around align-items-center">
                                <div><img src={props.stopwatch}></img></div>
                                <div><p className="color-family timer">{props.timer}</p></div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className="btn" style={{fontFamily:"Poppins",fontSize:"6px",color:"white", background:"#05CC69", width:"45%"}}>
                                    Message
                                </button>
                                <button className="btn" style={{fontFamily:"Poppins",fontSize:"6px",color:"white", background:"#CAC6D4", width:"50%"}}>
                                    Take Lecture Now
                                </button>
                            </div>
                        </Col>
                    </div>
                </div>
            </div>
        </>

    )

}