import React from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import Popper from 'popper.js';
import './ChooseFavouriteCourses.min.css'
import ChooseFavouriteRow2Component from './ChooseFavouriteRow2Component/ChooseFavouriteCoursesRow2Component';

import bookcatg from "../../../images/bookcatg.png"
import businesscatg from "../../../images/businesscatg.png"
import ITcatg from "../../../images/ITcatg.png"
import marketingcatg from "../../../images/marketingcatg.png"
import photographycatg from "../../../images/photographycatg.png"
import developmentcatg from "../../../images/developmentcatg.png"

// import Jello from 'react-reveal/Jello';

const ChooseFavouriteCourses = () => {
    return (
        // <Jello>
        <div className="container ChooseFavouriteCourses mt-5">
            <div className="row r1 align-items-center mb-4">
                <div className="col-md-8 col-sm-11 col-12">
                    <h3 className="mb-0 heading">Choose favourite courses from top category</h3>
                </div>
                <div className="col-md-2 col-sm-11 col-12 text-right">
                    <button className="btn button btn-md">See all Categories</button>
                </div>
                
                
            </div>
            <div className="row choosefav-cstm-row-2 ">
                <div className="col-md-2 col-sm-4 col-6 text-center">
                <ChooseFavouriteRow2Component id="1" name="Design" src={bookcatg} />
                </div>
                <div className="col-md-2 col-sm-4 col-6 text-center">
                <ChooseFavouriteRow2Component id="2" name="Development" src={businesscatg} />
                </div>
                <div className="col-md-2 col-sm-4 col-6 text-center">
                <ChooseFavouriteRow2Component id="3" name="IT & Soft" src={ITcatg} />
                </div>
                <div className="col-md-2 col-sm-4 col-6 text-center">
                <ChooseFavouriteRow2Component id="4" name="Bussiness" src={marketingcatg} />
                </div>
                <div className="col-md-2 col-sm-4 col-6 text-center">
                <ChooseFavouriteRow2Component id="5" name="Marketing" src={photographycatg} />
                </div>
                <div className="col-md-2 col-sm-4 col-6 text-center">
                <ChooseFavouriteRow2Component id="6" name="Photography" src={developmentcatg} />
                </div>
            </div>
        </div>
        // </Jello>
    )
}

export default ChooseFavouriteCourses;