import React, { Component } from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import Popper from 'popper.js';
import '../WhatOurStudents/WhatOurStudents.min.css';
import WhatOurStudentsRow2Component from './WhatOurStudentsRow2Component/WhatOurStudentsRow2Component';
import Preview from '../../../images/Preview.png';
import Next from '../../../images/Next.png';
import User1 from '../../../images/User1.png'
import User2 from '../../../images/User2.png'
import User3 from '../../../images/User3.png'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'



export default class WhatOurStudents extends Component {
// const WhatOurStudents = () => {

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
      }
      next() {
        this.slider.slickNext();
      }
      previous() {
        this.slider.slickPrev();
      }

    render() {
    const settings = {
        
        arrows:false,
        dots: true,
        autoplay: true,
        centerMode: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        infinite:true,
        speed:500,
        slidesToShow:3,
        slidesToScroll:1,
        cssEase: "linear",
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    arrows:false,
                    dots: true,
                    autoplay: true,
                    centerMode: true,
                    autoplaySpeed: 5000,
                    pauseOnHover: true,
                    infinite:true,
                    speed:500,
                    slidesToShow:1,
                    slidesToScroll:1,
                    cssEase: "linear",
                    focusOnSelect: true,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    arrows:false,
                    dots: true,
                    autoplay: true,
                    centerMode: true,
                    autoplaySpeed: 5000,
                    pauseOnHover: true,
                    infinite:true,
                    speed:500,
                    slidesToShow:1,
                    slidesToScroll:1,
                    cssEase: "linear",
                    focusOnSelect: true,
                }
            },
            {
                breakpoint: 375,
                settings: {
                    arrows:false,
                    dots: true,
                    autoplay: true,
                    centerMode: true,
                    autoplaySpeed: 5000,
                    pauseOnHover: true,
                    infinite:true,
                    speed:500,
                    slidesToShow:1,
                    slidesToScroll:1,
                    cssEase: "linear",
                    focusOnSelect: true,
                }
            }
        ]
    };

    return (
        <>

            <div className="container-fluid cstm-WhatOurStudents-containerfluid-1 d-flex ">
                <div className="container cstm-container ">
                    <div className="row cstm-WhatOurStudents-row-1  p-4">
                        <div>
                            <h4 className="flex-grow-1 cstm-h4">What our students have to say</h4>
                        </div>
                        <div>
                            <button className="btn cstm-WhatOurStudents-row-1-btn-1 " onClick={this.previous}><img src={Preview}></img></button>
                            <button className="btn cstm-WhatOurStudents-row-1-btn-2" onClick={this.next}><img src={Next}></img></button>
                        </div>
                    </div>
                    <div className="row carousel cstm-carousel">
                        <Slider ref={c => (this.slider = c)} {...settings} className="col-md-12 col-sm-12 col-12">
                            
                                <WhatOurStudentsRow2Component src={User1}/>
                            
                                <WhatOurStudentsRow2Component src={User2}/>
                            
                                <WhatOurStudentsRow2Component src={User3}/>
                            
                                <WhatOurStudentsRow2Component src={User1}/>
                            
                                <WhatOurStudentsRow2Component src={User2}/>
                            
                                <WhatOurStudentsRow2Component src={User3}/>
                            
                                <WhatOurStudentsRow2Component src={User1}/>
                        </Slider>
                    </div>
                </div>
            </div>

           

        </>

    )
    }
}

// export default WhatOurStudents;