import React, { useState } from 'react'
import { Button, Checkbox, Form } from 'semantic-ui-react'
// import { useHistory } from 'react-router';

import axios from 'axios';

const Create = () => {

    
    const [name, setName] = useState('');
    const [statusId, setStatusId] = useState('');

    

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(e)
    }

    
    const postData = () => {
        
        console.log(name);
        console.log(statusId);

        // axios.post(`https://616078fdfaa03600179fbaa7.mockapi.io/fakeData` http://zsktech1-001-site1.ftempurl.com/api/FeeType 
            axios.post(`http://zsktech1-001-site1.ftempurl.com/api/FeeType`, {
            name,
            statusId,
        })
        // .then(() => {
        //     history.push('/read')
        // })


    }


    return (
        <>
            <Form className="create-form" onSubmit={handleSubmit}>
                <Form.Field>
                    <label>Name</label>
                    <input placeholder='Name'
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Status Id</label>
                    <input placeholder='Status Id' 
                        name="statusId"
                        onChange={(e) => setStatusId(e.target.value)}
                    />
                </Form.Field>
                {/* <Form.Field>
                <Checkbox label='I agree to the Terms and Conditions'
                onChange={(e) => setCheckBox(!checkBox)} />
                </Form.Field> */}
                <Button type='submit' onClick={postData}>Submit</Button>
            </Form>
        </>
    )
}

export default Create;