import React, {useState} from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import Popper from 'popper.js';
import './LearnNewSkill.min.css';
import searchbtn from '../../../images/searchbtn.png';

// import { useSpring, animated } from 'react-spring'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'



const LearnNewSkill = () => {

    const [searchTerm, setSearchTerm] = useState('');

    // const props = useSpring({ from: { opacity: 0 , marginTop: -500 } , to: { opacity: 1 , marginTop:0 } })


    const settings = {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 10000,
        pauseOnHover: true,
        fade: true,
        infinite:true,
        speed:500,
        slidesToShow:1,
        slidesToScroll:1,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 10000,
                    pauseOnHover: true,
                    fade: true,
                    infinite:true,
                    speed:500,
                    slidesToShow:1,
                    slidesToScroll:1,
                    cssEase: "linear",
                }
            },
            {
                breakpoint: 425,
                settings: {
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 10000,
                    pauseOnHover: true,
                    fade: true,
                    infinite:true,
                    speed:500,
                    slidesToShow:1,
                    slidesToScroll:1,
                    cssEase: "linear",
                }
            },
            {
                breakpoint: 375,
                settings: {
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 10000,
                    pauseOnHover: true,
                    fade: true,
                    infinite:true,
                    speed:500,
                    slidesToShow:1,
                    slidesToScroll:1,
                    cssEase: "linear",
                }
            }
        ]
    }

    return (
        <>

            
        

        <div className="container-fluid learnNewSkill">

                    <div>
                        <Slider {...settings}>
                            {/* row 1 */}
                            <div className="cstm-learn1-img header-padding">
                                <div className="container">
                                    <div className="row r1 align-items-center pt-5">
                                        <div className="col-md-6 col-sm-12">
                                            {/* <animated.div style={props}> */}
                                            <h3 className="banner-heading">Learn new skills online with top educators</h3>
                                            <p className="banner-descr">Choose from over 100,000 online video cources with a new additions published every mont.</p>
                                            
                                            <div className="input-group banner_search w-75">
                                                <input type="text" className="form-control" onChange={event => {setSearchTerm(event.target.value)}} placeholder="Search your favourite courses" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-danger btn-sm" type="submit"><img src={searchbtn} className="seacrh_icon_width"></img></button>
                                                </div>
                                            </div>
                                            {/* </animated.div> */}
                                        </div>
                                        {/* <div className="col-md-6 col-sm-6 cstm-banner-image"></div> */}
                                    </div>
                                </div>
                            </div>
                            {/* row 2 */}
                            <div className="cstm-learn2-img header-padding">
                                <div className="container">
                                    <div className="row r1 align-items-center pt-5">
                                        <div className="col-md-6 col-sm-12">
                                            {/* <animated.div style={props}> */}
                                            <h3 className="banner-heading">Learn new skills online with top educators</h3>
                                            <p className="banner-descr">Choose from over 100,000 online video cources with a new additions published every mont.</p>
                                            
                                            <div className="input-group banner_search w-75">
                                                <input type="text" className="form-control" onChange={event => {setSearchTerm(event.target.value)}} placeholder="Search your favourite courses" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-danger btn-sm" type="submit"><img src={searchbtn} className="seacrh_icon_width"></img></button>
                                                </div>
                                            </div>
                                            {/* </animated.div> */}
                                        </div>
                                        <div className="col-md-6 col-sm-6 cstm-banner-image"></div>
                                    </div>
                                </div>
                            </div>
                            {/* row 3 */}
                            <div className="cstm-learn3-img header-padding">
                                <div className="container">
                                    <div className="row r1 align-items-center pt-5">
                                        <div className="col-md-6 col-sm-12">
                                            {/* <animated.div style={props}> */}
                                            <h3 className="banner-heading">Learn new skills online with top educators</h3>
                                            <p className="banner-descr">Choose from over 100,000 online video cources with a new additions published every mont.</p>
                                            
                                            <div className="input-group banner_search w-75">
                                                <input type="text" className="form-control" onChange={event => {setSearchTerm(event.target.value)}} placeholder="Search your favourite courses" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-danger btn-sm" type="submit"><img src={searchbtn} className="seacrh_icon_width"></img></button>
                                                </div>
                                            </div>
                                            {/* </animated.div> */}
                                        </div>
                                        <div className="col-md-6 col-sm-6 cstm-banner-image"></div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>

        </div>

        </>
    )
}

export default LearnNewSkill;