import React from 'react'
import './TableStructure.css'

const TableStructure = (props) => {
    return (
        <>
            <tr className="d-flex">
                            <td className="d-flex align-items-center" scope="row">
                                <img className="mr-2" src={props.image1}></img>
                                <span className="tabletext">{props.name}{props.ID}</span>
                            </td>
                            <td className="d-flex align-items-center">
                                <img className="mr-2" src={props.image2}></img>
                                <span className="tabletext">{props.courses}{props.teachername}</span>
                            </td>
                            <td className="tabletext d-flex align-items-center">{props.output}</td>
                            </tr>
            
        </>
    )
}

export default TableStructure;
