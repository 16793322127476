import React from 'react'
import './Summary.css'
import { Row, Col } from 'react-bootstrap'

const Summary = () => {
    return (
        <>
            <div className="container Summary bg-white">
                <Row className="borderbottom py-3 px-2">
                    <div>
                        <p className="titletext"><i class="fas fa-id-card pr-3"></i>Summary</p>
                    </div>
                </Row>
                <Row className="px-5">
                    <div className="details py-5">
                    <Row>
                        <Col className="col-4"><h5>Full Name:</h5></Col>
                        <Col className="col-8"><p className="text-justify">Fanny Siregar</p></Col>
                    </Row>
                    <Row>
                        <Col className="col-4"><h5>Email:</h5></Col>
                        <Col className="col-8"><p className="text-justify">arslanmaqsood91@gmail.com</p></Col>
                    </Row>
                    <Row>
                        <Col className="col-4"><h5>Gender:</h5></Col>
                        <Col className="col-8"><p className="text-justify">Male</p></Col>
                    </Row>
                    <Row>
                        <Col className="col-4"><h5>DOB:</h5></Col>
                        <Col className="col-8"><p className="text-justify">05/05/1991</p></Col>
                    </Row>
                    <Row>
                        <Col className="col-4"><h5>Phone:</h5></Col>
                        <Col className="col-8"><p className="text-justify">+92-321-7237787</p></Col>
                    </Row>
                    <Row>
                        <Col className="col-4"><h5>Language:</h5></Col>
                        <Col className="col-8"><p className="text-justify">English</p></Col>
                    </Row>
                    <Row>
                        <Col className="col-4"><h5>Address:</h5></Col>
                        <Col className="col-8"><p className="text-justify">33a model town link road</p></Col>
                    </Row>
                    <Row>
                        <Col className="col-4"><h5>City:</h5></Col>
                        <Col className="col-8"><p className="text-justify">Lahore</p></Col>
                    </Row>
                    <Row>
                        <Col className="col-4"><h5>State/Region:</h5></Col>
                        <Col className="col-8"><p className="text-justify">Punjab</p></Col>
                    </Row>
                    <Row>
                        <Col className="col-4"><h5>ZIP/Postal Code:</h5></Col>
                        <Col className="col-8"><p className="text-justify">54000</p></Col>
                    </Row>
                    <Row>
                        <Col className="col-4"><h5>Country:</h5></Col>
                        <Col className="col-8"><p className="text-justify">Pakistan</p></Col>
                    </Row>
                    <Row>
                        <Col className="col-4"><h5>Gegree:</h5></Col>
                        <Col className="col-8"><p className="text-justify">Software Engineer</p></Col>
                    </Row>
                    <Row>
                        <Col className="col-4"><h5>Institution:</h5></Col>
                        <Col className="col-8"><p className="text-justify">UOL</p></Col>
                    </Row>
                    <Row>
                        <Col className="col-4"><h5>About:</h5></Col>
                        <Col className="col-8"><p className="text-justify">I love sharing with my knowledge and experience this is why I’m working 
                            on The Awwwesomes - an initiative….</p></Col>
                    </Row>
                    </div>
                </Row>
            </div>
            
        </>
    )
}

export default Summary
