import React from 'react'
import './ModuleAPI.css'



import ReadModule from './Components/readModule'

const ModuleAPI = () => {
    return (
        <>
            <div className="container cstm-one">
                <div>
                    <ReadModule/>
                </div>
            </div>
            
        </>
    )
}

export default ModuleAPI;
