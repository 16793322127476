import React from 'react'
import './CProfileinfo.css'
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Whitestar from '../../../../../assets/images/whitestar.png';
import Yellowstar from '../../../../../assets/images/yellowstar.png';
import ellipse62 from '../../../../../images/Ellipse62.png'

export default function CProfileinfo () {


    return (

        <>
            <div className="bg-white CProfileinfo-container px-4 pb-5 position-absolute" style={{top:"-100px"}}>
                <Col className="PI-C1">
                    <Row className="d-flex flex-column PI-C1-R0">
                        <div className="d-flex flex-column align-items-center col-sm-12 col-12 p-5">
                            <img className="profile-img" src={ellipse62}></img>
                            <div className="d-flex justify-content-center my-2">
                                <p 
                                    className="cstm-teacherRanking"
                                    >
                                    <img src={Whitestar}></img>
                                    Top Teacher
                                </p>
                            </div>
                            <div className="my-2">
                                <h5 style={{fontSize:"18px", color:"black"}}>
                                    Creative coder & designer
                                </h5>
                            </div>
                            <div >
                                <button className="btn" style={{background:"#05CC69", fontSize:"13px",  color:"white"}}>
                                    Message
                                </button>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-3">
                            <div className="d-flex flex-column align-items-center">
                                <p className="text">22</p>
                                <p className="text">Courses</p>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <p className="text">22</p>
                                <p className="text">Students</p>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <p className="text"><img src={Yellowstar}></img>4.7</p>
                                <p className="text">Rating</p>
                            </div>
                        </div>
                    </Row>
                    <Row className="PI-C1-R1">
                        <h4 className="flex-grow-1">Teacher Info</h4>
                    </Row>
                    <Row className="PI-C1-R2">
                        <Row>
                            <Col><h5 >Skill Set :</h5></Col>
                            <Col><p >I love sharing with my knowledge and experience this is why I’m working 
                            on The Awwwesomes - an initiative….</p></Col>
                        </Row>
                        <Row>
                            <Col><h5 >Exp. :</h5></Col>
                            <Col><p>Themadbrains  •  2018 - Present
                                Dribbble  •  2018 - Present
                                Behance  •  2016 - Present
                                Uplabs  •  2014 - Present
                            </p></Col>
                        </Row>
                        <Row>
                            <Col><h5>Education :</h5></Col>
                            <Col><p>Kanpur university  •  2002 - 2004</p></Col>                            
                        </Row>
                        <Row>
                            <Col><h5>language : </h5></Col>
                            <Col><p>English, Urdu</p></Col>
                        </Row>
                        <Row>
                            <Col><h5>Other :</h5></Col>
                            <Col><p>Coach and mentor of General science/science and technology/ Ecology 
                            and Environment.<br /> I have 10 years teaching experience. 
                            Mentored more than 5000 student since 2010</p></Col>                            
                        </Row>
                    </Row>
                    {/* <Row className="PI-C1-R1">
                        <h4 className="flex-grow-1">Teacher Info</h4>
                    </Row>
                    <Row className="PI-C1-R2">
                        <Row>
                            <div>
                                <h5 >Skill Set :</h5>
                                <p >I love sharing with my knowledge and experience this is why I’m working 
                                on The Awwwesomes - an initiative….</p>
                            </div>
                        </Row>
                        <Row>
                            <div>
                                <h5 >Exp. :</h5>
                                <p>Themadbrains  •  2018 - Present
                                    Dribbble  •  2018 - Present
                                    Behance  •  2016 - Present
                                    Uplabs  •  2014 - Present
                                </p>
                            </div>
                        </Row>
                        <Row>
                            <div>
                                <h5>Education :</h5>
                                <p>Kanpur university  •  2002 - 2004</p>
                            </div>                            
                        </Row>
                        <Row>
                            <div>
                                <h5>language : </h5>
                                <p>English, Urdu</p>
                            </div>
                        </Row>
                        <Row>
                            <div>
                                <h5>Other :</h5>
                                <p>Coach and mentor of General science/science and technology/ Ecology 
                                and Environment.<br /> I have 10 years teaching experience. 
                                Mentored more than 5000 student since 2010</p>
                            </div>                            
                        </Row>
                    </Row> */}
                </Col>
            </div>
        </>

    )

}