import React from 'react'
import './DBprofileapproval.css'
import TableComponent from './TableComponent/TableComponent'
import search1 from '../../../../images/search1.png'
import addfriend from '../../../../images/addfriend.png'
import { Link } from 'react-router-dom'
import { Form, Row } from 'react-bootstrap'

const DBprofileapproval = () => {
    return (
        <>
            <div className="container DBprofileapproval col-md-12 p-5">
                <Row className="d-flex justify-content-between pb-4">
                    <div class="flex-grow-1">
                        <Form className="form-inline" action="/action_page.php">
                            <div className="px-2 py-1 dbsearch-div">
                                <input className="form-control dbsearch" type="text" placeholder="Search"/>
                                <Link to="#/"><img src={search1}></img></Link>
                            </div> 
                        </Form>
                    </div>
                    <div className="mx-3">
                        <select className="db-button px-2" name="year" id="language" placeholder="Language">
                            <option value="Active">Active Student</option>
                            <option value="Offline">Offline Student</option>

                            {/* <i className="fas fa-circle">
                            <i className="fas fa-circle"></i> */}
                            
                        </select>
                    </div>
                    <div className="">
                        <button className="btn newstd"><img className="px-2" src={addfriend}></img>New Student</button>
                    </div>
                </Row>
                <Row className="pb-3">
                    <TableComponent/>
                </Row>
                <Row className="d-flex justify-content-between">
                    <div>
                        <p>Showing 10 from 46 data</p>
                    </div>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                            </li>
                            <li class="page-item "><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                            </li>
                        </ul>
                    </nav>
                </Row>
            </div>
        </>
    )
}

export default DBprofileapproval
