import { Inject } from '@syncfusion/ej2-react-base'
import React from 'react'
import { Col } from 'react-bootstrap'
import MyCalander from './component/mycalander/MyCalander'
import Schedule from './component/Schedule/Schedule'
import './StdSchedule.css'

export default function StdSchedule () {


    return (
        <>
            
            <div className="container StdSchedule-container px-0 bg-white">
                <div className="d-flex px-4">
                    <Col>
                        <MyCalander />
                    </Col>
                    <Col>
                        <Schedule />
                    </Col>
                </div>
            </div>

        </>
    )

}