import React from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import Popper from 'popper.js';
import '../ChooseFavouriteRow2Component/ChooseFavouriteCoursesRow2Component(min).css';

const ChooseFavouriteRow2Component = (props) => {
    return (
        <div className="card CFRow2Component-cstm-card d-inline-block py-2 mb-3" style={props.style}>
            <img className="" src={props.src} alt="image"></img>
            <div className="card-body text-center CFRow2Component-cstm-card-body">
                <h4 className="card-card-title cstm-hovertext">{props.name}</h4>
                <p className="card-text cstm-hovertext">Tutor(100)</p>
            </div>
        </div>
    )
}

export default ChooseFavouriteRow2Component;