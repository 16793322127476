import React, { useEffect, useState } from 'react';

import { Button, Table } from 'semantic-ui-react'
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import MUIDataTable from "mui-datatables";
import {MuiThemeProvider, createMuiTheme, Tooltip, IconButton} from "@material-ui/core";
import { PersonAdd } from "@material-ui/icons";

const Final = () => {


    const [APIData, setAPIData] = useState([]);
    const [name, setName] = useState('');
    const [statusId, setStatusId] = useState('');
    const [feeTypeId, setFeeTypeId] = useState(null);
    const [modalState, setModalState] = useState(false);

    const manageState = () => {
        setModalState(!modalState)
    }

    useEffect(() => {
        axios.get(`http://zsktech1-001-site1.ftempurl.com/api/FeeType`)
        .then((response) => {
            console.log(response)
            setAPIData(response.data.data);
        })
    }, [])

    

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(e)
    }

    
    const postData = () => {
        
        console.log(name);
        console.log(statusId);

        // axios.post(`https://616078fdfaa03600179fbaa7.mockapi.io/fakeData`
        axios.post(`http://zsktech1-001-site1.ftempurl.com/api/FeeType`, {
            name,
            statusId,
        })
        // .then(() => {
        //     history.push('/read')
        // })


    }


    // const setData = (data) => {
    //     console.log(data);
    //     let { feeTypeId, name, statusId } = data;
    //     localStorage.setItem('ID', feeTypeId);
    //     localStorage.setItem('Name', name);
    //     localStorage.setItem('Status Id', statusId);
    //  }


    const columns = [
            {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
                }
            },
            // {
            // name: "statusId",
            // label: "Status Id",
            // options: {
            //     filter: true,
            //     sort: false,
            //     }
            // },
            {
                label: "Status Id",
                name: "statusId",
                options: {
                  filter: true,
                  sort: false,
                  empty: true,
                  customBodyRender: () => {
                    return (
                        <select class="form-select" aria-label="Default select example">
                            <option selected>--Choose</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                      </select>
                      // <button className="btn delete-btn btn-danger" onClick={() => {
                      //   const { countries } = this.state;
                      //   countries.splice(tableMeta.rowIndex,1);
                      //   console.log("tableMeta",tableMeta)
                      //   this.setState({ countries });
                      // }}>
                      //   Delete
                      // </button>
                    );
                    // console.log('Hello');
                    // console.log(tableMeta);
                  }
                }
            },
            {
                name: "Edit",
                options: {
                  filter: true,
                  sort: false,
                  empty: true,
                  customBodyRender: () => {
                    return (
                      <button className="btn edit-btn btn-primary">
                        Edit
                      </button>
                    );
                  }
                }
              },
              {
                name: "Delete",
                options: {
                  filter: true,
                  sort: false,
                  empty: true,
                  customBodyRender: () => {
                    return (
                      <button className="btn edit-btn btn-danger">
                        Delete
                      </button>
                    );
                  }
                }
              },
        ];

       

        {/* const data = [
        { name: "Joe James", company: "Test Corp", city: "Yonkers", state: "NY" },
        { name: "John Walsh", company: "Test Corp", city: "Hartford", state: "CT" },
        { name: "Bob Herm", company: "Test Corp", city: "Tampa", state: "FL" },
        { name: "James Houston", company: "Test Corp", city: "Dallas", state: "TX" },
        ]; */}


        // ADD custom ADD-button => to add new data
        const AddButton = () => (
            <Tooltip disableFocusListener title="Add User">
              <IconButton >
                <PersonAdd />
              </IconButton>
            </Tooltip>
          ); 

        const options = {
        filterType: 'checkbox',
        customToolbar: AddButton,
        };



    return (
        <>

            <MUIDataTable
            title={"Employee List"}
            data={APIData}
            columns={columns}
            options={options}
            />

            {/* create modal */}
            {/* <Modal show={modalState} onHide={() => manageState()}>
                <Modal.Body>
                  <Form className="create-form" onSubmit={handleSubmit}>
                    <Form.Field>
                        <label>Name</label>
                        <input placeholder='First Name'
                            name="name"
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Status Id</label>
                        <input placeholder='Last Name' 
                            name="statusId"
                            onChange={(e) => setStatusId(e.target.value)}
                        />
                    </Form.Field>
                    <Button type='submit' onClick={postData}>Submit</Button>
                  </Form>
                </Modal.Body>
            </Modal> */}
            
            {/* update modal */}
            {/* <Modal show={modalState} onHide={() => manageState()}>
                <Modal.Body>
                  <Form className="create-form" onSubmit={handleSubmit}>
                    <Form.Field>
                        <label>Name</label>
                        <input placeholder='First Name'
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Status Id</label>
                        <input placeholder='Last Name' 
                            name="statusId"
                            value={statusId}
                            onChange={(e) => setStatusId(e.target.value)}
                        />
                    </Form.Field>
                    <Button type='submit' onClick={updateAPIData}>Update</Button>
                  </Form>
                </Modal.Body>
            </Modal> */}
            
        </>
    )
}

export default Final
