import React, {useState} from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import './TeacherInfo.css';
import { Col, Row, Form, FormLabel, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import EditIcon from '../../../../assets/images/editicon.png';
import Facebook from '../../../../assets/images/facebook1.png'
import Twitter from '../../../../assets/images/twitter.png'
import Linkedin from '../../../../assets/images/linkedin1.png'
import TInfoList from './TInfoList';


export default function TeacherInfo() {

    const [modalState, setModalState] = useState(false);

    const manageState = () => {
        setModalState(!modalState)
    }

    const [data , setData] = useState({
        skillset:"",
        exp:"",
        year: "",
        degree: "",
        institute: "",
        edulvl: "",
        year1: "",
        language: "",
        other:"",
    })

    // const [list , setList] = useState([])

    

    const inputEvents = (e) => {
        console.log(e.target.value)
        console.log(e.target.name)
        // setData(e.target.value)

        // const value = e.target.value
        // const name = e.target.name

        const {name , value} = e.target;

        setData((preValue) => {

            return{
                ...preValue,
                [name]: value,
            }
            // setList({...preValue, [name]: value})


            // if (name === "skillset") {
            //     return {
            //         skillSet: value,
            //         experience: preValue.experience,
            //         year: preValue.year,
            //         degree: preValue.degree,
            //         institute: preValue.institute,
            //         edulvl: preValue.edulvl,
            //         year1: preValue.year1,
            //         language: preValue.language,
            //         other: preValue.other,
            //     };
            // }
            // else if (name === "exp") {
            //     return {
            //         skillSet: preValue.skillSet,
            //         experience: value,
            //         year: preValue.year,
            //         degree: preValue.degree,
            //         institute: preValue.institute,
            //         edulvl: preValue.edulvl,
            //         year1: preValue.year1,
            //         language: preValue.language,
            //         other: preValue.other,
            //     };
            // }
            // else if (name === "year") {
            //     return {
            //         skillSet: preValue.skillSet,
            //         experience: preValue.experience,
            //         year: value,
            //         degree: preValue.degree,
            //         institute: preValue.institute,
            //         edulvl: preValue.edulvl,
            //         year1: preValue.year1,
            //         language: preValue.language,
            //         other: preValue.other,
            //     };
            // }
            // else if (name === "degree") {
            //     return {
            //         skillSet: preValue.skillSet,
            //         experience: preValue.experience,
            //         year: preValue.year,
            //         degree: value,
            //         institute: preValue.institute,
            //         edulvl: preValue.edulvl,
            //         year1: preValue.year1,
            //         language: preValue.language,
            //         other: preValue.other,
            //     };
            // }
            // else if (name === "institute") {
            //     return {
            //         skillSet: preValue.skillSet,
            //         experience: preValue.experience,
            //         year: preValue.year,
            //         degree: preValue.degree,
            //         institute: value,
            //         edulvl: preValue.edulvl,
            //         year1: preValue.year1,
            //         language: preValue.language,
            //         other: preValue.other,
            //     };
            // }
            // else if (name === "edulvl") {
            //     return {
            //         skillSet: preValue.skillSet,
            //         experience: preValue.experience,
            //         year: preValue.year,
            //         degree: preValue.degree,
            //         institute: preValue.institute,
            //         edulvl: value,
            //         year1: preValue.year1,
            //         language: preValue.language,
            //         other: preValue.other,
            //     };
            // }
            // else if (name === "year1") {
            //     return {
            //         skillSet: preValue.skillSet,
            //         experience: preValue.experience,
            //         year: preValue.year,
            //         degree: preValue.degree,
            //         institute: preValue.institute,
            //         edulvl: preValue.edulvl,
            //         year1: value,
            //         language: preValue.language,
            //         other: preValue.other,
            //     };
            // }
            // else if (name === "language") {
            //     return {
            //         skillSet: preValue.skillSet,
            //         experience: preValue.experience,
            //         year: preValue.year,
            //         degree: preValue.degree,
            //         institute: preValue.institute,
            //         edulvl: preValue.edulvl,
            //         year1: preValue.year1,
            //         language: value,
            //         other: preValue.other,
            //     };
            // }
            // else if (name === "other") {
            //     return {
            //         skillSet: preValue.skillSet,
            //         experience: preValue.experience,
            //         year: preValue.year,
            //         degree: preValue.degree,
            //         institute: preValue.institute,
            //         edulvl: preValue.edulvl,
            //         year1: preValue.year1,
            //         language: preValue.language,
            //         other: value,
            //     };
            // }
        });

    }

    const onSubmit = (e) => {
        e.preventDefault()
        console.log(e)

        // setList([...list,data]);
    }

    // const [name , setName] = useState('');
    // let name , value
    // const [list , setList] = useState([]);
    // const [isEditing , setIsEditing] = useState(false)
    // const [editID , setEditID] = useState(null)

    // const handleSubmit = (e) => {
    //     e.preventDefault()
    //     console.log(e)

    //     name= e.target.name;
    //     value=e.target.value;

    //     if(!name) {
    //         alert("enter values")
    //     }
    //     else if(name && isEditing) {
    //         //deal with edit
    //     }
    //     else {
    //         // some alert
    //         const newItem = {
    //             id: new Date().getTime().toString(),
    //             title:name,
    //             text:value,
    //         };
    //         setList([...list,newItem]);
    //         // setName('')
    //     }
    // }
//{ skill:"", exp:"", edu:"", lang:"", other:""}
//{ skillset: "", experience: "", edu: "", lang: "", other: ""}
    // let name , value;
    // const handleInputs = (e) => {
    //     console.log(e)

    //     name = e.target.name;
    //     value = e.target.value;

    //     setData({...data, [name]:value});
    // }



    return (
        <div className="TI-containerfluid container-fluid bg-white p-3">
            <div className="TI-container container">
                <Col className="TI-C1">
                    <Row className="TI-C1-R1">
                        <h4 className="flex-grow-1">Teacher Info</h4>
                        {/* <Link to="/TeacherInfo2"><img src={EditIcon} ></img></Link> */}
                        <button className="btn" onClick={() => manageState()}><img src={EditIcon} ></img></button>
                    </Row>
                    <Row className="TI-C1-R2">
                        {/* <TInfoList items={list} /> */}
                        <Row>
                            <Col><h5 >Skill Set :</h5></Col>
                            <Col><p>{data.skillset}</p></Col>
                            {/* <Col><p >I love sharing with my knowledge and experience this is why I’m working 
                            on The Awwwesomes - an initiative….</p></Col> */}
                            {/* <Col> */}
                            {/* <p>{ArrayTeacherInfos.skill}</p> */}
                            {/* <p>{data.skill}</p> */}
                            {/* {data.map(e =>
                                <p>{e.skill}</p>
                            )} */}
                            {/* {list.map((info) => {
                                const {id, title} = info
                                return <div key={id}>
                                    <p>{title}</p>
                                </div>
                            })} */}
                            {/*</Col>*/}
                         </Row>
                        <Row>
                            <Col><h5 >Exp. :</h5></Col>
                            <Col>
                            <span><p>{data.exp}</p></span>
                            <span><p>{data.year}</p></span>
                            </Col>
                            {/* <Col><p>Themadbrains  •  2018 - Present
                                Dribbble  •  2018 - Present
                                Behance  •  2016 - Present
                                Uplabs  •  2014 - Present
                            </p></Col> */}
                            {/* <Col>
                                {ArrayTeacherInfos.map(e => (
                                    <p>{e.exp}</p>
                                ))}
                            </Col> */}
                        </Row>
                        <Row>
                            <Col><h5>Education :</h5></Col>
                            <Col>
                            <span><p>{data.degree}</p></span>
                            <span><p>{data.institute}</p></span>
                            <span><p>{data.edulvl}</p></span>
                            <span><p>{data.year1}</p></span>
                            </Col>
                            {/* <Col><p>Kanpur university  •  2002 - 2004</p></Col> */}
                            {/* <Col>
                                {   ArrayTeacherInfos.map(e => (
                                        <p>{e.edu}</p>
                                    ))}
                            </Col> */}
                        </Row>
                        <Row>
                            <Col><h5>language : </h5></Col>
                            <Col><span><p>{data.language}</p></span></Col>
                            {/* <Col><p>English, Urdu</p></Col> */}
                            {/* <Col>
                                {ArrayTeacherInfos.map(e => (
                                    <p>{e.lang}</p>
                                ))}
                            </Col> */}
                        </Row>
                        <Row>
                            <Col><h5>Other :</h5></Col>
                            <Col><p>{data.other}</p></Col>
                            {/* <Col><p>Coach and mentor of General science/science and technology/ Ecology 
                            and Environment.<br /> I have 10 years teaching experience. 
                            Mentored more than 5000 student since 2010</p></Col>  */}
                            {/* <Col>
                                {ArrayTeacherInfos.map(e => (
                                    <p>{e.other}</p>
                                ))}
                            </Col> */}
                        </Row>
                    </Row>
                    <Row className="TI-C1-R3">
                        <h5>Social Links</h5>
                        <Row>
                            <Link to='/#'><img src={Facebook}></img></Link>
                            <Link to='/#'><img src={Linkedin}></img></Link>
                            <Link to='/#'><img src={Twitter}></img></Link>
                        </Row>
                    </Row>
                </Col>
            </div>

            <Modal className="TPinfo-Modal" show={modalState} onHide={() => manageState()}>
                <Modal.Body>
                    <Form onSubmit={onSubmit}>
                        <Col className="TI2-C1">
                            <Row className="TI2-C1-R1">
                                <h4 className="flex-grow-1">Teacher Info</h4>
                                <button className="btn my-button" type="submit"
                                // onClick={() => addInfo}
                                >
                                
                                Save
                                </button>
                            </Row>
                            <Row className="TI2-C1-R2 py-3">
                                <Row>
                                    <div className="form-group d-flex">
                                        <Col className="col-md-5">
                                            <FormLabel htmlFor="skillset">Skill Set :</FormLabel>
                                        </Col>
                                        <Col className="col-md-7">
                                            <Row><input type="text" className="form-control" 
                                            name="skillset" onChange={inputEvents} value={data.skillset}
                                            placeholder="" 
                                            id="skillset" /></Row>
                                        </Col>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="form-group d-flex">
                                        <Col className="col-md-5">
                                            <FormLabel>Exp :</FormLabel>
                                        </Col>
                                        <Col className="col-md-7">
                                            <Row className="d-flex flex-column">
                                                <FormLabel htmlFor="experience">Experience</FormLabel>
                                                <input type="text" className="form-control" 
                                                name="exp" onChange={inputEvents} value={data.exp}
                                                // value={name} 
                                                // onChange={(e) => setName(e.target.value)} 
                                                placeholder="" id="experience" />
                                            </Row>
                                            <Row className="d-flex flex-row">
                                                <div className="d-flex flex-column col-md-6 p-0">
                                                    <FormLabel htmlFor="year">Year</FormLabel>
                                                    <select name="year" 
                                                    onChange={inputEvents} value={data.year}
                                                    id="year" placeholder="1990">
                                                        <option value="1990">1990</option>
                                                        <option value="1991">1991</option>
                                                        <option value="1992">1992</option>
                                                        <option value="1993">1993</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-6">
                                                    <Link to="/#"><button className="btn my-button">Add more</button></Link>
                                                </div>                                            
                                        </Row>                                       
                                        </Col>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="form-group d-flex">
                                        <Col className="col-md-5">
                                            <FormLabel>Education :</FormLabel>
                                        </Col>
                                        <Col className="col-md-7">
                                            <Row className="d-flex flex-column">
                                                <FormLabel htmlFor="degree">Degree</FormLabel>
                                                <select name="degree" 
                                                onChange={inputEvents} value={data.degree}
                                                id="degree" placeholder="Select your degree">
                                                        <option value="BSCS">BSCS</option>
                                                        <option value="BBA">BBA</option>
                                                        <option value="Medical">Medical</option>
                                                        <option value="M.A">M.A</option>
                                                    </select>
                                            </Row>
                                            <Row className="d-flex flex-column">
                                                <FormLabel htmlFor="institute">Institute</FormLabel>
                                                    <select name="institute" 
                                                    onChange={inputEvents} value={data.institute}
                                                    id="institute" placeholder="Select your institute">
                                                        <option value="A">A</option>
                                                        <option value="B">B</option>
                                                        <option value="C">C</option>
                                                        <option value="D">D</option>
                                                    </select>
                                            </Row>
                                            <Row className="d-flex flex-row">
                                                <div className="d-flex flex-column col-md-6 p-0">
                                                    <FormLabel htmlFor="edulvl">Education level</FormLabel>
                                                    <select name="edulvl" 
                                                    onChange={inputEvents} value={data.edulvl}
                                                    id="edulvl" placeholder="Graduation">
                                                        <option value="A">A</option>
                                                        <option value="B">B</option>
                                                        <option value="C">C</option>
                                                        <option value="D">D</option>
                                                    </select>
                                                </div>
                                                <div className="d-flex flex-column col-md-6">
                                                    <FormLabel htmlFor="year1">Year</FormLabel>
                                                    <select name="year1" 
                                                    onChange={inputEvents} value={data.year1}
                                                    id="year1" placeholder="1990">
                                                        <option value="1990">Volvo</option>
                                                        <option value="1991">Saab</option>
                                                        <option value="1992">Opel</option>
                                                        <option value="1993">Audi</option>
                                                    </select>
                                                </div>
                                            </Row>                                        
                                        </Col>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="form-group d-flex">
                                        <Col className="col-md-5">
                                            <FormLabel>Language :</FormLabel>
                                        </Col>
                                        <Col className="col-md-7">                                       
                                            <Row className="d-flex flex-row">
                                                <div className="d-flex flex-column col-md-6 p-0">
                                                    <FormLabel htmlFor="language">Select Language</FormLabel>
                                                    <select name="language" 
                                                    onChange={inputEvents} value={data.language}
                                                    id="language" placeholder="English">
                                                        <option value="A">A</option>
                                                        <option value="B">B</option>
                                                        <option value="C">C</option>
                                                        <option value="D">D</option>
                                                    </select>
                                                </div>
                                                <div classname="col-md-6">
                                                <Link to="/#"><button className="btn my-button">Add more</button></Link>
                                                </div>                                            
                                        </Row>                                       
                                        </Col>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="form-group d-flex">
                                        <Col className="col-md-5">
                                            <FormLabel htmlFor="other">Other :</FormLabel>
                                        </Col>
                                        <Col className="col-md-7">
                                            <Row><input type="text" className="form-control" 
                                            name="other" 
                                            onChange={inputEvents} value={data.other}
                                            // value={name} 
                                            // onChange={(e) => setName(e.target.value)} 
                                            placeholder="" id="other" /></Row>
                                        </Col>
                                    </div>
                                </Row>                          
                            </Row>
                            
                            <Row className="TI2-C1-R3">
                                <h5>Social Links</h5>
                                <Row>
                                    <Link to='/#'><img src={Facebook}></img></Link>
                                    <Link to='/#'><img src={Linkedin}></img></Link>
                                    <Link to='/#'><img src={Twitter}></img></Link>
                                </Row>
                            </Row>
                        </Col>
                    </Form>
                </Modal.Body>
            </Modal>



        </div>
    )

}