import React, {useState} from 'react'
import './ViewAllCourses.min.css'

import GetYourCoursesRow2Component from '../../../GetYourCoursesRow2component/GetYourCoursesRow2component'

import ReactPaginate from 'react-paginate'



import Screenshot1 from '../../../../../../images/Screenshot1.png'

import VACourselist from './VACourselist'

const ViewAllCourses = () => {

     const [displayCourses, setItems] = useState(VACourselist);
     const filterItems = (categItems) => {
        const updatedItems = VACourselist.filter((curElem) => {
            return curElem.category1 == categItems || curElem.category2 == categItems;
            
        });
        console.log(categItems);
         setItems(updatedItems);
     }

    


    const [pageNumber, setPageNumber] = useState(0)

    const coursesPerPage = 16
    const pageVisited = pageNumber * coursesPerPage

    const items = displayCourses
    .slice(pageVisited, pageVisited + coursesPerPage)
    .map((arrayarg, i) => {
        return (
            <GetYourCoursesRow2Component key={i}
            id="i" 
            name={VACourselist[i].name}
            src={VACourselist[i].src}
            />

        )
    })


    const pageCount = Math.ceil(VACourselist.length / coursesPerPage);
    const changePage = ({selected}) => {
        setPageNumber(selected)
    };

     

        return (
            <>
                <div className="container VACourses">
                    <div className="cstm-afterDiv px-3">
                        <img src={Screenshot1}></img>
                    </div>
                    <div className="row r1 px-3 pt-5">
                        <div className="col-md-3 col-sm-12 col-12" >
                            <select class="form-select cstm-select" aria-label="Default select example"
                            onChange = {(e) => {
                                const selectOption = e.target.value;
                                const updatedOptions = VACourselist.filter((curElem) => {
                                    return curElem.category3 == selectOption;
            
                                });
                                setItems(updatedOptions);

                            }} >
                                <option value="Popular">Popular</option>
                                <option value="Regular">Regular</option>
                            </select>
                        </div>
                        <div className="col-md-9 col-sm-12 col-12 d-flex justify-content-end" >
                            <button className="btn button" onClick={() => filterItems('All')}>All</button>
                            <button className="btn button"onClick={() => filterItems('Design')}>Design</button>
                            <button className="btn button"onClick={() => filterItems('Business')}>Business</button>
                            <button className="btn button"onClick={() => filterItems('Marketing')}>Marketing</button>
                            <button className="btn button"onClick={() => filterItems('Photography')}>Photography</button>
                            <button className="btn button"onClick={() => filterItems('Music')}>Music</button>
                        </div>
                    </div>
                    <div className="display-Courses d-flex flex-wrap">
                        {items}
                    </div>
                    {/* <div className="display-Courses d-flex flex-wrap">
                        {items}
                    </div> */}
                    {<ReactPaginate
                        previousLabel={<i class="fas fa-chevron-left"></i>}
                        nextLabel={<i class="fas fa-chevron-right"></i>}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"paginationButtons"}
                        previousLinkClassName={"previousButton"}
                        nextLinkClassName={"nextButton"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                    />}
                </div>
                
            </>
        )

    
}

export default ViewAllCourses