import React from 'react';
import './Conditions.css';

const Conditions = () => {
    return (
        <div className="container-fluid conditions-containerfluid p-5">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="termAndCondGener">
                            <h5>Terms and Conditions</h5>
                            <p>
                                Please read these Terms and Conditions (“Terms”, “Terms and Conditions”) carefully before using 
                                the https://www.zmk.com/ website (the “Service”) operated by Vavaweb Ltd, a company registered 
                                In Sha Allah England and Wales with registration number 8719126 (“us”, “we”, or “our”).
                            </p>
                            <p>
                                Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. 
                                These Terms apply to all visitors, users and others who access or use the Service.

                            </p>
                            <p>
                                By accessing or using the Service you agree to be bound by these Terms. If you disagree with 
                                any part of the terms then you may not access the Service.

                            </p>
                        </div>
                        <div className="termAndCondGener">
                            <h5>Links To Other Web Sites</h5>
                            <p>
                                Our Service may contain links to third-party web sites or services that are not 
                                owned or controlled by Vavaweb Ltd.
                            </p>
                            <p>
                                Vavaweb Ltd has no control over, and assumes no responsibility for, the content, privacy
                                policies, or practices of any third party  web sites or services. You further 
                                acknowledge and agree that Vavaweb Ltd shall not be responsible or liable, directly or
                                indirectly, for any damage or loss caused or alleged to be caused by or In Sha Allah 
                                connection with use of or reliance on any  such content, goods or services available on 
                                or through any such web sites or services.
                            </p>
                            <p>
                                We strongly advise you to read the terms and conditions and privacy policies of any 
                                third-party web sites or services that you  visit.
                            </p>
                        </div>
                        
                        <div className="termAndCondGener">
                            <h5>Indemnification</h5>
                            <p>
                                You agree to defend, indemnify and hold harmless Vavaweb Ltd and its licensee and 
                                licensors, and their employees, contractors, agents, officers and directors, from 
                                and against any and all claims, damages, obligations, losses, liabilities, costs or 
                                debt, and expenses (including but not limited to attorney’s fees), resulting from or 
                                arising out of a) your use and access of the  Service, or b) a breach of these Terms.
                                
                            </p>
                        </div>
                        <div className="termAndCondGener">
                            <h5>Limitation Of Liability</h5>
                            <p>
                                In no event shall Vavaweb Ltd, nor its directors, employees, partners, agents, 
                                suppliers, or affiliates, be liable for any indirect, incidental, special, consequential 
                                or punitive damages, including without limitation, loss of profits, data, 
                                use, goodwill, or other intangible losses, resulting from (i) your access to or use 
                                of or inability to access or use the Service; (ii) any conduct or content of any third 
                                party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized
                                access, use or alteration of your transmissions or content, whether based on 
                                warranty, contract, tort (including negligence) or any other legal theory, whether 
                                or not we have been informed of the possibility of such damage, and even if a remedy set
                                forth herein is found to have failed of its essential purpose.
                            </p>
                        </div>
                        <div className="termAndCondGener">
                            <h5>Disclaimer</h5>
                            <p>
                                Your use of the Service is at your sole risk. The Service is provided on an “AS IS”
                                and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, 
                                whether express or implied, including, but not limited to, implied warranties of 
                                merchantability, fitness for a particular purpose, non-infringement or course of 
                                performance.
                            </p>
                            <p>
                                Vavaweb Ltd its subsidiaries, affiliates, and its licensors do not warrant that a) the
                                Service will function uninterrupted, secure or available at any particular time 
                                or location; b) any errors or defects will be corrected; c) the Service is free of
                                viruses or other harmful components; or d) the results of using the Service will 
                                meet your requirements.
                            </p>
                        </div>
                        <div className="termAndCondGener">
                            <h5>Exclusions</h5>
                            <p>
                                Without limiting the generality of the foregoing and notwithstanding any other 
                                provision of these terms, under no circumstances will Vavaweb Ltd ever be liable 
                                to you or any other person for any indirect, incidental, consequential, special, 
                                punitive or exemplary loss or damage arising from, connected with, or relating to your
                                use of the Service, these Terms, the subject matter of these Terms, the termination of
                                these Terms or otherwise, including but not limited to personal injury, loss of
                                data, business, markets, savings, income, profits, use, production, reputation or
                                goodwill, anticipated or otherwise, or economic loss, under any theory of 
                                liability (whether in contract, tort, strict liability or any other theory or law or
                                equity), regardless of any negligence or other fault or wrongdoing (including
                                without limitation gross negligence and fundamental breach) by Vavaweb Ltd or any
                                person for whom Vavaweb Ltd is responsible, and even if Vavaweb Ltd has been 
                                advised of the possibility of such loss or damage being incurred.
                            </p>
                        </div>
                        <div className="termAndCondGener">

                            <h5>Governing Law</h5>
                            <p>
                                These Terms shall be governed and construed in accordance with the laws of England
                                and Wales, without regard to its conflict of law provisions.
                            </p>
                            <p>
                                Our failure to enforce any right or provision of these Terms will not be considered a 
                                waiver of those rights. If any provision of these Terms is held to be invalid or 
                                unenforceable by a court, the remaining provisions of these Terms will remain in 
                                effect. These Terms constitute the entire agreement between us regarding our 
                                Service, and supersede and replace any prior agreements we might have between us 
                                regarding the Service.
                            </p>
                        </div>
                        <div className="termAndCondGener">

                            <h5>Changes</h5>
                            <p>
                                We reserve the right, at our sole discretion, to modify or replace these Terms at 
                                any time. If a revision is material we will try to provide at least 30 days notice 
                                prior to any new terms taking effect. What constitutes a material change will be 
                                determined at our sole discretion.
                            </p>
                            <p>
                                By continuing to access or use our Service after those revisions become effective, you 
                                agree to be bound by the revised terms. If you do not agree to the new terms, you 
                                must stop using the service.
                            </p>
                        </div>
                        <div className="termAndCondGener">

                            <h5>Privacy Policy</h5>
                            <p>
                                Please refer to our Privacy Policy. You agree that they constitute part of these 
                                terms. You must read our Privacy Policy before you use the Service.
                            </p>
                        </div>
                        <div className="termAndCondGener">

                            <h5>Contact Us</h5>
                            <p>
                                If you have any questions about these Terms, please contact us.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Conditions;