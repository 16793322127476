import React, {useState} from 'react'
import { Col, Row, Button, Form, FormLabel, Modal} from 'react-bootstrap'
import './DBstdprofilev1.css'
import TableComponent from './TableComponent/TableComponent'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import search1 from '../../../../images/search1.png'
import addfriend from '../../../../images/addfriend.png'
import { Link } from 'react-router-dom'

const DBstdprofilev1 = () => {

    const [modalState, setModalState] = useState(false);

    const manageState = () => {
        setModalState(!modalState)
    }
   


    return (
        <>
            <div className="container DBstdprofilev1 col-md-12 p-5">
                <Row className="d-flex justify-content-between pb-4">
                    <div class="flex-grow-1">
                        <Form className="form-inline" action="/action_page.php">
                            <div className="px-2 py-1 dbsearch-div">
                                <input className="form-control dbsearch" type="text" placeholder="Search"/>
                                <Link to="#/"><img src={search1}></img></Link>
                            </div>
                        </Form>
                    </div>
                    <div className="mx-3">
                        <select className="db-button px-2" name="year" id="language" placeholder="Language">
                            <option value="Active">Active Student</option>
                            <option value="Offline">Offline Student</option>

                            {/* <i className="fas fa-circle">
                            <i className="fas fa-circle"></i> */}
                            
                        </select>
                    </div>
                    <div className="">
                        <button className="btn newstd" onClick={() => manageState()}><img className="px-2" src={addfriend}></img>New Student</button>
                    </div>
                </Row>
                <Row>
                    <TableComponent/>
                </Row>
                <Row className="d-flex justify-content-between">
                    <div>
                        <p>Showing 10 from 46 data</p>
                    </div>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                            </li>
                            <li class="page-item "><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                            </li>
                        </ul>
                    </nav>
                </Row>
            </div>


            <div>
                        
                        <Modal className="DBStdProfile-Modal" show={modalState} onHide={() => manageState()}>
                            <Modal.Header closeButton >
                                <div className="row d-flex px-3">
                                    <div><h5 className="header">Details</h5></div>
                                    
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    
    
                                    <div className="row d-flex justify-content-between">
                                        <div className="d-flex flex-column col">
                                            <FormLabel className="label" htmlFor="holdername">Full Name</FormLabel>
                                            <input type="text" className="form-control border" placeholder="Full Name" id="fullname" />
                                        </div>
                                        <div className="d-flex flex-column col">
                                            <FormLabel className="label" htmlFor="cardnumber">Email</FormLabel>
                                            <input type="text" className="form-control border" placeholder="Email" id="email" />
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column col">
                                            <FormLabel className="label" htmlFor="gender">Gender</FormLabel>
                                            <select className="border py-2" name="gender" id="gender" placeholder="Gender">
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="other">Other</option>
                                            </select>
                                    </div>
                                    <div className="row d-flex py-3">
                                        <div className="d-flex flex-column col">
                                            <FormLabel className="label" htmlFor="date">Date</FormLabel>
                                            <select className="border py-2" name="date" id="gender" placeholder="Date">
                                                <option value="01">01</option>
                                                <option value="02">02</option>
                                                <option value="03">03</option>
                                            </select>
                                        </div>
                                        <div className="d-flex flex-column col">
                                            <FormLabel className="label" htmlFor="date">Month</FormLabel>
                                            <select className="border py-2" name="month" id="month" placeholder="Month">
                                                <option value="01">01</option>
                                                <option value="02">02</option>
                                                <option value="03">03</option>
                                            </select>
                                        </div>
                                        <div className="d-flex flex-column col">
                                            <FormLabel className="label" htmlFor="year">Year</FormLabel>
                                            <select className="border py-2" name="year" id="gender" placeholder="Year">
                                                <option value="01">1994</option>
                                                <option value="02">1995</option>
                                                <option value="03">1996</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                    <div className="row d-flex ">
                                        <div className="d-flex flex-column col">
                                                <FormLabel className="label" htmlFor="phone">Phone</FormLabel>
                                                <input type="text" className="form-control border" placeholder="Phone" id="phone" />
                                            </div>
                                            <div className="d-flex flex-column col">
                                                <FormLabel className="label" htmlFor="language">Language</FormLabel>
                                                <input type="text" className="form-control border" placeholder="Language" id="language" />
                                            </div>
                                    </div>
                                    <div className="row d-flex ">
                                        <div className="d-flex flex-column col">
                                                <FormLabel className="label" htmlFor="address">Address</FormLabel>
                                                <input type="text" className="form-control border" placeholder="Address" id="address" />
                                            </div>
                                    </div>
                                    <div className="row d-flex ">
                                        <div className="d-flex flex-column col">
                                                <FormLabel className="label" htmlFor="city">City</FormLabel>
                                                <input type="text" className="form-control border" placeholder="City" id="city" />
                                            </div>
                                        <div className="d-flex flex-column col">
                                            <FormLabel className="label" htmlFor="state">State</FormLabel>
                                            <input type="text" className="form-control border" placeholder="state" id="state" />
                                        </div>
                                        <div className="d-flex flex-column col">
                                            <FormLabel className="label" htmlFor="zipcode">Zip Code</FormLabel>
                                            <input type="text" className="form-control border" placeholder="Zip Code" id="zipcode" />
                                        </div>
                                        <div className="d-flex flex-column col">
                                            <FormLabel className="label" htmlFor="country">Country</FormLabel>
                                            <input type="text" className="form-control border" placeholder="Country" id="country" />
                                        </div>
                                    </div>
                                    <div className="row d-flex ">
                                        <div className="d-flex flex-column col">
                                                <FormLabel className="label" htmlFor="degree">Degree</FormLabel>
                                                <input type="text" className="form-control border" placeholder="Degree" id="degree" />
                                        </div>
                                        <div className="d-flex flex-column col">
                                                <FormLabel className="label" htmlFor="institute">Institute</FormLabel>
                                                <input type="text" className="form-control border" placeholder="Institute" id="institute" />
                                        </div>
                                    </div>
                                    <div className="row d-flex ">
                                        <div className="d-flex flex-column col">
                                                <FormLabel className="label" htmlFor="about">About</FormLabel>
                                                <input type="text" className="form-control border" placeholder="About" id="about" />
                                        </div>
                                    </div>
                                </Form>
                            </Modal.Body>
    
                            <Modal.Footer >
                                <div className="row d-flex px-3">
                                    <div><button className="btn btn-success">Save</button></div>
                                    
                                </div>
                            </Modal.Footer>
                            
                            
                        </Modal>
                    </div>

        </>
    )
}

export default DBstdprofilev1
