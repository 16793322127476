import React from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import Popper from 'popper.js';
import './SkillfyPowers.css';
import bannerpic from '../../../../images/bannerpic.png';
import Searchbtn1 from '../../../../images/Searchbtn1.png';
import '../../../../../node_modules/video-react/dist/video-react.css';
import { Player } from 'video-react';

import { useSpring, animated } from 'react-spring'

const SkillfyPowers = () => {

    const props = useSpring({ from: { opacity: 0 , marginTop: -500 } , to: { opacity: 1 , marginTop:0 } })

    return (
        <div className="Skillfy-section col-12" style={{background:"#005D8A"}}>
                <div className="container SkillfyPowers">
                    <animated.div style={props}>
                    <div className="row">
                        <div className="col-lg-6">
                           <div className="skillfy-content-div">
                                <h3 className="cstm-h4">Skillfy powers Course 1,000,000 bussiness worldwide</h3>
                                <p>By connecting students all over the world to the best instructors, Skillfy is helping individuals reach their goals and pursue their dreams.</p>
                                <button className="btn button btn-danger">Read more</button>
                           </div>

                        </div>
                        <div className=" col-lg-6 AboutUs-ct-img-column2">
                            <img></img>
                        </div>
                    </div>
                    </animated.div>
            </div>
        </div>
    )
}

export default SkillfyPowers;