import React from 'react'
import './ADheader.css'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

import menu1 from '../../../images/menu1.png'
import office1 from '../../../images/office1.png'
import bell1 from '../../../images/bell1.png'
import chat1 from '../../../images/chat1.png'
import search1 from '../../../images/search1.png'
import profilePicture from '../../../images/profilePicture.png'
import logo1 from '../../../images/logo1.png'
import dashboard from '../../../images/dashboard.png'

import { Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import DBstdprofilev1 from '../components/DBstdprofilev1/DBstdprofilev1'
import DBstdprofilev2 from '../components/DBstdprofilev2/DBstdprofilev2'
import DBprofileapproval from '../components/DBprofileapproval/DBprofileapproval'
import SPProfile from '../components/SPProfile/SPProfile'
import DBfinance from '../components/DBfinance/DBfinance'
import Dashboard from '../components/Dashboard/Dashboard'
import DBteacherprofilev1 from '../components/DBteacherprofilev1/DBteacherprofilev1'
import DBteacherprofilev2 from '../components/DBteacherprofilev2/DBteacherprofilev2'
import DBteacherprofileapproval from '../components/DBteacherprofileapproval/DBteacherprofileapproval'
import SPProfileteacher from '../components/SPProfileteacher/SPProfileteacher'
import CountryAPI from '../Country/countryAPI'
import DegreeAPI from '../Degree/DegreeAPI'
import Degree_LevelAPI from '../Degree_Level/Degree_LevelAPI'
import EmployeeTypeAPI from '../EmployeeType/EmployeeTypeAPI'
import InstituteAPI from '../Institute/InstituteAPI'
import ModuleAPI from '../Module/ModuleAPI'
import PermissionAPI from '../Permission/PermissionAPI'
import RoleAPI from '../Role/RoleAPI'
import SkillAPI from '../Skill/SkillAPI'
import StatusAPI from '../Status/StatusAPI'
import BankTypeAPI from '../BankType/bankTypeAPI'
import CityAPI from '../City/cityAPI'
import CourseTypeAPI from '../CourseType/CourseTypeAPI'
import CourseSubTypeAPI from '../CourseSubType/CourseSubTypeAPI'
import LanguageAPI from '../Language/LanguageAPI'
import LanguageTypeAPI from '../LanguageType/LanguageTypeAPI'
import WeatherForcastAPI from '../WeatherForecast/WeatherForecastAPI'
import DiscountTypeAPI from '../DiscountType/DiscountTypeAPI'
// import feeType from '../feeType/feeType'
import FeeTypeAPI from '../feeType/feeTypeAPI'
import TicketTypeAPI from '../ticketType/ticketTypeAPI'
import QuizTypeAPI from '../quizType/quizTypeAPI'
// import FeeTypeExtra from '../feeTypeExtra/feeTypeExtra'
import NotificationAPI from '../Notification/NotificationAPI'
import EvaluationAPI from '../Evaluation/EvaluationAPI'

const ADheader = () => {
    return (
        <>
            <div className="container-fluid mySideNavBar">
                <div className="row flex-nowrap wrapper">
                    <div className="col-md-2 col-1 pl-0 pr-0 collapse width border-right sidebar vh-100">
                        <div id="sidebar" className="list-group">
                            <div className="list-group-item d-flex justify-content-around">
                                <div><img src={logo1}></img></div>
                                <div>
                                    <h5 className="zmk mb-0">Z M K</h5>
                                    <p className="sidebar-text">Admin Dashboard</p>
                                </div>
                            </div>
                            <div className="list-group-item d-flex justify-content-around">
                                {/* <div><img src={profilePicture}></img></div>
                                <div>
                                    <h5 className="proName mb-0">Alishan Nawaz</h5>
                                    <p className="sidebar-text">Super Admin</p>
                                </div>*/}
                                <div className="dropdown">
                                    <a className="btn button dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false">
                                    <div><img src={profilePicture}></img></div>
                                        <div className="px-1">
                                            <h5 className="proName mb-0">Alishan Nawaz</h5>
                                            <p className="sidebar-text">Super Admin</p>
                                        </div>
                                    </a>

                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </div>
                            </div> 
                           
                            <div className="list-group-item cstm-overflow">
                                <div><h5 className="mainMenu">Main Menu</h5></div>
                                <ul className="nav nav-tabs d-flex flex-column" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" data-toggle="tab" href="#Dashboard" role="tab"><i className="fas fa-th-large pr-2"></i>Dashboard</a>
                                    </li>
                                    <li className="nav-item">
                                        {/* <a className="nav-link" data-toggle="tab" href="#stdcourse" role="tab">Student</a> */}
                                        <a className="nav-link" data-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1"><i className="fas fa-graduation-cap pr-2"></i>Student</a>
                                        {/* <span className="badge badge-style clr-yellow"><p className="text-white badge-text">35</p></span> */}
                                        <div className="collapse" id="multiCollapseExample1">
                                            <ul className="nav nav-tabs px-3" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link" data-toggle="tab" href="#dbstdprofile" role="tab">Student Profiles</a>
                                                    <a className="nav-link" data-toggle="tab" href="#DBprofileapproval" role="tab">Profile Approval</a>
                                                    <a className="nav-link" data-toggle="tab" href="#stdquiz" role="tab">Student Payments</a>
                                                    <a className="nav-link" data-toggle="tab" href="#stdquiz" role="tab">Student Support</a>
                                                </li>
                                            </ul>
                                            
                                        </div>
                                    </li>
                                    <li className="nav-item">
                                    <a className="nav-link" data-toggle="collapse" href="#multiCollapseExample2" role="button" aria-expanded="false" aria-controls="multiCollapseExample2"><i className="fas fa-user-friends pr-2"></i>Teacher</a>
                                        <div className="collapse" id="multiCollapseExample2">
                                            <ul className="nav nav-tabs px-3" role="tablist">
                                                <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#dbteacherprofile" role="tab">Teacher Profiles</a>
                                                <a className="nav-link" data-toggle="tab" href="#DBteacherprofileapproval" role="tab">Profile Approval</a>
                                                <a className="nav-link" data-toggle="tab" href="#stdquiz" role="tab">Teacher Payments</a>
                                                <a className="nav-link" data-toggle="tab" href="#stdquiz" role="tab">Teacher Support</a>
                                                </li>
                                            </ul>
                                        </div>
                                        
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#stdassessment" role="tab"><i className="fas fa-calendar pr-2"></i>Reports</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#dbfinance" role="tab"><i className="fas fa-chart-bar pr-2"></i>Finance</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#stdlecture" role="tab"><i className="fas fa-cog pr-2"></i>Settings</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="collapse" href="#setupCollapse" role="button" aria-expanded="false" aria-controls="multiCollapseExample1"><i className="fas fa-user-cog pr-2"></i>Setup</a>
                                        <div className="collapse" id="setupCollapse">
                                            <ul className="nav nav-tabs px-3" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link" data-toggle="tab" href="#bankTypeAPI" role="tab">Bank Type</a>
                                                    <a className="nav-link" data-toggle="tab" href="#cityAPI" role="tab">City</a>
                                                    <a className="nav-link" data-toggle="tab" href="#languageAPI" role="tab">Language</a>
                                                    <a className="nav-link" data-toggle="tab" href="#language_typeAPI" role="tab">Language Type</a>
                                                    <a className="nav-link" data-toggle="tab" href="#course_typeAPI" role="tab">Course Type</a>
                                                    <a className="nav-link" data-toggle="tab" href="#weather_forcastAPI" role="tab">Weather Forcast</a>
                                                    <a className="nav-link" data-toggle="tab" href="#course_sub_typeAPI" role="tab">Course Sub Type</a>
                                                    {/* <a className="nav-link" data-toggle="tab" href="#city" role="tab">city</a> */}
                                                    <a className="nav-link" data-toggle="tab" href="#countryAPI" role="tab">Country</a>
                                                    <a className="nav-link" data-toggle="tab" href="#degreeAPI" role="tab">Degree</a>
                                                    <a className="nav-link" data-toggle="tab" href="#degree_levelAPI" role="tab">Degree Level</a>
                                                    <a className="nav-link" data-toggle="tab" href="#employee_typeAPI" role="tab">Employee Type</a>
                                                    <a className="nav-link" data-toggle="tab" href="#instituteAPI" role="tab">Institute</a>
                                                    <a className="nav-link" data-toggle="tab" href="#moduleAPI" role="tab">Module</a>
                                                    <a className="nav-link" data-toggle="tab" href="#permissionAPI" role="tab">Permission</a>
                                                    <a className="nav-link" data-toggle="tab" href="#roleAPI" role="tab">Role</a>
                                                    <a className="nav-link" data-toggle="tab" href="#skillAPI" role="tab">Skill</a>
                                                    <a className="nav-link" data-toggle="tab" href="#statusAPI" role="tab">Status</a>
                                                    <a className="nav-link" data-toggle="tab" href="#fee_typeAPI" role="tab">Fee Type</a>
                                                    <a className="nav-link" data-toggle="tab" href="#ticket_typeAPI" role="tab">Ticket Type</a>
                                                    <a className="nav-link" data-toggle="tab" href="#quiz_typeAPI" role="tab">Quiz Type</a>
                                                    {/* <a className="nav-link" data-toggle="tab" href="#FeeTypeExtra" role="tab">FeeTypeExtra</a> */}
                                                    <a className="nav-link" data-toggle="tab" href="#notificationAPI" role="tab">Notification</a>
                                                    <a className="nav-link" data-toggle="tab" href="#evaluationAPI" role="tab">Evaluation</a>
                                                    <a className="nav-link" data-toggle="tab" href="#discountTypeAPI" role="tab">Discount Type</a>

                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="list-group-item d-flex justify-content-around flex-column align-items-center">
                                <div>
                                    <p className="sidebar-footer-text1">ZMK TUTOR APP</p>
                                    <p className="sidebar-footer-text2">© 2021 All Rights Reserved</p>
                                </div>
                                <div>
                                    <p className="sidebar-footer-text2">Made with ♥ by ZMKTECHSOLUTIONS</p>
                                </div>
                                
                            </div>
                        </div>
                        {/* <div className="list-group border-0 card text-center text-md-left" id="sidebar">
                            <a href="#" className="list-group-item border-right-0 d-inline-block" data-parent="#sidebar"><i className="fa fa-dashboard"></i> <span className="d-none d-md-inline">Item 1</span> </a>
                            <a href="#" className="list-group-item border-right-0 d-inline-block" data-parent="#sidebar"><i className="fa fa-film"></i> <span className="d-none d-md-inline">Item 2</span></a>
                            <a href="#" className="list-group-item border-right-0 d-inline-block" data-parent="#sidebar"><i className="fa fa-heart"></i> <span className="d-none d-md-inline">Item 4</span></a>
                            <a href="#" className="list-group-item border-right-0 d-inline-block" data-parent="#sidebar"><i className="fa fa-list"></i> <span className="d-none d-md-inline">Item 5</span></a>
                            <a href="#" className="list-group-item border-right-0 d-inline-block" data-parent="#sidebar"><i className="fa fa-clock-o"></i> <span className="d-none d-md-inline">Link</span></a>
                            <a href="#" className="list-group-item border-right-0 d-inline-block" data-parent="#sidebar"><i className="fa fa-th"></i> <span className="d-none d-md-inline">Link</span></a>
                            <a href="#" className="list-group-item border-right-0 d-inline-block" data-parent="#sidebar"><i className="fa fa-gear"></i> <span className="d-none d-md-inline">Link</span></a>
                            <a href="#" className="list-group-item border-right-0 d-inline-block" data-parent="#sidebar"><i className="fa fa-calendar"></i> <span className="d-none d-md-inline">Link</span></a>
                            <a href="#" className="list-group-item border-right-0 d-inline-block" data-parent="#sidebar"><i className="fa fa-envelope"></i> <span className="d-none d-md-inline">Link</span></a>
                        </div> */}
                    </div>
                    <main className="col p-1 main d-flex flex-column">
                        <div className="d-flex">
                            {/* <ul className="d-flex pl-0"> */}
                                <div className="d-flex align-items-center px-2">
                                <a href="#" data-target=".sidebar" data-toggle="collapse"><img src={menu1}></img></a>
                                </div>
                                <div className="d-flex align-items-center px-2 flex-grow-1">
                                    <h5 className="dbtext mb-0">Dashboard</h5>
                                </div>
                                <div className="d-flex align-items-center px-2">
                                    <Form className="form-inline" action="/action_page.php">
                                        <div className="px-2 py-1 dbsearch-div">
                                            <input className="form-control dbsearch" type="text" placeholder="Search"/>
                                            <Link to="#/"><img src={search1}></img></Link>
                                        </div>
                                    </Form>
                                </div>
                                <div className="d-flex align-items-center px-2">
                                    <div className="px-2 position-relative">
                                        <a href="#"><img src={office1}></img></a>
                                        <span className="badge badge-style clr-yellow"><p className="text-white badge-text">35</p></span>
                                        
                                    </div>
                                    <div className="px-2 position-relative">
                                        <a href="#"><img src={chat1}></img></a>
                                        <span className="badge badge-style clr-green"><p className="text-white badge-text">42</p></span>
                                        
                                    </div>
                                    <div className="px-2 position-relative">
                                        <a href="#"><img src={bell1}></img></a>
                                        <span className="badge badge-style clr-red px-2"><p className="text-white badge-text">!</p></span>
                                        
                                    </div>
                                </div>
                                <div className="d-flex align-items-center px-2">
                                    <select className="db-button p-2" name="year" id="language" placeholder="Language">
                                        <option value="ENGLISH">ENGLISH</option>
                                        <option value="URDU">URDU</option>
                                        <option value="CHINESS">CHINESS</option>
                                        <option value="ARABIC">ARABIC</option>
                                    </select>
                                    {/* <button type="button" className="btn db-button dropdown-toggle" aria-haspopup="true" data-toggle="dropdown">
                                        ENGLISH
                                        <span className="dropdown-menu custom-navbar-dropdownmenu">
                                            <ul>
                                                <li>URDU</li>
                                                <li>CHINESS</li>
                                                <li>ARABIC</li>
                                            </ul>
                                        </span>
                                    </button> */}
                                </div>
                            {/* </ul> */}
                        </div>
                        <div>
                             {/* <!-- Tab panes --> */}
                            <div className="tab-content">
                                    <div className="tab-pane active" id="Dashboard" role="tabpanel">
                                        <Dashboard/>
                                    </div>
                                    <div className="tab-pane" id="dbstdprofile" role="tabpanel">
                                        <DBstdprofilev1/>
                                        {/* <DBstdprofilev2/> */}
                                        {/* <SPProfile/> */}
                                    </div>
                                    <div className="tab-pane" id="DBprofileapproval" role="tabpanel">
                                        <DBprofileapproval/>
                                    </div>
                                    <div className="tab-pane" id="dbteacherprofile" role="tabpanel">
                                        {/* <DBteacherprofilev1/> */}
                                        {/* <DBteacherprofilev2/> */}
                                        <SPProfileteacher/>
                                    </div>
                                    <div className="tab-pane" id="DBteacherprofileapproval" role="tabpanel">
                                        <DBteacherprofileapproval/>
                                    </div>
                                    <div className="tab-pane" id="dbfinance" role="tabpanel">
                                        <DBfinance/>
                                        
                                    </div>
                                    <div className="tab-pane" id="stdlecture" role="tabpanel">
                                        {/* <StdLecture /> */}
                                        
                                    </div>
                                    <div className="tab-pane" id="bankTypeAPI" role="tabpanel">
                                        <BankTypeAPI/>    
                                    </div>
                                    <div className="tab-pane" id="cityAPI" role="tabpanel">
                                        <CityAPI/>    
                                    </div>
                                    <div className="tab-pane" id="countryAPI" role="tabpanel">
                                        <CountryAPI/>    
                                    </div>
                                    <div className="tab-pane" id="degreeAPI" role="tabpanel">
                                        <DegreeAPI/>    
                                    </div>
                                    <div className="tab-pane" id="degree_levelAPI" role="tabpanel">
                                        <Degree_LevelAPI/>    
                                    </div>
                                    <div className="tab-pane" id="employee_typeAPI" role="tabpanel">
                                        <EmployeeTypeAPI/>    
                                    </div>
                                    <div className="tab-pane" id="instituteAPI" role="tabpanel">
                                        <InstituteAPI/>    
                                    </div>
                                    <div className="tab-pane" id="moduleAPI" role="tabpanel">
                                        <ModuleAPI/>    
                                    </div>
                                    <div className="tab-pane" id="permissionAPI" role="tabpanel">
                                        <PermissionAPI/>    
                                    </div>
                                    <div className="tab-pane" id="roleAPI" role="tabpanel">
                                        <RoleAPI/>    
                                    </div>
                                    <div className="tab-pane" id="skillAPI" role="tabpanel">
                                        <SkillAPI/>    
                                    </div>
                                    <div className="tab-pane" id="statusAPI" role="tabpanel">
                                        <StatusAPI/>    
                                    </div>
                                    <div className="tab-pane" id="course_typeAPI" role="tabpanel">
                                        <CourseTypeAPI/>    
                                    </div>
                                    <div className="tab-pane" id="course_sub_typeAPI" role="tabpanel">
                                        <CourseSubTypeAPI/>    
                                    </div>
                                    <div className="tab-pane" id="languageAPI" role="tabpanel">
                                        <LanguageAPI/>    
                                    </div>
                                    <div className="tab-pane" id="language_typeAPI" role="tabpanel">
                                        <LanguageTypeAPI/>    
                                    </div>
                                    <div className="tab-pane" id="weather_forcastAPI" role="tabpanel">
                                        <WeatherForcastAPI/>    
                                    </div>
                                    <div className="tab-pane" id="fee_typeAPI" role="tabpanel">
                                        <FeeTypeAPI/>    
                                    </div>
                                    <div className="tab-pane" id="ticket_typeAPI" role="tabpanel">
                                        <TicketTypeAPI/>    
                                    </div>
                                    <div className="tab-pane" id="quiz_typeAPI" role="tabpanel">
                                        <QuizTypeAPI/>    
                                    </div>
                                    {/* <div className="tab-pane" id="FeeTypeExtra" role="tabpanel">
                                        <FeeTypeExtra/>
                                    </div> */}
                                    <div className="tab-pane" id="notificationAPI" role="tabpanel">
                                        <NotificationAPI/>    
                                    </div>
                                    <div className="tab-pane" id="evaluationAPI" role="tabpanel">
                                        <EvaluationAPI/>    
                                    </div>
                                    <div className="tab-pane" id="discountTypeAPI" role="tabpanel">
                                        <DiscountTypeAPI/>    
                                    </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}

export default ADheader
