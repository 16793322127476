import React from 'react'
import './TableComponent.css'
import TableStructure from './TableStructure/TableStructure'
import icoption from '../../../../../images/icoption.png'
import profilePicture111 from '../../../../../images/profilePicture111.png'
import email1 from '../../../../../images/email1.png'

const TableComponent = () => {


    const ArrayListTeacherTableComponent = [
        {
            id:1,
            ID:"#001234",
            datejoin:"June 1, 2020, 08:22 AM",
            image:profilePicture111,
            name:"Fanny Siregar",
            icoption: icoption,
            
        },
        {
            id:2,
            ID:"#001234",
            datejoin:"June 1, 2020, 08:22 AM",
            image:profilePicture111,
            name:"Fanny Siregar",
            icoption: icoption,
           
        },
        {
            id:3,
            ID:"#001234",
            datejoin:"June 1, 2020, 08:22 AM",
            image:profilePicture111,
            name:"Fanny Siregar",
            icoption: icoption,
            
        },
        {
            id:4,
            ID:"#001234",
            datejoin:"June 1, 2020, 08:22 AM",
            image:profilePicture111,
            name:"Fanny Siregar",
            icoption: icoption,
            
        },
        {
            id:5,
            ID:"#001234",
            datejoin:"June 1, 2020, 08:22 AM",
            image:profilePicture111,
            name:"Fanny Siregar",
            icoption: icoption,
           
        },
        {
            id:6,
            ID:"#001234",
            datejoin:"June 1, 2020, 08:22 AM",
            image:profilePicture111,
            name:"Fanny Siregar",
            icoption: icoption,
            
        },
        {
            id:7,
            ID:"#001234",
            datejoin:"June 1, 2020, 08:22 AM",
            image:profilePicture111,
            name:"Fanny Siregar",
            icoption: icoption,
            
        },
        {
            id:8,
            ID:"#001234",
            datejoin:"June 1, 2020, 08:22 AM",
            image:profilePicture111,
            name:"Fanny Siregar",
            icoption: icoption,
           
        },
        {
            id:9,
            ID:"#001234",
            datejoin:"June 1, 2020, 08:22 AM",
            image:profilePicture111,
            name:"Fanny Siregar",
            icoption: icoption,
           
        },
        {
            id:10,
            ID:"#001234",
            datejoin:"June 1, 2020, 08:22 AM",
            image:profilePicture111,
            name:"Fanny Siregar",
            icoption: icoption,
           

        },
    ]


    // "arraylistarg" is a variable name which can holds Array current values and "i" is index
    const TeacherTableList = ArrayListTeacherTableComponent.map( (arg, i) => {
        return (
            <TableStructure key={i} 
            id="i"
            ID={ArrayListTeacherTableComponent[i].ID}
            datejoin={ArrayListTeacherTableComponent[i].datejoin}
            image={ArrayListTeacherTableComponent[i].image} 
            name={ArrayListTeacherTableComponent[i].name}
            icoption={ArrayListTeacherTableComponent[i].icoption}
            />
        )
    })

    return (
        <>
            <div className="col-md-12 bg-white">
                <div className="Tablestructure">
                    <table class="table tbl-style">
                        <thead>
                            <tr>
                            <th scope="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                    <label class="form-check-label tableheading" htmlFor="flexCheckDefault">
                                        ID
                                    </label>
                                </div>
                            </th>
                            <th className="tableheading" scope="col">Date Join</th>
                            <th className="tableheading" scope="col">Name</th>
                            <th className="tableheading" scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {TeacherTableList}
                        </tbody>
                    </table>
                </div>
            </div>
            
        </>
    )
}

export default TableComponent
