import React from 'react'
import './Cards2.css'
import { Row, Col } from 'react-bootstrap'

const Cards2 = (props) => {
    return (
        <>
            <div className="col-md-6 py-3 px-2">
                <div className="card dashboardCard" style={props.style}>
                    <div className="card-body p-3">
                        <Row>
                            <Col>
                                <div><h5 className="number">{props.number}</h5></div>
                                <div><p className="text">{props.text1}</p></div>
                                {/* <div><p className="text">{props.text2}</p></div> */}
                            </Col>
                            <Col className="col-4 d-flex flex-column justify-content-center">
                                
                                    {/* {props.d1} */}
                                    {props.chart}
                                
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cards2
