import React from 'react';
import './StdCourse.css';
import Course from './Course/Course';

export default function StdCourse () {



    return (
        <>
            <div className="TC-cstm px-0 container">
                    <div className="TC-cstm-course "><Course/></div>
                    
            </div>
        </>
    )

}