import React from 'react'
import './EmployeeTypeAPI.css'



import ReadEmployeeType from './Components/readEmployeeType'

const EmployeeTypeAPI = () => {
    return (
        <>
            <div className="container cstm-one">
                <div>
                    <ReadEmployeeType/>
                </div>
            </div>
            
        </>
    )
}

export default EmployeeTypeAPI;
