import React from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import Popper from 'popper.js';
import '../KnowAboutLearning/KnowAboutLearning.css';
import Tick2 from '../../../../images/tick2.png';

import Slide from 'react-reveal/Slide';

const KnowAboutLearning = () => {


    

    return (
        <div className="AboutUs-KnowAboutLearning-section">
            <div className="container">
            <Slide right>
               <div className="row">
                     <div className="col-md-6 col-md-6 AboutUs-KnowAboutLearning-column2"><div><img></img></div></div>
                     <div className="col-md-6 AboutUs-KnowAboutLearning-column1">
                        <h3 className="cstm-h3">Know about learning learning platform.</h3>
                        <p className="mb-3 cstm-text">With our growing catalog of over 30 Nanodegree
                            programs from beginner to advanced, you’re 
                            sure to find one that fits your career goals</p>
                        <div className="AboutUs-KnowAboutLearning-list">
                            <p className="cstm-p">
                                <img src={Tick2}></img>
                                <span>Free E-book, Video & Consolation</span>
                            </p>
                            <p className="cstm-p">
                                <img src={Tick2}></img>
                                <span>Top instructors from around wrold</span>
                            </p>
                            <p className="cstm-p">
                                <img src={Tick2}></img>
                                <span>Top courses for your team</span>
                            </p>
                        </div>
                        <button className="btn button btn-danger">Start learning now</button>
                    </div>
                </div>
            </Slide>    
            </div>
        </div>
    )
}

export default KnowAboutLearning;