import React from 'react';
import './Banner.css';

const Banner = () => {
    return (
        <div className="container-fluid banner-containerfluid p-5">
            <div className="container Banner-container col-12">
                <div className="Banner-col1 col-md-8 col-5">
                    <h3>FAQ's</h3>
                </div>
                <div className="Banner-col2 col-md-4 col-7">
                    <h5>Home / FAQ's</h5>
                </div>
            </div>
        </div>
    )
}

export default Banner;