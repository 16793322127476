import './StdCurriculum.css'
import React from 'react'
import Clock from '../../../../../images/clock.png'
import StdCuComponent from './StdCuComponent/StdCuComponent'

export default function StdCurriculum () {


    const ArraylistStdCurriculum = [
        {
            id: 1,
            title:"Everything You Need to Know Business",
            image: Clock,
            duration:"Duration 10 week",
        },
        {
            id: 2,
            title:"Everything You Need to Know Business",
            image: Clock,
            duration:"Duration 10 week",
        },
        {
            id: 3,
            title:"Everything You Need to Know Business",
            image: Clock,
            duration:"Duration 10 week",
        },
    ]

    const listStdCurriculum = ArraylistStdCurriculum.map((arg,i) => {
        return(
            <StdCuComponent key={i}
                id="i"
                title= {ArraylistStdCurriculum[i].title}
                image= {ArraylistStdCurriculum[i].image}
                duration= {ArraylistStdCurriculum[i].duration}
            />
        )
    })



    return (


        <>
            <div className="container StdCu-container py-3">
                {listStdCurriculum}
            </div>
        </>

    )


}