import Ellipse62 from '../../../../../images/Ellipse62.png'
import questionanswer from '../../../../../images/questionanswer.png'
import timer from '../../../../../images/timer.png'
import Group3dots from '../../../../../images/Group3dots.png'

const ArraylistQuizes = [
        {
            id:1,
            Ellipse62: Ellipse62,
            title: "English Basic quiz.",
            Group3dots : Group3dots,
            timer: timer,
            questionanswer: questionanswer,
            qa: "10",
            time: "10 min",

        },
        {
            id:2,
            Ellipse62: Ellipse62,
            title: "English Basic quiz.",
            Group3dots : Group3dots,
            timer: timer,
            questionanswer: questionanswer,
            qa: "10",
            time: "10 min",

        },
        {
            id:3,
            Ellipse62: Ellipse62,
            title: "English Basic quiz.",
            Group3dots : Group3dots,
            timer: timer,
            questionanswer: questionanswer,
            qa: "10",
            time: "10 min",

        },
        {
            id:4,
            Ellipse62: Ellipse62,
            title: "English Basic quiz.",
            Group3dots : Group3dots,
            timer: timer,
            questionanswer: questionanswer,
            qa: "10",
            time: "10 min",

        },
        {
            id:5,
            Ellipse62: Ellipse62,
            title: "English Basic quiz.",
            Group3dots : Group3dots,
            timer: timer,
            questionanswer: questionanswer,
            qa: "10",
            time: "10 min",

        },
    ]

    export default ArraylistQuizes;