import React from 'react'
import './CourseSubTypeAPI.css'



import ReadCourseSubType from './Components/readCourseSubType'

const CourseSubTypeAPI = () => {
    return (
        <>
            <div className="container cstm-one">
                <div>
                    <ReadCourseSubType/>
                </div>
            </div>
            
        </>
    )
}

export default CourseSubTypeAPI;
