import React from 'react'
import './RecentStudents.css'

const RecentStudents = (props) => {
    return (
        <>
            <tr className="d-flex RecentStudentsList py-2">
                            <td className="d-flex align-items-center" scope="row">
                                <img className="mr-2" src={props.image1}></img>
                                <span className="tabletext">{props.name}{props.ID}</span>
                            </td>
                            </tr>
            
        </>
    )
}

export default RecentStudents;
