import {React , Component} from 'react';
import './StudentProfile.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import SPVHeaderArea from '../SPVHeaderArea/SPVHeaderArea';
import Navbar from '../../../components/Header/NavbarComponent/NavbarComponent';
import StdInfo from './StdInfo/StdInfo';
import StdInfo2 from './StdInfo2/StdInfo2';
import Footer from '../../../components/CustomFooter/CustomFooter';
import { Fragment } from 'react';
import StdFavCourse from './StdFavCourse/StdFavCourse';

class StudentProfile extends Component {

    componentDidMount = () => {}

    render () {
        return (
            // <div className="TP-container">
            //     <Navbar/>
            //     <TVHeaderArea />
                <div className="sp-cstm px-0 container mb-5">
                    <div className="tp-cstmSI container col-md-4 mx-0 px-0 "><StdInfo/></div>
                    {/* <div className="tp-cstmSI container col-md-4 mx-0 px-0 "><StdInfo2/></div> */}
                    {/* <div ></div> */}
                    <div className="tp-cstmSFC container col-md-8 pr-0"><StdFavCourse/></div>
                </div>
            //    <Footer/>
            //</div>
        )
    }
}

export default StudentProfile;

