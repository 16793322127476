import React, {useState} from 'react'
import './TableStructure.css'
import { Col, Row, Button, Form, FormLabel, Modal } from 'react-bootstrap'

const TableStructure = (props) => {


    const [modalState, setModalState] = useState(false);

    const manageState = () => {
        setModalState(!modalState)
    }

    return (
        <>
            <tr>
                            <td className="py-4" scope="row">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                    <label class="form-check-label tabletext" htmlFor="flexCheckDefault">
                                        {props.ID}
                                    </label>
                                </div>
                            </td>
                            <td className="tabletext py-4">{props.datejoin}</td>
                            <td className="pr-5 tabletext"><img className="mr-2" src={props.image}></img>{props.name}</td>
                            <td className="pr-5 tabletext py-4">{props.teachername}</td>
                            <td><img src={props.contact}></img></td>
                            <td className="py-4"><span className="tabletext p-3" style={props.style}>{props.currentcourse}</span></td>
                            <td className="py-4">
                                <img className="dropdown-toggle" aria-haspopup="true" data-toggle="dropdown" src={props.icoption}></img>
                                <span className="dropdown-menu cstm-CQuiz-dropdownmenu">
                                <a className="dropdown-item" onClick={() => manageState()}>Edit</a>
                                <a className="dropdown-item" href="#">Delete</a>
                                </span>
                            </td>
            </tr>

            <div>
                        
                        <Modal className="DBStdProfile-Modal" show={modalState} onHide={() => manageState()}>
                            <Modal.Header closeButton >
                                <div className="row d-flex px-3">
                                    <div><h5 className="header">Details</h5></div>
                                    
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    
    
                                    <div className="row d-flex justify-content-between">
                                        <div className="d-flex flex-column col">
                                            <FormLabel className="label" htmlFor="holdername">Full Name</FormLabel>
                                            <input type="text" className="form-control border" placeholder="Full Name" id="fullname" />
                                        </div>
                                        <div className="d-flex flex-column col">
                                            <FormLabel className="label" htmlFor="cardnumber">Email</FormLabel>
                                            <input type="text" className="form-control border" placeholder="Email" id="email" />
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column col">
                                            <FormLabel className="label" htmlFor="gender">Gender</FormLabel>
                                            <select className="border py-2" name="gender" id="gender" placeholder="Gender">
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="other">Other</option>
                                            </select>
                                    </div>
                                    <div className="row d-flex py-3">
                                        <div className="d-flex flex-column col">
                                            <FormLabel className="label" htmlFor="date">Date</FormLabel>
                                            <select className="border py-2" name="date" id="gender" placeholder="Date">
                                                <option value="01">01</option>
                                                <option value="02">02</option>
                                                <option value="03">03</option>
                                            </select>
                                        </div>
                                        <div className="d-flex flex-column col">
                                            <FormLabel className="label" htmlFor="date">Month</FormLabel>
                                            <select className="border py-2" name="month" id="month" placeholder="Month">
                                                <option value="01">01</option>
                                                <option value="02">02</option>
                                                <option value="03">03</option>
                                            </select>
                                        </div>
                                        <div className="d-flex flex-column col">
                                            <FormLabel className="label" htmlFor="year">Year</FormLabel>
                                            <select className="border py-2" name="year" id="gender" placeholder="Year">
                                                <option value="01">1994</option>
                                                <option value="02">1995</option>
                                                <option value="03">1996</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                    <div className="row d-flex ">
                                        <div className="d-flex flex-column col">
                                                <FormLabel className="label" htmlFor="phone">Phone</FormLabel>
                                                <input type="text" className="form-control border" placeholder="Phone" id="phone" />
                                            </div>
                                            <div className="d-flex flex-column col">
                                                <FormLabel className="label" htmlFor="language">Language</FormLabel>
                                                <input type="text" className="form-control border" placeholder="Language" id="language" />
                                            </div>
                                    </div>
                                    <div className="row d-flex ">
                                        <div className="d-flex flex-column col">
                                                <FormLabel className="label" htmlFor="address">Address</FormLabel>
                                                <input type="text" className="form-control border" placeholder="Address" id="address" />
                                            </div>
                                    </div>
                                    <div className="row d-flex ">
                                        <div className="d-flex flex-column col">
                                                <FormLabel className="label" htmlFor="city">City</FormLabel>
                                                <input type="text" className="form-control border" placeholder="City" id="city" />
                                            </div>
                                        <div className="d-flex flex-column col">
                                            <FormLabel className="label" htmlFor="state">State</FormLabel>
                                            <input type="text" className="form-control border" placeholder="state" id="state" />
                                        </div>
                                        <div className="d-flex flex-column col">
                                            <FormLabel className="label" htmlFor="zipcode">Zip Code</FormLabel>
                                            <input type="text" className="form-control border" placeholder="Zip Code" id="zipcode" />
                                        </div>
                                        <div className="d-flex flex-column col">
                                            <FormLabel className="label" htmlFor="country">Country</FormLabel>
                                            <input type="text" className="form-control border" placeholder="Country" id="country" />
                                        </div>
                                    </div>
                                    <div className="row d-flex ">
                                        <div className="d-flex flex-column col">
                                                <FormLabel className="label" htmlFor="degree">Degree</FormLabel>
                                                <input type="text" className="form-control border" placeholder="Degree" id="degree" />
                                        </div>
                                        <div className="d-flex flex-column col">
                                                <FormLabel className="label" htmlFor="institute">Institute</FormLabel>
                                                <input type="text" className="form-control border" placeholder="Institute" id="institute" />
                                        </div>
                                    </div>
                                    <div className="row d-flex ">
                                        <div className="d-flex flex-column col">
                                                <FormLabel className="label" htmlFor="about">About</FormLabel>
                                                <input type="text" className="form-control border" placeholder="About" id="about" />
                                        </div>
                                    </div>
                                </Form>
                            </Modal.Body>
    
                            <Modal.Footer >
                                <div className="row d-flex px-3">
                                    <div><button className="btn btn-success">Save</button></div>
                                    
                                </div>
                            </Modal.Footer>
                            
                            
                        </Modal>
                    </div>
            
        </>
    )
}

export default TableStructure;
