import React from 'react'
import './TableStructure.css'

const TableStructure = (props) => {

    return (
        <>
            <tr>
                            <td className="py-4" scope="row">
                                <div class="form-check ">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                    <label class="form-check-label tabletext" htmlFor="flexCheckDefault">
                                        {props.ID}
                                    </label>
                                </div>
                            </td>
                            <td className="tabletext py-4">{props.datejoin}</td>
                            <td className="pr-5 tabletext"><img className="mr-2" src={props.image}></img>{props.name}</td>
                            <td className="pr-5 tabletext py-4 d-flex justify-content-between">
                                <button className="btn button yellow">View</button>
                                <button className="btn button green">Approve</button>
                                <button className="btn button red">Reject</button>
                            </td>
                            <td className="py-4">
                                <img className="dropdown-toggle" aria-haspopup="true" data-toggle="dropdown" src={props.icoption}></img>
                                <span className="dropdown-menu cstm-CQuiz-dropdownmenu">
                                <a className="dropdown-item" href='#'>Edit</a>
                                <a className="dropdown-item" href="#">Delete</a>
                                </span>
                            </td>
            </tr>

           
            
        </>
    )
}

export default TableStructure;
