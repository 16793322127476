import React from 'react';
import './GetInTouch.css';

const GetInTouch = () => {
    return (
        <div className="container-fluid GetInTouch mb-70">
            <div className="container">
                <div className="contact_form">
                    <div className="text-center mb-5"><h4 className="contact_form_heading">Get In Touch With Us</h4></div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <input type="text" className="form-control contact-form-cstm" placeholder="Your Name" />

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="for-group">
                                <input type="text" className=" form-control contact-form-cstm" placeholder="Email Address" />
                            </div>
                        </div>
                        <div className="col-12 mb-4">
                            <textarea className="form-control text_area_cstm" rows="6" placeholder="Write Message"></textarea>
                        </div>
                        <div className="col-12 text-center">
                            <button className="btn btn-danger">Submit Comment</button>
                        </div>
                    </div>
                    
                    
                    
                </div>
                
            </div>
        </div>
    )
}

export default GetInTouch;