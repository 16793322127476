import React, {useState} from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import Popper from 'popper.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheckSquare, faCoffee, fashoppingcart} from '@fortawesome/free-solid-svg-icons';
import './NavbarComponent.min.css';
import searchhere1 from '../../../../src/images/searchhere1.png';
import searchhere2 from '../../../../src/images/Searchhere2.png';
import Cart1 from '../../../images/Cart1.png';
import Cart2 from '../../../images/Cart2.png';
import Logo1 from '../../../images/logo1.png';
import { Link } from 'react-router-dom';
import Home from '../../Home';
import About from '../../AboutUs/AboutUs'
import menu1 from '../../../images/menu1.png'
import catsub1 from "../../../images/catsub1.png"
import catsub2 from "../../../images/catsub2.png"
import catsub3 from "../../../images/catsub3.png"
import catsub4 from "../../../images/catsub4.png"
import catsub5 from "../../../images/catsub5.png"
import catsub6 from "../../../images/catsub6.png"
import catsub7 from "../../../images/catsub7.png"
import catsub8 from "../../../images/catsub8.png"
import catsubarrow from "../../../images/catsubarrow.png"
import Polygon1 from '../../../images/Polygon.png'
import menu from "../../../images/menu.png"
import menu2 from "../../../images/menu2.png"

const Navbar = () => {

    const Search = () => {
        const [show, toggleShow] = React.useState(false);
      
        return (
          <div className="cstm-mobAndtab-columnview d-flex flex-row-reverse">
            <button style={{border: "none", background: "none"}}
              onClick={() => toggleShow(!show)}
            >
              {/* toggle: {show ? 'show' : 'hide'} */}
              <img className="cstm-search1-img" src={searchhere1}></img>
              <img className="cstm-search2-img" src={searchhere2}></img>
            </button>    
            {show && <div><input type="search" style={{borderRadius: "15px"}}></input></div>}
          </div>
        )
      }

    return (
        <div className="container " style={{position: "relative", zIndex: "999"}}>
            <nav className="navbar navbar-expand-md text-center container cstm-navbar col-md-12 col-sm-11 col-11">
                <a className="navbar-brand cstm-navbar-brand col-md-2 col-sm-2" href="#"><img src={Logo1}></img></a>
                <div>
                    <div className="cstm-nav-item p-2  desktop-hide">
                        <Search/>
                    </div>
                    <div className="cstm-nav-item p-2  desktop-hide">
                        <Link to='/Cartpage'>
                            <img src={Cart1}></img>
                        </Link>
                    </div>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                        <span><i class="fas fa-bars "></i></span>
                    </button>
                </div>
                <div className="collapse navbar-collapse py-3 cstm-responsive-body col-md-9 d-flex justify-content-end" id="collapsibleNavbar">
                    <div className="dropdown cstm-navbar-dropdown">

                        {/* --------------mobile and tablet view menu starts----------- */}

                            <div class="panel-group cstm_fAq experimentdiv" id="accordionGroupOpen" role="tablist" aria-multiselectable="true">
                                
                                {/* categories */}

                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="categories">
                                    <h4 class="panel-title px-5">
                                        <span><a href="/" className="cstmHeadingAfterIcon d-inline">Categories</a></span>
                                        <a class="collapsed d-inline" role="button" data-toggle="collapse" data-parent="#accordionGroupOpen" href="#collapseOpenCategories" aria-expanded="false" aria-controls="collapseCategories"/>
                                        
                                    </h4>
                                    </div>
                                    <div id="collapseOpenCategories" class="panel-collapse collapse" role="tabpanel" aria-labelledby="categories">
                                    <div class="panel-body">
                                        {/* design */}
                                        <div class="panel panel-default">
                                            <div class="panel-heading" role="tab" id="designing">
                                            <h4 class="panel-title px-5">
                                                <span><a href="/" className="cstmHeadingAfterIcon d-inline"><img src={catsub1}></img>Designing</a></span>
                                                <a class="collapsed d-inline" role="button" data-toggle="collapse" data-parent="#accordionGroupOpen" href="#collapseOpenDesigning" aria-expanded="false" aria-controls="collapseDesigning"/>
                                                
                                            </h4>
                                            </div>
                                            <div id="collapseOpenDesigning" class="panel-collapse collapse" role="tabpanel" aria-labelledby="designing">
                                            <div class="panel-body">
                                                <ul className="d-flex flex-column align-items-start">
                                                    <li>Design</li>
                                                    <li>Design</li>
                                                    <li>Design</li>
                                                    <li>Design</li>
                                                </ul>
                                                
                                            </div>
                                            </div>
                                        </div>

                                        {/* software */}
                                        <div class="panel panel-default">
                                            <div class="panel-heading" role="tab" id="software">
                                            <h4 class="panel-title px-5">
                                                <span><a href="/" className="cstmHeadingAfterIcon d-inline"><img src={catsub2}></img>Software</a></span>
                                                <a className="collapsed d-inline" role="button" data-toggle="collapse" data-parent="#accordionGroupOpen" href="#collapseOpenSoftware" aria-expanded="false" aria-controls="collapseSoftware"/>
                                                
                                            </h4>
                                            </div>
                                            <div id="collapseOpenSoftware" class="panel-collapse collapse" role="tabpanel" aria-labelledby="software">
                                            <div class="panel-body">
                                                <ul className="d-flex flex-column align-items-start">
                                                    <li>Software</li>
                                                    <li>Software</li>
                                                    <li>Software</li>
                                                    <li>Software</li>
                                                </ul>
                                                
                                            </div>
                                            </div>
                                        </div>

                                        {/* languages */}
                                        <div class="panel panel-default">
                                            <div class="panel-heading" role="tab" id="languages">
                                            <h4 class="panel-title px-5">
                                                <span><a href="/" className="cstmHeadingAfterIcon d-inline"><img src={catsub3}></img>Languages</a></span>
                                                <a class="collapsed d-inline" role="button" data-toggle="collapse" data-parent="#accordionGroupOpen" href="#collapseOpenLanguages" aria-expanded="false" aria-controls="collapseLanguages"/>
                                                
                                            </h4>
                                            </div>
                                            <div id="collapseOpenLanguages" class="panel-collapse collapse" role="tabpanel" aria-labelledby="languages">
                                            <div class="panel-body">
                                                <ul className="d-flex flex-column align-items-start">
                                                    <li>Languages</li>
                                                    <li>Languages</li>
                                                    <li>Languages</li>
                                                    <li>Languages</li>
                                                </ul>
                                                
                                            </div>
                                            </div>
                                        </div>
                                        {/* science */}
                                        <div class="panel panel-default">
                                            <div class="panel-heading" role="tab" id="science">
                                            <h4 class="panel-title px-5">
                                                <span><a href="/" className="cstmHeadingAfterIcon d-inline"><img src={catsub4}></img>Science</a></span>
                                                <a class="collapsed d-inline" role="button" data-toggle="collapse" data-parent="#accordionGroupOpen" href="#collapseOpenScience" aria-expanded="false" aria-controls="collapseScience"/>
                                                
                                            </h4>
                                            </div>
                                            <div id="collapseOpenScience" class="panel-collapse collapse" role="tabpanel" aria-labelledby="science">
                                            <div class="panel-body">
                                                <ul className="d-flex flex-column align-items-start">
                                                    <li>Science</li>
                                                    <li>Science</li>
                                                    <li>Science</li>
                                                    <li>Science</li>
                                                </ul>
                                                
                                            </div>
                                            </div>
                                        </div>
                                        {/* computer */}
                                        <div class="panel panel-default">
                                            <div class="panel-heading" role="tab" id="computer">
                                            <h4 class="panel-title px-5">
                                                <span><a href="/" className="cstmHeadingAfterIcon d-inline"><img src={catsub5}></img>Computer</a></span>
                                                <a class="collapsed d-inline" role="button" data-toggle="collapse" data-parent="#accordionGroupOpen" href="#collapseOpenComputer" aria-expanded="false" aria-controls="collapseComputer"/>
                                                
                                            </h4>
                                            </div>
                                            <div id="collapseOpenComputer" class="panel-collapse collapse" role="tabpanel" aria-labelledby="computer">
                                            <div class="panel-body">
                                                <ul className="d-flex flex-column align-items-start">
                                                    <li>Computer</li>
                                                    <li>Computer</li>
                                                    <li>Computer</li>
                                                    <li>Computer</li>
                                                </ul>
                                                
                                            </div>
                                            </div>
                                        </div>
                                        {/* art */}
                                        <div class="panel panel-default">
                                            <div class="panel-heading" role="tab" id="art">
                                            <h4 class="panel-title px-5">
                                                <span><a href="/" className="cstmHeadingAfterIcon d-inline"><img src={catsub6}></img>Art</a></span>
                                                <a class="collapsed d-inline" role="button" data-toggle="collapse" data-parent="#accordionGroupOpen" href="#collapseOpenArt" aria-expanded="false" aria-controls="collapseArt"/>
                                                
                                            </h4>
                                            </div>
                                            <div id="collapseOpenArt" class="panel-collapse collapse" role="tabpanel" aria-labelledby="art">
                                            <div class="panel-body">
                                                <ul className="d-flex flex-column align-items-start">
                                                    <li>Art</li>
                                                    <li>Art</li>
                                                    <li>Art</li>
                                                    <li>Art</li>
                                                </ul>
                                                
                                            </div>
                                            </div>
                                        </div>
                                        {/* history */}
                                        <div class="panel panel-default">
                                            <div class="panel-heading" role="tab" id="history">
                                            <h4 class="panel-title px-5">
                                                <span><a href="/" className="cstmHeadingAfterIcon d-inline"><img src={catsub7}></img>History</a></span>
                                                <a class="collapsed d-inline" role="button" data-toggle="collapse" data-parent="#accordionGroupOpen" href="#collapseOpenHistory" aria-expanded="false" aria-controls="collapseHistory"/>
                                                
                                            </h4>
                                            </div>
                                            <div id="collapseOpenHistory" class="panel-collapse collapse" role="tabpanel" aria-labelledby="history">
                                            <div class="panel-body">
                                                <ul className="d-flex flex-column align-items-start">
                                                    <li>History</li>
                                                    <li>History</li>
                                                    <li>History</li>
                                                    <li>History</li>
                                                </ul>
                                                
                                            </div>
                                            </div>
                                        </div>
                                        {/* business */}
                                        <div class="panel panel-default">
                                            <div class="panel-heading" role="tab" id="business">
                                            <h4 class="panel-title px-5">
                                                <span><a href="/" className="cstmHeadingAfterIcon d-inline"><img src={catsub8}></img>Business</a></span>
                                                <a class="collapsed d-inline" role="button" data-toggle="collapse" data-parent="#accordionGroupOpen" href="#collapseOpenBusiness" aria-expanded="false" aria-controls="collapseBusiness"/>
                                                
                                            </h4>
                                            </div>
                                            <div id="collapseOpenBusiness" class="panel-collapse collapse" role="tabpanel" aria-labelledby="business">
                                            <div class="panel-body">
                                                <ul className="d-flex flex-column align-items-start">
                                                    <li>Business</li>
                                                    <li>Business</li>
                                                    <li>Business</li>
                                                    <li>Business</li>
                                                </ul>
                                                
                                            </div>
                                            </div>
                                        </div>

                                        
                                    </div>
                                    </div>
                                </div>
                                
                                
                                {/* Home */}

                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="home">
                                    <h4 class="panel-title px-5">
                                        <span><a href="/" className="cstmHeadingAfterIcon d-inline">Home</a></span>
                                        <a className="collapsed d-inline" role="button" data-toggle="collapse" data-parent="#accordionGroupOpen" href="#collapseOpenHome" aria-expanded="false" aria-controls="collapseHome"/>
                                        
                                    </h4>
                                    </div>
                                    <div id="collapseOpenHome" class="panel-collapse collapse" role="tabpanel" aria-labelledby="home">
                                    <div class="panel-body">
                                        <ul className="d-flex flex-column align-items-start">
                                            <Link className="text-black-50" to="/teacherview">Teacher View</Link>
                                            <Link className="text-black-50" to="/studentview">Student View</Link>
                                            <Link className="text-black-50" to="/stdproview">Student Profile View</Link>
                                            <Link className="text-black-50" to="/adboard">Dashboard</Link>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                                
                                {/* About */}
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="about">
                                    <h4 class="panel-title px-5">
                                        <span><a href="/AboutUs" className="cstmHeadingAfterIcon d-inline">About</a></span>
                                        <a class="collapsed d-inline" role="button" data-toggle="collapse" data-parent="#accordionGroupOpen" href="#collapseOpenAbout" aria-expanded="false" aria-controls="collapseAbout"/>
                                        
                                    </h4>
                                    </div>
                                    <div id="collapseOpenAbout" class="panel-collapse collapse" role="tabpanel" aria-labelledby="about">
                                    <div class="panel-body">
                                        <Link className="text-black-50" to="/termsandconditions">Terms & Conditions</Link>
                                    </div>
                                    </div>
                                </div>


                                {/* Contact Us */}

                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="contact">
                                    <h4 class="panel-title px-5">
                                        <span><a href="/ContactUs" className="cstmHeadingAfterIcon d-inline">Contact Us</a></span>
                                        <a class="collapsed d-inline" role="button" data-toggle="collapse" data-parent="#accordionGroupOpen" href="#collapseOpenContact" aria-expanded="false" aria-controls="collapseContact"/>
                                        
                                    </h4>
                                    </div>
                                    <div id="collapseOpenContact" class="panel-collapse collapse" role="tabpanel" aria-labelledby="contact">
                                    <div class="panel-body">
                                        <Link className="text-black-50" to="/FAQ">FAQ</Link>
                                    </div>
                                    </div>
                                </div>

                            </div>
                            <ul className="navbar-nav align-items-center">
                                <div className="cstm-nav-item p-2 ">
                                    <Search/>
                                </div> 
                                
                                <div className="cstm-nav-item p-2  ">
                                    <Link to='/Cartpage'>
                                        <img className="cstm-cart1-img" src={Cart1}></img>
                                        <img className="cstm-cart2-img" src={Cart2}></img>
                                    </Link>
                                </div>
                                <div className="cstm-nav-item p-2">
                                <Link to="/Signup" className="btn registerbutton">Register</Link>
                                    
                                </div>
                                <div className="cstm-nav-item p-2">
                                <Link to="/Login" className="btn loginbutton">Login</Link>
                                    
                                </div>
                            </ul>

                            

                            {/* -----------------mobile & tablet view menu ends---------- */}

                        <ul className="cstm-ul desktop-menu position-relative">

                            <p>
                                
                                <Link to='#'>
                                    <div>
                                        <button type="button" className="btn menu-button dropdown-toggle" aria-haspopup="true" data-toggle="dropdown">
                                        <img className="pr-2 HomeMenu-img" src={menu}></img>
                                        <img className="pr-2 categoryMenu-img" src={menu2}></img>
                                        Categories
                                        </button>
                                    </div>
                                </Link>
                                <span className="dropdown-menu custom-navbar-dropdownmenu">
                                    <img className="position-absolute" src={Polygon1} style={{top: "-13px", left: "50px", zIndex: "-10"}}></img>
                                    <li className="dropdown-submenu cstm-subbutton px-3 relative">
                                        <Link to='#' className="d-flex justify-content-between align-items-center ali">
                                        <div className="d-inline-block pr-2">
                                            <img src={catsub1}></img>
                                            <button type="button" className="btn sub-button dropdown-toggle" aria-haspopup="true" data-toggle="dropdown">
                                            Designing
                                            </button>
                                        </div>
                                        <div><img src={catsubarrow}></img></div>
                                        </Link>
                                        <ul className="dropdown-menu custom-navbar-subsdropdownmenu" style={{top: "13px", left: "96%", marginTop: "-1px", borderRadius: "15px"}}>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Designing</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Designing</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Designing</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Designing</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Designing</button></Link></li>
                                        </ul>
                                    </li>

                                    <li className="dropdown-submenu cstm-subbutton px-3 relative">
                                        <Link to='#' className="d-flex justify-content-between align-items-center">
                                        <div className="d-inline-block pr-2">
                                            <img src={catsub2}></img>
                                            <button type="button" className="btn sub-button dropdown-toggle" aria-haspopup="true" data-toggle="dropdown">
                                            Software
                                            </button>
                                        </div>
                                        <div><img src={catsubarrow}></img></div>
                                        </Link>
                                        <ul className="dropdown-menu custom-navbar-subsdropdownmenu" style={{top: "60px", left: "96%", marginTop: "-1px", borderRadius: "15px"}}>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Software</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Software</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Software</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Software</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Software</button></Link></li>
                                        </ul>
                                    </li>


                                    <li className="dropdown-submenu cstm-subbutton px-3 relative">
                                        <Link to='#' className="d-flex justify-content-between align-items-center">
                                        <div className="d-inline-block pr-2">
                                            <img src={catsub3}></img>
                                            <button type="button" className="btn sub-button dropdown-toggle" aria-haspopup="true" data-toggle="dropdown">
                                            Languages
                                            </button>
                                        </div>
                                        <div><img src={catsubarrow}></img></div>
                                        </Link>
                                        <ul className="dropdown-menu custom-navbar-subsdropdownmenu" style={{top: "105px", left: "96%", marginTop: "-1px", borderRadius: "15px"}}>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Languages</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Languages</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Languages</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Languages</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Languages</button></Link></li>
                                        </ul>
                                    </li>

                                    <li className="dropdown-submenu cstm-subbutton px-3 relative">
                                        <Link to='#' className="d-flex justify-content-between align-items-center">
                                        <div className="d-inline-block pr-2">
                                            <img src={catsub4}></img>
                                            <button type="button" className="btn sub-button dropdown-toggle" aria-haspopup="true" data-toggle="dropdown">
                                            Science
                                            </button>
                                        </div>
                                        <div><img src={catsubarrow}></img></div>
                                        </Link>
                                        <ul className="dropdown-menu custom-navbar-subsdropdownmenu" style={{top: "150px", left: "96%", marginTop: "-1px", borderRadius: "15px"}}>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Science</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Science</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Science</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Science</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Science</button></Link></li>
                                        </ul>
                                    </li>

                                    <li className="dropdown-submenu cstm-subbutton px-3 relative">
                                        <Link to='#' className="d-flex justify-content-between align-items-center">
                                        <div className="d-inline-block pr-2">
                                            <img src={catsub5}></img>
                                            <button type="button" className="btn sub-button dropdown-toggle" aria-haspopup="true" data-toggle="dropdown">
                                            Computer
                                            </button>
                                        </div>
                                        <div><img src={catsubarrow}></img></div>
                                        </Link>
                                        <ul className="dropdown-menu custom-navbar-subsdropdownmenu" style={{top: "193px", left: "96%", marginTop: "-1px", borderRadius: "15px"}}>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Computer</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Computer</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Computer</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Computer</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Computer</button></Link></li>
                                        </ul>
                                    </li>

                                    <li className="dropdown-submenu cstm-subbutton px-3 relative">
                                        <Link to='#' className="d-flex justify-content-between align-items-center">
                                        <div className="d-inline-block pr-2">
                                            <img src={catsub6}></img>
                                            <button type="button" className="btn sub-button dropdown-toggle" aria-haspopup="true" data-toggle="dropdown">
                                            Art
                                            </button>
                                        </div>
                                        <div><img src={catsubarrow}></img></div>
                                        </Link>
                                        <ul className="dropdown-menu custom-navbar-subsdropdownmenu" style={{top: "240px", left: "96%", marginTop: "-1px", borderRadius: "15px"}}>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Art</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Art</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Art</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Art</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Art</button></Link></li>
                                        </ul>
                                    </li>

                                    <li className="dropdown-submenu cstm-subbutton px-3 relative">
                                        <Link to='#' className="d-flex justify-content-between align-items-center">
                                        <div className="d-inline-block pr-2">
                                            <img src={catsub7}></img>
                                            <button type="button" className="btn sub-button dropdown-toggle" aria-haspopup="true" data-toggle="dropdown">
                                            History
                                            </button>
                                        </div>
                                        <div><img src={catsubarrow}></img></div>
                                        </Link>
                                        <ul className="dropdown-menu custom-navbar-subsdropdownmenu" style={{top: "280px", left: "96%", marginTop: "-1px", borderRadius: "15px"}}>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">History</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">History</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">History</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">History</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">History</button></Link></li>
                                        </ul>
                                    </li>

                                    <li className="dropdown-submenu cstm-subbutton px-3 relative">
                                        <Link to='#' className="d-flex justify-content-between align-items-center">
                                        <div className="d-inline-block pr-2">
                                            <img src={catsub8}></img>
                                            <button type="button" className="btn sub-button dropdown-toggle" aria-haspopup="true" data-toggle="dropdown">
                                            Business
                                            </button>
                                        </div>
                                        <div><img src={catsubarrow}></img></div>
                                        </Link>
                                        <ul className="dropdown-menu custom-navbar-subsdropdownmenu" style={{top: "325px", left: "96%", marginTop: "-1px", borderRadius: "15px"}}>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Business</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Business</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Business</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Business</button></Link></li>
                                            <li className="px-3"><Link to='#'><button className="btn sub-button" href="#">Business</button></Link></li>
                                        </ul>
                                    </li>

                                </span>
                            </p>
                        
                            <p>
                                
                                <Link to='/'>
                                    <button type="button" className="btn menu-button dropdown-toggle" aria-haspopup="true" data-toggle="dropdown">
                                    Home
                                    </button>
                                </Link>
                                <span className="dropdown-menu custom-navbar-dropdownmenu">
                                    <img className="position-absolute" src={Polygon1} style={{top: "-13px", left: "20px", zIndex: "-10"}}></img>
                                    <Link className="dropdown-item" to="/teacherview"><button className="btn sub-button">TeacherView</button></Link>
                                    <Link className="dropdown-item" to="/studentview"><button className="btn sub-button">StudentView</button></Link>
                                    <Link className="dropdown-item" to="/stdproview"><button className="btn sub-button">StdProView</button></Link>
                                    <Link className="dropdown-item" to="/adboard"><button className="btn sub-button">Dashboard</button></Link>

                                </span>
                            </p>
                            <p>
                                <Link to='/AboutUs'>
                                    <button type="button" className="btn menu-button dropdown-toggle" aria-haspopup="true" data-toggle="dropdown">
                                    About
                                    </button>
                                </Link>
                                <span className="dropdown-menu custom-navbar-dropdownmenu">
                                    <img className="position-absolute" src={Polygon1} style={{top: "-13px", left: "20px",  zIndex: "-10"}}></img>
                                    <Link className="dropdown-item" to="/termsandconditions"><button className="btn sub-button">Terms & Conditions</button></Link>
                                </span>
                            </p>
                            <p>
                                <Link to='/ContactUs'>
                                    <button type="button" className="btn menu-button dropdown-toggle" aria-haspopup="true" data-toggle="dropdown">
                                    Contact Us
                                    </button>
                                </Link>
                                <span className="dropdown-menu custom-navbar-dropdownmenu">
                                    <img className="position-absolute" src={Polygon1} style={{top: "-13px", left: "20px",  zIndex: "-10"}}></img>
                                    <Link className="dropdown-item" to="/FAQ"><button className="btn sub-button">FAQ</button></Link>
                                </span>
                            </p>
                            
                        </ul>
                    </div>



                </div>
                
                
                
                
                
                {/* <div className="col-md-3 collapse navbar-collapse" id="collapsibleNavbar">
                    
                </div>   */}
            </nav>
        </div>
        
    )
}

export default Navbar;