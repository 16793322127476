import React from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import Popper from 'popper.js';
import './Instructors.min.css';
import InstructorsComponent from './Component/InstructorsComponent';
import instructorsIMG from "../../../images/instructorsIMG.png"
import instructorsIMG1 from "../../../images/instructorsIMG1.png"
import instructorsIMG2 from "../../../images/instructorsIMG2.png"
import instructorsIMG3 from "../../../images/instructorsIMG3.png"
import instructorsIMG4 from "../../../images/instructorsIMG4.png"

import { Link } from 'react-router-dom';

// import Jello from 'react-reveal/Jello';

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Instructors = () => {

    const settings = {
        dots:true,
        centerMode: true,
        arrows:false,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        infinite:true,
        speed:500,
        slidesToShow:5,
        slidesToScroll:1,
        cssEase: "linear",
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    speed:500,
                    cssEase: "linear",
                    focusOnSelect: true,
                    arrows:false,
                    autoplay: true,
                    autoplaySpeed: 5000,
                    pauseOnHover: true,
                    dots: true,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    speed:500,
                    cssEase: "linear",
                    focusOnSelect: true,
                    arrows:false,
                    autoplay: true,
                    autoplaySpeed: 5000,
                    pauseOnHover: true,
                    dots: true,
                }
            }
        ]
    }


    return (
        // <Jello>
        <div className="container Instructors">
            <div className="row r1 align-items-center mb-4">
                <div className="col-md-9 col-sm-8 col-7">
                    <div>
                        <h3 className="Instructors-heading">Instructors</h3>
                    </div>
                    <div>
                        <h5 className="mb-0 Teachers-heading">Online Teachers</h5>
                    </div>
                </div>
                <div className="col-md-3 col-sm-4 col-5">
                    <Link to='/InstructorViewAll'>
                        <button className="btn btn-danger VA-button btn-md px-4">View all</button>
                    </Link>
                </div>
                
                
                
            </div>
            <div className="row choosefav-cstm-row-2  ">
                <Slider {...settings} className="col-md-12 col-sm-12 col-12">
                    
                    <InstructorsComponent src={instructorsIMG}/>
                    <InstructorsComponent src={instructorsIMG1}/>
                    <InstructorsComponent src={instructorsIMG2}/>
                    <InstructorsComponent src={instructorsIMG3}/>
                    <InstructorsComponent src={instructorsIMG4}/>
                    <InstructorsComponent src={instructorsIMG}/>
                    <InstructorsComponent src={instructorsIMG1}/>
                    <InstructorsComponent src={instructorsIMG2}/>
                    <InstructorsComponent src={instructorsIMG3}/>
                    <InstructorsComponent src={instructorsIMG4}/>
                </Slider>
            </div>
            
        </div>
        /*{ </Jello> }*/
    )
}

export default Instructors;