import React from 'react'
import leftImg from '../assets/images/iMAGE.png'
import linkedin from '../assets/images/linkedin.png'
import facebook from '../assets/images/facebook.png'
import gOOGLE from '../assets/images/gOOGLE.png'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
export default function PersonalDetail() {
    return (
        <>
            <div className="d-lg-flex half">
                <div className="bg">
                    <div className="inner_content">
                        <div className="logo_area">
                            <h1>Logo</h1>
                        </div>
                        <div className="img_area">
                            <img src={leftImg} className="img-fluid" />
                        </div>
                        <div className="branding_content">
                            <h1 className="branding_content_heading">Turn your ambition into a success story </h1>
                            <p className="branding_content_text">Choose from over 100,000 online video.</p>
                        </div>
                    </div>

                </div>
                
                    <div className="contents">
                    <div className="container">
                    
                        <div className="text-end w-75 my-4 dont_have_account"><a href="./login.html" type="submit" className="btn btn-success btn-sm">Log in</a></div>
                        <div className="row align-items-center content_container">
                            <div className="content_container_inner">
                                <div className="mb-4">
                                    <h2>Personal Detail</h2>
                                   
                                </div>
                                <form action="#" method="post" className="form_style">
                                    <div className="d-flex justify-content-between">
                                        <div className="form-group">
                                            <label htmlFor="fullname" className="mb-1">Degree</label>
                                            <select className="form-control h-56">
                                                <option>Prof</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>

                                        <div className="form-group mx-2">
                                            <label htmlFor="name" className="mb-1">Name</label>
                                            <input type="text" className="form-control" id="name" placeholder="Name"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="sureName" className="mb-1">Surename</label>
                                            <input type="text" className="form-control" id="sureName" placeholder="Surename"/>
                                        </div>

                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="form-group">
                                            <label htmlFor="fullname" className="mb-1">DOB</label>
                                            <div className="d-flex">
                                                <select className="form-control h-56 w-auto">
                                                    <option>Month</option>
                                                    <option>Jun</option>
                                                    <option>Feb</option>
                                                    <option>Mar</option>
                                                    <option>Apr</option>
                                                    <option>may</option>
                                                </select>
                                                <select className="form-control h-56 mx-2">
                                                    <option>01</option>
                                                    <option>02</option>
                                                    <option>03</option>
                                                    <option>04</option>
                                                    <option>05</option>
                                                </select>
                                                <select className="form-control h-56 w-auto">
                                                    <option>Years</option>
                                                    <option>1990</option>
                                                    <option>1991</option>
                                                    <option>1992</option>
                                                    <option>1993</option>
                                                </select>
                                            </div>
                                            
                                        </div>

                                        <div className="form-group mx-2">
                                            <label htmlFor="name" className="mb-1">Gander</label>
                                            <select className="form-control h-56">
                                                <option>Male</option>
                                                <option>Female</option>
                                                    
                                            </select>
                                        </div>
                                      

                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="fullname" className="mb-1">Select your region</label>
                                        <select className="form-control h-56">
                                            <option>Muslim</option>
                                            <option>Non-Muslim</option>
                                           
                                        </select>
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <div className="form-group w-50 mr-1">
                                            <label htmlFor="fullname" className="mb-1">Select your state</label>
                                            <select className="form-control h-56">
                                                <option>Punjab</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>
                                        <div className="form-group w-50 ml-1">
                                            <label htmlFor="fullname" className="mb-1">Select your City</label>
                                            <select className="form-control h-56">
                                                <option>Lahore</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>

                                       

                                    </div>
                                    <div className="d-flex">
                                        <div className="form-group">
                                            <label htmlFor="fullname" className="mb-1">Phone</label>
                                            <div className="d-flex">
                                                <select className="form-control h-56 w-auto mr-2">
                                                    <option>+92</option>
                                                    <option>+93</option>
                                                    <option>+94</option>
                                                    <option>+95</option>
                                                    <option>+96</option>
                                                </select>
                                                <input type="text" className="form-control" id="Phone" placeholder="Phone"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="fullname" className="mb-1">Select your Language</label>
                                        <select className="form-control h-56">
                                            <option>English</option>
                                            <option>Urdu</option>
                                            
                                           
                                        </select>
                                    </div>

                                    <Link className="btn btn-block btn-success w-100 mb-1" to="/educationaldetail">Next Step </Link>
                                    
                                    <div className="dont_have_account">
                                        <span>By signing up, you agree to our communications and usage
                                            terms Already have an account? <Link to="/Login">Sign in</Link></span>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}
