import React, { useState } from 'react'
import './Quizes.css'
import CQuizes from './Component/CQuizes'
import SubjQuiz from './SubjQuiz/SubjQuiz'
import NewQuiz from './NewQuiz/NewQuiz'
import ReactPaginate from 'react-paginate'
import ArraylistQuizes from './ArraylistQuizes'
import { Button, Form, FormLabel, Modal } from 'react-bootstrap'


export default function Quizes () {

    const [modalState, setModalState] = useState(false);

    const manageState = () => {
        setModalState(!modalState)
    }


    const [pageNumber, setPageNumber] = useState(0)

    const feedbackPerPage = 3
    const pageVisited = pageNumber * feedbackPerPage

    const QuizList = ArraylistQuizes
    .slice(pageVisited, pageVisited + feedbackPerPage)
    .map((arrayarg, i) => {
        return (
            
            <CQuizes key={i} 
            id="i" 
            Ellipse62={ArraylistQuizes[i].Ellipse62}
            title={ArraylistQuizes[i].title}
            questionanswer={ArraylistQuizes[i].questionanswer} 
            qa={ArraylistQuizes[i].qa} 
            timer={ArraylistQuizes[i].timer} 
            time={ArraylistQuizes[i].time}
            Group3dots={ArraylistQuizes[i].Group3dots}
            />
            

        )
    })
    
    const pageCount = Math.ceil(ArraylistQuizes.length / feedbackPerPage);
    const changePage = ({selected}) => {
        setPageNumber(selected)
    };




    return (


        <>
            <div className="container Quizes-container">
                
                {QuizList}
                
                {/* <SubjQuiz/> */}


                


                <div className="py-3 d-flex justify-content-center CQuizes-pagination">
                    {<ReactPaginate
                            previousLabel={<i class="fas fa-chevron-left"></i>}
                            nextLabel={<i class="fas fa-chevron-right"></i>}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationButtons"}
                            previousLinkClassName={"previousButton"}
                            nextLinkClassName={"nextButton"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />}
                </div>

                <div className="pb-3">
                    <button className="btn Qbutton" onClick={() => manageState()}>Create New Quiz</button>
                </div>

                <div className="cstm-Modal">
                        
                        <Modal 
                        show={modalState} 
                        onHide={() => manageState()} 
                        contentClassName="cstm-ModalContent"
                        >
                            <Modal.Header closeButton>
                                <div className="row py-3 d-flex justify-content-center">
                                    <button className="btn NQ-btn">Create New Quiz</button>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <NewQuiz/>
                            </Modal.Body>
                        </Modal>
                </div>

            </div>

        </>

    )

}