import React from 'react'
import './InstructorViewAll.css'

import NavbarComponent from '../../../Header/NavbarComponent/NavbarComponent'
import CustomFooter from '../../../CustomFooter/CustomFooter'
import InstructorsAll from './Components/InstructorsAll/InstructorsAll'
import FavCourseSlider from '../../GetYourCourses/ViewAll/Components/FavCourseSlider/FavCourseSlider'
import InstructorVABanner from './Components/InstructorBanner/InstructorVABanner'

const InstructorViewAll = () => {
    return (
        <>
            <NavbarComponent />
            <InstructorVABanner/>
            <FavCourseSlider/>
            <InstructorsAll/>
            <CustomFooter/>
        </>
    )
}

export default InstructorViewAll
