import React from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import Popper from 'popper.js';
import '../GetYourCoursesRow2component/GetYourCoursesRow2component.min.css';
import Star from '../../../../images/Star.png';
import Layer8 from '../../../../images/Layer8.png';
import Layer9 from '../../../../images/Layer9.png';
import profileimg from '../../../../images/profileimg.png'
import Courseimage1 from '../../../../images/Courseimage1.png'

const GetYourCoursesRow2Component = (props) => {
    return (
            
            <div className="px-3">
                <div className="card GetYourCourses-Row2Component-card h-100 w-100">
                        
                        <div className="card-body  GetYourCourses-Row2Component-card-body">
                            <div className="d-flex justify-content-center"><img className="cstm-img" src={props.src} alt="image"></img></div>
                            <div className="d2" style={{borderBottom:"#EFEFF6 solid 1px", display:"flex",justifyContent:"space-between", padding:"16px 0px"}}>
                                <span className="d-flex align-items-center cstm-hovertext" style={{fontSize:"12px",fontFamily:"Poppins"}}>
                                    <img className="mr-2" src={Star} style={{height:"20px", width:"20px"}}></img>4.5(120)
                                </span>
                                <span className="d-flex align-items-center cstm-hovertext"  style={{fontSize:"12px",fontFamily:"Poppins"}}>
                                    <img className="mr-2" src={Layer8} style={{height:"20px", width:"20px"}}></img>28,500
                                </span>
                                <span className="d-flex align-items-center cstm-hovertext"  style={{fontSize:"12px",fontFamily:"Poppins"}}>
                                    <img className="mr-2" src={Layer9} style={{height:"20px", width:"20px"}}></img>36 Lesion
                                </span>
                            </div>
                            <h4 className="card-card-title cstm-hovertext my-3">{props.name}</h4>
                            <div className="row d3">
                                <div className="col-md-6 col-sm-6 col-12">
                                    <span className="mini-img mr-2"><img src={profileimg}></img></span>
                                    <span className="userNameTitle cstm-hovertext">Nicole Brown</span>
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <span className="prceCut mr-2"><del>$99.99</del></span>
                                    <span className="prceDiscounted" style={{color:"#F68C20"}}>$49.65</span>
                                </div>
                                
                            </div>
                        </div>
                </div>
            </div>
    )
}

export default GetYourCoursesRow2Component;