import React from 'react'
import { Col } from 'react-bootstrap'
import './CTitle.css'

export default function CTitle () {

    const ArrayTitle = [
        {
            id:1,
            title: "Paul Walker",
        },
    ]

    

    return (

        <>
            <Col className="col-md-8">
                <div>
                    {ArrayTitle.map(e => (
                        <h5 style={{fontSize:"40px", fontFamily:"sans-serif", color:"white"}}>{e.title}</h5>
                    ))}
                </div>
            </Col>
            <Col className="col-md-4">
                <div>
                    <h5 style={{fontSize:"18px", fontFamily:"Poppins", color:"white"}}>Home / Teachers / Teachers Profile</h5>
                </div>
            </Col>
        </>

    )


}