import React from 'react'
import './cityAPI.css'



import ReadCity from './Components/readCity'

const CityAPI = () => {
    return (
        <>
            <div className="container cityAPI">
                <div>
                    <ReadCity/>
                </div>
            </div>
            
        </>
    )
}

export default CityAPI;
