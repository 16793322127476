import React from 'react'
import './ViewAll.css'

import NavbarComponent from '../../../Header/NavbarComponent/NavbarComponent'
import CustomFooter from '../../../CustomFooter/CustomFooter'
import ViewAllBanner from './Components/Banner/ViewAllBanner'
import FavCourseSlider from './Components/FavCourseSlider/FavCourseSlider'
import ViewAllCourses from './Components/Courses/ViewAllCourses'

const ViewAll = () => {
    return (
        <>
            <NavbarComponent />
            <ViewAllBanner/>
            <FavCourseSlider/>
            <ViewAllCourses/>
            <CustomFooter/>
        </>
    )
}

export default ViewAll
