import React from 'react';
import { Row } from 'react-bootstrap';
import './StdOverview.css';

export default function StdOverview () {




    return (
        <>
            <div className="container StdOV-container">
                <Row className="my-2">
                    <h5 className="heading">Course Description</h5>
                    <p className="paragraph">See-through delicate embroidered organza blue lining luxury acetate-mix stretch pleat 
                    detailing. Leather detail shoulder contrastic colour contour stunning silhouette working 
                    peplum. Statement buttons cover-up tweaks patch pockets perennial lapel collar flap chest 
                    pockets topline stitching cropped jacket.</p>
                </Row>
                <Row className="my-2">
                    <h5 className="heading">Certification</h5>
                    <p className="paragraph">Effortless comfortable full leather lining eye-catching unique detail to the toe low 
                    ‘cut-away’ sides clean and sleek. Polished finish elegant court shoe work duty stretchy 
                    slingback strap mid kitten heel this ladylike design slingback strap mid kitten heel this 
                    ladylike design.</p>
                </Row>
                <Row className="my-2">
                    <h5 className="heading">Who this course is for</h5>
                    <p className="paragraph">Anyone interested in learning about business (only practical concepts that you can use 
                    and no boring theory + we won’t cover business topics that are common sense</p>
                </Row>
            </div>
        </>
    )

}