import React, {useState} from 'react'
import './CAssessment.css'
import Ellipse62 from '../../../../images/Ellipse62.png'
import pdf from '../../../../images/pdf.png'
import timer from '../../../../images/timer.png'
import Group3dots from '../../../../images/Group3dots.png'
import CCAssessment from './Component/CCAssessment'
// import Modal from 'react-modal'
import { Button, Form, FormLabel, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import attach from '../../../../images/attach.png'
import ReactPaginate from 'react-paginate'

export default function CAssessment () {

    const [modalState, setModalState] = useState(false);

    const manageState = () => {
        setModalState(!modalState)
    }


    const ArraylistCAssessment = [
        {
            id:1,
            Ellipse62: Ellipse62,
            title: "Student Name",
            Group3dots : Group3dots,
            timer: timer,
            pdf: pdf,
            FileName: "File Name",
            time: "1 week",
            date: "05/05/2021",
            

        },
        {
            id:2,
            Ellipse62: Ellipse62,
            title: "Student Name",
            Group3dots : Group3dots,
            timer: timer,
            pdf: pdf,
            FileName: "File Name",
            time: "1 week",
            date: "05/05/2021",
            

        },
        {
            id:3,
            Ellipse62: Ellipse62,
            title: "Student Name",
            Group3dots : Group3dots,
            timer: timer,
            pdf: pdf,
            FileName: "File Name",
            time: "1 week",
            date: "05/05/2021",
            

        },
        {
            id:4,
            Ellipse62: Ellipse62,
            title: "Student Name",
            Group3dots : Group3dots,
            timer: timer,
            pdf: pdf,
            FileName: "File Name",
            time: "1 week",
            date: "05/05/2021",
            

        },
        {
            id:5,
            Ellipse62: Ellipse62,
            title: "Student Name",
            Group3dots : Group3dots,
            timer: timer,
            pdf: pdf,
            FileName: "File Name",
            time: "1 week",
            date: "05/05/2021",
            

        },
    ]

    // "arraylistarg" is a variable name which can holds Array current values and "i" is index
    // const CAssessmentList = ArraylistCAssessment.map( (arg, i) => {
    //     return (
    //         <CCAssessment key={i} 
    //         id="i" 
    //         Ellipse62={ArraylistCAssessment[i].Ellipse62}
    //         title={ArraylistCAssessment[i].title}
    //         pdf={ArraylistCAssessment[i].pdf} 
    //         FileName={ArraylistCAssessment[i].FileName} 
    //         timer={ArraylistCAssessment[i].timer} 
    //         time={ArraylistCAssessment[i].time}
    //         date={ArraylistCAssessment[i].date}
    //         Group3dots={ArraylistCAssessment[i].Group3dots}
    //         />
    //     )
    // })

    const [pageNumber, setPageNumber] = useState(0)

    const feedbackPerPage = 3
    const pageVisited = pageNumber * feedbackPerPage

    const cAssessmentList = ArraylistCAssessment
    .slice(pageVisited, pageVisited + feedbackPerPage)
    .map((arrayarg, i) => {
        return (
            
            <CCAssessment key={i} 
            id="i" 
            Ellipse62={ArraylistCAssessment[i].Ellipse62}
            title={ArraylistCAssessment[i].title}
            pdf={ArraylistCAssessment[i].pdf} 
            FileName={ArraylistCAssessment[i].FileName} 
            timer={ArraylistCAssessment[i].timer} 
            time={ArraylistCAssessment[i].time}
            date={ArraylistCAssessment[i].date}
            Group3dots={ArraylistCAssessment[i].Group3dots}
            />
            

        )
    })

    const pageCount = Math.ceil(ArraylistCAssessment.length / feedbackPerPage);
    const changePage = ({selected}) => {
        setPageNumber(selected)
    };




    return (


        <>
            <div className="container CAssessment-container">
                {cAssessmentList}


                <div className="py-3 d-flex justify-content-center CA-pagination">
                {<ReactPaginate
                            previousLabel={<i class="fas fa-chevron-left"></i>}
                            nextLabel={<i class="fas fa-chevron-right"></i>}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationButtons"}
                            previousLinkClassName={"previousButton"}
                            nextLinkClassName={"nextButton"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />}
                </div>

                {/* <div className="pb-3 d-flex justify-content-end">
                    <button className="btn CAbutton" onClick={() => manageState()}>Create New Quiz</button>
                </div> */}


                {/* <div>
                        
                    <Modal className="cstm-CAModal" show={modalState} onHide={() => manageState()}>
                        <Modal.Header closeButton>
                            <div className="row col-md-12 d-flex justify-content-center">
                                <div><h5 className="CAMheader">Assign Documents to students</h5></div>
                                
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>

                                <div className="row d-flex mb-3 justify-content-center">
                                    <div className="d-flex col-md-10">
                                        <FormLabel className="CAMlabel col-md-3" htmlFor="coursecategory">Title</FormLabel>
                                        <select className="CAMfield col-md-9" name="year" id="coursecategory" placeholder="Student id">
                                            <option value="1990">1990</option>
                                            <option value="1991">1991</option>
                                            <option value="1992">1992</option>
                                            <option value="1993">1993</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row d-flex mb-3 justify-content-center">
                                    <div className="d-flex col-md-10">
                                        <FormLabel className="CAMlabel col-md-3" htmlFor="coursecategory">Student</FormLabel>
                                        <select className="CAMfield col-md-9" name="year" id="coursecategory" placeholder="Student id">
                                            <option value="1990">1990</option>
                                            <option value="1991">1991</option>
                                            <option value="1992">1992</option>
                                            <option value="1993">1993</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row d-flex mb-3 justify-content-center">
                                    <div className="d-flex col-md-10">
                                        <FormLabel className="CAMlabel col-md-3" htmlFor="coursecategory">Duration</FormLabel>
                                        <select className="CAMfield col-md-9" name="year" id="coursecategory" placeholder="Student id">
                                            <option value="1990">1990</option>
                                            <option value="1991">1991</option>
                                            <option value="1992">1992</option>
                                            <option value="1993">1993</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row d-flex mb-3 justify-content-center">
                                    <div className="d-flex">
                                    <Link to="#/" className="btn CAMbutton-red"><img src={attach}></img>File Attach</Link>
                                    </div>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer><Button className="CAMbutton">Save</Button></Modal.Footer>
                        
                    </Modal>
                </div> */}

            </div>

        </>

    )

}