import React, { useState } from 'react'
import './Quizes.css'
import Ellipse62 from '../../../../../images/Ellipse62.png'
import questionanswer from '../../../../../images/questionanswer.png'
import timer from '../../../../../images/timer.png'
import Group3dots from '../../../../../images/Group3dots.png'
import CQuizes from './Component/CQuizes'
import ReactPaginate from 'react-paginate'


export default function Quizes () {

    


    const ArraylistQuizes = [
        {
            id:1,
            Ellipse62: Ellipse62,
            title: "English Basic quiz.",
            Group3dots : Group3dots,
            timer: timer,
            questionanswer: questionanswer,
            qa: "10",
            time: "10 min",

        },
        {
            id:2,
            Ellipse62: Ellipse62,
            title: "English Basic quiz.",
            Group3dots : Group3dots,
            timer: timer,
            questionanswer: questionanswer,
            qa: "10",
            time: "10 min",

        },
        {
            id:3,
            Ellipse62: Ellipse62,
            title: "English Basic quiz.",
            Group3dots : Group3dots,
            timer: timer,
            questionanswer: questionanswer,
            qa: "10",
            time: "10 min",

        },
        {
            id:4,
            Ellipse62: Ellipse62,
            title: "English Basic quiz.",
            Group3dots : Group3dots,
            timer: timer,
            questionanswer: questionanswer,
            qa: "10",
            time: "10 min",

        },
        {
            id:5,
            Ellipse62: Ellipse62,
            title: "English Basic quiz.",
            Group3dots : Group3dots,
            timer: timer,
            questionanswer: questionanswer,
            qa: "10",
            time: "10 min",

        },
    ]

    // "arraylistarg" is a variable name which can holds Array current values and "i" is index
    // const QuizesList = ArraylistQuizes.map( (arg, i) => {
    //     return (
    //         <CQuizes key={i} 
    //         id="i" 
    //         Ellipse62={ArraylistQuizes[i].Ellipse62}
    //         title={ArraylistQuizes[i].title}
    //         questionanswer={ArraylistQuizes[i].questionanswer} 
    //         qa={ArraylistQuizes[i].qa} 
    //         timer={ArraylistQuizes[i].timer} 
    //         time={ArraylistQuizes[i].time}
    //         Group3dots={ArraylistQuizes[i].Group3dots}
    //         />
    //     )
    // })

    const [pageNumber, setPageNumber] = useState(0)

    const feedbackPerPage = 3
    const pageVisited = pageNumber * feedbackPerPage

    const stdQuiztList = ArraylistQuizes
    .slice(pageVisited, pageVisited + feedbackPerPage)
    .map((arrayarg, i) => {
        return (
            
            <CQuizes key={i} 
            id="i" 
            Ellipse62={ArraylistQuizes[i].Ellipse62}
            title={ArraylistQuizes[i].title}
            questionanswer={ArraylistQuizes[i].questionanswer} 
            qa={ArraylistQuizes[i].qa} 
            timer={ArraylistQuizes[i].timer} 
            time={ArraylistQuizes[i].time}
            Group3dots={ArraylistQuizes[i].Group3dots}
            />
            

        )
    })
    
    const pageCount = Math.ceil(ArraylistQuizes.length / feedbackPerPage);
    const changePage = ({selected}) => {
        setPageNumber(selected)
    };




    return (


        <>
            <div className="container Quizes-container">
                
                {stdQuiztList}
                {/* <SubjQuiz/> */}
                {/* <NewQuiz/> */}


                

            {/*--------------pagination-------------------  */}
                <div className="py-3 d-flex justify-content-center CQuizes-pagination">
                    {<ReactPaginate
                            previousLabel={<i class="fas fa-chevron-left"></i>}
                            nextLabel={<i class="fas fa-chevron-right"></i>}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationButtons"}
                            previousLinkClassName={"previousButton"}
                            nextLinkClassName={"nextButton"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />}
                </div>

                

            </div>

        </>

    )

}