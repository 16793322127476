import React from 'react';
import './TeacherCourse.css';
import Course from './Course/Course';
import NewCourse from './NewCourse/NewCourse';
import CourseDetails from './CourseDetails/CourseDetails';

export default function TeacherCourse () {



    return (
        <>
            <div className="TC-cstm container px-0">
                    {/* <div className="TC-cstm-course "><Course/></div> */}
                    {/* <div className="TC-cstmTA "><NewCourse/></div> */}
                    <div classname="TC-cstm-CD"><CourseDetails/></div>
            </div>
        </>
    )

}