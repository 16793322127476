import React from 'react'
import './ADBoard.css'
import ADheader from './header/ADheader'

const ADBoard = () => {
    return (
        <>
            <ADheader/>
           
        </>
    )
}

export default ADBoard
