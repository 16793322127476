import React from 'react'
import { Col } from 'react-bootstrap'
import History from './History/History'
import './Wallet.css'
import Withdraw from './Withdraw/Withdraw'

export default function Wallet () {


    return (

        <>
            <div className="cstm-Wallet-padding">
                <div className="container W-container px-0 bg-white">
                    <div className="d-flex cstm-div" style={{maxHeight:"105vh", height:"100%"}}>
                        <Col className="p-3 col-md-6">
                            <Withdraw/>
                        </Col>
                        <Col className="p-3 col-md-6">
                            <History/>
                        </Col>
                    </div>
                </div>
            </div>
        </>

    )

}