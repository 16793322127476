import React, {useState} from 'react'
import { Col, Row, Button, Form, FormLabel, Modal } from 'react-bootstrap'
import './Withdraw.css'
import wallet from '../../../../images/wallet.png'
import money from '../../../../images/money.png'
import visacrd from '../../../../images/visacrd.png'
import card2 from '../../../../images/card2.png'
import tickicon from '../../../../images/tickicon.png'

export default function Withdraw () {


    const [modalState, setModalState] = useState(false);

    const manageState = () => {
        setModalState(!modalState)
    }

    const ArrayWithdraw = [
        {
            id:1,
            title: "My Balance",
            cash: "$250",
            addedd: "8 May 2021",
        }
    ]

    return (

        <>
            <div className="WDcontainer col-md-12">
                <Row style={{borderBottom:"#CAC6D4 solid 1px"}} className="mb-3 py-2">
                    <Col className="d-flex align-items-center">
                        <span><img src={wallet}></img></span>
                        <span className="ml-2"><h5 className="WDheading">Wallet</h5></span>
                    </Col>
                    <Col className="text-end">
                        <button className="btn WDbutton"  onClick={() => manageState()}>Add Balance</button>
                    </Col>
                </Row>
                <Row className="py-5 d-flex align-content-center justify-content-center">
                    <div className="card WDcard px-5">
                        <div className="card-body">
                            <div className="text-center"><img src={money}></img></div>
                            <div className="text-center">
                                {ArrayWithdraw.map(e => (
                                    <h5 className="title">{e.title}</h5>
                                ))}</div>
                            <div className="text-center">
                                {ArrayWithdraw.map(e => (
                                    <p className="cashtext">{e.cash}</p>
                                ))}
                            </div>
                            <div className="text-center" style={{background:"#F56962",borderRadius:"10px",padding:"3px 5px"}}>
                                {ArrayWithdraw.map(e => (
                                    <p>Addedd: {e.addedd}</p>
                                ))}
                            </div>
                        </div>
                    </div>
                </Row>


                <div>
                        
                    <Modal className="StdWD-Modal" show={modalState} onHide={() => manageState()}>
                        <Modal.Header closeButton >
                            <div className="row d-flex px-3">
                                <div><h5 className="header">Add Balance</h5></div>
                                
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                

                                <div className="row d-flex justify-content-between">
                                    <div className="d-flex flex-column col">
                                        <FormLabel className="label" htmlFor="holdername">Holder Name*</FormLabel>
                                        <input type="text" className="form-control border" placeholder="Holder Name" id="holdername" />
                                    </div>
                                    <div className="d-flex flex-column col">
                                        <FormLabel className="label" htmlFor="cardnumber">Card Number*</FormLabel>
                                        <input type="text" className="form-control border" placeholder="Card Number" id="cardnumber" />
                                    </div>
                                </div>
                                <div className="row d-flex py-3">
                                    <div className="d-flex flex-column col">
                                        <FormLabel className="label" htmlFor="expirationmonth">Expiration Month*</FormLabel>
                                        <select className="border py-2" name="year" id="expirationmonth" placeholder="Month">
                                            <option value="january">january</option>
                                            <option value="feburary">feburary</option>
                                            <option value="march">march</option>
                                            <option value="april">april</option>
                                        </select>
                                    </div>
                                    <div className="d-flex flex-column col">
                                        <FormLabel className="label" htmlFor="expirationyear">Expiration Year*</FormLabel>
                                        <input type="text" className="form-control border" placeholder="Year" id="expirationyear" />
                                    </div>
                                    <div className="d-flex flex-column col">
                                        <FormLabel className="label" htmlFor="cvv">CVV*</FormLabel>
                                        <input type="text" className="form-control border" placeholder="CVV" id="cvv" />
                                    </div>
                                </div>
                                
                                <div className="row d-flex ">
                                    <div className="d-flex flex-column col">
                                            <FormLabel className="label" htmlFor="addbalance">Add Balance*</FormLabel>
                                            <input type="text" className="form-control border" placeholder="$10" id="addbalance" />
                                        </div>
                                    </div>
                                <div className="row d-flex py-3">
                                    <div className="d-flex col">
                                    <button className="btn button px-3">Add Balance</button>
                                    </div>
                                    <div className="d-flex justify-content-end col">
                                        <img src={visacrd}></img>
                                        <img src={card2}></img>
                                    </div>
                                </div>
                            </Form>
                        </Modal.Body>
                        
                        
                    </Modal>

                    <Modal className="StdWD-Modal2">
                    <Modal.Header closeButton >

                    </Modal.Header>

                    <Modal.Body>
                        <div className="text-center">
                            <div><img className="image" src={tickicon}></img></div>
                            <div><p className="successfully py-3">Add Successfully</p></div>
                        </div>
                    </Modal.Body>

                    </Modal>
                </div>

            </div>
        </>

    )

}