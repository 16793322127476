import React from 'react'
import './AppExpanse.css'
import { Row } from 'react-bootstrap'
import icoption from '../../../../../../images/icoption.png'
import TableComponent from './TableComponent/TableComponent'

const AppExpanse = () => {
    return (
        <>
            <div className="container AppExpanse p-4">
                <Row>
                    <div className="flex-grow-1 d-flex flex-column align-items-start">
                        <h5>APP Expense</h5>
                        <p>You have 456 contacts</p>
                    </div>
                    <div>
                        <img className="dropdown-toggle" aria-haspopup="true" data-toggle="dropdown" src={icoption}></img>
                        <span className="dropdown-menu cstm-CQuiz-dropdownmenu">
                            <a className="dropdown-item" href="#">Quiz</a>
                            <a className="dropdown-item" href="#">Edit</a>
                            <a className="dropdown-item" href="#">Delete</a>
                        </span>
                    </div>
                </Row>
                <Row>
                    <TableComponent/>
                </Row>
            </div>
        </>
    )
}

export default AppExpanse
