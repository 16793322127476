import React from 'react'
import { Col, Row } from 'react-bootstrap'
import EduandDeals from '../CourseStudent/Components/EduandDeals/EduandDeals'
import CartDetails from './CartDetails/CartDetails'
import CartItem from './CartItem/CartItem'
import './Cartpage.css'
import CartTotal from './CartTotal/CartTotal'

export default function Cartpage () {


    return (

        <>
            <div className="container-fluid bg-white">
            <div className="container Cartcontainer" style={{position: "relative", top: "-204px"}}>
                <Row className="px-3">
                    <div><h5 style={{fontSize: "40px", fontFamily: "Poppins", color:"white"}}>Shopping Cart</h5></div>
                </Row>
                <Row>
                    <Col className="col-md-8 col-sm-12">
                        <CartItem/>
                    </Col>
                    <Col className="col-md-4 col-sm-12 py-4">
                        <CartTotal/>
                        {/* <CartDetails/> */}
                    </Col>
                </Row>
                <Row>

                </Row>
            </div>
            </div>

            <div className="container-fluid">
                <EduandDeals/>
            </div>

        </>

    )

}