import Reacr from 'react';
import './OnlineLessons.css';

import Jello from 'react-reveal/Jello';

const OnlineLessons = () => {
    return (
        <div className="container-fluid OnlineLessons-containerfluid">
            <div className="container OnlineLessons-container">
                <div className="col-md-12  p-5 ">
                <Jello>
                    <h3 className="cstm-h3">Online Coaching Lessons For Remote Learning</h3>
                    <p className="cstm-p">100% online learning from the world’s best universities and companies</p>
                    <button className="btn button">Join Today</button>
                </Jello>
                </div>
            </div>
        </div>
    )
}

export default OnlineLessons;