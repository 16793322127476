import thumbsuporange from '../../../../../images/thumbsuporange.png'
import thumbsdown2 from '../../../../../images/thumbsdown2.png'

const ArraylistCPAF = [
    {
        id:1,
        image1: thumbsuporange,
        image2: thumbsdown2,
        title: "Wynton McCurdy",
        text1: "a month ago",
        text2: "Very specific course, but it definitely has value. Created a freqtrade rading bot from their other course, and this makes it super easy to install on a raspberry (in my case). I had no experience with a raspberry before. With the scripts provided the bot will automatically start in case of no connection or no power, which is awesome, so I won’t have to worry about that anymore. Definitely recommend",
    },
    {
        id:2,
        image1:thumbsuporange,
        image2: thumbsdown2,
        title: "Wynton McCurdy",
        text1: "a month ago",
        text2: "Very specific course, but it definitely has value. Created a freqtrade rading bot from their other course, and this makes it super easy to install on a raspberry (in my case). I had no experience with a raspberry before. With the scripts provided the bot will automatically start in case of no connection or no power, which is awesome, so I won’t have to worry about that anymore. Definitely recommend",
    },
    {
        id:3,
        image1:thumbsuporange,
        image2: thumbsdown2,
        title: "Wynton McCurdy",
        text1: "a month ago",
        text2: "Very specific course, but it definitely has value. Created a freqtrade rading bot from their other course, and this makes it super easy to install on a raspberry (in my case). I had no experience with a raspberry before. With the scripts provided the bot will automatically start in case of no connection or no power, which is awesome, so I won’t have to worry about that anymore. Definitely recommend",
    },
    {
        id:4,
        image1: thumbsuporange,
        image2: thumbsdown2,
        title: "Wynton McCurdy",
        text1: "a month ago",
        text2: "Very specific course, but it definitely has value. Created a freqtrade rading bot from their other course, and this makes it super easy to install on a raspberry (in my case). I had no experience with a raspberry before. With the scripts provided the bot will automatically start in case of no connection or no power, which is awesome, so I won’t have to worry about that anymore. Definitely recommend",
    },
    {
        id:5,
        image1:thumbsuporange,
        image2: thumbsdown2,
        title: "Wynton McCurdy",
        text1: "a month ago",
        text2: "Very specific course, but it definitely has value. Created a freqtrade rading bot from their other course, and this makes it super easy to install on a raspberry (in my case). I had no experience with a raspberry before. With the scripts provided the bot will automatically start in case of no connection or no power, which is awesome, so I won’t have to worry about that anymore. Definitely recommend",
    },
    {
        id:6,
        image1:thumbsuporange,
        image2: thumbsdown2,
        title: "Wynton McCurdy",
        text1: "a month ago",
        text2: "Very specific course, but it definitely has value. Created a freqtrade rading bot from their other course, and this makes it super easy to install on a raspberry (in my case). I had no experience with a raspberry before. With the scripts provided the bot will automatically start in case of no connection or no power, which is awesome, so I won’t have to worry about that anymore. Definitely recommend",
    },
    {
        id:7,
        image1: thumbsuporange,
        image2: thumbsdown2,
        title: "Wynton McCurdy",
        text1: "a month ago",
        text2: "Very specific course, but it definitely has value. Created a freqtrade rading bot from their other course, and this makes it super easy to install on a raspberry (in my case). I had no experience with a raspberry before. With the scripts provided the bot will automatically start in case of no connection or no power, which is awesome, so I won’t have to worry about that anymore. Definitely recommend",
    },
    {
        id:8,
        image1:thumbsuporange,
        image2: thumbsdown2,
        title: "Wynton McCurdy",
        text1: "a month ago",
        text2: "Very specific course, but it definitely has value. Created a freqtrade rading bot from their other course, and this makes it super easy to install on a raspberry (in my case). I had no experience with a raspberry before. With the scripts provided the bot will automatically start in case of no connection or no power, which is awesome, so I won’t have to worry about that anymore. Definitely recommend",
    },
]

export default ArraylistCPAF;