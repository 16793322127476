import instructorsIMG from "../../../../../../images/instructorsIMG.png"
import instructorsIMG1 from "../../../../../../images/instructorsIMG1.png"
import instructorsIMG2 from "../../../../../../images/instructorsIMG2.png"
import instructorsIMG3 from "../../../../../../images/instructorsIMG3.png"
import instructorsIMG4 from "../../../../../../images/instructorsIMG4.png"


const VAInstructorlist = [
    {
        id:1,
        src: instructorsIMG,
        category1: 'Design',
        category2: 'All',
        category3: 'Popular'
        
    },
    {
        id:2,
        src: instructorsIMG1,
        category1: 'Business',
        category2: 'All',
        
    },
    {
        id:3,
        src: instructorsIMG2,
        category1: 'Marketing',
        category2: 'All',
        
    },
    {
        id:4,
        src: instructorsIMG3,
        category1: 'Photography',
        category2: 'All',
        
    },
    {
        id:5,
        src: instructorsIMG4,
        category1: 'Music',
        category2: 'All',
        
    },
    {
        id:6,
        src: instructorsIMG,
        category1: 'Design',
        category2: 'All',
        
    },
    {
        id:7,
        src: instructorsIMG1,
        category1: 'Design',
        category2: 'All',
        
    },
    {
        id:8,
        src: instructorsIMG2,
        category1: 'Design',
        category2: 'All',
        
    },
    {
        id:9,
        src: instructorsIMG3,
        category1: 'Design',
        category2: 'All',
        
    },
    {
        id:10,
        src: instructorsIMG4,
        category1: 'Design',
        category2: 'All',
        
    },
    {
        id:11,
        src: instructorsIMG,
        category1: 'Design',
        category2: 'All',
        
    },
    {
        id:12,
        src: instructorsIMG1,
        category1: 'Design',
        category2: 'All',
        
    },
    {
        id:13,
        src: instructorsIMG2,
        category1: 'Design',
        category2: 'All',
        
    },
    {
        id:14,
        src: instructorsIMG3,
        category1: 'Business',
        category2: 'All',
        
    },
    {
        id:15,
        src: instructorsIMG4,
        category1: 'Business',
        category2: 'All',
        
    },
    {
        id:16,
        src: instructorsIMG,
        category1: 'Business',
        category2: 'All',
        
    },
    {
        id:17,
        src: instructorsIMG1,
        category1: 'Business',
        category2: 'All',
        
    },
    {
        id:18,
        src: instructorsIMG2,
        category1: 'Business',
        category2: 'All',
        
    },
    {
        id:19,
        src: instructorsIMG3,
        category1: 'Business',
        category2: 'All',
        
    },
    {
        id:20,
        src: instructorsIMG4,
        category1: 'Photography',
        category2: 'All',
        
    },
    {
        id:21,
        src: instructorsIMG,
        category1: 'Photography',
        category2: 'All',
        
    },
    {
        id:22,
        src: instructorsIMG1,
        category1: 'Photography',
        category2: 'All',
        
    },
    {
        id:23,
        src: instructorsIMG2,
        category1: 'Photography',
        category2: 'All',
        
    },
    {
        id:24,
        src: instructorsIMG3,
        category1: 'Photography',
        category2: 'All',
        
    },
    {
        id:25,
        src: instructorsIMG4,
        category1: 'Photography',
        category2: 'All',
        
    },
    {
        id:26,
        src: instructorsIMG,
        category1: 'Photography',
        category2: 'All',
        
    },
    {
        id:27,
        src: instructorsIMG1,
        category1: 'Photography',
        category2: 'All',
        category3: 'Popular'
        
    },
    {
        id:28,
        src: instructorsIMG2,
        category1: 'Photography',
        category2: 'All',
        category3: 'Popular'
        
    },
    {
        id:29,
        src: instructorsIMG3,
        category1: 'Music',
        category2: 'All',
        category3: 'Popular'
        
    },
    {
        id:30,
        src: instructorsIMG4,
        category1: 'Music',
        category2: 'All',
        category3: 'Popular'
        
    },
    
];

export default VAInstructorlist;