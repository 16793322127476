import React, {useState} from 'react'
import './QuizResult.css'
import Ellipse62 from '../../../../../images/Ellipse62.png'
import questionanswer from '../../../../../images/questionanswer.png'
import timer from '../../../../../images/timer.png'
import Group3dots from '../../../../../images/Group3dots.png'
import check from '../../../../../images/check.png'
import cross from '../../../../../images/cross.png'
import list from '../../../../../images/list.png'
import CQuizResult from './Component/CQuizResult'
import ReactPaginate from 'react-paginate'

export default function QuizResult () {


    const ArraylistQuizResult = [
        {
            id:1,
            Ellipse62: Ellipse62,
            title: "English Basic quiz.",
            Group3dots : Group3dots,
            timer: timer,
            questionanswer: questionanswer,
            qa: "10",
            time: "10 min",
            check: check,
            cross: cross,
            list: list,
            one: "1",
            nine: "9",
            per: "90%",

        },
        {
            id:2,
            Ellipse62: Ellipse62,
            title: "English Basic quiz.",
            Group3dots : Group3dots,
            timer: timer,
            questionanswer: questionanswer,
            qa: "10",
            time: "10 min",
            check: check,
            cross: cross,
            list: list,
            one: "1",
            nine: "9",
            per: "90%",

        },
        {
            id:3,
            Ellipse62: Ellipse62,
            title: "English Basic quiz.",
            Group3dots : Group3dots,
            timer: timer,
            questionanswer: questionanswer,
            qa: "10",
            time: "10 min",
            check: check,
            cross: cross,
            list: list,
            one: "1",
            nine: "9",
            per: "90%",

        },
        {
            id:4,
            Ellipse62: Ellipse62,
            title: "English Basic quiz.",
            Group3dots : Group3dots,
            timer: timer,
            questionanswer: questionanswer,
            qa: "10",
            time: "10 min",
            check: check,
            cross: cross,
            list: list,
            one: "1",
            nine: "9",
            per: "90%",

        },
        {
            id:5,
            Ellipse62: Ellipse62,
            title: "English Basic quiz.",
            Group3dots : Group3dots,
            timer: timer,
            questionanswer: questionanswer,
            qa: "10",
            time: "10 min",
            check: check,
            cross: cross,
            list: list,
            one: "1",
            nine: "9",
            per: "90%",

        },
    ]


    const [pageNumber, setPageNumber] = useState(0)

    const feedbackPerPage = 3
    const pageVisited = pageNumber * feedbackPerPage

    const QuizResultList = ArraylistQuizResult
    .slice(pageVisited, pageVisited + feedbackPerPage)
    .map((arrayarg, i) => {
        return (
            
            <CQuizResult key={i} 
            id="i" 
            Ellipse62={ArraylistQuizResult[i].Ellipse62}
            title={ArraylistQuizResult[i].title}
            questionanswer={ArraylistQuizResult[i].questionanswer} 
            qa={ArraylistQuizResult[i].qa} 
            timer={ArraylistQuizResult[i].timer} 
            time={ArraylistQuizResult[i].time}
            check={ArraylistQuizResult[i].check}
            cross={ArraylistQuizResult[i].cross}
            list={ArraylistQuizResult[i].list}
            one={ArraylistQuizResult[i].one}
            nine={ArraylistQuizResult[i].nine}
            per={ArraylistQuizResult[i].per}
            Group3dots={ArraylistQuizResult[i].Group3dots}
            />
            

        )
    })
    
    const pageCount = Math.ceil(ArraylistQuizResult.length / feedbackPerPage);
    const changePage = ({selected}) => {
        setPageNumber(selected)
    };




    return (


        <>
            <div className="container QuizResult-container">
                {QuizResultList}


                <div className="py-3 d-flex justify-content-center CQR-pagination">
                    {<ReactPaginate
                            previousLabel={<i class="fas fa-chevron-left"></i>}
                            nextLabel={<i class="fas fa-chevron-right"></i>}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationButtons"}
                            previousLinkClassName={"previousButton"}
                            nextLinkClassName={"nextButton"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />}
                </div>

            </div>

        </>

    )

}