import React from 'react'
import './CourseComponent.css'
import { Link } from 'react-router-dom';

export default function CourseComponent (props) {


    return (

        <>
            <div className="col-md-4 col-sm-6">
                <div className="card my-3 CourseC-card">
                        
                        
                    <div className="card-body CourseC-card-body">
                        <div className="CourseC-img"><img className="" src={props.image1} alt="image"></img></div>
                        <div className="CourseC-SWP">
                            <span >
                                <img src={props.image2} ></img>
                                <p>{props.star}</p>
                            </span>
                            <span >
                                <img src={props.image3}></img>
                                <p>{props.watch}</p>
                            </span>
                            <span >
                                <img src={props.image4} ></img>
                                <p>{props.play}</p>
                            </span>
                        </div>
                        <h4 className="card-card-title">{props.heading}</h4>
                        <div>
                            <div>
                                <span><img src={props.image5}></img></span>
                                <span ><p>{props.name}</p></span>
                            </div>
                            <div>
                                <span ><p>{props.price}</p></span>
                                <span ><p>{props.dprice}</p></span>
                            </div>
                                
                                
                        </div>
                    </div>
                </div>
            </div>
        </>

    )


}