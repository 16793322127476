import React from 'react'
import Lectures from './component/Lectures/Lectures'
import LecturesHistory from './component/LecturesHistory/LecturesHistory'
import './Lecture.css'

export default function Lecture () {

    return (

        <>
            <div className="cstm-Lecture-padding">
                <div className="container Leccontainer bg-white px-0">
                    <div className="cstm-Lectab">
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item col-md-6 px-0">
                                <a className="text-center nav-link active " data-toggle="tab" href="#lectures" role="tab">Lectures</a>
                            </li>
                            <li className="nav-item col-md-6 px-0">
                                <a className="text-center nav-link" data-toggle="tab" href="#lectureshistory" role="tab">Lectures History</a>
                            </li>
                        </ul>
                        {/* <!-- Tab panes --> */}
                        <div className="tab-content mt-5">
                            <div className="tab-pane active" id="lectures" role="tabpanel">
                                <Lectures />
                                
                                

                            </div>
                            <div className="tab-pane" id="lectureshistory" role="tabpanel">
                                <LecturesHistory />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )


}