import React from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import Popper from 'popper.js';
import '../CustomFooter/CustomFooter.min.css';
import Layer1 from '../../images/Layer1.png';
import Layer2 from '../../images/Layer2.png';
import Layertwitter from '../../images/Layertwitter.png';
import Layerlinkedin from '../../images/Layerlinkedin.png';


const CustomFooter = () => {
    return (
        <div className="container-fluid CustomFooter-container-fluid">
            <div className="container footer-container">
                <div className="row p-5 cstm-img">
                    <div className="col-md-6 col-sm-12 col-12 ">
                        <h5 className="heading">Join Us</h5>
                        <p className="text">We have best deal for stands and teachers.</p>
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 footer-banner-button">
                        <button className="btn button-red"> Apply as teacher </button>
                        <button className="btn button-green"> Apply as student </button>
                    </div>
                </div>
                <div className="row r1">
                    <div className="d1 col-md-3 col-sm-6 col-6">
                        <div className="footer_cstm_cel">
                            <h5 className="">Company</h5>
                            <ul>
                                <li><a href="#">About</a></li>
                                <li><a href="#">Carrier</a></li>
                                <li><a href="#">Press</a></li>
                                <li><a href="#">Blog</a></li>
                                <li><a href="#">Affiliates</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="d2 col-md-3 col-sm-6 col-6">
                        <div className="footer_cstm_cel">

                            <h5 className="">Community</h5>
                            <ul>
                                <li><a href="#">Go Premiun</a></li>
                                <li><a href="#">Free Plans</a></li>
                                <li><a href="#">Refer a Friend</a></li>
                                <li><a href="#">Gift Cards</a></li>
                                <li><a href="#">Scholorships</a></li>
                                <li><a href="#">Free Classes</a></li>
                            </ul>
                        </div>
                        
                    </div>
                    <div className="mobile-responsive-cstm my-1 col-12">
                        <br></br>
                        <br></br>
                    </div>
                    <div className=" d3 col-md-3 col-sm-6 col-6">
                        <div className="footer_cstm_cel">
                            
                            <h5 className="">Teaching</h5>
                            <ul>
                                <li><a href="#">Become a Teacher</a></li>
                                <li><a href="#">Teaching Academy</a></li>
                                <li><a href="#">Teacher Handbook</a></li>
                                <li><a href="#">Parterships</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="d4 col-md-3 col-sm-6 col-6">
                        <div className="footer_cstm_cel">

                            <h5 className="">Support</h5>
                            <ul>
                                <li><a href="#">Suppoert</a></li>
                                <li><a href="#">Contact Us</a></li>
                                <li><a href="#">System Requirements</a></li>
                                <li><a href="#">Register Activation Key</a></li>
                                <li><a href="#">Site Feedback</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <hr className="footer_divider" />
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="footer_copyRight">
                                <h4 className="">© 2020-2021 Skillfy Education Private Limited. All rights reserved</h4>
                            </div>
                            <div className="d-flex align-items-center">
                                <a href="#" className="btn"><img src={Layer1}></img></a>
                                <a href="#" className="btn"><img src={Layer2}></img></a>
                                <a href="#" className="btn "><img src={Layertwitter}></img></a>
                                <a href="#" className="btn "><img src={Layerlinkedin}></img></a>
                            </div>
                            
                        </div>
                    </div>

                </div>
                
            </div>
            
            
        </div>
    )
}

export default CustomFooter;