import React from 'react';
import './LearnMoreComponent.css';

import Tada from 'react-reveal/Tada';

const LearnMoreComponent = (props) => {

    // componentDidMount = () => {}

    return (
        <Tada>
        <div className="LearnMoreComponent-container  col-md-4 col-sm-12">
            <img className="m-1" src={props.image1}></img>
            <h5>{props.name}</h5>
            <p>{props.text}</p>
            <a href="#"><img src={props.image2}></img></a>
            <div className="LearnMoreComponent-overlay"></div>
        </div>
        </Tada>
    )
}

export default LearnMoreComponent;