import React from 'react';
import './FAQ.css';
import Navbar from '../Header/NavbarComponent/NavbarComponent';
import Banner from './Components/Banner/Banner';
import OurMost from './Components/OurMost/OurMost';
import GetInTouch from '../ContactUs/GetInTouch/GetInTouch';
import OnlineLessons from './Components/OnlineLessons/OnlineLessons';
import BecomeOrConstruct from '../AboutUs/Components/BecomeOrConstruct/BecomeOrConstruct';
import CustomFooter from '../CustomFooter/CustomFooter';


const FAQ = () => {
    return (
        <div className="main_wrapper">
            <Navbar />
            <Banner />
            <OurMost />
            <GetInTouch />
            <OnlineLessons />
            <BecomeOrConstruct />
            <CustomFooter />
        </div>
    )
}

export default FAQ;