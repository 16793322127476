import React from 'react';
import './LearnMore.css';
import 'jquery';
import Popper from 'popper.js';
import LearnMoreComponent from './LearnMoreComponent/LearnMoreComponent';
import Action from '../../../../images/Action.png';
import Audio from '../../../../images/Audio.png';
import Bulb from '../../../../images/bulb.png';
import Trofy from '../../../../images/Trofy.png';



const LearnMore = (props) => {


    // $(document).onReady(function(){
    //     $(".btn1").click(function(){
    //       $("p").slideUp();
    //     });
    //     $(".btn2").click(function(){
    //       $("p").slideDown();
    //     });
    //   });

    
    
    
    const ArraylistLearnMore = [
        {
            id:1,
            image1: Trofy,
            name:"Award-winning Support",
            text:"Get the help you want with 24/7  support—before, during, and after your trial.",
            image2: Action,
        },
        {
            id:2,
            image1: Bulb,
            name:"Carrier Opportunities",
            text:"Learn how you can have an impact by exploring opportunities at Skillfy.",
            image2: Action
        },
        {
            id:3,
            image1: Audio,
            name:"Press and Media",
            text:"Find press releases, executive team  bios, and more.",
            image2: Action
        }
    ]

    // "arraylistarg" is a variable name which can holds Array current values and "i" is index
    const LearnMoreList = ArraylistLearnMore.map( (arrayarg, i) => {
        return (
            <LearnMoreComponent key={i} id="i" image1={ArraylistLearnMore[i].image1} 
            name={ArraylistLearnMore[i].name}
            text={ArraylistLearnMore[i].text}
            image2={ArraylistLearnMore[i].image2}
            />
        )
    })


    return (
        <div className="learn-more-section">
            <div className="container col-md-12">
                <div className="row learn-more-section-title">
                    <div className="col-md-9 px-0">
                        <h4 className="cstm-h4">Learn more about skillfy</h4>
                    </div>
                     <div className="col-md-3 px-0 cstm-btn-div">
                        <button className="btn button btn-danger">Start now</button>
                    </div>
                </div>
                <div className="LearnMore-row2">
                    {LearnMoreList}
                </div>
            </div>
        </div>
    )
}

export default LearnMore;