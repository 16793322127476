import React , {useEffect, useState} from 'react';
import '../components/Home.css';
import Navbar from './Header/NavbarComponent/NavbarComponent';
import LearnNewSkill from './HomePageComponents/LearnNewSkill/LearnNewSkill';
import ChooseFavouriteCourses from './HomePageComponents/ChooseFavouriteCourses/ChooseFavouriteCourses';
import GetYourCourses from './HomePageComponents/GetYourCourses/GetYourCourses';
import WeWillHelp from './HomePageComponents/WeWillHelp/WeWillHelp';
import WhatOurStudents from './HomePageComponents/WhatOurStudents/WhatOurStudents';
import TrustedPartners from './HomePageComponents/TrustedPartners/TrustedPartners';
import CustomFooter from './CustomFooter/CustomFooter';
import axios from 'axios';
import Instructors from './HomePageComponents/Instructors/Instructors';
// import Navbar from './Header2/NavbarComponent2/NavbarComponent2';


const HomePage = () => {
    useEffect(() => {
        // getYourAllCourses();
        getCountry();
        getDegree();
        addCountry();
        updateCountry();
    }, []);
    const getYourAllCourses = () => {
        // GET request using axios with set headers
        const headers = {
            'Authorization': 'Bearer my-token',
        };
        axios.get('https://api.npms.io/v2/search?q=react', { headers })
            .then(response => console.log(response))
            .catch(error => console.error(`Error : ${error}`));
    }
    
    const getCountry = () => {
        // GET request using axios with set headers
        // const headers = {
        //     'Authorization': 'Bearer my-token',
        // };
        axios.get('http://zsktech-001-site1.ctempurl.com/api/country/get')
            .then(response => console.log(response))
            .catch(error => console.error(`Error : ${error}`));
    }

    const addCountry = () => {
            const country = {
                Name:"Country Test 2",
                Code:"Code Test 2"
            };
            axios.post('http://zsktech-001-site1.ctempurl.com/api/country/add', country)
                .then(response => console.log(response.data));
    }

    const updateCountry = () => {
            const country = {
                Id:2,
                Name:"Country Update Test 2",
                Code:"Code Update Test 2"
            }
            axios.post('http://zsktech-001-site1.ctempurl.com/api/country/add', country)
                .then(response => console.log(response.data));
    }

    const getDegree = () => {
        // GET request using axios with set headers
        // const headers = {
        //     'Authorization': 'Bearer my-token',
        // };
        axios.get('http://zsktech-001-site1.ctempurl.com/api/degree/get')
            .then(response => console.log(response))
            .catch(error => console.error(`Error : ${error}`));
    }
    return (
        <>
            <div className="Home-Container">
                <div className="Home-Navbar"><Navbar/></div>
                <LearnNewSkill className="Home-LearnNewSkills"/>
                <ChooseFavouriteCourses className="Home-ChooseFavouriteCourses"/>
                <GetYourCourses />
                <WeWillHelp />
                <Instructors />
                <WhatOurStudents />
                <TrustedPartners />
                <CustomFooter />
            </div>
        </>
    )
}

export default HomePage;