import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Player from 'video-react/lib/components/Player'
import './StdCuComponent.css'

export default function CuComponent (props) {


    return (
        <>
            <div className="my-2">
                <Row className="py-3 px-1 d-flex align-items-center">
                    <Col className="col-md-3">
                        <div className="StdCu-myplayer">
                            <Player
                                playsInline
                                poster="/assets/poster.png"
                                src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                                className="custom-video"
                            />
                        </div>
                    </Col>
                    <Col className="col-md-6">
                        <div><h5 className="StdCu-h5">{props.title}</h5></div>
                        <div className="d-flex align-items-center">
                            <img className="mr-1" src={props.image}></img>
                            <p className="StdCu-text">{props.duration}</p>
                        </div>
                    </Col>
                    <Col className="col-md-3">
                        <button className="btn StdCu-button">Try with Pro</button>
                    </Col>
                </Row>
            </div>
        </>
    )


}