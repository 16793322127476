import React from 'react'
import './InstituteAPI.css'



import ReadInstitute from './Components/readInstitute'

const InstituteAPI = () => {
    return (
        <>
            <div className="container cstm-one">
                <div>
                    <ReadInstitute/>
                </div>
            </div>
            
        </>
    )
}

export default InstituteAPI;
