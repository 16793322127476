import React from 'react'
import './RoleAPI.css'



import ReadRole from './Components/readRole'

const RoleAPI = () => {
    return (
        <>
            <div className="container cstm-one">
                <div>
                    <ReadRole/>
                </div>
            </div>
            
        </>
    )
}

export default RoleAPI;
