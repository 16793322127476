import React from 'react';
import './ItsTime.css';

import Jello from 'react-reveal/Jello';

const ItsTime = () => {
    return (
        <div className="container-fluid Contactus-itsTime mb-5">
            <div className="container ">
                <div className="row WeWillHelp-container-row-2">
                    <div className="WeWillHelp-container-2 p-3">
                    <Jello>
                        <h1 className="cstm-h1">It's time to start investing in yourself in learning</h1>
                        <p className="cstm-p">Match your goals to our programs, explore your options, and map out your
                        path to success. I plan to use skillfy for a long time!.</p>
                        <button className="btn btn-danger px-4">Get Started</button>
                    </Jello>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ItsTime;