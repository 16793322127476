import React from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import Popper from 'popper.js';
import '../WhatOurStudentsRow2Component/WhatOurStudentsRow2Component.min.css';

const WhatOurStudentsRow2Component = (props) => {
    return (
        
        <div className="px-3 cstm-padding-WOS">
            <div className="cstm-WhatOurStudents-Row2Component col-md-11  p-3">
            <div className="cstm-WhatOurStudents-Row2Component-row-1 p-2">
                <img src={props.src}></img>
                <h5 className="mb-0">Rebecca Moore</h5>
            </div>
            <div className="cstm-WhatOurStudents-Row2Component-row-2 p-2">
                <p className="text-left">Skillfy is a life saver. I don’t have the time money for a 
                   college education. My goal is to become a freelance web designer and thanks to 
                   Skillfy.</p>
            </div>
            </div>
        </div>
    )
}

export default WhatOurStudentsRow2Component;