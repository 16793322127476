import React from 'react'
import { Col, Row } from 'react-bootstrap'
import CProfileAbout from './TPScomponents/CProfileAbout/CProfileAbout'
import CProfileinfo from './TPScomponents/CProfileinfo/CProfileinfo'
import CTitle from './TPScomponents/CTitle/CTitle'
import './TPStudent.css'

export default function TPStudent () {

    return (

        <>
            <div className="container TPScontainer position-relative">
                <Row className="col-md-12 position-absolute cstm-col0" style={{top:"-175px"}}>
                    <CTitle/>
                </Row>
                <Row className="pt-5 cstm-row2">
                    <Col className="col-md-4 col-sm-12 col-12 px-2 position-relative cstm-col1">
                        <CProfileinfo/>
                    </Col>
                    <Col className="col-md-8 col-sm-12 col-12 px-3 cstm-col2">
                        <CProfileAbout/>
                    </Col>
                </Row>
            </div>
        </>

    )


}