import {React , Component} from 'react';
import './TeacherProfile.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import TVHeaderArea from '../TVHeaderArea/TVHeaderArea';
import Navbar from '../../../components/Header/NavbarComponent/NavbarComponent';
import TeacherAbout from './TeacherAbout/TeacherAbout';
import TeacherInfo from './TeacherInfo/TeacherInfo';
import TeacherAbout2 from './TeacherAbout2/TeacherAbout2';
import TeacherInfo2 from './TeacherInfo2/TeacherInfo2';
import Footer from '../../../components/CustomFooter/CustomFooter';
import { Fragment } from 'react';

class TeacherProfile extends Component {

    componentDidMount = () => {}

    render () {
        return (
            // <div className="TP-container">
            //     <Navbar/>
            //     <TVHeaderArea />
                <div className="tp-cstm container px-0 mb-5">
                    <div className="tp-cstmTI col-md-4 pr-1"><TeacherInfo/></div>
                    {/* <div ></div> */}
                    <div className="tp-cstmTA col-md-8 pl-1 pr-0"><TeacherAbout/></div>
                </div>
            //    <Footer/>
            //</div>
        )
    }
}

export default TeacherProfile;

