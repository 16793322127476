import React from 'react'
import './Piechart.css'
import { Doughnut } from 'react-chartjs-2'

const Piechart = () => {


    const data1 = {
        labels: [
          'Active Students'
          
        ],
        datasets: [{
          label: 'Active Students',
          data: [80 , 20],
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(246, 238, 255)',
            // 'rgb(255, 205, 86)'
          ],
          hoverOffset: 4
        }]
    };

    const options = {
        display: true,
        text: "80%",
    }

    const data2 = {
        labels: [
          'Inactive Students'
          
        ],
        datasets: [{
          label: 'Inactive Students',
          data: [20 , 80],
          backgroundColor: [
            'rgb(43, 192, 85)',
            'rgb(246, 238, 255)',
            // 'rgb(255, 205, 86)'
          ],
          hoverOffset: 4
        }]
    };

    const data3 = {
        labels: [
          'Teachers'
          
        ],
        datasets: [{
          label: 'teachers',
          data: [60 , 40],
          backgroundColor: [
            'rgb(9, 60, 189)',
            'rgb(246, 238, 255)',
            // 'rgb(255, 205, 86)'
          ],
          hoverOffset: 4
        }]
    };

    //   'rgb(43, 192, 85)'
    return (
        <>
            <Doughnut data={data1} options={options} />
            <Doughnut data={data2} />
            <Doughnut data={data3} />
        </>
    )
}

export default Piechart
