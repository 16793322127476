import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Quizes from './Components/Quizes/Quizes'
import SubjQuiz from './Components/SubjQuiz/SubjQuiz'
import QuizResult from './Components/QuizResult/QuizResult'
import './StdQuiz.css'

export default function StdQuiz () {


    return (


        <>
            {/* <SubjQuiz/> */}
            
             <div className="container StdQuiz-container px-0 bg-white">
                <div className="Std-Quiztab">
                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item col-md-6 px-0">
                            <a className="text-center nav-link active " data-toggle="tab" href="#stdquizes" role="tab">Quizes</a>
                        </li>
                        <li className="nav-item col-md-6 px-0">
                            <a className="text-center nav-link" data-toggle="tab" href="#stdquizresult" role="tab">Quiz Result</a>
                        </li>
                    </ul>
                    {/* <!-- Tab panes --> */}
                    <div className="tab-content mt-5">
                        <div className="tab-pane active" id="stdquizes" role="tabpanel">
                            <Quizes />
                            
                            

                        </div>
                        <div className="tab-pane" id="stdquizresult" role="tabpanel">
                            <QuizResult />
                        </div>
                    </div>
                </div>
            </div> 


        </>

    )


}

