import React from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import './StdInfo.css';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import EditIcon from '../../../../assets/images/editicon.png';
import Facebook from '../../../../assets/images/facebook1.png'
import Twitter from '../../../../assets/images/twitter.png'
import Linkedin from '../../../../assets/images/linkedin1.png'

export default function StdInfo() {



    return (
        
            <div className="SI-container bg-white px-3 py-3">
                <Col className="SI-C1">
                    <Row className="SI-C1-R1">
                        <h4 className="flex-grow-1">Std Info</h4>
                        <Link to="/StdInfo2"><img src={EditIcon} ></img></Link>
                    </Row>
                    <Row className="SI-C1-R2">
                        <Row>
                            <Col><h5 >Skill Set :</h5></Col>
                            <Col><p >I love sharing with my knowledge and experience this is why I’m working 
                            on The Awwwesomes - an initiative….</p></Col>
                        </Row>
                        <Row>
                            <Col><h5 >Exp. :</h5></Col>
                            <Col><p>Themadbrains  •  2018 - Present
                                Dribbble  •  2018 - Present
                                Behance  •  2016 - Present
                                Uplabs  •  2014 - Present
                            </p></Col>
                        </Row>
                        <Row>
                            <Col><h5>Education :</h5></Col>
                            <Col><p>Kanpur university  •  2002 - 2004</p></Col>                            
                        </Row>
                        <Row>
                            <Col><h5>language : </h5></Col>
                            <Col><p>English, Urdu</p></Col>
                        </Row>
                        <Row>
                            <Col><h5>Other :</h5></Col>
                            <Col><p>Coach and mentor of General science/science and technology/ Ecology 
                            and Environment.<br /> I have 10 years teaching experience. 
                            Mentored more than 5000 student since 2010</p></Col>                            
                        </Row>
                    </Row>
                    <Row className="SI-C1-R3 px-3">
                        <h5>Social Links</h5>
                        <Row>
                            <Link to='/#'><img src={Facebook}></img></Link>
                            <Link to='/#'><img src={Linkedin}></img></Link>
                            <Link to='/#'><img src={Twitter}></img></Link>
                        </Row>
                    </Row>
                </Col>
            </div>
        
    )

}