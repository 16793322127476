import React from 'react'
import { Row } from 'react-bootstrap'
import './SPProfileteacher.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import Panel from './components/Panel/Panel'
import Summary from './components/Summary/Summary'

const SPProfileteacher = () => {
    return (
        <>
            <div className="container SPProfileteacher col-md-12 p-5">
                <Row className="d-flex justify-content-between pb-4">
                    <div className="flex-grow-1">
                        <button className="btn btnbackarrow"><i class="fas fa-arrow-left"></i></button>
                    </div>
                    <div className="mx-3"><button className="btn button"><i class="fas fa-ban pr-2"></i>Block Profile</button></div>
                    <div><button className="btn button"><i class="fas fa-trash pr-2"></i>Delete Profile</button></div>
                </Row>
                <Row className="d-flex">
                    <div className="col-md-4 pl-0"><Panel/></div>
                    <div className="col-md-8 pr-0">
                        {/* <!-- Tab panes --> */}
                        <div className="tab-content">
                            <div className="tab-pane active" id="summary" role="tabpanel">
                                <Summary/>
                            </div>
                        </div>
                    </div>
                    
                    
                </Row>
            </div>
            
        </>
    )
}

export default SPProfileteacher
