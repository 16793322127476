import { Inject } from '@syncfusion/ej2-react-base'
import React from 'react'
import { Col } from 'react-bootstrap'
import MyCalander from './component/mycalander/MyCalander'
import Schedule from './component/Schedule/Schedule'
import './MySchedule.css'

export default function MySchedule () {


    return (
        <>
            
            <div className="cstm-mySchedule-padding">
                <div className="container shd-container px-0 bg-white ">
                    <div className="d-flex px-4 cstm-div ">
                        <Col>
                            <MyCalander />
                        </Col>
                        <Col>
                            <Schedule />
                        </Col>
                    </div>
                </div>
            </div>

        </>
    )

}