import {React , Component , useState} from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import './TeacherAbout.css';
import { Col, Row, Form, FormLabel, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import EditIcon from '../../../../assets/images/editicon.png';

export default function TeacherAbout() {


    const [modalState, setModalState] = useState(false);

    const manageState = () => {
        setModalState(!modalState)
    }

    const [data , setData] = useState({
        datainfo: "",
    })

   

    

    const inputEvents = (e) => {
        console.log(e.target.value)
        console.log(e.target.name)
        

        const {name , value} = e.target;

        setData((preValue) => {

            return{
                ...preValue,
                [name]: value,
            }
            
        });

    }

    const onSubmit = (e) => {
        e.preventDefault()
        console.log(e)

        // setList([...list,data]);
    }




    return (
        <div className="TAbout-containerfluid container-fluid bg-white p-3">
            <div className="TA-container container">
                <Row className="TA-R1">
                    <h4 className="flex-grow-1">About Paul Walker</h4>
                    <button className="btn" onClick={() => manageState()}><img src={EditIcon}></img></button>
                    {/* <Link to="/TeacherAbout2"><img src={EditIcon}></img></Link> */}
                </Row>
                <Row className="TA-R2">
                    <p style={{lineBreak: 'anywhere'}}>{data.datainfo}</p>
                    {/* <p>
                    Effortless comfortable full leather lining eye-catching unique detail to the toe low 
                   ‘cut away’ sides clean and sleek. Polished finish elegant court shoe work duty stretchy
                   mid kitten heel this ladylike design slingback strap mid kitten heel this lady like 
                    design. Sharing is who I am, and teaching is where I am at my best, because I’ve been on 
                    both sides of that equation, and getting to deliver useful training is my meaningful 
                    way to  be a part of the creative community.am at my best. Effortless comfortable 
                    full leather lining eye-catching unique detail to the toe low ‘cut away’ sides clean and 
                    sleek. Polished finish elegant court shoe work duty stretchy mid kitten heel this 
                    ladylike design slingback strap mid kitten heel this lady like design. Sharing is who 
                    I am, and teaching is where I am at my best, because I’ve been on both sides of that 
                    equation, and getting to deliver useful training is my meaningful way to  be a part of 
                    the creative community.am at my best,</p> */}
                </Row>
            </div>


            <Modal show={modalState} onHide={() => manageState()}>
                <Modal.Body>
                    <Form onSubmit={onSubmit}>
                        <Row>
                            <h4 className="flex-grow-1">Data Info</h4>
                                <button className="btn my-button" type="submit"
                                // onClick={() => addInfo}
                                >
                                
                                Save
                                </button>
                        </Row>
                        <Row>
                            <div className="form-group d-flex">
                                
                                    <FormLabel htmlFor="datainfo">Data Info </FormLabel>
                                    <input type="text" className="form-control" 
                                    name="datainfo" onChange={inputEvents} value={data.datainfo}
                                    placeholder="" 
                                    id="datainfo" />
                            </div>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

        </div>
    )

}