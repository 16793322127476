import React from 'react'
import './quizTypeAPI.css'



import ReadQuizType from './Components/readQuizType'

const QuizTypeAPI = () => {
    return (
        <>
            <div className="container cstm-one">
                <div>
                    <ReadQuizType/>
                </div>
            </div>
            
        </>
    )
}

export default QuizTypeAPI;
