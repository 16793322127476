import React from 'react';
import './ContactUs.css';
import Navbar from '../Header/NavbarComponent/NavbarComponent';
import GotAQueestion from './GotAQueestion/GotAQueestion';
import Cards from './Cards/Cards';
import GetInTouch from './GetInTouch/GetInTouch';
import ExpandingLearning from './ExpandingLearning/ExpandingLearning';
import ItsTime from './ItsTime/ItsTime';
import BecomeOrConstruct from '../AboutUs/Components/BecomeOrConstruct/BecomeOrConstruct';
import CustomFooter from '../CustomFooter/CustomFooter';


const ContactUs = () => {
    return (
        <div className="main_wrapper">
            <Navbar />
            <GotAQueestion />
            <Cards />
            <GetInTouch />
            <ExpandingLearning />
            <ItsTime />
            <BecomeOrConstruct />
            <CustomFooter />
        </div>
    )
}

export default ContactUs;