import React, {useState} from 'react'
import './InstructorsAll.min.css'

import InstructorsComponent from '../../../Component/InstructorsComponent'

import ReactPaginate from 'react-paginate'


import Screenshot1 from '../../../../../../images/Screenshot1.png'

import VAInstructorlist from './VAInstructorlist'


const InstructorsAll = () => {

     const [displayInstructors, setItems] = useState(VAInstructorlist);
     const filterItems = (categItems) => {
        const updatedItems = VAInstructorlist.filter((curElem) => {
            return curElem.category1 == categItems || curElem.category2 == categItems;
            
        });
        console.log(categItems);
         setItems(updatedItems);
     }


    
    const [pageNumber, setPageNumber] = useState(0)

    const instructorsPerPage = 16
    const pageVisited = pageNumber * instructorsPerPage

    const items = displayInstructors
    .slice(pageVisited, pageVisited + instructorsPerPage)
    .map((arrayarg, i) => {
        return (
            <InstructorsComponent key={i} 
            id="i" 
            src={VAInstructorlist[i].src}
            />

        )
    })



    const pageCount = Math.ceil(VAInstructorlist.length / instructorsPerPage);
    const changePage = ({selected}) => {
        setPageNumber(selected)
    };

        return (
            <>
                <div className="container VAInstructors">
                    <div className="cstm-afterDiv px-3">
                        <img src={Screenshot1}></img>
                    </div>
                    <div className="row r1 px-3 pt-5">
                        <div className="col-md-3 col-sm-12 col-12" >
                            <select class="form-select cstm-select" aria-label="Default select example"
                            onChange = {(e) => {
                                const selectOption = e.target.value;
                                const updatedOptions = VAInstructorlist.filter((curElem) => {
                                    return curElem.category3 == selectOption;
            
                                });
                                setItems(updatedOptions);

                            }} >
                                <option value="Popular">Popular</option>
                                <option value="Regular">Regular</option>
                            </select>
                        </div>
                        <div className="col-md-9 col-sm-12 col-12 d-flex justify-content-end" >
                            <button className="btn button" onClick={() => filterItems('All')}>All</button>
                            <button className="btn button"onClick={() => filterItems('Design')}>Design</button>
                            <button className="btn button"onClick={() => filterItems('Business')}>Business</button>
                            <button className="btn button"onClick={() => filterItems('Marketing')}>Marketing</button>
                            <button className="btn button"onClick={() => filterItems('Photography')}>Photography</button>
                            <button className="btn button"onClick={() => filterItems('Music')}>Music</button>
                        </div>
                    </div>
                    <div className="display-Instructors d-flex flex-wrap">
                        {items}
                    </div>
                    {<ReactPaginate
                        previousLabel={<i class="fas fa-chevron-left"></i>}
                        nextLabel={<i class="fas fa-chevron-right"></i>}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"paginationButtons"}
                        previousLinkClassName={"previousButton"}
                        nextLinkClassName={"nextButton"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                    />}
                </div>
                
            </>
        )

    
}

export default InstructorsAll