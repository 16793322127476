import React from 'react';
import '../Ibelieve/Ibelieve.css';
import '../../../../../node_modules/video-react/dist/video-react.css';
import { Player } from 'video-react';

import Slide from 'react-reveal/Slide';

const IBelieve = () => {
    return (
        <div className="ibelieve-section">
            <div className="container ibelieve-section-inner">
            <Slide left>
               <div className="row">
                     <div className="col-md-6">
                        <div className="ibelieve-section-inner-content">
                            <p className="cstm-p">I believe in lifelong learning and Skillfy  is a great place 
                                to learn from experts. I’ve learned a lot and recommend it to all my friends.
                            </p>
                        </div>
                        <div className="IBelieve-author">
                            <p>Riaz Surti | Hearthy Foods</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <Player
                            playsInline
                            poster="/assets/poster.png"
                            src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                            className="custom-video"
                        />
                    </div>
               </div>
               </Slide>
            </div>
        </div>
    )
}

export default IBelieve;