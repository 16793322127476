import React from 'react'
import './FavCourseSlider.min.css'

import { Link } from 'react-router-dom';

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import bookcatg from "../../../../../../images/bookcatg.png"
import businesscatg from "../../../../../../images/businesscatg.png"
import ITcatg from "../../../../../../images/ITcatg.png"
import marketingcatg from "../../../../../../images/marketingcatg.png"
import photographycatg from "../../../../../../images/photographycatg.png"
import developmentcatg from "../../../../../../images/developmentcatg.png"

import ChooseFavouriteRow2Component from '../../../../ChooseFavouriteCourses/ChooseFavouriteRow2Component/ChooseFavouriteCoursesRow2Component';

const FavCourseSlider = () => {


    const settings = {
        arrows:true,
        dots:false,
        // centerMode: true,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        infinite:true,
        speed:500,
        slidesToShow: 6,
        slidesToScroll: 1,
        cssEase: "linear",
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    cssEase: "linear",
                    focusOnSelect: true,
                    autoplay: true,
                    pauseOnHover: true,
                    dots: false,
                    autoplaySpeed: 5000,
                    speed:500,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    dots:false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    cssEase: "linear",
                    focusOnSelect: true,
                    autoplay: true,
                    pauseOnHover: true,
                    autoplaySpeed: 5000,
                    speed:500,
                }
            },
            {
                breakpoint: 375,
                settings: {
                    dots:false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    cssEase: "linear",
                    focusOnSelect: true,
                    autoplay: true,
                    pauseOnHover: true,
                    autoplaySpeed: 5000,
                    speed:500,
                }
            }
        ]
    }


    return (
        <>
            <div className="container FCSlider">
                <div className="row r1 pb-4 ">
                    <Slider {...settings} className="col-md-12 col-sm-12 col-9">
                        <Link to="/CourseStudent">
                            <ChooseFavouriteRow2Component id="1" name="Design" src={bookcatg}/>
                        </Link>
                        <Link to="/CourseStudent">
                            <ChooseFavouriteRow2Component id="2" name="Development" src={businesscatg}/>
                        </Link>
                        <Link to="/CourseStudent">
                            <ChooseFavouriteRow2Component id="3" name="IT & Soft" src={ITcatg}/>
                        </Link>
                        <Link to="/CourseStudent">
                            <ChooseFavouriteRow2Component id="4" name="Bussiness" src={marketingcatg}/>
                        </Link>
                        <Link to="/CourseStudent">
                            <ChooseFavouriteRow2Component id="5" name="Marketing" src={photographycatg}/>
                        </Link>
                        <Link to="/CourseStudent">
                            <ChooseFavouriteRow2Component id="6" name="Photography" src={developmentcatg}/>
                        </Link>
                        <Link to="/CourseStudent">
                            <ChooseFavouriteRow2Component id="7" name="Design" src={bookcatg}/>
                        </Link>
                        <Link to="/CourseStudent">
                            <ChooseFavouriteRow2Component id="8" name="Development" src={businesscatg}/>
                        </Link>
                        <Link to="/CourseStudent">
                            <ChooseFavouriteRow2Component id="9" name="IT & Soft" src={ITcatg}/>
                        </Link>
                        <Link to="/CourseStudent">
                            <ChooseFavouriteRow2Component id="10" name="Bussiness" src={marketingcatg}/>
                        </Link>
                        <Link to="/CourseStudent">
                            <ChooseFavouriteRow2Component id="11" name="Marketing" src={photographycatg}/>
                        </Link>
                        <Link to="/CourseStudent">
                            <ChooseFavouriteRow2Component id="12" name="Photography" src={developmentcatg}/>
                        </Link>
                        
                        
                    </Slider>
                </div>
            </div>
            
        </>
    )
}

export default FavCourseSlider
