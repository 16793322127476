import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import CHistory from './CHistory/CHistory'
import './History.css'

export default function History () {

    const ArrayHistory = [
        {
            id:1,
            course: "Course 1",
            transid: "Halal14255896",
            datetime: "6 May 2018, 12.56PM",
            cash: "$20",
        },
        {
            id:2,
            course: "Course 2",
            transid: "Halal14255896",
            datetime: "6 May 2018, 12.56PM",
            cash: "$20",
        },
        {
            id:3,
            course: "Course 3",
            transid: "Halal14255896",
            datetime: "6 May 2018, 12.56PM",
            cash: "$20",
        },
        {
            id:4,
            course: "Course 4",
            transid: "Halal14255896",
            datetime: "6 May 2018, 12.56PM",
            cash: "$20",
        },
        {
            id:5,
            course: "Course 5",
            transid: "Halal14255896",
            datetime: "6 May 2018, 12.56PM",
            cash: "$20",
        },
        {
            id:6,
            course: "Course 6",
            transid: "Halal14255896",
            datetime: "6 May 2018, 12.56PM",
            cash: "$20",
        },
    ]

     // "arraylistarg" is a variable name which can holds Array current values and "i" is index
     const CHistoryList = ArrayHistory.map( (arg, i) => {
        return (
            <CHistory key={i} 
            id="i" 
            course={ArrayHistory[i].course}
            transid={ArrayHistory[i].transid}
            datetime={ArrayHistory[i].datetime} 
            cash={ArrayHistory[i].cash} 
            />
        )
    })


    return (

        <>
            <div className="Historycontainer col-md-12">
                <Row className="p-3 mb-3" style={{background:"#005D8A"}}>
                    <div>
                        <h5 className="heading">History</h5>
                    </div>
                </Row>
                <div style={{maxHeight:"54vh", height:"100%"}} class="overflow-auto">
                    {CHistoryList}
                    
                </div>
            </div>
        </>

    )

}