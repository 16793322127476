import React from "react"
import { Row } from "react-bootstrap"
import './Course.css'
import Courseimage from '../../../../assets/images/courseimage.png';
import Star from '../../../../images/Star.png';
import Layer8 from '../../../../images/Layer8.png';
import Layer9 from '../../../../images/Layer9.png';
import Rectangle22 from '../../../../assets/images/Rectangle22.png';
import threedots from '../../../../assets/images/threedots.png';
import CourseComponent from "./CourseComponent/CourseComponent";

export default function Course () {

    // ----------courses---------------------
    const ArraylistCourse = [
        {
            id:1,
            image0: threedots,
            image1: Courseimage,
            image2: Star,
            image3: Layer8,
            image4: Layer9,
            star:"4.5(120)",
            watch:"28,500",
            play:"36 Lesion",
            heading:"Statistic Data Science and Bussiness Analysis",
            image5: Rectangle22,
            name:"Nicole Brown",
            price:"$99.99",
            dprice:"$49.65",
            
        },
        {
            id:2,
            image0: threedots,
            image1: Courseimage,
            image2: Star,
            image3: Layer8,
            image4: Layer9,
            star:"4.5(120)",
            watch:"28,500",
            play:"36 Lesion",
            heading:"Statistic Data Science and Bussiness Analysis",
            image5: Rectangle22,
            name:"Nicole Brown",
            price:"$99.99",
            dprice:"$49.65",
        },
        {
            id:3,
            image0: threedots,
            image1: Courseimage,
            image2: Star,
            image3: Layer8,
            image4: Layer9,
            star:"4.5(120)",
            watch:"28,500",
            play:"36 Lesion",
            heading:"Statistic Data Science and Bussiness Analysis",
            image5: Rectangle22,
            name:"Nicole Brown",
            price:"$99.99",
            dprice:"$49.65",
        },
        
    ]

    // "arraylistarg" is a variable name which can holds Array current values and "i" is index
    const CourseList = ArraylistCourse.map( (arrayarg, i) => {
        return (
            <CourseComponent key={i} 
            id="i" 
            image0={ArraylistCourse[i].image0}
            image1={ArraylistCourse[i].image1}
            image2={ArraylistCourse[i].image2} 
            image3={ArraylistCourse[i].image3} 
            image4={ArraylistCourse[i].image4} 
            star={ArraylistCourse[i].star}
            watch={ArraylistCourse[i].watch}
            play={ArraylistCourse[i].play}
            heading={ArraylistCourse[i].heading}
            image5={ArraylistCourse[i].image5}
            name={ArraylistCourse[i].name}
            price={ArraylistCourse[i].price}
            dprice={ArraylistCourse[i].dprice}
            />
        )
    })



    return (

        <>

                
                     {/* -------------courses part---------------------- */}
                    <div className="py-3">
                        <div className="row Course-R2 d-flex flex-wrap justify-content-between align-content-between">
                        
                            {CourseList}
                        
                            
                        </div>
                    </div>

        </>

    )

}