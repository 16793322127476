import React from 'react';
import './Cards.css';
import Address from '../../../images/address.png';
import Phone from '../../../images/phone.png';
import Email from '../../../images/email.png';

import Tada from 'react-reveal/Tada';

const Cards = (props) => {


    return (
        <div className="container-fluid ContactUsCards-containerfluid">
            <div className="container">
                {/* card1 */}
                <div className="row">
                    <Tada>
                     <div className="col-md-4 cstm-row-col">
                        <div className="card card_cstm">
                            <div className="card-body d-flex align-items-center">
                                <div className="card_icon card_icons_bg_phone">
                                    <img src={Phone}></img>
                                </div>
                                <div className="card_descr">
                                    <p className="card_heading_cstm">Help Line</p>
                                    <p className="card_desc_cstm">666 888 000</p>
                                </div>
                            </div>
                        </div>
                     </div>
                     </Tada>
                     <Tada>
                     <div className="col-md-4 cstm-row-col">

                        <div className="card card_cstm">
                            <div className="card-body d-flex align-items-center">
                                <div className="card_icon card_icons_bg_email">
                                    <img src={Email}></img>
                                </div>
                                <div className="card_descr">
                                    <p className="card_heading_cstm">Email</p>
                                    <p className="card_desc_cstm">needhelp@skans.com</p>
                                </div>
                            </div>
                        </div>
                        
                     </div>
                     </Tada>
                     <Tada>
                     <div className="col-md-4 cstm-row-col">
                        <div className="card card_cstm">
                            <div className="card-body d-flex align-items-center">
                                <div className="card_icon card_icons_bg_address">
                                    <img src={Address}></img>
                                </div>
                                <div className="card_descr">
                                    <p className="card_heading_cstm">Address</p>
                                    <p className="card_desc_cstm">60 Road Broklyn New York</p>
                                </div>
                            </div>
                        </div>

                        
                     </div>
                     </Tada>
                </div>
                    
            </div>
        </div>
    )
}

export default Cards;