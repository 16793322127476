import React from 'react'
// import './one.css'


import Create from './components/create'
import Read from './components/read'

const FeeTypeExtra = () => {
    return (
        <>
            <div className="container cstm-one">
                <h2>React CRUD</h2>
                {/* <br/>
                <br/>
                <div>
                    <Create/>
                </div> */}
                <br/>
                <br/>
                <div>
                    <Read/>
                </div>
            </div>
            
        </>
    )
}

export default FeeTypeExtra;
