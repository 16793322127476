import React from 'react';
import './Banner.css';

const Banner = () => {
    return (
        <div className="container-fluid T_C-banner-containerfluid p-5">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="Banner-col1 col-md-6">
                        <h3>Terms And Conditions</h3>
                    </div>
                    <div className="Banner-col2 col-md-6 text-right">
                        <h5>Home / Terms and Conditions</h5>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default Banner;