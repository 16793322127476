import React, {useState} from 'react'
import leftImg from '../assets/images/iMAGE.png'
import linkedin from '../assets/images/linkedin.png'
import facebook from '../assets/images/facebook.png'
import gOOGLE from '../assets/images/gOOGLE.png'
import Login from './Login'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  import SecondStep from './SecondStep'
  import PersonalDetail from './PersonalDetail'
export default function Signup() {
  
    return (
        
        <>
            <div className="d-lg-flex half">
                <div className="bg">
                    <div className="inner_content">
                        <div className="logo_area">
                            <h1>Logo</h1>
                        </div>
                        <div className="img_area">
                            <img src={leftImg} className="img-fluid" />
                        </div>
                        <div className="branding_content">
                            <h1 className="branding_content_heading">Turn your ambition into a success story </h1>
                            <p className="branding_content_text">Choose from over 100,000 online video.</p>
                        </div>
                    </div>

                </div>
                
                    <div className="contents">
                    <div className="container">
                    
                        <div className="text-end w-75 my-4 dont_have_account"><span className="mx-3">Don’t have an account?</span><a href="./login.html" type="submit" className="btn btn-success btn-sm">Log in</a></div>
                        <div className="row align-items-center content_container">
                            <div className="content_container_inner">
                                <div className="mb-4">
                                    <h2>Create your free account</h2>
                                    <p className="login_descr">See how the world’s best user experiences are created</p>
                                </div>
                                <form action="#" method="post" className="form_style">
                                    <div className="social-login">
                                    <a href="#" className="social_btn">
                                                <img src={linkedin} /><span className="">Sign with Linkedin</span>
                                            </a>
                                            <a href="#" className="social_btn">
                                                <img src={facebook} /><span className="">Sign with Facebook</span>
                                            </a>
                                            <a href="#" className="social_btn">
                                                <img src={gOOGLE} /><span className="">Sign with Google</span>

                                            </a>
                                    </div>
                                    <div className="separated_line">
                                        <p><span>OR</span></p>
                                    </div>

                                    <div className="form-group first">
                                        <label for="fullname">Full name</label>
                                        <input type="text" className="form-control" id="fullname" placeholder="Your name" />
                                    </div>

                                    <div className="form-group first">
                                        <label for="emailAddress">Email address</label>
                                        <input type="email" className="form-control" id="emailAddress" placeholder="Exampl@email.com"/>
                                    </div>
                                    <div className="form-group last mb-3">
                                        <label for="password">Password</label>
                                        <input type="password" className="form-control" id="password" placeholder="Enter password"/>
                                    </div>




                                    <Link type="button" className="btn btn-block btn-success w-100 mb-1" to="/SecondStep">Next Step </Link>
                                    
                                    <div className="dont_have_account">
                                        <span>By signing up, you agree to our communications and usage
                                            terms Already have an account? <Link to="/Login">Sign in</Link></span>
                                    </div>


                                </form>
                            </div>
                        </div>
                      
                   
                 
                    </div>
                </div>
                
                

            </div>
            
        </>
    )
}
