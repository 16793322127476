import React from 'react'
import MUIDataTable from "mui-datatables";

const dataTablesexmp = () => {


    const columns = [
        {
         name: "name",
         label: "Name",
         options: {
          filter: true,
          sort: true,
         }
        },
        {
         name: "company",
         label: "Company",
         options: {
          filter: true,
          sort: false,
         }
        },
        {
         name: "city",
         label: "City",
         options: {
          filter: true,
          sort: false,
         }
        },
        {
            name: "status",
            label: "Status",
            options: {
              filter: true,
              filterType: 'dropdown',
              responsive: 'scroll',
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <select class="form-select" aria-label="Default select example">
                    <option selected>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                    </select>
                    )
                }
            }
          },
          {
            name: "Delete",
            options: {
              filter: true,
              sort: false,
              empty: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <button onClick={() => {
                    const { data } = this.state;
                    data.shift();
                    this.setState({ data });
                  }}>
                    Delete
                  </button>
                );
              }
            }
          },
       ];
       
       const data = [
        { name: "Joe James", company: "Test Corp", city: "Yonkers", state: "NY" },
        { name: "John Walsh", company: "Test Corp", city: "Hartford", state: "CT" },
        { name: "Bob Herm", company: "Test Corp", city: "Tampa", state: "FL" },
        { name: "James Houston", company: "Test Corp", city: "Dallas", state: "TX" },
       ];
       
       const options = {
         filterType: 'checkbox',
       };


    return (
        <>
            <MUIDataTable
            title={"Employee List"}
            data={data}
            columns={columns}
            options={options}
            />
        </>
    )
}

export default dataTablesexmp
