import React from 'react'
import './LineChart.css'

import { Line } from 'react-chartjs-2'

const LineChart = () => {


    const data= (canvas) => {

        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(9,189,60,0.2);
        // createLinearGradient(9,189,60,0.2);

        return {
            labels: ['week 01' , 'week 02' , 'week 03' , 'week 04' , 'week 05' , 'week 06' , 
        'week 07' , 'week 08' , 'week 09' , 'week 10' , 'week 11' , ],

        datasets: [
            {
                label: '# of expense',
                data: [60,80,20,20,40,50,30,20,30,10,20],
                borderColor: ['rgba(9,189,60,0.2)'],
                background: gradient,
                pointBackgroungColor: ['rgba(9,189,60,0.2)'],
                pointBorderColor: ['rgba(9,189,60,0.2)'],
            },
            {
                label: '# of income',
                data: [17,17,30,50,30,20,50,40,60,70,40],
                borderColor: ['rgba(255,170,42,0.2)'],
                background: ['rgba(255,170,42,0.2)'],
                pointBackgroungColor: ['rgba(255,170,42,0.2)'],
                pointBorderColor: ['rgba(255,170,42,0.2)'],
                
            },
        ],
        }
        
    }
    
    const options={
        title: {
            display: true,
            text: 'Balance Analytics',
        },
        maintainAspectRatio: false,
        scales: {
            yAxes: [
                {
                    ticks: {
                        min: 0,
                        max: 60,
                        stepSize: 15,
                    },
                },
            ],
        },
    }


    return <Line data={data} options={options}/>
}

export default LineChart
