import React from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import Popper from 'popper.js';
import '../TrustedPartners/TrustedPartners.min.css';

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import client1 from '../../../images/client1.png';
import client2 from '../../../images/client2.png';
import client3 from '../../../images/client3.png';
import client4 from '../../../images/client4.png';
import client5 from '../../../images/client5.png';
import client6 from '../../../images/client6.png';
import client7 from '../../../images/client7.png';
import client8 from '../../../images/client8.png';

// import Jump from 'react-reveal/Jump';



const TrustedPartners = () => {

    const settings = {
        
        arrows:false,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        infinite:true,
        speed:500,
        slidesToShow:4,
        slidesToScroll:1,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 425,
                settings: {
                    autoplay: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    speed:500,
                    autoplaySpeed: 5000,
                    cssEase: "linear",
                    focusOnSelect: true,
                    arrows:false,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    autoplay: true,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    speed:500,
                    autoplaySpeed: 5000,
                    cssEase: "linear",
                    focusOnSelect: true,
                    arrows:false,
                }
            }
        ]
    }


    return (

        // <Jump>

        <div className="container-fluid TrustedPartners-containerfluid-1 d-flex ">
            <div className="container  TrustedPartners-container-1">
                <div className="row TrustedPartners-row-1  p-4">
                    <h4 className="flex-grow-1 cstm-h4">Trusted by our awsome partners</h4>
                </div>
                <div className="row TrustedPartners-row-2 row-col-sm-2 row-col-md-2 carousel cstm-carousel1">
                    <Slider {...settings}>
                        
                        <img src={client1}></img>
                        <img src={client2}></img>
                        <img src={client3}></img>
                        <img src={client4}></img>
                        <img src={client5}></img>
                        <img src={client6}></img>
                        <img src={client7}></img>
                        <img src={client8}></img>
                        
                    </Slider>
                    
                </div>
            </div>
        </div>

        // </Jump>
    )
}

export default TrustedPartners;