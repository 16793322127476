import React from 'react'
import './Cards.css'
import { Row, Col } from 'react-bootstrap'

const Cards = (props) => {
    return (
        <>
            <div className="col-md-3 py-3 px-2">
                <div className="card financeCard">
                    <div className="card-body px-3 pt-3 pb-0">
                        <Row>
                            <Col className="col-4">
                                <div>
                                    <img src={props.image1}></img>
                                </div>
                            </Col>
                            <Col>
                                <div><h5 className="number">{props.number}</h5></div>
                                <div><p className="text">{props.text}</p></div>
                            </Col>
                        </Row>
                        <Row>
                            <div>
                                <img className="image2" src={props.image2}></img>
                            </div>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cards
