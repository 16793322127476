import Courseimage from '../../../../../assets/images/courseimage.png';
import Star from '../../../../../images/Star.png';
import Layer8 from '../../../../../images/Layer8.png';
import Layer9 from '../../../../../images/Layer9.png';
import Rectangle22 from '../../../../../assets/images/Rectangle22.png';
import threedots from '../../../../../assets/images/threedots.png';

const ArraylistCPA = [
    {
        id:1,
        image0: threedots,
        image1: Courseimage,
        image2: Star,
        image3: Layer8,
        image4: Layer9,
        star:"4.5(120)",
        watch:"28,500",
        play:"36 Lesion",
        heading:"Statistic Data Science and Bussiness Analysis",
        image5: Rectangle22,
        Name:"Nicole Brown",
        price:"$99.99",
        dprice:"$49.65",
        
    },
    {
        id:2,
        image0: threedots,
        image1: Courseimage,
        image2: Star,
        image3: Layer8,
        image4: Layer9,
        star:"4.5(120)",
        watch:"28,500",
        play:"36 Lesion",
        heading:"Statistic Data Science and Bussiness Analysis",
        image5: Rectangle22,
        Name:"Nicole Brown",
        price:"$99.99",
        dprice:"$49.65",
    },
    {
        id:3,
        image0: threedots,
        image1: Courseimage,
        image2: Star,
        image3: Layer8,
        image4: Layer9,
        star:"4.5(120)",
        watch:"28,500",
        play:"36 Lesion",
        heading:"Statistic Data Science and Bussiness Analysis",
        image5: Rectangle22,
        Name:"Nicole Brown",
        price:"$99.99",
        dprice:"$49.65",
    },
    
]

export default ArraylistCPA;