import React from 'react'
import './StdInstructor.css'
import TeacherProfile from '../../../../../images/TeacherProfile.png'
import Star from '../../../../../images/Star.png';
import Layer8 from '../../../../../images/Layer8.png';
import Layer9 from '../../../../../images/Layer9.png';
import StdInstComponent from './StdInstComponent/StdInstComponent';

export default function StdInstructor () {

    const ArrayListInst = [
        {
            id: 1,
            image1: TeacherProfile,
            name: "Demetri Caron",
            image2: Star,
            image3: Layer8,
            image4: Layer9,
            star:"4.5 (4.5 (1,348 ratings)",
            watch:"271,658 Students",
            play:"28 Courses",
            info: "CBE brings you courses that are affordable, current, entertaining and based on practical work experience instead of theory.",
            title: "Adobe Certified Instructor & Adobe Certified Expert",
            description: "Effortless comfortable full leather lining eye-catching unique detail to the toe low ‘cut away’ sides clean and sleek. Polished finish elegant court shoe work duty stretchy mid kitten heel this ladylike design slingback strap mid kitten heel this ladylike design. Sharing is who I am, and teaching is where I am at my best, because I’ve been on both sides of that equation, and getting to deliver useful training is my meaningful way to be a part of the creative community.am at my best, because I’ve been on both sides of that equation, and getting to deliver useful training is my meaningful Thanks.",

            
        },
    ]

    // "arraylistarg" is a variable name which can holds Array current values and "i" is index
    const SICList = ArrayListInst.map( (arrayarg, i) => {
        return (

            

            <StdInstComponent key={i} 
            id="i" 
            image1={ArrayListInst[i].image1}
            name={ArrayListInst[i].name}
            image2={ArrayListInst[i].image2} 
            image3={ArrayListInst[i].image3} 
            image4={ArrayListInst[i].image4} 
            star={ArrayListInst[i].star}
            watch={ArrayListInst[i].watch}
            play={ArrayListInst[i].play}
            info={ArrayListInst[i].info}
            title={ArrayListInst[i].title}
            description={ArrayListInst[i].description}
            />
        )
    })

    return(
        <>
            <div className="container InstStdcontainer py-3 my-3">
                {SICList}

            </div>
        </>
    )

}