import React from 'react'
import './DoughntChart.css'

import { Doughnut } from 'react-chartjs-2'

import Cards2 from '../Cards2/Cards2'

const DoughntChart = (props) => {


    const data1 = {
        // labels: [
        //   'Active Students'
          
        // ],
        datasets: [{
        //   label: 'Active Students',
          data: [80 , 20],
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(246, 238, 255)',
            // 'rgb(255, 205, 86)'
          ],
          hoverOffset: 4
        }]
    };

    const options1 = {
        display: true,
        text: "80%",
    }

    const data2 = {
        // labels: [
        //   'Inactive Students'
          
        // ],
        datasets: [{
          label: 'Inactive Students',
          data: [20 , 80],
          backgroundColor: [
            'rgb(43, 192, 85)',
            'rgb(246, 238, 255)',
            // 'rgb(255, 205, 86)'
          ],
          hoverOffset: 4
        }]
    };
    
    const options2 = {
        display: true,
        text: "80%",
    }

    // const data3 = {
    //     labels: [
    //       'Teachers'
          
    //     ],
    //     datasets: [{
    //       label: 'teachers',
    //       data: [60 , 40],
    //       backgroundColor: [
    //         'rgb(9, 60, 189)',
    //         'rgb(246, 238, 255)',
    //         // 'rgb(255, 205, 86)'
    //       ],
    //       hoverOffset: 4
    //     }]
    // };

    //   'rgb(43, 192, 85)'

    // const chartArray = [
    //     {
    //         id: 1,
    //         d1: <Doughnut data={data1} options={options1} />,
    //     },
    //     {
    //         id: 2,
    //         d1: <Doughnut data={data2} options={options2} />,
    //     },
    // ]

    // const chartlist = chartArray.map(( arg, i ) => {
    //     return (
    //         <Cards2 key={i}
    //         id='i'
    //         d1={chartArray[i].d1}
    //          />
    //     )
    // })

    return (
        <>
            {/* {chartlist} */}
            <Doughnut data={data1} options={options1} />
            {/* <Doughnut data={data2} options={options2} /> */}
            {/* <Doughnut data={data3} /> */}
        </>
    )
}

export default DoughntChart
