import React from 'react'
import './LanguageTypeAPI.css'



import ReadLanguageType from './Components/readLanguageType'

const LanguageTypeAPI = () => {
    return (
        <>
            <div className="container cstm-one">
                <div>
                    <ReadLanguageType/>
                </div>
            </div>
            
        </>
    )
}

export default LanguageTypeAPI;
