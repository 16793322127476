
import Courseimage1 from '../../../../../../images/Courseimage1-min.png'
import Courseimage2 from '../../../../../../images/Courseimage2-min.png'
import Courseimage3 from '../../../../../../images/Courseimage3-min.png'

const VACourselist = [
    {
        id:1,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage1,
        category1: 'Design',
        category2: 'All',
        category3: 'Popular'

        
    },
    {
        id:2,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage2,
        category1: 'Business',
        category2: 'All',
        
    },
    {
        id:3,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage3,
        category1: 'Marketing',
        category2: 'All',
        
    },
    {
        id:4,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage1,
        category1: 'Photography',
        category2: 'All',
        
    },
    {
        id:5,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage2,
        category1: 'Music',
        category2: 'All',
        
    },
    {
        id:6,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage1,
        category1: 'Design',
        category2: 'All',     
    },
    {
        id:7,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage2,
        category1: 'Design',
        category2: 'All',       
    },
    {
        id:8,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage3,
        category1: 'Design',
        category2: 'All',        
    },
    {
        id:9,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage1,
        category1: 'Design',
        category2: 'All',
        
    },
    {
        id:10,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage2,
        category1: 'Design',
        category2: 'All',     
    },
    {
        id:11,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage1,
        category1: 'Design',
        category2: 'All',

        
    },
    {
        id:12,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage2,
        category1: 'Business',
        category2: 'All',

        
    },
    {
        id:13,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage3,
        category1: 'Marketing',
        category2: 'All', 

        
    },
    {
        id:14,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage1,
        category1: 'Music',
        category2: 'All',
        
    },
    {
        id:15,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage2,
        category1: 'Music',
        category2: 'All',
 
        
    },
    {
        id:16,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage1,
        category1: 'Design',
        category2: 'All',

        
    },
    {
        id:17,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage2,
        category1: 'Music',
        category2: 'All',

        
    },
    {
        id:18,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage3,
        category1: 'Marketing',
        category2: 'All', 

        
    },
    {
        id:19,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage1,
        category1: 'Photography',
        category2: 'All',
        
    },
    {
        id:20,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage2,
        category1: 'Music',
        category2: 'All',
 
        
    },
    {
        id:21,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage1,
        category1: 'Design',
        category2: 'All',

        
    },
    {
        id:22,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage2,
        category1: 'Business',
        category2: 'All',

        
    },
    {
        id:23,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage3,
        category1: 'Marketing',
        category2: 'All', 

        
    },
    {
        id:24,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage1,
        category1: 'Photography',
        category2: 'All',
        
    },
    {
        id:25,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage2,
        category1: 'Music',
        category2: 'All',
 
        
    },
    {
        id:26,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage1,
        category1: 'Design',
        category2: 'All',

        
    },
    {
        id:27,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage2,
        category1: 'Business',
        category2: 'All',

        
    },
    {
        id:28,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage3,
        category1: 'Marketing',
        category2: 'All', 

        
    },
    {
        id:29,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage1,
        category1: 'Photography',
        category2: 'All',
        
    },
    {
        id:30,
        name: "AWS Certified Solutions Architect Associate",
        src: Courseimage2,
        category1: 'Music',
        category2: 'All',
 
        
    },
    
];

export default VACourselist;