import './Curriculum.css'
import React from 'react'
import Clock from '../../../../../../images/clock.png'
import CuComponent from './CuComponent/CuComponent'

export default function Curriculum () {


    const ArraylistCurriculum = [
        {
            id: 1,
            title:"Everything You Need to Know Business",
            image: Clock,
            duration:"Duration 10 week",
        },
        {
            id: 2,
            title:"Everything You Need to Know Business",
            image: Clock,
            duration:"Duration 10 week",
        },
        {
            id: 3,
            title:"Everything You Need to Know Business",
            image: Clock,
            duration:"Duration 10 week",
        },
    ]

    const listCurriculum = ArraylistCurriculum.map((arg,i) => {
        return(
            <CuComponent key={i}
                id="i"
                title= {ArraylistCurriculum[i].title}
                image= {ArraylistCurriculum[i].image}
                duration= {ArraylistCurriculum[i].duration}
            />
        )
    })



    return (


        <>
            <div className="container Cu-container py-3">
                {listCurriculum}
            </div>
        </>

    )


}