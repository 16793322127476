import React from 'react'
import { Col, Row } from 'react-bootstrap'
import './Review.css'

export default function Review () {



    return (


        <>
            <div className="container Review-container py-3 my-3">
                <Row className="flex-column">
                    <div className="pb-3"><p className="stu-fb text-left px-3">Student feedback</p></div>
                    <div className="d-flex cstm-row1-div2">
                        <Col className="col-md-4">
                            <div className="d-flex flex-column cstm-star bg-white">
                                <h5 className="heading">4.6</h5>
                                <div className="rate d-flex justify-content-center align-items-center">
                                    <input type="radio" id="star5" name="rate" value="5"/>
                                    <label htmlFor="star5" title="text"></label>
                                    <input type="radio" id="star4" name="rate" value="4"/>
                                    <label htmlFor="star4" title="text"></label>
                                    <input type="radio" id="star3" name="rate" value="3"/>
                                    <label htmlFor="star3" title="text"></label>
                                    <input type="radio" id="star2" name="rate" value="2"/>
                                    <label htmlFor="star2" title="text"></label>
                                    <input type="radio" id="star1" name="rate" value="1"/>
                                    <label htmlFor="star1" title="text"></label>
                                </div>                            
                                <p className="text">Course Rating</p>
                            </div>
                        </Col>
                        <Col className="col-md-8 d-flex pl-5 pr-0 row1-div2-col2">
                            <div className="cstm-progress">
                                <div class="progress py-1">
                                    <div className="progress-bar" role="progressbar" style={{width: "100%"}} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div className="progress py-1">
                                    <div className="progress-bar" role="progressbar" style={{width: "75%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div className="progress py-1">
                                    <div className="progress-bar" role="progressbar" style={{width: "50%"}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div className="progress py-1">
                                    <div className="progress-bar" role="progressbar" style={{width: "25%"}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div className="progress py-1">
                                    <div className="progress-bar" role="progressbar" style={{width: "5%"}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div className="cstm-rate">
                                <div className="rate">
                                    <input type="radio" id="star10" name="rate" value="5"/>
                                    <label htmlFor="star5" title="text"></label>
                                    <input type="radio" id="star9" name="rate" value="4"/>
                                    <label htmlFor="star4" title="text"></label>
                                    <input type="radio" id="star8" name="rate" value="3"/>
                                    <label htmlFor="star3" title="text"></label>
                                    <input type="radio" id="star7" name="rate" value="2"/>
                                    <label htmlFor="star2" title="text"></label>
                                    <input type="radio" id="star6" name="rate" value="1"/>
                                    <label htmlFor="star1" title="text"></label>
                                </div>
                                <div className="rate">
                                    <input type="radio" id="star15" name="rate" value="5"/>
                                    <label htmlFor="star5" title="text"></label>
                                    <input type="radio" id="star14" name="rate" value="4"/>
                                    <label htmlFor="star4" title="text"></label>
                                    <input type="radio" id="star13" name="rate" value="3"/>
                                    <label htmlFor="star3" title="text"></label>
                                    <input type="radio" id="star12" name="rate" value="2"/>
                                    <label htmlFor="star2" title="text"></label>
                                    <input type="radio" id="star11" name="rate" value="1"/>
                                    <label htmlFor="star1" title="text"></label>
                                </div>
                                <div className="rate">
                                    <input type="radio" id="star20" name="rate" value="5"/>
                                    <label htmlFor="star5" title="text"></label>
                                    <input type="radio" id="star19" name="rate" value="4"/>
                                    <label htmlFor="star4" title="text"></label>
                                    <input type="radio" id="star18" name="rate" value="3"/>
                                    <label htmlFor="star3" title="text"></label>
                                    <input type="radio" id="star17" name="rate" value="2"/>
                                    <label htmlFor="star2" title="text"></label>
                                    <input type="radio" id="star16" name="rate" value="1"/>
                                    <label htmlFor="star1" title="text"></label>
                                </div>
                                <div className="rate">
                                    <input type="radio" id="star25" name="rate" value="5"/>
                                    <label htmlFor="star5" title="text"></label>
                                    <input type="radio" id="star24" name="rate" value="4"/>
                                    <label htmlFor="star4" title="text"></label>
                                    <input type="radio" id="star23" name="rate" value="3"/>
                                    <label htmlFor="star3" title="text"></label>
                                    <input type="radio" id="star22" name="rate" value="2"/>
                                    <label htmlFor="star2" title="text"></label>
                                    <input type="radio" id="star21" name="rate" value="1"/>
                                    <label htmlFor="star1" title="text"></label>
                                </div>
                                <div className="rate">
                                    <input type="radio" id="star30" name="rate" value="5"/>
                                    <label htmlFor="star5" title="text"></label>
                                    <input type="radio" id="star29" name="rate" value="4"/>
                                    <label htmlFor="star4" title="text"></label>
                                    <input type="radio" id="star28" name="rate" value="3"/>
                                    <label htmlFor="star3" title="text"></label>
                                    <input type="radio" id="star27" name="rate" value="2"/>
                                    <label htmlFor="star2" title="text"></label>
                                    <input type="radio" id="star26" name="rate" value="1"/>
                                    <label htmlFor="star1" title="text"></label>
                                </div>  
                            </div>
                        </Col>
                    </div>
                </Row>
                <Row>
                    <div className="d-flex py-2">
                        <Col className="col-md-2 col-sm-2 col-2">
                            <div>
                                <img style={{height:"60px", width:"60px", background:"#707070"}}></img>
                            </div>
                        </Col>
                        <Col className="col-md-10 col-sm-10 col-10">
                            <div className="d-flex justify-content-between pb-2">
                                <div>
                                    <h5>Wynton McCurdy</h5>
                                    <p>16 courses, 10 reviews</p>
                                </div>
                                <div>
                                    <div className="rate">
                                        <input type="radio" id="star35" name="rate" value="5"/>
                                        <label htmlFor="star5" title="text"></label>
                                        <input type="radio" id="star34" name="rate" value="4"/>
                                        <label htmlFor="star4" title="text"></label>
                                        <input type="radio" id="star33" name="rate" value="3"/>
                                        <label htmlFor="star3" title="text"></label>
                                        <input type="radio" id="star32" name="rate" value="2"/>
                                        <label htmlFor="star2" title="text"></label>
                                        <input type="radio" id="star31" name="rate" value="1"/>
                                        <label htmlFor="star1" title="text"></label>
                                    </div>
                                    <p>a year ago</p>
                                </div>
                            </div>
                            <div><p>Wow, I’ve learnt so much and it has already changed what and how I do things. 
                            I can not wait to start the next course.</p></div>
                        </Col>
                    </div>
                    <div className="d-flex py-2">
                        <Col className="col-md-2 col-sm-2 col-2">
                            <div>
                                <img style={{height:"60px", width:"60px", background:"#707070"}}></img>
                            </div>
                        </Col>
                        <Col className="col-md-10 col-sm-10 col-10">
                            <div className="d-flex justify-content-between pb-2">
                                <div>
                                    <h5>Wynton McCurdy</h5>
                                    <p>16 courses, 10 reviews</p>
                                </div>
                                <div>
                                    <div className="rate">
                                        <input type="radio" id="star40" name="rate" value="5"/>
                                        <label htmlFor="star5" title="text"></label>
                                        <input type="radio" id="star39" name="rate" value="4"/>
                                        <label htmlFor="star4" title="text"></label>
                                        <input type="radio" id="star38" name="rate" value="3"/>
                                        <label htmlFor="star3" title="text"></label>
                                        <input type="radio" id="star37" name="rate" value="2"/>
                                        <label htmlFor="star2" title="text"></label>
                                        <input type="radio" id="star36" name="rate" value="1"/>
                                        <label htmlFor="star1" title="text"></label>
                                    </div>
                                    <p>a year ago</p>
                                </div>
                            </div>
                            <div><p>Wow, I’ve learnt so much and it has already changed what and how I do things. 
                            I can not wait to start the next course.</p>
                            </div>
                        </Col>
                    </div>
                </Row>
            </div>
        </>



    )


}