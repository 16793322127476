import React from 'react'
import { Col } from 'react-bootstrap'
import History from './History/History'
import './StdWallet.css'
import Withdraw from './Withdraw/Withdraw'

export default function StdWallet () {


    return (

        <>
            <div className="container StdW-container px-0 bg-white">
                <div className="d-flex" style={{maxHeight:"105vh", height:"100%"}}>
                    <Col className="p-3 col-md-6">
                        <Withdraw/>
                    </Col>
                    <Col className="p-3 col-md-6">
                        <History/>
                    </Col>
                </div>
            </div>
        </>

    )

}