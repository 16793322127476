import {React , Component} from 'react';
import './OurMost.css';
import $ from 'jquery';
import { findDOMNode } from 'react-dom';
import Plus from '../../../../images/plus.png';
import Minus from '../../../../images/minus.png';

class OurMost extends Component {
    render () {
    return (
        <div className="container-fluid OurMost-containerfluid">
            <div className="container">
                
                <div className="row">
                    <div className="col-12">
                        <h3 className="cstm-h3">Our most frequently asked questions</h3>

                        <div class="panel-group cstm_fAq" id="accordionGroupOpen" role="tablist" aria-multiselectable="true">
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="Skillfy_Teams_plan">
                                <h4 class="panel-title">
                                    <a role="button" class="collapsed" data-toggle="collapse" data-parent="#accordionGroupOpen" href="#collapseOpenSkillfy_Teams_plan" aria-expanded="false" aria-controls="collapseOpenSkillfy_Teams_plan">
                                        Which Skillfy for Teams plan is right for me?
                                    </a>
                                </h4>
                                </div>
                                <div id="collapseOpenSkillfy_Teams_plan" class="panel-collapse collapse" role="tabpanel" aria-labelledby="Skillfy_Teams_plan">
                                <div class="panel-body">
                                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird
                                    on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table,
                                    raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                </div>
                                </div>
                            </div>
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="work_together">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordionGroupOpen" href="#collapseOpenWork_together" aria-expanded="false" aria-controls="collapseWork_together">
                                     Can we work together?
                                    </a>
                                </h4>
                                </div>
                                <div id="collapseOpenWork_together" class="panel-collapse collapse" role="tabpanel" aria-labelledby="work_together">
                                <div class="panel-body">
                                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird
                                    on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table,
                                    raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                </div>
                                </div>
                            </div>
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="addSeatsThroughout">
                                    <h4 class="panel-title">
                                        <a class="" role="button" data-toggle="collapse" data-parent="#accordionGroupOpen" href="#collapseOpenAddSeatsThroughout" aria-expanded="true" aria-controls="collapseAddSeatsThroughout">
                                        Can I remove or add seats throughout my contract? 
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseOpenAddSeatsThroughout" class="panel-collapse collapse show" role="tabpanel" aria-labelledby="addSeatsThroughout">
                                    <div class="panel-body">
                                    User access can be revoked only when a user leaves the organization. That user’s seat can then be 
                                    re-assigned to a new employee. You can add seats at any time throughout your contract and the cost 
                                    of any additional seats are prorated. User access can be revoked only when a user leaves the organization. 
                                    That user’s seat can then be re-assigned to a new employee. You can add seats at any time throughout your 
                                    contract and the cost of any additional seats are prorated. User access can be revoked only when a user 
                                    leaves the organization. That user’s seat can then be re-assigned to a new employee. 
                                    </div>
                                </div>
                            </div>
                            
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="bulk_pricing">
                                    <h4 class="panel-title">
                                        <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordionGroupOpen" href="#collapseOpenBulkPricing" aria-expanded="false" aria-controls="collapseThree">
                                            Do you offer bulk pricing? 
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseOpenBulkPricing" class="panel-collapse collapse" role="tabpanel" aria-labelledby="bulk_pricing">
                                    <div class="panel-body">
                                    User access can be revoked only when a user leaves the organization. That user’s seat can then be 
                                    re-assigned to a new employee. You can add seats at any time throughout your contract and the cost 
                                    of any additional seats are prorated. User access can be revoked only when a user leaves the organization. 
                                    That user’s seat can then be re-assigned to a new employee. You can add seats at any time throughout your 
                                    contract and the cost of any additional seats are prorated. User access can be revoked only when a user 
                                    leaves the organization. That user’s seat can then be re-assigned to a new employee. 
                                    </div>
                                </div>
                            </div>
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="Admin_seat_count">
                                    <h4 class="panel-title">
                                        <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordionGroupOpen" href="#collapseOpenAdminSeatCount" aria-expanded="false" aria-controls="collapseThree">
                                            Does the Team Admin seat count towards my plan’s seat count?  
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseOpenAdminSeatCount" class="panel-collapse collapse" role="tabpanel" aria-labelledby="Admin_seat_count">
                                    <div class="panel-body">
                                    User access can be revoked only when a user leaves the organization. That user’s seat can then be 
                                    re-assigned to a new employee. You can add seats at any time throughout your contract and the cost 
                                    of any additional seats are prorated. User access can be revoked only when a user leaves the organization. 
                                    That user’s seat can then be re-assigned to a new employee. You can add seats at any time throughout your 
                                    contract and the cost of any additional seats are prorated. User access can be revoked only when a user 
                                    leaves the organization. That user’s seat can then be re-assigned to a new employee. 
                                    </div>
                                </div>
                            </div>
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="nonProfits_and_schools">
                                    <h4 class="panel-title">
                                        <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordionGroupOpen" href="#collapseOpennonProfits_and_schools" aria-expanded="false" aria-controls="collapseThree">
                                            Do you work with non-profits and schools?  
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseOpennonProfits_and_schools" class="panel-collapse collapse" role="tabpanel" aria-labelledby="nonProfits_and_schools">
                                    <div class="panel-body">
                                    User access can be revoked only when a user leaves the organization. That user’s seat can then be 
                                    re-assigned to a new employee. You can add seats at any time throughout your contract and the cost 
                                    of any additional seats are prorated. User access can be revoked only when a user leaves the organization. 
                                    That user’s seat can then be re-assigned to a new employee. You can add seats at any time throughout your 
                                    contract and the cost of any additional seats are prorated. User access can be revoked only when a user 
                                    leaves the organization. That user’s seat can then be re-assigned to a new employee. 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
    }
}

export default OurMost;