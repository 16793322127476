import React from 'react';
import './InstructorVABanner.min.css';

import searchbtn from '../../../../../../images/searchbtn.png';

const InstructorVABanner = () => {
    return (
        <div className="container-fluid InstructorVA-Banner cstm-containerfluid p-5">
            <div className="container cstm-container">
                <div className="c1 col-md-4 col-sm-12 col-12">
                    <h3 className="heading welcome">Welcome</h3>
                    <p className="text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when 
                    an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                </div>
                <div className="c2 col-md-8 col-sm-12 col-12">
                    <h5 className="heading course">Instructors</h5>
                    <div className="input-group banner_search w-100">
                        <input type="text" className="form-control" placeholder="Search your favourite courses" />
                        <div className="input-group-append">
                            <button className="btn btn-danger btn-sm" type="submit"><img src={searchbtn} className="seacrh_icon_width"></img></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InstructorVABanner;