import React from 'react'
import './SkillAPI.css'



import ReadSkill from './Components/readSkill'

const SkillAPI = () => {
    return (
        <>
            <div className="container cstm-one">
                <div>
                    <ReadSkill/>
                </div>
            </div>
            
        </>
    )
}

export default SkillAPI;
