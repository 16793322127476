import React from 'react'
import './TableComponent.css'
import TableStructure from './TableStructure/TableStructure'
import checkbox from '../../../../../../../images/checkbox.png'
import profilePicture111 from '../../../../../../../images/profilePicture111.png'

const TableComponent = () => {


    const ArrayListTeacherTableComponent = [
        {
            id:1,
            image1: profilePicture111,
            name: "Dawud Khan",
            ID:" ID 0012345678",
            image2: checkbox,
            courses: "Courses",
            teachername: "Teacher Name",
            output: "+$5,553",
            
        },
        {
            id:2,
            image1: profilePicture111,
            name: "Dawud Khan",
            ID:" ID 0012345678",
            image2: checkbox,
            courses: "Courses",
            teachername: "Teacher Name",
            output: "+$5,553",
            
        },
        {
            id:3,
            image1: profilePicture111,
            name: "Dawud Khan",
            ID:" ID 0012345678",
            image2: checkbox,
            courses: "Courses",
            teachername: "Teacher Name",
            output: "+$5,553",
            
        },
        {
            id:4,
            image1: profilePicture111,
            name: "Dawud Khan",
            ID:" ID 0012345678",
            image2: checkbox,
            courses: "Courses",
            teachername: "Teacher Name",
            output: "+$5,553",
            
        },
        {
            id:5,
            image1: profilePicture111,
            name: "Dawud Khan",
            ID:" ID 0012345678",
            image2: checkbox,
            courses: "Courses",
            teachername: "Teacher Name",
            output: "+$5,553",
            
        },
        {
            id:6,
            image1: profilePicture111,
            name: "Dawud Khan",
            ID:" ID 0012345678",
            image2: checkbox,
            courses: "Courses",
            teachername: "Teacher Name",
            output: "+$5,553",
            
        },
     
    ]


    // "arraylistarg" is a variable name which can holds Array current values and "i" is index
    const TeacherTableList = ArrayListTeacherTableComponent.map( (arg, i) => {
        return (
            <TableStructure key={i} 
            id="i"
            ID={ArrayListTeacherTableComponent[i].ID}
            image1={ArrayListTeacherTableComponent[i].image1}
            image2={ArrayListTeacherTableComponent[i].image2}
            name={ArrayListTeacherTableComponent[i].name}
            courses={ArrayListTeacherTableComponent[i].courses}
            teachername={ArrayListTeacherTableComponent[i].teachername} 
            output={ArrayListTeacherTableComponent[i].output}
            />
        )
    })

    return (
        <>
            <div className="bg-white">
                <div className="Tablestructurefinance">
                    <table class="table tbl-style">
                        <tbody>
                            {TeacherTableList}
                        </tbody>
                    </table>
                </div>
            </div>
            
        </>
    )
}

export default TableComponent
