import react from 'react'
import { Col, Row } from 'react-bootstrap'
import './StdInstComponent.css'

export default function StdInstComponent (props) {


    return (

        <>
            <div className="Stdinstructor">
                <Row className="r1">
                    <Col className="col-md-3">
                        <div><img src={props.image1}></img></div>
                    </Col>
                    <Col className="col-md-9 r1-col2">
                        <div><h5 className="dfh5 name">{props.name}</h5></div>
                        <div className="d-flex justify-content-between mb-2">
                            <span className="d-flex cstm-span">
                                <img className="mr-1" src={props.image2} ></img>
                                <p className="dfp SWP">{props.star}</p>
                            </span>
                            <span className="d-flex cstm-span">
                                <img className="mr-1" src={props.image3}></img>
                                <p className="dfp SWP">{props.watch}</p>
                            </span>
                            <span className="d-flex cstm-span">
                                <img className="mr-1" src={props.image4} ></img>
                                <p className="dfp SWP">{props.play}</p>
                            </span>
                        </div>
                        <div><p className="dfp di text-justify">{props.info}</p></div>
                    </Col>
                </Row>
                <Row className="p-3">
                    <div><h5 className="dfh5 title">{props.title}</h5></div>
                    <div><p className="dfp di text-justify">{props.description}</p></div>
                </Row>
            </div>
        </>

    )

}