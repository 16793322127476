import React from 'react'
import './SFComponent.css'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import thumbsuporange from '../../../../../../images/thumbsuporange.png'
import thumbsdown2 from '../../../../../../images/thumbsdown2.png'

export default function SFComponent (props) {


    return (


        <>

            <div className="container bg-white my-3 py-3" style={{borderRadius:"10px"}}>
            
                {/* --------------------else feedback part------------------------------- */}
                <Row>
                    <Col className="col-md-1 col-sm-2 col-2">
                            <div>
                                <img style={{height:"60px", width:"60px", background:"#707070"}}></img>
                            </div>
                        </Col>
                        <Col className="col-md-11">
                            <div className="d-flex justify-content-between pb-2 pl-3">
                                <div>
                                    <h5>{props.title}</h5>
                                    <div className="d-flex">
                                        <div className="rate pl-0 align-items-center">
                                            <input type="radio" id="star35" name="rate" value="5"/>
                                            <label htmlFor="star5" title="text"></label>
                                            <input type="radio" id="star34" name="rate" value="4"/>
                                            <label htmlFor="star4" title="text"></label>
                                            <input type="radio" id="star33" name="rate" value="3"/>
                                            <label htmlFor="star3" title="text"></label>
                                            <input type="radio" id="star32" name="rate" value="2"/>
                                            <label htmlFor="star2" title="text"></label>
                                            <input type="radio" id="star31" name="rate" value="1"/>
                                            <label htmlFor="star1" title="text"></label>
                                        </div>
                                        <p>{props.text1}</p>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="mr-1">
                                        <img src={props.image1}></img>
                                        <img src={props.image2}></img>
                                    </div>
                                    <div><Link style={{fontSize:"14px", color:"#5F5982"}} to="#/">Report</Link></div>
                                    
                                </div>
                            </div>
                        </Col>
                </Row>
                <Row>
                    <Col className="col-md-11 col-sm-10 col-10">
                        <div className="d-flex">
                            <p style={{color:"#5F5982 !important", textAlign:"justify"}}>{props.text2}</p>
                        </div>
                    </Col>
                    <Col className="col-md-1 d-flex align-items-end">
                    </Col>
                </Row>
            </div>

        </>

    )

}