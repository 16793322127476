import React, {useState} from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import leftImg from '../assets/images/iMAGE.png'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
export default function SecondStep() {
    
    return (
        <>
            <div className="d-lg-flex half">
                <div className="bg">
                    <div className="inner_content">
                        <div className="logo_area">
                            <h1>Logo</h1>
                        </div>
                        <div className="img_area">
                            <img src={leftImg} className="img-fluid" />
                        </div>
                        <div className="branding_content">
                            <h1 className="branding_content_heading">Turn your ambition into a success story </h1>
                            <p className="branding_content_text">Choose from over 100,000 online video.</p>
                        </div>
                    </div>

                </div>
                
                <div className="contents">
                    <div className="container">
                        <div className="secondStepContainer">
                            <div>
                                <div className="mb-4">
                                    <h3 className="forgot_heading digit_code">Please enter the 4 digit code sent to you at example@mail.com</h3>
                                </div>
                                
                                <div id="enterCode">
                                    <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                                    <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                                    <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                                    <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                                    <div>
                                        <p className="sendText">Send Again</p>
                                    </div>
                                </div>
                                    
                                
                                <Link to="/PersonalDetail">Verify</Link>
                                {/* <a className="btn btn-block btn-success w-50 mx-auto">
                                        Verify
                                </a> */}
                            </div>
                            
                        </div>
                    </div>
                 </div>
            </div>
        </>
        
    )
}
