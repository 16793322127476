import {React , Component} from 'react';
import './TeacherView.css'
import Navbar from '../../components/Header/NavbarComponent/NavbarComponent';
import TVHeaderArea from './TVHeaderArea/TVHeaderArea';
import TeacherProfile from './TeacherProfile/TeacherProfile';
import Footer from '../../components/CustomFooter/CustomFooter';
import TeacherCourse from './TeacherCourse/TeacherCourse';
import TeacherReview from './TeacherReview/TeacherReview';
import MySchedule from './mySchedule/MySchedule';
import MyQuiz from './MyQuiz/MyQuiz';
import Assessment from './Assessment/Assessment';
import Wallet from './Wallet/Wallet';
import Lecture from './Lecture/Lecture';



class TeacherView extends Component {

    componentDidMount = () => {}

    render () {
        return (
            <>
                <Navbar/>
                <TVHeaderArea />
                {/* <div className="TV-cstm-bg"><TeacherProfile /></div> */}
                {/* <!-- Tab panes --> */}
                <div className="tab-content TV-cstmTab">
                    <div className="tab-pane active" id="tabs-1" role="tabpanel">
                        <TeacherProfile />
                    </div>
                    <div className="tab-pane" id="tabs-2" role="tabpanel">
                        <TeacherCourse />
                    </div>
                    <div className="tab-pane" id="tabs-3" role="tabpanel">
                        <TeacherReview />
                    </div>
                    <div className="tab-pane" id="tabs-4" role="tabpanel">
                        <MySchedule />
                    </div>
                    <div className="tab-pane" id="tabs-5" role="tabpanel">
                        <MyQuiz />
                    </div>
                    <div className="tab-pane" id="assessment" role="tabpanel">
                        <Assessment />
                    </div>
                    <div className="tab-pane" id="wallet" role="tabpanel">
                        <Wallet />
                        
                    </div>
                    <div className="tab-pane" id="lecture" role="tabpanel">
                        <Lecture />
                        
                    </div>
                </div>
                <Footer/>

                

                
            </>
        )
    }
}

export default TeacherView;

