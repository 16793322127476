import React from "react"
import { Row } from "react-bootstrap"
import Course from "../Course/Course"
import './StdFavCourse.css'

export default function StdFavCourse () {




    return (

        <>

            <div className="SFCcontainer px-3">
                <Row className="bg-white py-3 px-3">
                    <h5>Favourite Courses</h5>
                </Row>
                <Row>
                     <Course/>
                </Row>
            </div>

        </>

    )

}