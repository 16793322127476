import {React , Component} from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './SPVHeaderArea.css';
import Ellipse62 from '../../../images/Ellipse62.png';


class SPVHeaderArea extends Component {

    componentDidMount = () => {}

    render () {
        return (
            <div className="container-fluid SPVHA-containerfluid col-md-12">
                <div className="container SPVHA-container px-0">
                    <h3>StudentProfile</h3>
                    <section className="p-3 cstm-section col-md-12">
                        <Row className="SPVHA-cstm-section-R1">
                            <Col className="d-flex align-items-center">
                                <div>
                                    <img src={Ellipse62}></img>
                                </div>
                                <div className="name pl-4">
                                    <h5>Paul Walker</h5>
                                </div>
                            </Col>
                            <Col className="d-flex" style={{justifyContent:"space-evenly"}}>
                                <div>
                                    <button className="btn btnred button px-5">Lectures</button>
                                </div>
                                <div>
                                    <button className=" btn btngreen button px-5">Message</button>
                                </div>
                            </Col>
                        </Row>
                        <Row className="SPVHA-cstm-section-R2 col-md-6">
                            {/* <Link to='/#'><button>About</button></Link>
                            <Link to='/#'><button>Courses</button></Link>
                            <Link to='/#'><button>Review</button></Link> */}
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" data-toggle="tab" href="#studentprofile" role="tab">About</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#stdcourse" role="tab">Course</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#stdschedule" role="tab">Schedule</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#stdquiz" role="tab">Quiz</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#stdassessment" role="tab">Assessment</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#stdwallet" role="tab">Wallet</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#stdlecture" role="tab">Lecture</a>
                                </li>
                            </ul>
                        </Row>
                        
                    </section>
                </div>
            </div>
        )
    }
}

export default SPVHeaderArea;

