import React from 'react'
import { Col } from 'react-bootstrap'
import './EduandDealsComp.css'

export default function EduandDealsComp (props) {


    return (

        <>
            <div className="col-md-4 cstm-padding">
            <div className="Educard bg-white position-relative pt-3">
                <img className="backimg" src={props.backgroundimg}></img>
                <div className="cstmbody d-flex">
                    <Col className="col-md-6 d-flex flex-column position-relative">
                        <div className="d-flex flex-column align-items-start cstm-lineheight">
                            <div><p className="td textcolor">{props.text}</p></div>
                            <div><h5 className="title">{props.title}</h5></div>
                        </div>
                        <div className="disdiv">
                            <div><p className="td discolor">{props.discount}</p></div>
                            <div><h5 className="dvalue">{props.dvalue}</h5></div>
                        </div>
                    </Col>
                    <Col className="col-md-6">
                        <div><img className="sideimg"></img></div>
                    </Col>
                </div>
            </div>
            </div>
        </>

    )

}