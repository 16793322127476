import React from 'react'
import './Degree_LevelAPI.css'





import ReadDegree_Level from './Components/readDegree_Level'

const Degree_LevelAPI = () => {
    return (
        <>
            <div className="container cstm-one">
                <div>
                    <ReadDegree_Level/>
                </div>
            </div>
            
        </>
    )
}

export default Degree_LevelAPI;
