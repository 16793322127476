import React from 'react'
import leftImg from '../assets/images/iMAGE.png'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
export default function SelectPicture() {
    return (
      
            <>
            <div className="d-lg-flex half">
                <div className="bg">
                    <div className="inner_content">
                        <div className="logo_area">
                            <h1>Logo</h1>
                        </div>
                        <div className="img_area">
                            <img src={leftImg} className="img-fluid" />
                        </div>
                        <div className="branding_content">
                            <h1 className="branding_content_heading">Turn your ambition into a success story </h1>
                            <p className="branding_content_text">Choose from over 100,000 online video.</p>
                        </div>
                    </div>

                </div>
                
                    <div className="contents">
                    <div className="container">
                    
                        <div className="text-end w-75 my-4 dont_have_account"><a href="./login.html" type="submit" className="btn btn-success btn-sm">Log in</a></div>
                        <div className="row align-items-center content_container">
                            <div className="content_container_inner">
                                <div className="mb-4">
                                    <p className="mb-3">Log in with your data that you entered during Your registration.</p>
                                    <h2>Select Your Picture</h2>
                                </div>
                                <form action="#" method="post" className="form_style">
                                    <div className="avatar-upload">
                                        <div className="avatar-edit">
                                            <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" />
                                            <label htmlFor="imageUpload"></label>
                                        </div>
                                        <div className="avatar-preview">
                                            <div id="imagePreview">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="expert" className="mb-1">Expert</label>
                                        
                                        <input type="text" className="form-control" id="expert" placeholder="Creative coder and designer"/>
                                        
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="youtech" className="mb-1">What to You Teach</label>
                                        <input type="text" className="form-control" id="youtech" placeholder="What to You Teach"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="about" className="mb-1">About</label>
                                        <textarea className="form-control" rows="5" id="about" placeholder="text"></textarea>
                                    </div>

                                    
                                   
                                   
                                    <Link className="btn btn-block btn-success w-100 mb-1" to="/Regsuccess">Complete </Link>
                                    
                                    <div className="dont_have_account">
                                        <span>By signing up, you agree to our communications and usage
                                            terms Already have an account? <Link to="/Login">Sign in</Link></span>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}
