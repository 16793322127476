import React from 'react'
import './Panel.css'
import { Row, Col } from 'react-bootstrap'
import Ellipse62 from '../../../../../../images/Ellipse62.png'
import Summary from '../Summary/Summary'

const Panel = () => {
    return (
        <>
            <div className="container Panel bg-white py-3">
                <Row className="pb-3">
                    <div className="col d-flex flex-column align-items-center">
                        <div><img src={Ellipse62}></img></div>
                        <div className="py-2">
                            <div><h5 className="heading">Fanny Siregar</h5></div>
                            <div><p className="text">user@example.com</p></div>
                        </div>
                        <div><button className="btn button">Message</button></div>
                    </div>
                </Row>
                <Row>
                    <ul className="nav nav-tabs d-flex flex-column px-0 col" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link pl-5 active" data-toggle="tab" href="#summary" role="tab"><i className="fas fa-th-large pr-2"></i>Summary</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link pl-5" data-toggle="tab" href="#" role="tab"><i class="fas fa-bars pr-2"></i>Courses</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link pl-5" data-toggle="tab" href="#" role="tab"><i class="far fa-calendar-alt pr-2"></i>Schedule</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link pl-5" data-toggle="tab" href="#" role="tab"><i class="fas fa-file pr-2"></i>Assessment</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link pl-5" data-toggle="tab" href="#" role="tab"><i class="fas fa-scroll pr-2"></i>Quiz</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link pl-5" data-toggle="tab" href="#" role="tab"><i class="fas fa-user-friends pr-2"></i>Teachers</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link pl-5" data-toggle="tab" href="#" role="tab"><i class="fas fa-credit-card pr-2"></i>Payments</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link pl-5" data-toggle="tab" href="#" role="tab"><i class="fas fa-comment-alt pr-2"></i>Reviews</a>
                        </li>
                    </ul>
                </Row>
            </div>

            
            
        </>
    )
}

export default Panel
