import React from 'react'
import './StdSummary.css'

import Barchart from '../Barchart/Barchart'

import arrowatseven from '../../../../../../images/arrowatseven.png'

const StdSummary = () => {
    return (
        <>
            <div className="card StdSummaryContainer p-2 mb-3">
                <div className="card-body">
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <h5 className="heading">Student Summary</h5>
                            <h4 className="heading">4,563</h4>
                            <p className="text">+1.6% than last week</p>
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                            <img src={arrowatseven}></img>
                        </div>
                    </div>
                    <div className="Barchart-row pt-2">
                        <Barchart />
                    </div>
                </div>
            </div>
        </>
    )
}

export default StdSummary
