import React from 'react'
import './PermissionAPI.css'



import ReadPermission from './Components/readPermission'

const PermissionAPI = () => {
    return (
        <>
            <div className="container cstm-one">
                <div>
                    <ReadPermission/>
                </div>
            </div>
            
        </>
    )
}

export default PermissionAPI;
