import React from 'react'
import './Lectures.css'
import ellipse62 from '../../../../../images/Ellipse62.png'
import greentimer from '../../../../../images/greentimer.png'
import { Row } from 'react-bootstrap'
import CLecs from './CLecs/CLecs'

export default function Lectures () {

    const ArrayLectures = [
        {
            id:1,
            image: ellipse62,
            name: "Alan Walker",
            date: "25/05/2021",
            title: "Basic English",
            lecturenum: "3rd Lecture",
            text: "User access can be revoked only when a user leaves the organization. That user’s seat can then be re-assigned to a new employee. You can add seats at any time your the cost of any additional seats are prorated.",
            stopwatch: greentimer,
            timer: "01:05:36",
        },
        {
            id:2,
            image: ellipse62,
            name: "Alan Walker",
            date: "25/05/2021",
            title: "Basic English",
            lecturenum: "3rd Lecture",
            text: "User access can be revoked only when a user leaves the organization. That user’s seat can then be re-assigned to a new employee. You can add seats at any time your the cost of any additional seats are prorated.",
            stopwatch: greentimer,
            timer: "01:05:36",
        },
        {
            id:3,
            image: ellipse62,
            name: "Alan Walker",
            date: "25/05/2021",
            title: "Basic English",
            lecturenum: "3rd Lecture",
            text: "User access can be revoked only when a user leaves the organization. That user’s seat can then be re-assigned to a new employee. You can add seats at any time your the cost of any additional seats are prorated.",
            stopwatch: greentimer,
            timer: "01:05:36",
        },
        {
            id:4,
            image: ellipse62,
            name: "Alan Walker",
            date: "25/05/2021",
            title: "Basic English",
            lecturenum: "3rd Lecture",
            text: "User access can be revoked only when a user leaves the organization. That user’s seat can then be re-assigned to a new employee. You can add seats at any time your the cost of any additional seats are prorated.",
            stopwatch: greentimer,
            timer: "01:05:36",
        },
        {
            id:5,
            image: ellipse62,
            name: "Alan Walker",
            date: "25/05/2021",
            title: "Basic English",
            lecturenum: "3rd Lecture",
            text: "User access can be revoked only when a user leaves the organization. That user’s seat can then be re-assigned to a new employee. You can add seats at any time your the cost of any additional seats are prorated.",
            stopwatch: greentimer,
            timer: "01:05:36",
        },
    ]

    // "arraylistarg" is a variable name which can holds Array current values and "i" is index
    const lecsList = ArrayLectures.map( (arg, i) => {
        return (
            <CLecs key={i} 
            id="i" 
            image={ArrayLectures[i].image}
            name={ArrayLectures[i].name}
            date={ArrayLectures[i].date} 
            title={ArrayLectures[i].title} 
            lecturenum={ArrayLectures[i].lecturenum} 
            text={ArrayLectures[i].text}
            stopwatch={ArrayLectures[i].stopwatch}
            timer={ArrayLectures[i].timer}
            />
        )
    })

    return (

        <>
            <div className="container lecscontainer">
                <Row className="d-flex justify-content-center">
                    <div>
                        <h5 style={{fontSize:"22px",fontFamily:"Poppins",color:"#5F5982"}}>Students Today Lectures</h5>
                    </div>
                </Row>
                <Row>
                    {lecsList}
                </Row>
            </div>
        </>

    )

}