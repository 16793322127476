import React from 'react'
import './auths.css'
import leftImg from '../assets/images/iMAGE.png'
import successtick from '../images/successtick.png'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

const Regsuccess = () => {
    return (
        <>
            <div className="d-lg-flex half">
                <div className="bg">
                    <div className="inner_content">
                        <div className="logo_area">
                            <h1>Logo</h1>
                        </div>
                        <div className="img_area">
                            <img src={leftImg} className="img-fluid" />
                        </div>
                        <div className="branding_content">
                            <h1 className="branding_content_heading">Turn your ambition into a success story </h1>
                            <p className="branding_content_text">Choose from over 100,000 online video.</p>
                        </div>
                    </div>

                </div>
                
                <div className="contents">
                    <div className="container">
                        <div className="regsuccess">
                            <div>
                            <div className="text-end w-75 my-4 dont_have_account"><a href="./login.html" type="submit" className="btn btn-success btn-sm">Log in</a></div>
                        <div className="row align-items-center content_container d-flex flex-column ">
                            <img src={successtick}></img>
                            <p className="mt-1 successtext">Profile Successfully Create</p>
                            <Link to='/'>
                                <button className="btn button mt-5 px-5 py-2">Open Your Profile</button>
                            </Link>
                        </div>
                               
                            </div>
                            
                        </div>
                    </div>
                 </div>
            </div>
            
        </>
    )
}

export default Regsuccess
