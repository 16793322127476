import React from 'react'
import './CQuizes.css'
import {Button, Form, Modal, FormLabel} from 'react-bootstrap'

export default function CQuizes (props) {


    return (
        <>
            <div className="CQuizes-container">
                
                <div>
                    <table class="table table-hover">
                        {/* <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">First</th>
                            <th scope="col">Last</th>
                            <th scope="col">Handle</th>
                            </tr>
                        </thead> */}
                        <tbody>
                            <tr>
                            <th scope="row"><img src={props.Ellipse62}></img></th>
                            <td>{props.title}</td>
                            <td className="text-end"><img src={props.questionanswer}></img>{props.qa}</td>
                            <td><img src={props.timer}></img>{props.time}</td>
                            <td className="text-end">
                                <button className="btn button px-3">Start</button>
                            </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                {/* <Modal className="cstm-Modal">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <div className="row d-flex mb-3">
                                <div className="d-flex flex-column col-md-6">
                                    <img src={props.Ellipse62}></img>
                                    <div>
                                        <FormLabel className="CQMlabel" htmlFor="coursecategory">{props.title}</FormLabel>
                                        <select className="CQMfield" name="year" id="coursecategory" placeholder="Student id">
                                            <option value="1990">1990</option>
                                            <option value="1991">1991</option>
                                            <option value="1992">1992</option>
                                            <option value="1993">1993</option>
                                        </select>
                                    </div>
                                    <div>
                                        <FormLabel className="CQMlabel" htmlFor="coursecategory">Duration</FormLabel>
                                        <select className="CQMfield" name="year" id="coursecategory" placeholder="Student id">
                                            <option value="1990">1990</option>
                                            <option value="1991">1991</option>
                                            <option value="1992">1992</option>
                                            <option value="1993">1993</option>
                                        </select>
                                    </div>
                                    
                                </div>
                            </div>                                
                        </Form>
                    </Modal.Body>
                    
                </Modal> */}
                
            </div>
        </>
    )

}