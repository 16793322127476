import React from 'react'
import Lectures from './component/Lectures/Lectures'
import LecturesHistory from './component/LecturesHistory/LecturesHistory'
import './StdLecture.css'

export default function StdLecture () {

    return (

        <>
            <div className="container StdLeccontainer bg-white px-0">
                <div className="cstm-StdLectab">
                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item col-md-6 px-0">
                            <a className="text-center nav-link active " data-toggle="tab" href="#stdlectures" role="tab">Lectures</a>
                        </li>
                        <li className="nav-item col-md-6 px-0">
                            <a className="text-center nav-link" data-toggle="tab" href="#stdlectureshistory" role="tab">Lectures History</a>
                        </li>
                    </ul>
                    {/* <!-- Tab panes --> */}
                    <div className="tab-content mt-5">
                        <div className="tab-pane active" id="stdlectures" role="tabpanel">
                            <Lectures />
                            
                            

                        </div>
                        <div className="tab-pane" id="stdlectureshistory" role="tabpanel">
                            <LecturesHistory />
                        </div>
                    </div>
                </div>
            </div>
        </>

    )


}