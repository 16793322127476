import React from 'react'
import './CourseStudent.css'
import { Col, Row } from 'react-bootstrap';
import Star from '../../../images/Star.png';
import Layer8 from '../../../images/Layer8.png';
import Layer9 from '../../../images/Layer9.png';
import Duration from '../../../images/Duration.png';
import Book from '../../../images/book.png';
import TV from '../../../images/tv.png';
import Stopw from '../../../images/stopw.png';
import Window from '../../../images/window.png';
import Player from 'video-react/lib/components/Player';
import Instagram from '../../../images/Instagram.png';
import Facebook1 from '../../../images/facebook1.png';
import Whatsapp1 from '../../../images/whatsapp1.png';
import Twitter1 from '../../../images/twitter1.png';
import Linkedin1 from '../../../images/linkedin1.png';
import Youtube1 from '../../../images/youtube1.png';
import reddit from '../../../images/reddit.png';
import { Link } from 'react-router-dom';
import Overview from './Components/StdOverview/StdOverview';
import Curriculum from './Components/StdCurriculum/StdCurriculum';
import Review from './Components/StdReview/StdReview';
import Courseimage from '../../../assets/images/courseimage.png';
import Rectangle22 from '../../../assets/images/Rectangle22.png';
import threedots from '../../../assets/images/threedots.png';
import CSComponent from './Components/CSComponent';
import StdInstructor from './Components/StdInstructor/StdInstructor';
import EduandDeals from './Components/EduandDeals/EduandDeals';

export default function CourseStudent () {


    // ----------courses---------------------
    const ArraylistCS = [
        {
            id:1,
            image0: threedots,
            image1: Courseimage,
            image2: Star,
            image3: Layer8,
            image4: Layer9,
            star:"4.5(120)",
            watch:"28,500",
            play:"36 Lesion",
            heading:"Statistic Data Science and Bussiness Analysis",
            image5: Rectangle22,
            name:"Nicole Brown",
            price:"$99.99",
            dprice:"$49.65",
            
        },
        {
            id:2,
            image0: threedots,
            image1: Courseimage,
            image2: Star,
            image3: Layer8,
            image4: Layer9,
            star:"4.5(120)",
            watch:"28,500",
            play:"36 Lesion",
            heading:"Statistic Data Science and Bussiness Analysis",
            image5: Rectangle22,
            name:"Nicole Brown",
            price:"$99.99",
            dprice:"$49.65",
        },
        {
            id:3,
            image0: threedots,
            image1: Courseimage,
            image2: Star,
            image3: Layer8,
            image4: Layer9,
            star:"4.5(120)",
            watch:"28,500",
            play:"36 Lesion",
            heading:"Statistic Data Science and Bussiness Analysis",
            image5: Rectangle22,
            name:"Nicole Brown",
            price:"$99.99",
            dprice:"$49.65",
        },
        
    ]

    // "arraylistarg" is a variable name which can holds Array current values and "i" is index
    const CSList = ArraylistCS.map( (arrayarg, i) => {
        return (

            

            <CSComponent key={i} 
            id="i" 
            image0={ArraylistCS[i].image0}
            image1={ArraylistCS[i].image1}
            image2={ArraylistCS[i].image2} 
            image3={ArraylistCS[i].image3} 
            image4={ArraylistCS[i].image4} 
            star={ArraylistCS[i].star}
            watch={ArraylistCS[i].watch}
            play={ArraylistCS[i].play}
            heading={ArraylistCS[i].heading}
            image5={ArraylistCS[i].image5}
            name={ArraylistCS[i].name}
            price={ArraylistCS[i].price}
            dprice={ArraylistCS[i].dprice}
            />
        )
    })

    
    
    
    return (
// -----------
        <>
            <div>
            {/* --------course over view-------------------------- */}
            <div className="container-fluid bg-white">
                <div className="container CourseStdcontainer" style={{position:"relative", top:"-190px"}}>
                    <div className="col-md-12">
                        <div className="container">
                            <Row className="p-3 CourseStd-R1">
                                <h3 className="CourseStd-mytitle">Adobe Masterclass Photoshop, Illustrator, XD & InDesign</h3>
                                <div className="col-md-6 d-flex pl-0 py-2">
                                    <Col className="px-0">
                                        <div className="d-flex mb-2">
                                            <img src={Star}></img>
                                            <p className="my-white">4.5 (4.5 (1,348 ratings)</p>
                                        </div>
                                        <div className="d-flex">
                                            <img src={Duration}></img>
                                            <p className="my-white">Duration 10 week</p>
                                        </div>         
                                    </Col>
                                    <Col className="px-0">
                                        <div className="d-flex mb-2">
                                            <img src={Layer8}></img>
                                            <p className="my-white">Enrolled 45 students</p>
                                        </div>
                                        <div className="d-flex">
                                            <img src={Layer9}></img>
                                            <p className="my-white">36 Lesion</p>
                                        </div>    
                                    </Col>
                                </div>
                            </Row>
                            <Row>
                                <Col className="col-md-7">
                                    <div className="my-4">
                                        <Player
                                        playsInline
                                        poster="/assets/poster.png"
                                        src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                                        className="custom-video"
                                        />
                                    </div>
                                    <div className="CourseStd-cstmtab">
                                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item mr-2">
                                                <a className="nav-link active" data-toggle="tab" href="#CourseStd-overview" role="tab">Overview</a>
                                            </li>
                                            <li className="nav-item mr-2">
                                                <a className="nav-link" data-toggle="tab" href="#CourseStd-cirriculum" role="tab">Cirriculum</a>
                                            </li>
                                            <li className="nav-item mr-2">
                                                <a className="nav-link" data-toggle="tab" href="#CourseStd-instructor" role="tab">Instructor</a>
                                            </li>
                                            <li className="nav-item mr-2">
                                                <a className="nav-link" data-toggle="tab" href="#CourseStd-reviews" role="tab">Reviews</a>
                                            </li>
                                        </ul>
                                        {/* <!-- Tab panes --> */}
                                        <div className="tab-content">
                                            <div className="tab-pane active my-5" id="CourseStd-overview" role="tabpanel">
                                                <Overview />
                                            </div>
                                            <div className="tab-pane" id="CourseStd-cirriculum" role="tabpanel">
                                                <Curriculum />
                                            </div>
                                            <div className="tab-pane" id="CourseStd-instructor" role="tabpanel">
                                                <StdInstructor/>
                                            </div>
                                            <div className="tab-pane" id="CourseStd-reviews" role="tabpanel">
                                                
                                                <Review />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="CourseStd-mycard col-md-5">
                                    <div className="card my-4 px-4">
                                        <div className="card-header">
                                            <h4>$49.65</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">This course includes</div>
                                            <div className="card-text mb-1"><img src={Book}></img>Language - Englisha</div>
                                            <div className="card-text mb-1"><img src={TV}></img>Use on desktop, tablet & mobile</div>
                                            <div className="card-text mb-1"><img src={Stopw}></img>Full lifetime access</div>
                                            <div className="card-text mb-1"><img src={Window}></img>Certificate of Completion</div>
                                        </div>
                                        <div className="card-footer CourseStd-cstmcard-footer bg-white pr-0">
                                            <div className="card-title">Share this course</div>
                                            <Link className="mr-1" to="#/"><img src={Instagram}></img></Link>
                                            <Link className="mr-1" to="#/"><img src={Facebook1}></img></Link>
                                            <Link className="mr-1" to="#/"><img src={Whatsapp1}></img></Link>
                                            <Link className="mr-1" to="#/"><img src={Twitter1}></img></Link>
                                            <Link className="mr-1" to="#/"><img src={Linkedin1}></img></Link>
                                            <Link className="mr-1" to="#/"><img src={Youtube1}></img></Link>
                                            <Link className="mr-1" to="#/"><img src={reddit}></img></Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>

            {/* -----------------more courses you might like----------------------- */}

            <div className="container CrsStdCrdcontainer px-0">
                <div className="py-3">
                <div className="row py-4 cstm-r1 align-items-center">
                    <div className="col-md-9 my-4">
                        <h4 className="heading mb-0">More courses you might like</h4>
                    </div>
                    <div className="col-md-3 cstm-col2 d-flex justify-content-between align-items-center">
                    <div className="dropdown GetYourCourses-dropdown">
                        <button type="button" className="btn dropdown-toggle dropdownbtn" data-toggle="dropdown">
                            {/* Design */}
                        </button>
                        <div className="dropdown-menu">
                            <a className="dropdown-item" href="#">Link 1</a>
                            <a className="dropdown-item" href="#">Link 2</a>
                            <a className="dropdown-item" href="#">Link 3</a>
                        </div>
                    </div>
                    <button className="btn btn-danger btn-md px-4">View all</button>
                    </div>
                    
                    
                </div>
                    {/* CPA-R2  , CrsStdCrd*/}
                    <div className="row CrsStdCrd-R2 d-flex flex-wrap justify-content-between align-content-between">
                    
                    
                        {CSList}
                    
                        
                    </div>
                </div>
            </div>

            {/* --------------i belivev----------------- */}
            <div className="container-fluid bg-white py-5">
                <div className="container CrsStd-ibelieve-section-inner">
                <div className="row">
                        <div className="col-md-6">
                            <div className="CrsStd-ibelieve-section-inner-content">
                                <p className="cstm-p">I believe in lifelong learning and Skillfy  is a great place 
                                    to learn from experts. I’ve learned a lot and recommend it to all my friends.
                                </p>
                            </div>
                            <div className="CrsStd-IBelieve-author">
                                <p>Riaz Surti | Hearthy Foods</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <Player
                                playsInline
                                poster="/assets/poster.png"
                                src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                                className="custom-video"
                            />
                        </div>
                </div>
                </div>
            </div>

            {/* Top education offers and deals */}

            <EduandDeals/>

            </div>

        </>

    )

}