import React from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import Popper from 'popper.js';
import './InstructorsComponent.min.css';



const InstructorsComponent = (props) => {
    return (
        <div className="px-2 ">
            <div className="card InstructorsComponent-cstm-card w-100">
                <div className="card-body d-flex flex-column align-items-center InstructorsComponent-cstm-card-body">
                    <img className="rounded-circle cstm-image" src={props.src} alt="image"></img>
                    <h4 className="card-card-title cstm-heading cstm-hovertext">Jame Kong</h4>
                    <p className="card-text cstm-text cstm-hovertext">Creative art teacher</p>
                    <div className="rate d-flex justify-content-center align-items-center">
                        <input type="radio" id="star5" name="rate" value="5"/>
                        <label htmlFor="star5" title="text"></label>
                        <input type="radio" id="star4" name="rate" value="4"/>
                        <label htmlFor="star4" title="text"></label>
                        <input type="radio" id="star3" name="rate" value="3"/>
                        <label htmlFor="star3" title="text"></label>
                        <input type="radio" id="star2" name="rate" value="2"/>
                        <label htmlFor="star2" title="text"></label>
                        <input type="radio" id="star1" name="rate" value="1"/>
                        <label htmlFor="star1" title="text"></label>
                    </div>   
                    <button className="btn button">Reserve a live meeting</button>
                </div>
            </div>
        </div>
    )
}

export default InstructorsComponent;