import React, { useState, Component } from 'react'
import './auths.css'
import { Button, Form, Modal } from 'react-bootstrap'
import leftImg from '../assets/images/iMAGE.png'
import linkedin from '../assets/images/linkedin.png'
import facebook from '../assets/images/facebook.png'
import gOOGLE from '../assets/images/gOOGLE.png'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import Signup from './Signup.js'
export default function Login() {
    
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [showMe, setShows] = useState(false);
    const [hideForgot, showForgot] = useState(true);

    const resetPwd = () => {
        const emailVal = document.getElementById('getForgotEmail');
        if(emailVal.value !== ''){
            setShows(true);
            showForgot(false);
        }  
    }
    const [showVerify, setshowVerify] = useState(false);
    
    const verifyPwd = () => {
        
        const enterCode = document.getElementById('enterCode');
        if(enterCode.value !== ''){
            setshowVerify(true);
            setShows(false);
        }  
    }
    const [showpwdVerify, setpwdVerify] = useState(false);
    const createResetPwd = () => {
        
        const createPwd = document.getElementById('creatNewPassword');
        const createPwdRepeat = document.getElementById('creatNewPasswordRepeat');
        if(createPwd.value !== '' && createPwdRepeat.value !== ''){
            setpwdVerify(true);
            setshowVerify(false);
        }  
    }
    const backToLogin = () => {
      
        setShow(false)
        // const createPwd = document.getElementById('creatNewPassword');
        // const createPwdRepeat = document.getElementById('creatNewPasswordRepeat');
        // if(createPwd.value !== '' && createPwdRepeat.value !== ''){
        //     setpwdVerify(true);
        //     setshowVerify(false);
        // }  
    }
    

    return (
        <div className="d-lg-flex half">
            <div className="bg">
                <div className="inner_content">
                    <div className="logo_area">
                        <h1>Logo</h1>
                    </div>
                    <div className="img_area">
                        <img src={leftImg} className="img-fluid" />

                    </div>
                    <div className="branding_content">
                        <h1 className="branding_content_heading">Turn your ambition into a success story </h1>
                        <p className="branding_content_text">Choose from over 100,000 online video.</p>
                    </div>
                </div>
            </div>
            <div className="contents">
                <div className="container">
                    <div className="row align-items-center content_container">
                        <div className="content_container_inner">
                            <div className="mb-4">
                                <h2>Hello ! Welcome back.</h2>
                                <p className="login_descr">Log in with your data that you entered during Your registration.</p>
                            </div>

                            {/* React form */}
                            <Form className="form_style">
                                <div className="social-login">
                                    <a href="#" className="social_btn">
                                        <img src={linkedin} /><span className="">Sign with Linkedin</span>
                                    </a>
                                    <a href="#" className="social_btn">
                                        <img src={facebook} /><span className="">Sign with Facebook</span>
                                    </a>
                                    <a href="#" className="social_btn">
                                        <img src={gOOGLE} /><span className="">Sign with Google</span>

                                    </a>
                                </div>
                                <div className="separated_line">
                                    <p><span>OR</span></p>
                                </div>

                                <Form.Group controlId="formBasicEmail" className="first">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" placeholder="Exampl@email.com" />
                                    <Form.Text className="text-muted">
                                        We'll never share your email with anyone else.
                                        </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword" className="last mb-2">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" placeholder="Enter password" />
                                </Form.Group>

                                <div className="text-end mb-3"><a href="#" onClick={handleShow} className="forgot-pass">Forgot Password</a></div>
                                
                                <Link to="/" className="btn btn-block btn-success w-100 mb-1">Start Now!</Link>
                                <div className="dont_have_account">
                                    <span>Don’t have an account? <Link to="/Signup">Sign up</Link></span>
                                </div>
                            </Form>
                            <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false} >

                                <Modal.Body className="forgot_modal_body">
                                    <Form className="form_style">
                                        {
                                        
                                         hideForgot?<> <div className="mb-4">
                                                        <h3 className="forgot_heading">Forgot password</h3>
                                                        <p className="forgot_descri">Please enter your email or phone number then we will help you create a new password</p>
                                                    </div>

                                                    <Form.Group controlId="getForgotEmail" className="first mb-3">

                                                        <Form.Control type="text" placeholder="ida_dennis2412@ex" />

                                                    </Form.Group>
                                                    <Button className="btn btn-block btn-success w-50 mx-auto" onClick={resetPwd}>
                                                            Reset Password
                                                    </Button></>: null
                                        }
                                       
                                        {
                                            showMe? <> <div className="mb-4">
                                            <h3 className="forgot_heading digit_code">Please enter the 4 digit code sent to you at example@mail.com</h3>
                                        </div>
                                       
                                            <div id="enterCode">
                                                <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                                                <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                                                <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                                                <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                                                <div>
                                                <p className="sendText">Send Again</p>
                                            </div>
                                            </div>
                                            
                                       
                                       
                                        <Button className="btn btn-block btn-success w-50 mx-auto" onClick={verifyPwd}>
                                                Verify
                                        </Button></>: null
                                        }
                                        {
                                           showVerify?<> <div className="mb-4">
                                           <h3 className="forgot_heading">Create new password</h3>
                                           <p className="forgot_descri">Please enter your new password and confirm</p>
                                       </div>

                                       <Form.Group controlId="creatNewPassword" className="first mb-3">

                                           <Form.Control type="password" placeholder="Confirm password" />

                                       </Form.Group>

                                       <Form.Group controlId="creatNewPasswordRepeat" className="first mb-3">

                                           <Form.Control type="password" placeholder="Confirm password" />

                                       </Form.Group>
                                       <Button className="btn btn-block btn-success w-50 mx-auto" onClick={createResetPwd}>
                                               Reset Password
                                       </Button></>:null 
                                        }
                                        {
                                            showpwdVerify?<>
                                            
                                              
                                              
                                                <div className="mb-3">
                                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                                    <circle class="path circle" fill="#42c142" stroke="#00AC00" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                                                    <polyline class="path check" fill="none" stroke="#fff" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                                                    </svg>
                                                </div>
                                                

                                                <h3 className="forgot_heading mb-5">Password Successfully Change</h3>
                                                
                                                <Button className="btn btn-block btn-success w-50 mx-auto" onClick={backToLogin}>
                                                    Login
                                                </Button>
                                            
                                            </>:null
                                        }
                                        
                                    </Form>

                                </Modal.Body>

                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
