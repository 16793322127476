import React from 'react'
import { Col, Row } from 'react-bootstrap'
import './CartItem.css'
import Rec22 from '../../../../images/Rec22.png'
import Close from '../../../../images/Close.png'
import CIComponent from './Component/CIComponent'
import Player from 'video-react/lib/components/Player'

export default function CartItem () {


    const ArraylistStdCartItem = [
        {
            id: 1,
            title:"Statistics Data Science and Business Analysis",
            profile: Rec22,
            name:"Nicole Brown",
            price: "$49.65",
            aprice: "$99.99",
            dvalue: "50% off",
            close: Close,
        },
        {
            id: 2,
            title:"Statistics Data Science and Business Analysis",
            profile: Rec22,
            name:"Nicole Brown",
            price: "$49.65",
            aprice: "$99.99",
            dvalue: "50% off",
            close: Close,
        },
        {
            id: 3,
            title:"Statistics Data Science and Business Analysis",
            profile: Rec22,
            name:"Nicole Brown",
            price: "$49.65",
            aprice: "$99.99",
            dvalue: "50% off",
            close: Close,
        },
        
    ]

    const listStdCartItem = ArraylistStdCartItem.map((arg,i) => {
        return(
            <CIComponent key={i}
                id="i"
                title= {ArraylistStdCartItem[i].title}
                profile= {ArraylistStdCartItem[i].profile}
                name= {ArraylistStdCartItem[i].name}
                price= {ArraylistStdCartItem[i].price}
                aprice= {ArraylistStdCartItem[i].aprice}
                dvalue= {ArraylistStdCartItem[i].dvalue}
                close= {ArraylistStdCartItem[i].close}
            />
        )
    })


    


    return (


        <>
            <div className="container StdCI-container py-3">
            {listStdCartItem}
            </div>
        </>

    )

}