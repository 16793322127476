import React  from 'react'
import './TableComponent.css'
import TableStructure from './TableStructure/TableStructure'
import icoption from '../../../../../images/icoption.png'
import profilePicture111 from '../../../../../images/profilePicture111.png'
import email1 from '../../../../../images/email1.png'

const TableComponent = () => {


    // const [useState, setUseState] = useState(false);
    
    // const addRowHandlers = () => {
        
    //     var table = document.getElementById("stdProfileTable");
    //     var rows = table.getElementsByTagName("tr");
    //     for (var i = 0; i < rows.length; i++) {
    //       var currentRow = table.rows[i];
    //       var createClickHandler = function(row) {
    //         return function() {
    //           var cell = row.getElementsByTagName("td")[0];
    //           var id = cell.innerHTML;
    //           alert("id:" + id);
    //         };
    //       };
    //       currentRow.onclick = createClickHandler(currentRow);
    //     }
    //     setUseState(!useState)
    //   }



    const ArrayListTeacherTableComponent = [
        {
            id:1,
            ID:"#001234",
            datejoin:"June 1, 2020, 08:22 AM",
            image:profilePicture111,
            name:"Fanny Siregar",
            teachername:"Mr. Johnson",
            contact: email1,
            currentcourse:"English",
            icoption: icoption,
            style: {
                background: "#391995",
                borderRadius: "15px",
                color: "white",
                textAlign: "center",
            }
        },
        {
            id:2,
            ID:"#001234",
            datejoin:"June 1, 2020, 08:22 AM",
            image:profilePicture111,
            name:"Fanny Siregar",
            teachername:"Mr. Johnson",
            contact: email1,
            currentcourse:"English",
            icoption: icoption,
            style: {
                background: "#FFAA2A",
                borderRadius: "15px",
                color: "white",
                textAlign: "center",
            }
        },
        {
            id:3,
            ID:"#001234",
            datejoin:"June 1, 2020, 08:22 AM",
            image:profilePicture111,
            name:"Fanny Siregar",
            teachername:"Mr. Johnson",
            contact: email1,
            currentcourse:"English",
            icoption: icoption,
            style: {
                background: "#1EAE7A",
                borderRadius: "15px",
                color: "white",
                textAlign: "center",
            }
        },
        {
            id:4,
            ID:"#001234",
            datejoin:"June 1, 2020, 08:22 AM",
            image:profilePicture111,
            name:"Fanny Siregar",
            teachername:"Mr. Johnson",
            contact: email1,
            currentcourse:"English",
            icoption: icoption,
            style: {
                background: "#391995",
                borderRadius: "15px",
                color: "white",
                textAlign: "center",
            }
        },
        {
            id:5,
            ID:"#001234",
            datejoin:"June 1, 2020, 08:22 AM",
            image:profilePicture111,
            name:"Fanny Siregar",
            teachername:"Mr. Johnson",
            contact: email1,
            currentcourse:"English",
            icoption: icoption,
            style: {
                background: "#FFAA2A",
                borderRadius: "15px",
                color: "white",
                textAlign: "center",
            }
        },
        {
            id:6,
            ID:"#001234",
            datejoin:"June 1, 2020, 08:22 AM",
            image:profilePicture111,
            name:"Fanny Siregar",
            teachername:"Mr. Johnson",
            contact: email1,
            currentcourse:"English",
            icoption: icoption,
            style: {
                background: "#1EAE7A",
                borderRadius: "15px",
                color: "white",
                textAlign: "center",
            }
        },
        {
            id:7,
            ID:"#001234",
            datejoin:"June 1, 2020, 08:22 AM",
            image:profilePicture111,
            name:"Fanny Siregar",
            teachername:"Mr. Johnson",
            contact: email1,
            currentcourse:"English",
            icoption: icoption,
            style: {
                background: "#391995",
                borderRadius: "15px",
                color: "white",
                textAlign: "center",
            }
        },
        {
            id:8,
            ID:"#001234",
            datejoin:"June 1, 2020, 08:22 AM",
            image:profilePicture111,
            name:"Fanny Siregar",
            teachername:"Mr. Johnson",
            contact: email1,
            currentcourse:"English",
            icoption: icoption,
            style: {
                background: "#FFAA2A",
                borderRadius: "15px",
                color: "white",
                textAlign: "center",
            }
        },
        {
            id:9,
            ID:"#001234",
            datejoin:"June 1, 2020, 08:22 AM",
            image:profilePicture111,
            name:"Fanny Siregar",
            teachername:"Mr. Johnson",
            contact: email1,
            currentcourse:"English",
            icoption: icoption,
            style: {
                background: "#FFAA2A",
                borderRadius: "15px",
                color: "white",
                textAlign: "center",
            }
        },
        {
            id:10,
            ID:"#001234",
            datejoin:"June 1, 2020, 08:22 AM",
            image:profilePicture111,
            name:"Fanny Siregar",
            teachername:"Mr. Johnson",
            contact: email1,
            currentcourse:"English",
            icoption: icoption,
            style: {
                background: "#FFAA2A",
                borderRadius: "15px",
                color: "white",
                textAlign: "center",
            }

        },
    ]


    // "arraylistarg" is a variable name which can holds Array current values and "i" is index
    const TeacherTableList = ArrayListTeacherTableComponent.map( (arg, i) => {
        return (
            <TableStructure key={i} 
            id="i"
            ID={ArrayListTeacherTableComponent[i].ID}
            datejoin={ArrayListTeacherTableComponent[i].datejoin}
            image={ArrayListTeacherTableComponent[i].image} 
            name={ArrayListTeacherTableComponent[i].name} 
            teachername={ArrayListTeacherTableComponent[i].teachername} 
            contact={ArrayListTeacherTableComponent[i].contact}
            currentcourse={ArrayListTeacherTableComponent[i].currentcourse}
            style={ArrayListTeacherTableComponent[i].style}
            icoption={ArrayListTeacherTableComponent[i].icoption}
            />
        )
    })

    return (
        <>
            <div className="col-md-12">
                <div className="Tablestructure">
                    <table id="stdProfileTable" class="table tbl-style">
                        <thead>
                            <tr>
                            <th scope="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                    <label class="form-check-label tableheading" htmlFor="flexCheckDefault">
                                        ID
                                    </label>
                                </div>
                            </th>
                            <th className="tableheading" scope="col">Date Join</th>
                            <th className="tableheading" scope="col">Name</th>
                            <th className="tableheading" scope="col">Teacher Name</th>
                            <th className="tableheading" scope="col">Contact</th>
                            <th className="tableheading" scope="col">Current Courses</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                                {TeacherTableList}
                            
                            
                        </tbody>
                    </table>
                </div>
            </div>
            
        </>
    )
}

export default TableComponent
