import logo from './logo.svg';
import React from 'react';
import './App.css';
import "./components/FontawesomeIcons/index"
import {Route, Router, Switch, Redirect} from 'react-router-dom';
import HomePage from './components/Home';

import Home from './components/Home';
import AboutUs from './components/AboutUs/AboutUs';
import ContactUs from './components/ContactUs/ContactUs';
import FAQ from './components/FAQ/FAQ';
import TermsAndConditions from './components/Terms&Conditions/Terms&Conditions';
import history from './History';
import TeacherView from './dashboard/TeacherView/TeacherView';
import Login from './auths/Login'
import Signup from './auths/Signup'
import SecondStep from './auths/SecondStep'
import PersonalDetail from './auths/PersonalDetail'
import EducationalDetail from './auths/EducationalDetail'
import SelectPicture from './auths/SelectPicture'
import StudentView from './dashboard/Student View/StudentView';
import StdProView from './dashboard/StudentProfileView/StdProView';
import Files from './dashboard/StudentProfileView/StdAssessment/Component/Files/Files';
import ADBoard from './dashboard/Admin Dashboard/ADBoard';
import DBstdprofilev1 from './dashboard/Admin Dashboard/components/DBstdprofilev1/DBstdprofilev1';
import DBprofileapproval from './dashboard/Admin Dashboard/components/DBprofileapproval/DBprofileapproval'
import CourseStudent from './dashboard/Student View/CourseStudent/CourseStudent';
import axios from 'axios';
import StdCourse from './dashboard/StudentProfileView/StdCourse/StdCourse';
import Cartpage from './dashboard/Student View/Cartpage/Cartpage';
import Country from './dashboard/Admin Dashboard/Country/countryAPI';
import Degree from './dashboard/Admin Dashboard/Degree/DegreeAPI';
import UpdateDegreeLevel from './dashboard/Admin Dashboard/Degree_Level/Components/updateDegree_Level';
import Regsuccess from './auths/Regsuccess';
import ViewAll from './components/HomePageComponents/GetYourCourses/ViewAll/ViewAll';
import InstructorViewAll from './components/HomePageComponents/Instructors/InstructorViewAll/InstructorViewAll';
import dataTablesexmp from './dashboard/dataTablesexmp';
import BankTypeAPI from './dashboard/Admin Dashboard/BankType/bankTypeAPI';
import City from './dashboard/Admin Dashboard/City/cityAPI';
import CourseSubType from './dashboard/Admin Dashboard/CourseSubType/CourseSubTypeAPI';
import CourseType from './dashboard/Admin Dashboard/CourseType/CourseTypeAPI';
import LanguageType from './dashboard/Admin Dashboard/LanguageType/LanguageTypeAPI';
import Language from './dashboard/Admin Dashboard/Language/LanguageAPI';
import WeatherForecast from './dashboard/Admin Dashboard/WeatherForecast/WeatherForecastAPI';
import feeType from './dashboard/Admin Dashboard/feeType/feeTypeAPI';
import quizType from './dashboard/Admin Dashboard/quizType/quizTypeAPI';
import ticketType from './dashboard/Admin Dashboard/ticketType/ticketTypeAPI';
import FeeTypeExtra from './dashboard/Admin Dashboard/feeTypeExtra/feeTypeExtra';
import Final from './dashboard/Admin Dashboard/feeTypeExtra/components/final';

// import { response } from 'express';

// const express = require("express")
// const app = express()
// const cors = require("cors")
// app.use(
//     cors({
//         origin: "*",
//         // methods: ["GET", "POST", "DELETE", "PUT", "OPTIONS"] ,
//         credentials: true
//     })
// )

// app.listen(3000)

// const api = axios.create({
//   baseURL: 'http://zsktech-001-site1.ctempurl.com/api/'
// })
function App(props) {
  return (
    <>
      <Router history={history}>
            <Switch>
                <Route path='/' exact component={HomePage}/>
                {/* <Route exact path='/Home1' component={Home}/> */}
                <Route exact path='/aboutus' component={AboutUs}/>
                <Route exact path='/contactus' component={ContactUs}/>
                <Route exact path='/FAQ' component={FAQ}/>
                <Route exact path='/termsandconditions' component={TermsAndConditions}/>
                <Route exact path='/TeacherView' component={TeacherView}/>
                <Route exact path='/studentview' component={StudentView}/>
                <Route exact path='/CourseStudent' component={CourseStudent}/>
                <Route exact path='/stdproview' component={StdProView}/>
                <Route exact path='/StdCourse' component={StdCourse}/>
                <Route exact path='/Cartpage' component={Cartpage}/>
                <Route exact path='/adboard' component={ADBoard}/>
                <Route path="/files" component={Files}/>
                <Route path="/dbstdprofile" component={DBstdprofilev1}/>
                <Route path="/DBprofileapproval" component={DBprofileapproval}/>
                <Route path="/Regsuccess" component={Regsuccess}/>
                <Route path="/bankTypeAPI" component={BankTypeAPI}/>
                <Route path="/Country" component={Country}/>
                <Route path="/Degree" component={Degree}/>
                <Route path="/City" component={City}/>
                <Route path="/feetype" component={feeType}/>
                <Route path="/quiztype" component={quizType}/>
                <Route path="/tickettype" component={ticketType}/>
                <Route path="/CourseSubType" component={CourseSubType}/>
                <Route path="/CourseType" component={CourseType} />
                <Route path="/LanguageType" component={LanguageType} />
                <Route path="/Language" component={Language} />
                <Route path="/WeatherForecast" component={WeatherForecast} />
                <Route path="/ViewAllCourses" component={ViewAll}/>
                <Route path="/InstructorViewAll" component={InstructorViewAll}/>
                <Route path="/dataTablesexmp" component={dataTablesexmp}/>
                <Route path="/FeeTypeExtra" component={FeeTypeExtra} />
                <Route path="/Final" component={Final} />
                <Route path="/UpdateDegreeLevel" component={UpdateDegreeLevel} />
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/signup">
                  <Signup />
                </Route>
                <Route path="/secondstep">
                  <SecondStep />
                </Route>
                <Route path="/personalDetail">
                  <PersonalDetail />
                </Route>
                <Route path="/educationaldetail">
                  <EducationalDetail />
                </Route>
                <Route path="/selectpicture">
                  <SelectPicture />
                </Route>
            </Switch>
        </Router>


        
    </>
  );
}

export default App;
