import React from 'react'
import './TableStructure.css'

const TableStructure = (props) => {
    return (
        <>
            <tr className="d-flex">
                            <td className="d-flex align-items-center" scope="row">
                                <img className="mr-2" src={props.image}></img>{props.ID}
                            </td>
                            <td className="tabletext d-flex align-items-center">{props.time}</td>
                            <td className="tabletext d-flex align-items-center">{props.output}</td>
                            <td className=" tabletext d-flex align-items-center">
                                <button className="btn button">Completed</button>
                            </td>
                            </tr>
            
        </>
    )
}

export default TableStructure;
