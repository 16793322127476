import React from 'react'
import './CourseTypeAPI.css'



import ReadCourseType from './Components/readCourseType'

const CourseTypeAPI = () => {
    return (
        <>
            <div className="container cstm-one">
                <div>
                    <ReadCourseType/>
                </div>
            </div>
            
        </>
    )
}

export default CourseTypeAPI;
