import React from 'react'
import CFiles from './Component/CFiles'
import './Files.css'

export default function Files () {


    return (

        <>
            <div className="container StdAFiles-container px-0 bg-white">
                <div className="cstm-StdAFilestab">
                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item col-md-12 px-0">
                            <a className="text-center nav-link active " data-toggle="tab" href="#files" role="tab">Files Names</a>
                        </li>
                    </ul>
                    {/* <!-- Tab panes --> */}
                    <div className="tab-content mt-5">
                        <div className="tab-pane active" id="files" role="tabpanel">
                            <CFiles />
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}