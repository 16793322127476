import React from 'react';
import { Link } from 'react-router-dom';
import './CComponent.css';
// import Courseimage from '../../../../../assets/images/courseimage.png';
// import Star from '../../../../../images/Star.png';
// import Layer8 from '../../../../../images/Layer8.png';
// import Layer9 from '../../../../../images/Layer9.png';

export default function CComponent (props) {



    return (
        <>
        <div className="col-md-4 col-sm-6">
        <div className="card my-3 CC-card">
            <div className="card-head">
                    <div className="dropdown cstm-CC-dropdown">
                        <Link to="#/"><img className="dropdown-toggle" aria-haspopup="true" data-toggle="dropdown" src={props.image0}></img></Link>
                        <span className="dropdown-menu custom-CC-dropdownmenu">
                            <a className="dropdown-item" href="#">Edit</a>
                            <a className="dropdown-item" href="#">Delete</a>
                        </span>
                    </div>
            </div>
                
                
                <div className="card-body CC-card-body">
                <div className="CC-img"><img className="" src={props.image1} alt="image"></img></div>
                <div className="CC-SWP">
                    <span >
                        <img src={props.image2} ></img>
                        <p>{props.star}</p>
                    </span>
                    <span >
                        <img src={props.image3}></img>
                        <p>{props.watch}</p>
                    </span>
                    <span >
                        <img src={props.image4} ></img>
                        <p>{props.play}</p>
                    </span>
                </div>
                    <h4 className="card-card-title">{props.heading}</h4>
                    <div>
                        <div>
                            <span><img src={props.image5}></img></span>
                            <span ><p>{props.name}</p></span>
                        </div>
                        <div>
                            <span ><p>{props.price}</p></span>
                            <span ><p>{props.dprice}</p></span>
                        </div>
                        
                        
                    </div>
                </div>
            </div>
        </div>
           
        </>
    )

}