import React, { useState } from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import Popper from 'popper.js';
import '../WeWillHelp/WeWillHelp.min.css';
import reservemeeting from "../../../images/reservemeeting.png"
import certificates from "../../../images/certificates.png"
import wewillhelpIMAGE from "../../../images/wewillhelpIMAGE.png"
import playbutton from "../../../images/playbutton.png"

// import Jello from 'react-reveal/Jello';
import { Modal } from 'react-bootstrap';
import Player from 'video-react/lib/components/Player';
import { Link } from 'react-router-dom';

const WeWillHelp = () => {

    const [modalState, setModalState] = useState(false);

    const manageState = () => {
        setModalState(!modalState)
    }


    return (
        <>

        <div className="container-fluid WeWillHelp-container-fluid p-5">
            <div className="container cstm-container">
                <div className="row WeWillHelp-container-row-1">
                    <div className="col-12">
                    <h4 className="mb-5 helpUsHeading">We will help you learn what you are passionates about</h4>
                    </div>
                    
                    <div className="WeWillHelp-container-row1-column1 col-md-5">
                        <img className="cstm-hide" src={wewillhelpIMAGE}></img>
                    </div>
                    <div className="col-md-7 WeWillHelp-container-row1-column2">
                        <div class="progress-steps">
                            <div class="">
                            <h1 className="h1-cstm">
                                <span class="number">01.</span>
                                <span>Go at your own pace</span>
                                
                            </h1>
                            <p className="steps-description">It's a phase to check in to ensure that you are still on the way 
                            to heading towards your ambition. And if you feel like you are on the offshoots, then maybe that's 
                            correct where you need to be for now. </p>
                            </div>
                            <div class="">
                            <h1 className="h1-cstm">
                                <span class="number">02.</span>
                                <span>Learn from industry experts</span>
                                
                            </h1>
                            <p className="steps-description">Here at Tutor.bizsoftech, we’ve shaped career education programs 
                            designed to open up global prospects for your career and your life expectancy!  Students get a 
                            firm academic foundation in our programs via our curriculum; but in toting, they get to learn 
                            from our industry experts. </p>
                            </div>
                            <div class="active">
                            <h1 className="h1-cstm">
                                <span class="number">03.</span>
                                <span>Find video courses on almost any topic</span>
                                
                            </h1>
                            <p className="steps-description">At our site “tutor bizsoftech, you can get videos related to 
                            courses on almost any topic to build your career, with limitless learning and more possibilities. </p>
                            
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row WeWillHelp-container-row-2 ">
                    <div className="WeWillHelp-container-2 p-3">
                        {/* <Jello> */}
                            <div>
                                <img className="blob white cstm-play-button" src={playbutton} onClick={() => manageState()}></img>
                            </div>
                            <h1 className="h4-custom pt-5">It's time to start investing in yourself in learning</h1>
                        {/* </Jello> */}
                        
                    </div>
                    
                </div>
                <div className="row WeWillHelp-container-row-3 d-flex pt-5">
                    <div className="col-md-6 col-sm-12 col-12">
                            <Link to='#'><img className="cstm-img" src={reservemeeting}></img></Link>
                        </div>
                        <div className="col-md-6 col-sm-12 col-12">
                            <Link to='#'><img  className="cstm-img"src={certificates}></img></Link>
                        </div>
                    
                    
                </div>
            </div>
        </div>

        <Modal className="DBStdProfile-Modal" show={modalState} onHide={() => manageState()}>
              {/* <Modal.Header closeButton ></Modal.Header> */}
              <Modal.Body style={{padding: "0px"}}>
                    {/* <div className="col-md-6"> */}
                        <Player
                            playsInline
                            poster="/assets/poster.png"
                            src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                            className="custom-video"
                        />
                    {/* </div> */}
              </Modal.Body>


            </Modal>

        </>
    )
}

export default WeWillHelp;