import React from 'react'
import './CSComponent.css'
import { Link } from 'react-router-dom';

export default function CSComponent (props) {


    return (

        <>
            <div className="col-md-4 col-sm-12 col-12">
                <div className="card my-3 CSC-card">
                {/* extra  */}
                    {/* <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" data-interval="100000">
			        	<div class="w-100 carousel-inner mb-5" role="listbox">
			          		<div class="carousel-item active"> */}
                                  {/* card body start */}

                                  {/* <div className="card-body CSC-card-body">
                        <div className="CSC-img"><img className="" src={props.image1} alt="image"></img></div>
                        <div className="CSC-SWP">
                            <span >
                                <img src={props.image2} ></img>
                                <p>{props.star}</p>
                            </span>
                            <span >
                                <img src={props.image3}></img>
                                <p>{props.watch}</p>
                            </span>
                            <span >
                                <img src={props.image4} ></img>
                                <p>{props.play}</p>
                            </span>
                        </div>
                        <h4 className="card-card-title">{props.heading}</h4>
                        <div>
                            <div>
                                <span><img src={props.image5}></img></span>
                                <span ><p>{props.name}</p></span>
                            </div>
                            <div>
                                <span ><p>{props.price}</p></span>
                                <span ><p>{props.dprice}</p></span>
                            </div>
                                
                                
                        </div>
                    </div> */}

                                  {/* crd body end */}
                            {/* </div>
                        </div> */}
                    {/* buttons nxt prew */}
                        {/* <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
				          <span class="carousel-control-prev-icon" aria-hidden="true"><i class="fas fa-chevron-left"></i></span>
				          <span class="sr-only">Previous</span>
				        </a>
				        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
				          <span class="carousel-control-next-icon" aria-hidden="true"><i class="fas fa-chevron-right"></i></span>
				          <span class="sr-only">Next</span>
				        </a>
                    </div> */}
                {/*  extra */}
                        
                        
                    <div className="card-body CSC-card-body">
                        <div className="CSC-img"><img className="" src={props.image1} alt="image"></img></div>
                        <div className="CSC-SWP">
                            <span >
                                <img src={props.image2} ></img>
                                <p>{props.star}</p>
                            </span>
                            <span >
                                <img src={props.image3}></img>
                                <p>{props.watch}</p>
                            </span>
                            <span >
                                <img src={props.image4} ></img>
                                <p>{props.play}</p>
                            </span>
                        </div>
                        <h4 className="title">{props.heading}</h4>
                        <div>
                            <div>
                                <span><img src={props.image5}></img></span>
                                <span ><p className="name">{props.name}</p></span>
                            </div>
                            <div>
                                <span ><p className="price">{props.price}</p></span>
                                <span ><p className="dprice">{props.dprice}</p></span>
                            </div>
                                
                                
                        </div>
                    </div>
                </div>
            </div>
        </>

    )


}