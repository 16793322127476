import React, { useState, useEffect } from 'react'
import { Button, Checkbox, Form } from 'semantic-ui-react'
// import { useHistory } from 'react-router';

import axios from 'axios';

const UpdateCity = () => {

    
    
    const [countryId, setCountryId] = useState('');
    const [name, setName] = useState('');
    const [county, setCounty] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [areaCode, setAreaCode] = useState('');
    const [cityId, setCityId] = useState(null);

    let wrapper = React.createRef();

    useEffect(() => {
            // setID(localStorage.getItem('ID'))
            // setFirstName(localStorage.getItem('First Name'));
            // setLastName(localStorage.getItem('Last Name'));
            // setCheckBox(localStorage.getItem('Checkbox Value'))
            setCityId(localStorage.getItem('ID'))
            setCountryId(localStorage.getItem('Country Id'));
            setName(localStorage.getItem('Name'));
            setCounty(localStorage.getItem('County'));
            setZipCode(localStorage.getItem('Zip Code'));
            setAreaCode(localStorage.getItem('Area Code'));
            
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(e)
    }

    // let history = useHistory();

    const updateAPIData = () => {
        axios.put(`http://zsktech1-001-site1.ftempurl.com/api/City/${cityId}`, {
            countryId,
            name,
            county,
            zipCode,
            areaCode,
            
        })
        // .then(() => {
        //     // history.push('/read')
            
        // })
    }
    
    // const postData = () => {
    //     console.log(firstName);
    //     console.log(lastName);
    //     console.log(checkBox);

    //     axios.post(`https://616078fdfaa03600179fbaa7.mockapi.io/fakeData`, {
    //         firstName,
    //         lastName,
    //         checkBox
    //     })


    // }


    return (
        <>
            <Form className="create-form" onSubmit={handleSubmit}>
                <Form.Field>
                    <label>Country Id</label>
                    <input placeholder='Country Id'
                        ref={wrapper}
                        name="countryId"
                        value={countryId}
                        onChange={(e) => setCountryId(e.target.value)}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Name</label>
                    <input placeholder='Name'
                        ref={wrapper}
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Country</label>
                    <input placeholder='Country' 
                        ref={wrapper}
                        name="county"
                        value={county}
                        onChange={(e) => setCounty(e.target.value)}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Zip Code</label>
                    <input placeholder='Zip Code' 
                        ref={wrapper}
                        name="zipCode"
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Area Code</label>
                    <input placeholder='Area Code' 
                        ref={wrapper}
                        name="areaCode"
                        value={areaCode}
                        onChange={(e) => setAreaCode(e.target.value)}
                    />
                </Form.Field>
                {/* <Form.Field>
                <Checkbox 
                    label='I agree to the Terms and Conditions'
                    onChange={(e) => setCheckBox(!checkBox)}
                    checked={checkBox} 
                />
                </Form.Field> */}
                <Button className="btn cstm-Updatebtn" type='submit' onClick={updateAPIData}>Update</Button>
            </Form>
        </>
    )
}

export default UpdateCity;