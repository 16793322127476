import React from 'react'
import './CPAComponent.css'
import Star from '../../../../../../images/Star.png'
import Layer8 from '../../../../../../images/Layer8.png';
import Layer9 from '../../../../../../images/Layer9.png';
import Courseimage from '../../../../../../assets/images/courseimage.png';
import { Link } from 'react-router-dom';

export default function CPAComponent (props) {


    return (

        <>
            <div className="px-2">
            <Link to="/CourseStudent">
                <div className="card my-3 CPAC-card">
                        
                        
                    <div className="card-body CPAC-card-body">
                        <div className="CPAC-img"><img className="" src={props.image1} alt="image"></img></div>
                        <div className="CPAC-SWP">
                            <span >
                                <img src={props.image2} ></img>
                                <p className="cstm-hovertext">{props.star}</p>
                            </span>
                            <span >
                                <img src={props.image3}></img>
                                <p className="cstm-hovertext">{props.watch}</p>
                            </span>
                            <span >
                                <img src={props.image4} ></img>
                                <p className="cstm-hovertext">{props.play}</p>
                            </span>
                        </div>
                        <h4 className="card-card-title cstm-hovertext">{props.heading}</h4>
                        <div>
                            <div>
                                <span><img src={props.image5}></img></span>
                                <span ><p>{props.name}</p></span>
                            </div>
                            <div>
                                <span ><p>{props.price}</p></span>
                                <span ><p>{props.dprice}</p></span>
                            </div>
                                
                                
                        </div>
                    </div>
                </div>
            </Link>
            </div>
        </>

    )


}