import React from 'react'
import { Row } from 'react-bootstrap'
import './CartTotal.css'

export default function CartTotal () {


    return (

        <>
            <div className="card CTcontainer">
                <div className="card-body p-5" style={{lineHeight:"4"}}>
                    <Row>
                        <div><h5 className="tp">Cart Total</h5></div>
                    </Row>
                    <Row className="d-flex justify-content-between align-items-center">
                        <div><p className="tp">$49.65</p></div>
                        <div className="d-flex">
                            <span><p className="APDV" style={{textDecoration:"line-through"}}>$99.99</p></span>
                            <span><p className="APDV">50% off</p></span>
                        </div>
                    </Row>
                    <Row>
                        <button className="btn button col-md-12">Check Out</button>
                    </Row>
                    <Row className="d-flex justify-content-between">
                        <div>
                            <input className="input" type="text"></input>
                        </div>
                        <div>
                            <button className="btn button">Apply</button>
                        </div>
                    </Row>
                </div>
            </div>
        </>

    )

}