import React from 'react'
import leftImg from '../assets/images/iMAGE.png'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
export default function EducationalDetail() {
    return (
        <div>
            <>
            <div className="d-lg-flex half">
                <div className="bg">
                    <div className="inner_content">
                        <div className="logo_area">
                            <h1>Logo</h1>
                        </div>
                        <div className="img_area">
                            <img src={leftImg} className="img-fluid" />
                        </div>
                        <div className="branding_content">
                            <h1 className="branding_content_heading">Turn your ambition into a success story </h1>
                            <p className="branding_content_text">Choose from over 100,000 online video.</p>
                        </div>
                    </div>

                </div>
                
                    <div className="contents">
                    <div className="container">
                    
                        <div className="text-end w-75 my-4 dont_have_account"><a href="./login.html" type="submit" className="btn btn-success btn-sm">Log in</a></div>
                        <div className="row align-items-center content_container">
                            <div className="content_container_inner">
                                <div className="mb-4">
                                    <h5 className="mb-3">Log in with your data that you entered during Your registration.</h5>
                                    <h2>Educational</h2>
                                </div>
                                <form action="#" method="post" className="form_style">
                                    
                                    <div className="form-group">
                                        <label htmlFor="fullname" className="mb-1">Degree</label>
                                        <select className="form-control h-56">
                                            <option>Select your degree</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="fullname" className="mb-1">Institue</label>
                                        <select className="form-control h-56">
                                            <option>Select your Institue</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                    </div>

                                      

                                    
                                    <div className="d-flex">
                                        <div className="form-group mr-2">
                                            <label htmlFor="fullname" className="mb-1">Education level</label>
                                                <select className="form-control h-56">
                                                    <option>Graduation</option>
                                                    <option>Jun</option>
                                                    <option>Feb</option>
                                                    <option>Mar</option>
                                                    <option>Apr</option>
                                                    <option>may</option>
                                                </select>
                                        </div>

                                        <div className="form-group mx-2">
                                            <label htmlFor="name" className="mb-1">Year</label>
                                            <select className="form-control h-56">
                                                <option>1990</option>
                                                <option>1991</option>
                                                <option>1992</option>
                                                <option>1993</option>
                                                    
                                            </select>
                                        </div>
                                      

                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="skills" className="mb-1">Skills</label>
                                        <input type="text" className="form-control" id="skills" placeholder="Skills"/>
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <div className="form-group w-75 mr-2">
                                            <label htmlFor="fullname" className="mb-1">Experience</label>
                                            <select className="form-control h-56">
                                                <option>Graduation</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>
                                        <div className="form-group w-25 ml-2">
                                            <label htmlFor="fullname" className="mb-1">Years</label>
                                            <select className="form-control h-56">
                                                <option>1990</option>
                                                <option>1992</option>
                                                <option>1993</option>
                                                <option>1994</option>
                                                <option>1995</option>
                                            </select>
                                        </div>

                                       

                                    </div>
                                   
                                    <div className="form-group">
                                        <label htmlFor="others" className="mb-1">Other</label>
                                        
                                            <input type="text" className="form-control" id="others" placeholder="Select your degree"/>
                                        
                                    </div>
                                    <Link className="btn btn-block btn-success w-100 mb-1" to="/selectpicture">Next Step </Link>
                                    
                                    <div className="dont_have_account">
                                        <span>By signing up, you agree to our communications and usage
                                            terms Already have an account? <Link to="/Login">Sign in</Link></span>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
        </div>
    )
}
