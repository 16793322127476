import React from 'react'
import { Col, Row } from 'react-bootstrap'
import './StdAssessment.css'
import CAssessment from './Component/CAssessment'

export default function StdAssessment () {


    return (


        <>
            
            <div className="container StdAssessment-container px-0 bg-white">
                <div className="cstm-StdAssessmenttab">
                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item col-md-12 px-0">
                            <a className="text-center nav-link active " data-toggle="tab" href="#tabs-10" role="tab">Assessment</a>
                        </li>
                    </ul>
                    {/* <!-- Tab panes --> */}
                    <div className="tab-content mt-5">
                        <div className="tab-pane active" id="tabs-10" role="tabpanel">
                            <CAssessment />
                        </div>
                    </div>
                </div>
            </div>


        </>

    )


}

