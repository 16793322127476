import React, {useState} from 'react'
import { Col, Row } from 'react-bootstrap'
import Quizes from './Components/Quizes/Quizes'
import QuizResult from './Components/QuizResult/QuizResult'
import './MyQuiz.css'
import SubjQuiz from './Components/Quizes/SubjQuiz/SubjQuiz'
import CQuizes from './Components/Quizes/Component/CQuizes'

export default function MyQuiz (props) {

    
    // const receiveView = () => {
    //     return (
    //     <>
    //         <buton className="btn btn-primary" onClick={() => setCurrentComponent("SubjQuiz")}>subjquizes</buton>
    //         </>
    //         )
    // }
    // const view = () => {
    //     // setCurrentComponent("SubjQuiz")
    //     setCurrentComponent(<SubjQuiz/>)
    // }
    const [currentComponent, setCurrentComponent] = useState("Quizes");

    // const showButtonDiv = () => {
    //     return (
    //         <div>
    //             <buton className="btn btn-primary" onClick={() => setCurrentComponent("SubjQuiz")}>subjquizes</buton>
    //             {/* <CQuizes show={showButtonDiv}/> */}
    //         </div>
    //     )
    // }

    // <CQuizes data={showButtonDiv}/>
    

    



    return (


        <>
            
            <div className="cstm-myQuiz-padding">
                <div className="container MQuiz-container px-0">
                    <div className="cstm-Quiztab bg-white">
                        <ul className="nav nav-tabs" role="tablist">
                            {/* <li className="nav-item col-md-6 px-0">
                                <a className="text-center nav-link active " data-toggle="tab" 
                                href="#Quizes" role="tab">
                                Quizes
                                </a>
                            </li>
                            <li className="nav-item col-md-6 px-0">
                                <a className="text-center nav-link" data-toggle="tab" href="#tabs-11" role="tab">Quiz Result</a>
                            </li> */}
                            <li><buton className="btn btn-danger" onClick={() => setCurrentComponent("Quizes")}>quizes</buton></li>
                            {/* <li><buton className="btn btn-primary" onClick={() => setCurrentComponent("SubjQuiz")}>subjquizes</buton></li> */}
                            <li><buton className="btn btn-success" onClick={() => setCurrentComponent("QuizResutl")}>subjquizes</buton></li>
                        </ul>
                        {/* <!-- Tab panes --> */}
                        <div className="tab-content mt-5">
                            <div>
                                {currentComponent == "Quizes" && <Quizes />}
                                {/* {currentComponent == "SubjQuiz" && <SubjQuiz/>} */}
                                {/* <CQuizes showFunc={() => view} /> */}
                                {/* {props.data} */}
                                {currentComponent == "QuizResutl" && <QuizResult/>}
                            </div>
                            {/* <div className="tab-pane active " id="Quizes" role="tabpanel">
                                    <Quizes />
                                </div> */}
                        
                            {/* <div className="tab-pane" id="tabs-11" role="tabpanel">
                                <QuizResult />
                            </div> */}
                            {/* <CQuizes showFunc={() => view} /> */}
                        </div>
                        {/* <div className="tab-content TV-cstmTab">
                            <div className="tab-pane active" id="subjQuiz" role="tabpanel">
                                {/* <SubjQuiz/> */}
                                {/* {currentComponent == "subjQuiz" && <SubjQuiz/>};
                            </div>
                        </div>  */}
                        {/* <CQuizes data={showButtonDiv}/> */}
                        
                    </div>
                </div>
            </div>


        </>

    )


}

